import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../images/lobo.png'


function HeaderInicio() {
    const [active, setActive] = useState(false);
    const handleClick = () => {
        setActive(!active);
    };
    const [mostrarComponente, setMostrarComponente] = useState(false)

    ////menu

    const [isMenuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleClick1 = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleMouseEnter = () => {
        setMenuOpen(true);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);


    return (
        <header id='MenuHorizontal' className='rj-header'>
            <div className='Logo-header-rj'>
                <Link to="/" style={{ textDecoration: 'none' }} >
                    <h1 className="gradient-text" id='logo_css_header'
                        onClick={() => window.scrollTo({ top: 0 })}>
                        Cariñosas Hub
                    </h1>
                </Link>
            </div>

            <div className="container-header-p">
                <button id='btn_mostrar_menu_link' onClick={() => setMostrarComponente(!mostrarComponente)}>
                    {mostrarComponente ? <i class="fa-solid fa-x"></i> : <i class="fa-solid fa-bars"></i>}
                </button>
                <div className={mostrarComponente ? "show-element" : null}>
                    {mostrarComponente &&
                        <nav className='nav_paginas'>
                            <div className='div_nav_pafinas' onClick={() => setMostrarComponente(!mostrarComponente)}>
                                <Link to="/publicar-anuncios-kinesiologas">
                                    <i class="fa-solid fa-earth-americas" style={{ marginRight: '5px' }}></i>Publicar Anuncio
                                </Link>
                            </div>
                            <div className='div_nav_pafinas' onClick={() => setMostrarComponente(!mostrarComponente)}>
                                <Link to="/Politica-privacidad" >
                                    <i class="fa-solid fa-book" style={{ marginRight: '5px' }}></i>Politica
                                </Link>
                            </div>
                            <div className='div_nav_pafinas' onClick={() => setMostrarComponente(!mostrarComponente)}>
                                <Link to="/contactanos">
                                    <i class="fa-solid fa-user-tie" style={{ marginRight: '5px' }}></i>Contacto
                                </Link>
                            </div>
                            <div className='div_nav_pafinas' onClick={() => setMostrarComponente(!mostrarComponente)}>
                                <Link to="/terminos-condiciones">
                                    <i class="fa-solid fa-handshake-simple"></i>Terminos y condiciones
                                </Link>
                            </div>
                        </nav>}
                </div>
                <div >
                    <i class="fa-solid fa-bars" id='navMenu'
                        onClick={handleClick1}
                        onMouseEnter={handleMouseEnter}
                        ref={menuRef}
                    ></i>
                    {isMenuOpen && (
                        <div className='menu_opcion'>
                            <Link to="/publicar-anuncios-kinesiologas" id='publicar_auncios_btn'>
                                <i class="fa-solid fa-dumpster"></i> Publicar Anuncio
                            </Link>
                            <Link to="/Politica-privacidad" >
                                <i class="fa-solid fa-address-book"></i> Politica
                            </Link>
                            <Link to="/contactanos">
                                <i class="fa-solid fa-address-card"></i> Contacto
                            </Link>
                            <Link to="/terminos-condiciones">
                                <i class="fa-regular fa-address-book"></i> Terminos
                            </Link>
                        </div>
                    )}
                </div>
                <div className='icons-headerP_inicio'>
                    <div className='icons-headerP'>
                        <div className='icons-headerP_btn'>
                            <div className='icons-header-p'>
                                <Link className="mapas" to="/">
                                    <i class="fa-solid fa-house"></i>
                                </Link>
                            </div>
                            <small>Home</small>
                        </div>
                        <div className='icons-headerP_btn'>
                            <div className='icons-header-p'>
                                <Link className="mapas" to={'/mapaubicacion'} >
                                    <i class="fa-solid fa-location-dot"></i>
                                </Link>
                            </div>
                            <small>Ubicación</small>
                        </div>
                        <div className='icons-headerP_btn' >
                            <div className='icons-header-p'>
                                <Link className="redsocial" to={'/publicaciones-red-social-kines'}>
                                    <i class="fa-regular fa-heart"></i>
                                </Link>
                            </div>
                            <small>Red-social</small>
                        </div>
                        <div className='icons-headerP_btn'>
                            <div className='icons-header-p'>
                                <Link className="cardhot" to="/red-social-kines">
                                    <i class="fa-solid fa-circle-info"></i>
                                </Link>
                            </div>
                            <small>Info</small>

                        </div>
                        <div class="dropup-center dropup">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-solid fa-user"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/publicar-anuncios-kinesiologas">
                                        <a class="dropdown-item" href="#">Publicar Anuncios</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/register">
                                        <a class="dropdown-item" href="#">Registrarse</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/login">
                                        <a class="dropdown-item" href="#">Iniciar sesion</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='botones-principal'>
                    <Link to="/register">
                        <button className='btn_header_logre register'>
                            <i class="fa-solid fa-users"></i>Regístrate
                        </button>
                    </Link>
                    <Link to="/login">
                        <button className='btn_header_logre'>
                            <i class="fa-solid fa-lock-open"></i>Iniciar
                        </button>
                    </Link>
                </div>
            </div>
        </header>
    )
}

export default HeaderInicio