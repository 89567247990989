import { GLOBALTYPES } from './globalTypes'
import { imageUploadP } from '../../utils/imageUpload'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'

export const PUBLIC_TYPES = {
    CREATE_PUBLIC: 'CREATE_PUBLIC',
    LOADING_PUBLIC: 'LOADING_PUBLIC',
    GET_PUBLICS: 'GET_PUBLICS',
    UPDATE_PUBLIC: 'UPDATE_PUBLIC',
    GET_PUBLIC: 'GET_PUBLIC',
    DELETE_PUBLIC: 'DELETE_PUBLIC'
}

export const createPublic = ({
    nombre, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura, titulo, phora, pmediahora, ppack, pvideollamada,
    horario, telefono, dcorta, pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot, lugar1,
    lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6, servicio7, servicio8, especial1, especial2,
    especial3, especial4, especial5, especial6, especial7, especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader,
    content, images, categoria, auth, socket
}) => async (dispatch) => {

    let media = []
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (images.length > 0) media = await imageUploadP(images)

        const res = await postDataAPI('publics', {
            nombre, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura, titulo, phora, pmediahora, ppack, pvideollamada, horario, telefono, dcorta,
            pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot, lugar1, lugar2, lugar3, lugar4, servicio1, servicio2, servicio3,
            servicio4, servicio5, servicio6, servicio7, servicio8, especial1, especial2, especial3, especial4, especial5, especial6, especial7, especial8, dgustos,
            dservicio, dlugares, descripcionfi, iconBanader, categoria, content, images: media
        }, auth.token)

        dispatch({
            type: PUBLIC_TYPES.CREATE_PUBLIC,
            payload: { ...res.data.newPublic, user: auth.user }
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

        // Notify
        const msg = {
            id: res.data.newPublic._id,
            text: 'agregó nuevo anuncio.',
            recipients: res.data.newPublic.user.followers,
            url: `/publicidad/${res.data.newPublic._id}`,
            content,
            image: media[0].url
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })

    }
}

export const getPublics = (token) => async (dispatch) => {
    try {
        dispatch({ type: PUBLIC_TYPES.LOADING_PUBLIC, payload: true })
        const res = await getDataAPI('publics', token)

        dispatch({
            type: PUBLIC_TYPES.GET_PUBLICS,
            payload: { ...res.data, page: 2 }
        })

        dispatch({ type: PUBLIC_TYPES.LOADING_PUBLIC, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const updatePublic = ({
    nombre, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura, titulo, phora, pmediahora, ppack, pvideollamada,
    horario, telefono, dcorta, pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot, lugar1,
    lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6, servicio7, servicio8, especial1, especial2,
    especial3, especial4, especial5, especial6, especial7, especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader,
    content, images, categoria, auth, statuPublic
}) => async (dispatch) => {
    let media = []
    const imgNewUrl = images.filter(img => !img.url)
    const imgOldUrl = images.filter(img => img.url)

    if (statuPublic.content === content
        && imgNewUrl.length === 0
        && imgOldUrl.length === statuPublic.images.length
    ) return;

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (imgNewUrl.length > 0) media = await imageUploadP(imgNewUrl)

        const res = await patchDataAPI(`public/${statuPublic._id}`, {
            nombre, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura, titulo, phora, pmediahora, ppack, pvideollamada,
            horario, telefono, dcorta, pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot, lugar1,
            lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6, servicio7, servicio8, especial1, especial2,
            especial3, especial4, especial5, especial6, especial7, especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader,
            content, categoria, images: [...imgOldUrl, ...media]
        }, auth.token)

        dispatch({ type: PUBLIC_TYPES.UPDATE_PUBLIC, payload: res.data.newPublic })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

//updatepublic adm 
export const updatePublicadm = ({ condicion, categoria, planduracion, ids }) => async (dispatch) => {

    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await patchDataAPI(`publicupd/${ids}`, {
            condicion, categoria, planduracion
        })
        
        console.log(res)

        dispatch({type: GLOBALTYPES.ALERT, payload: { loading: false }})
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}
//

export const likePublic = ({ post, auth, socket }) => async (dispatch) => {
    const newPublic = { ...post, likes: [...post.likes, auth.user] }
    dispatch({ type: PUBLIC_TYPES.UPDATE_PUBLIC, payload: newPublic })

    socket.emit('likePublic', newPublic)

    try {
        await patchDataAPI(`public/${post._id}/like`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'acaba de dar like a tú Anuncio.',
            recipients: [post.user._id],
            url: `/publicidad/${post._id}`,
            content: post.content,
            image: post.images[0].url
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const unLikePublic = ({ post, auth, socket }) => async (dispatch) => {
    const newPublic = { ...post, likes: post.likes.filter(like => like._id !== auth.user._id) }
    dispatch({ type: PUBLIC_TYPES.UPDATE_PUBLIC, payload: newPublic })

    socket.emit('unLikePublic', newPublic)

    try {
        await patchDataAPI(`public/${post._id}/unlike`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'acaba de dar like a tú post.',
            recipients: [post.user._id],
            url: `/publicidad/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const getPublic = ({ detailPublic, id, auth }) => async (dispatch) => {
    if (detailPublic.every(publicuno => publicuno._id !== id)) {
        try {
            const res = await getDataAPI(`public/${id}`, auth.token)
            dispatch({ type: PUBLIC_TYPES.GET_PUBLIC, payload: res.data.public })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}

export const deletePublic = ({ post, auth, socket }) => async (dispatch) => {
    dispatch({ type: PUBLIC_TYPES.DELETE_PUBLIC, payload: post })

    try {
        const res = await deleteDataAPI(`public/${post._id}`, auth.token)

        // Notify
        const msg = {
            id: post._id,
            text: 'agregó una nueva publicación.',
            recipients: res.data.newPublic.user.followers,
            url: `/publicidad/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }

}

export const savePublic = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: [...auth.user.saved, post._id] }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`savePublic/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const unSavePublic = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: auth.user.saved.filter(id => id !== post._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`unSavePublic/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}