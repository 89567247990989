import { useState, useEffect } from 'react'
import axios from 'axios'

function PublicidadAPI() {
  const [publicidad, setPublicidad] = useState([])
  const [publicidadtot, setPublicidadtot] = useState([])

  const [publicidadrecien, setPublicidadrecien] = useState([])


  const [publicvip, setPublicvip] = useState([])
  const [publicpref, setPublicpref] = useState([])

  const [callback, setCallback] = useState(false)
  const [category, setCategory] = useState('')
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [result, setResult] = useState(0)
  const [loading, setLoading] = useState(false)

   /****** paginas kine*/
  //lima centro 
  const [publicbrena, setPublicbrena] = useState([])
  const [publiccercado, setPublicercado] = useState([])
  const [publicjesusmaria, setPublicjesusmaria] = useState([])
  const [publiclince, setPubliclince] = useState([])
  const [publicpueblolibre, setPublicpueblolibre] = useState([])
  const [publicsanluis, setPublicsanluis] = useState([])
  const [publicsanmiguel, setPublicsanmiguel] = useState([])
  //lima este
  const [publicatevitarte, setPublicatevitarte] = useState([])
  const [publiccarabayllo, setPubliccarabayllo] = useState([])
  const [publicchaclacayo, setPublicchaclacayo] = useState([])
  const [publiccieneguilla, setPubliccieneguilla] = useState([])
  const [publicagustino, setPublicagustino] = useState([])
  const [publicmolina, setPublicmolina] = useState([])
  const [publiclurigancho, setPubliclurigancho] = useState([])
  const [publicsjlurigancho, setPublicsjlurigancho] = useState([])
  const [publicsanita, setPublicsanita] = useState([])
  //lima norte
  const [publicancon, setPublicancon] = useState([])
  const [publiccomas, setPubliccomas] = useState([])
  const [publicindependencia, setPublicindependencia] = useState([])
  const [publicolivos, setPublicolivos] = useState([])
  const [publicptpiedra, setPublicptpiedra] = useState([])
  const [publicrimac, setPublicrimac] = useState([])
  const [publicsmdeporras, setPublicsmdeporras] = useState([])
  //lima sur
  const [publicbarranco, setPublicbarranco] = useState([])
  const [publicchorrillos, setPublicchorrillos] = useState([])
  const [publicluryn, setPublicluryn] = useState([])
  const [publicmagdalena, setPublicmagdalena] = useState([])
  const [publicmiraflores, setPublicmiraflores] = useState([])
  const [publicsanborja, setPublicsanborja] = useState([])
  const [publicsanisidro, setPublicsanisidro] = useState([])
  const [publicsjmiraflores, setPublicsjmiraflores] = useState([])
  const [publicsurco, setPublicsurco] = useState([])
  const [publicsurquillo, setPublicsurquillo] = useState([])
  const [publicsalvador, setPublicsalvador] = useState([])
  const [publicvmt, setPublicvmt] = useState([])

  /****** */

  useEffect(() => {
    const getPublic = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidad?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicidad(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublic()
  }, [callback, category, sort, search, page])


  //pubicidad reciente
  useEffect(() => {
    const getPublicren = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadreciente?limit=${page * 16}&${sort}&nombre[regex]=${search}`)
      setPublicidadrecien(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicren()
  }, [callback, category, sort, search, page])
  //

  useEffect(() => {
    const getPublics = async () => {
      const res = await axios.get(`/api/publicidadtot?limit=${page * 18}`)
      setPublicidadtot(res.data.publics)
      setResult(res.data.result)
    }
    getPublics()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicvip = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadvip?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicvip(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicvip()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicpref = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadpref?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicpref(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicpref()
  }, [callback, category, sort, search, page])

  
  /****pages kine */
  //lima centro
  useEffect(() => {
    const getPublicbrena = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadbrena?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicbrena(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicbrena()
  }, [callback, category, sort, search, page])


  useEffect(() => {
    const getPubliccercado = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadcercado?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicercado(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliccercado()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicjesusmaria = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadjesusmaria?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicjesusmaria(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicjesusmaria()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliclince = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadlince?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPubliclince(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliclince()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicplibre = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadplibre?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicpueblolibre(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicplibre()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsanluis = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsanluis?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsanluis(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsanluis()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsanmiguel = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsanmiguel?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsanmiguel(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsanmiguel()
  }, [callback, category, sort, search, page])

  //lima este
  useEffect(() => {
    const getPublicatevitarte = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadatevitarte?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicatevitarte(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicatevitarte()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliccarabayllo = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidcarabayllo?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPubliccarabayllo(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliccarabayllo()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicchaclacayo = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadchaclacayo?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicchaclacayo(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicchaclacayo()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliccieneguilla = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadcieneguilla?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPubliccieneguilla(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliccieneguilla()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicagustino = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadagustino?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicagustino(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicagustino()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicmolina = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadmolina?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicmolina(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicmolina()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliclurigancho = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadlurigancho?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPubliclurigancho(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliclurigancho()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicSJlurigancho = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadSJlurigancho?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsjlurigancho(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicSJlurigancho()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsanita = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsanita?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsanita(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsanita()
  }, [callback, category, sort, search, page])

  //lima norte
  useEffect(() => {
    const getPublicancon = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadancon?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicancon(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicancon()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliccomas = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadcomas?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPubliccomas(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliccomas()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicindependencia = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadindependencia?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicindependencia(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicindependencia()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicolivos = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadolivos?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicolivos(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicolivos()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicptpiedra = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadptpiedra?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicptpiedra(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicptpiedra()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicrimac = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadrimac?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicrimac(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicrimac()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsmporras = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsmporras?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsmdeporras(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsmporras()
  }, [callback, category, sort, search, page])

  //lima sur

  useEffect(() => {
    const getPublicbarraco = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadbarraco?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicbarranco(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicbarraco()
  }, [callback, category, sort, search, page])


  useEffect(() => {
    const getPublichorrillos = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadchorrillos?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicchorrillos(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublichorrillos()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPubliclurin = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadlurin?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicluryn(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPubliclurin()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicmagdalena = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadmagdalena?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicmagdalena(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicmagdalena()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicmiraflores = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadmiraflores?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicmiraflores(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicmiraflores()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsanborja = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsanborja?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsanborja(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsanborja()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsanisidro = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsanisidro?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsanisidro(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsanisidro()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsjmiraflores = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsjmiraflores?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsjmiraflores(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsjmiraflores()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsurco = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsurco?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsurco(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsurco()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsurquillo = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsurquillo?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsurquillo(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsurquillo()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublicsalvador = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadsalvador?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicsalvador(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublicsalvador()
  }, [callback, category, sort, search, page])

  useEffect(() => {
    const getPublictriunfo = async () => {
      setLoading(true)
      const res = await axios.get(`/api/publicidadtriunfo?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setPublicvmt(res.data.publics)
      setResult(res.data.result)
      setLoading(false)
    }
    getPublictriunfo()
  }, [callback, category, sort, search, page])



  return {
    publicidad: [publicidad, setPublicidad],
    publicidadtot: [publicidadtot, setPublicidadtot],
    publicvip: [publicvip, setPublicvip],
    publicpref: [publicpref, setPublicpref],

    //publicidad reciente
    publicidadrecien:[publicidadrecien, setPublicidadrecien],

    /****paginas kines */
    //cono lima centro
    publicbrena: [publicbrena, setPublicbrena],
    publiccercado: [publiccercado, setPublicercado],
    publicjesusmaria: [publicjesusmaria, setPublicjesusmaria],
    publiclince: [publiclince, setPubliclince],
    publicpueblolibre: [publicpueblolibre, setPublicpueblolibre],
    publicsanluis: [publicsanluis, setPublicsanluis],
    publicsanmiguel: [publicsanmiguel, setPublicsanmiguel],
    //lima este
    publicatevitarte: [publicatevitarte, setPublicatevitarte],
    publiccarabayllo: [publiccarabayllo, setPubliccarabayllo],
    publicchaclacayo: [publicchaclacayo, setPublicchaclacayo],
    publiccieneguilla: [publiccieneguilla, setPubliccieneguilla],
    publicagustino: [publicagustino, setPublicagustino],
    publicmolina: [publicmolina, setPublicmolina],
    publiclurigancho: [publiclurigancho, setPubliclurigancho],
    publicsjlurigancho: [publicsjlurigancho, setPublicsjlurigancho],
    publicsanita: [publicsanita, setPublicsanita],
    //lima norte
    publicancon: [publicancon, setPublicancon],
    publiccomas: [publiccomas, setPubliccomas],
    publicindependencia: [publicindependencia, setPublicindependencia],
    publicolivos: [publicolivos, setPublicolivos],
    publicptpiedra: [publicptpiedra, setPublicptpiedra],
    publicrimac: [publicrimac, setPublicrimac],
    publicsmdeporras: [publicsmdeporras, setPublicsmdeporras],
    //lima sur
    publicbarranco: [publicbarranco, setPublicbarranco],
    publicchorrillos: [publicchorrillos, setPublicchorrillos],
    publicluryn: [publicluryn, setPublicluryn],
    publicmagdalena: [publicmagdalena, setPublicmagdalena],
    publicmiraflores: [publicmiraflores, setPublicmiraflores],
    publicsanborja: [publicsanborja, setPublicsanborja],
    publicsanisidro: [publicsanisidro, setPublicsanisidro],
    publicsjmiraflores: [publicsjmiraflores, setPublicsjmiraflores],
    publicsurco: [publicsurco, setPublicsurco],
    publicsurquillo: [publicsurquillo, setPublicsurquillo],
    publicsalvador: [publicsalvador, setPublicsalvador],
    publicvmt: [publicvmt, setPublicvmt],
    /**** */

    callback: [callback, setCallback],
    category: [category, setCategory],
    sort: [sort, setSort],
    search: [search, setSearch],
    page: [page, setPage],
    result: [result, setResult],
    loading: [loading, setLoading]
  }
}

export default PublicidadAPI
