import React, { useState, useRef, useEffect, useContext } from 'react'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { useSelector, useDispatch } from 'react-redux'

import { createForum, updateForum } from '../../redux/actions/forumAction'
import { imageShow, videoShow } from '../../utils/mediaShow'

import { GlobalState } from '../../GlobalState'

import { useParams } from 'react-router-dom';
 import { Link } from 'react-router-dom'


const StatusforModal = () => {

    const { auth, theme, statusForum, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const { name } = useParams();
    const textareaRef = useRef(null);

    const state = useContext(GlobalState)
    const [categories] = state.categoriesAPI.categories
    const [isUservip] = state.userAPI.isUservip

    const [titulo, setTitulo] = useState('')
    const [content, setContent] = useState('')
    const [images, setImages] = useState([])
    const [tags, setTags] = useState([])
    const [category, setCategory] = useState('')

    const [stream, setStream] = useState(false)
    const videoRef = useRef()
    const refCanvas = useRef()
    const [tracks, setTracks] = useState('')

    const handleChangeImages = e => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {
            if (!file) return err = "File does not exist."

            if (file.size > 1024 * 1024 * 5) {
                return err = "The image/video largest is 5mb."
            }

            return newImages.push(file)
        })

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        setImages([...images, ...newImages])
    }

    const deleteImages = (index) => {
        const newArr = [...images]
        newArr.splice(index, 1)
        setImages(newArr)
    }

    const handleStream = () => {
        setStream(true)
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(mediaStream => {
                    videoRef.current.srcObject = mediaStream
                    videoRef.current.play()

                    const track = mediaStream.getTracks()
                    setTracks(track[0])
                }).catch(err => console.log(err))
        }
    }

    const handleCapture = () => {
        const width = videoRef.current.clientWidth;
        const height = videoRef.current.clientHeight;

        refCanvas.current.setAttribute("width", width)
        refCanvas.current.setAttribute("height", height)

        const ctx = refCanvas.current.getContext('2d')
        ctx.drawImage(videoRef.current, 0, 0, width, height)
        let URL = refCanvas.current.toDataURL()
        setImages([...images, { camera: URL }])
    }

    const handleStopStream = () => {
        tracks.stop()
        setStream(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (images.length > 2)
             return dispatch({
                 type: GLOBALTYPES.ALERT, payload: { error: "Maximo dos imagenes" }
             })

        if (titulo.length = 0)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que agregar un titulo" }
            })

        if (titulo.length > 60)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Su titulo es maximo de 60 caracteres" }
            })

        if (content.length = 0)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que agregar un contenido" }
            })

        if (content.length > 1200)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Su publicación es maximo de 1200 caracteres" }
            })

        if (category.length = 0)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que elegir una categoria" }
            })

        if (statusForum.onEdit) {
            dispatch(updateForum({ titulo, content, images, tags, category, auth, statusForum }))
        } else {
            dispatch(createForum({ titulo, content, images, tags, category, auth, socket }))
        }

        setTitulo('')
        setContent('')
        setImages([])
        setTags([])
        setCategory('')
        dispatch({ type: GLOBALTYPES.STATUSFORUM, payload: false })
    }

    useEffect(() => {
        if (statusForum.onEdit) {
            setTitulo(statusForum.titulo)
            setContent(statusForum.content)
            setImages(statusForum.images)
            setTags(statusForum.tags)
            setCategory(statusForum.category)
        }
    }, [statusForum])

    // Función para manejar el cambio en el contenido del textarea
    const handleChange = (e) => {
        setContent(e.target.value);
    };

    // Función para agregar el formulario preestablecido seleccionado al contenido del textarea
    const handleAddTemplate = (template) => {
        setContent(template);
    };

    // Ajustar automáticamente la altura del textarea al contenido
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [content]);

 ////modal
 const [show, setShow] = useState(false);

 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 const handleClick = () => {
     window.scrollTo({ top: 200, behavior: 'smooth' });
 };

 const handleButtonClick = () => {
     handleShow();
     handleClick();
 };

    return (
        <div className="status_modal">
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0', filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <div id="alerta-titulo" style={{margin:'0',height:'55vh'}}>
                        <i class="fa-regular fa-message"></i>
                        <h4>IMPORTANTE!!!! <br/> Para subir imagenes tiene que subir de rango ó suscribirse
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button type="button" class="outline-danger" >Suscribirse</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="status_header">
                    <h5 className="m-0">Crear Tema de Conversación</h5>
                    <span onClick={() => dispatch({
                        type: GLOBALTYPES.STATUSFORUM, payload: false
                    })}>
                        &times;
                    </span>
                </div>
                <div className="status_body forum" id='status_form_MODAL'>

                    <div className="title_MODEL_body">
                        <label form='titulo' >Titulo:</label>
                        <input type="text" id="titulo" name="titulo" placeholder='Titulo' value={titulo} required
                            onChange={e => setTitulo(e.target.value)} style={{ backgroundColor: titulo.length > 60 ? '#fba3a3bd' : '' }} />
                    </div>

                    <div className='content_btn_form_'>
                        <button type="button" onClick={() => handleAddTemplate("")} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                            Limpiar
                        </button>
                        <button type="button" onClick={() => handleAddTemplate(
                            "1.Descripción fisica: \n2.Edad: \n3.Descripción Culo/tetas: \n4.Señas Particulares: \n5.Ajuste Vaginal: \n6.Sexo Anal: \n7.Sexo Oral: \n8.Descripción completa del servicio: \n\n9.Tiempo de servicio: \n10.Limpieza: \n11.Ubicación: \n12.Puntaje por el servicio:  \n13.Información adicional:"
                        )} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                            Usar plantilla
                        </button>
                    </div>

                    <textarea name="content"
                        ref={textareaRef}
                        placeholder={`${auth.user.username}, Deseas comentar sobre algun tema?`}
                        //onChange={e => setContent(e.target.value)}
                        value={content}
                        onChange={handleChange} // Manejar el cambio en el contenido del textarea
                        style={{
                            filter: theme ? 'invert(1)' : 'invert(0)',
                            color: theme ? 'white' : '#111',
                            background: theme ? 'rgba(0,0,0,.03)' : '',
                            backgroundColor: content.length > 1200 ? '#fba3a3bd' : ''
                        }}
                        required
                    />

                    { /*<input type="text" id="category" name="category" placeholder='category' value={category} required
                        onChange={e => setCategory(e.target.value)} />*/}

                    <select class="form-select form-select-lg mb-3" aria-label="Large select example"
                        onChange={e => setCategory(e.target.value)}
                        value={category} required
                    >
                        <option selected>Seleccionar Tema</option>
                        {
                            categories.map(cate => (
                                <option value={cate.idtemaprincipal} >
                                    {cate.name}
                                </option>
                            ))
                        }

                    </select>

                    <input type="text" id="tags" name="tags" placeholder='tags' value={tags}
                        onChange={e => setTags(e.target.value)} />

                    <div className="show_images">
                        {
                            images.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? <>
                                                    {
                                                        img.url.match(/video/i)
                                                            ? videoShow(img.url, theme)
                                                            : imageShow(img.url, theme)
                                                    }
                                                </>
                                                : <>
                                                    {
                                                        img.type.match(/video/i)
                                                            ? videoShow(URL.createObjectURL(img), theme)
                                                            : imageShow(URL.createObjectURL(img), theme)
                                                    }
                                                </>
                                    }
                                    <span onClick={() => deleteImages(index)}>&times;</span>
                                </div>
                            ))
                        }
                    </div>

                    {
                        stream &&
                        <div className="stream position-relative">
                            <video autoPlay muted ref={videoRef} width="100%" height="100%"
                                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />

                            <span onClick={handleStopStream}>&times;</span>
                            <canvas ref={refCanvas} style={{ display: 'none' }} />
                        </div>
                    }

                    <div className="input_images">
                        {
                            stream
                                ? <i className="fas fa-camera" onClick={handleCapture} />
                                : <>
                                    {/*<i className="fas fa-camera" onClick={handleStream} />*/}

                                    {
                                        isUservip
                                            ? <div className="file_upload">
                                                <i className="fas fa-image" />
                                                <input type="file" name="file" id="file"
                                                    multiple accept="image/*,video/*" onChange={handleChangeImages} />
                                            </div>
                                            : <div className="file_upload">
                                                <i className="fas fa-image" onClick={handleButtonClick}/>
                                            </div>
                                    }

                                </>
                        }

                    </div>
                </div>
                <div className="status_footer" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <button className="btn btn-secondary w-100" type="submit" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    )

}

export default StatusforModal
