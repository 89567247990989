import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { createtiktok } from '../../redux/actions/tiktokAction'


function TiktokModal() {

    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

    const [ urlcont, setUrlcont ] = useState('')


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!urlcont)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar un Link"  }
            })
        dispatch(createtiktok({urlcont, auth}))
        setUrlcont('')
        dispatch({ type: GLOBALTYPES.TIKTOKLINK, payload: false })
    }


    return (
        <div className='anuncio_modal'>
            <form onSubmit={handleSubmit}>
                <div className='anuncio_header'>
                    <h5 className='m-0'>Enviar Link</h5>
                    <span onClick={() => dispatch({
                        type: GLOBALTYPES.TIKTOKLINK, payload: false
                    })} >
                        &times;
                    </span>
                </div>
                <div className='anuncio_body'>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon3">https://</span>
                        <input type="text" class="form-control" aria-describedby="basic-addon3"
                            id="urlcont" name="urlcont" placeholder='Link de su Video de Tiktok' value={urlcont}
                            onChange={ e => setUrlcont(e.target.value)}
                            />
                    </div>
                    
                </div>
                <div className='anuncio_footer'>
                    <button className='btn btn-secondary w-100' type='submit'  >
                        Enviar Link Video tiktok
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TiktokModal