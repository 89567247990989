import React from 'react'


function Legalidad() {
    return (
        <div className='politicas_condiciones_page'>
            <div className='terminos_politicas'>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <h3><i class="fa-regular fa-handshake"></i> CONDICIONES DE USO</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        El acceso y uso de los servicios del sitio web CariñosasHub (en adelante “EL  SITIO”), suponen la completa y total aceptación de las condiciones que
                                        aquí se exponen por parte del usuario o usuaria (en adelante “EL USUARIO”), siendo este el responsable del incumplimiento de las mismas, respondiendo según
                                        convenga en derecho del quebrantamiento o mal uso de las mismas o de la legislación aplicable de cualquier ámbito y localización, respondiendo ante terceros
                                        y también ante los legítimos propietarios titulares de este dominio web por los daños de cualquier índole que un mal uso del USUARIO les pudiera ocasionar.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        En este sitio web tambien tiene términos que abordan asuntos importantes que rigen su acceso y uso. Tales asuntos incluyen los derechos de propiedad,
                                        la limitación de responsabilidad, así como los usos permitidos y prohibidos del sitio, su contenido y servicios. Se establece expresamente
                                        en estos términos que los visitantes o usuarios de este sitio web deben ser mayores de 18 años. Como tal, visitar este sitio, así como usar la
                                        plataforma CariñosasHub no será responsable de su acceso o las consecuencias de acceder al sitio, su contenido y materiales o el uso de los
                                        servicios por parte de personas menores de edad, que es de 18 años.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <h3><i class="fa-solid fa-lock"></i> POLÍTICA DE PRIVACIDAD</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        Este sitio web tiene una política de privacidad clara. Esta política es simplemente un acuerdo entre el usuario y el sitio
                                        sobre cómo debe recopilar, almacenar, procesar, usar y divulgar información que no sea de identificación personal e información
                                        de identificación personal. El acceso a CariñosasHub confirma su aceptación y reconocimiento de esta Política de privacidad.
                                        Lea y comprenda esta Política de privacidad antes de utilizar este sitio web.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO garantiza en todo momento el cumplimiento integro y escrupuloso de lo establecido por la legislación que le es aplicable en lo
                                        relativo a la protección de datos personales y la privacidad de los mismos, la política de privacidad articulada de forma completa se encuentra
                                        en texto en el mismo sitio CariñosasHub.

                                        Además de los derechos que la legislación otorga al USUARIO en la protección de sus datos personales, el USUARIO en las condiciones y opciones
                                        que así ofrezca en cada momento el SITIO podrá configurar la relación interna que le vincula con el SITIO (comunicaciones por correo electrónico,…)

                                        El USUARIO acepta que todo el contenido publicado por su persona en el sitio será visible públicamente sin más restricciones que las que estipule
                                        libremente el SITIO.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <h3><i class="fa-regular fa-hand-point-up"></i> RESPONSABILIDADES DEL USUARIO</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        El USUARIO es el único responsable del contenido de cualquier tipo publicado por él en el SITIO, eximiendo el USUARIO al SITIO o bien a terceros
                                        de cualquier responsabilidad que pudiera emanar de un contenido indebido publicado por él en el SITIO. El SITIO declara expresamente que en virtud
                                        de esta responsabilidad del USUARIO el SITIO no supervisa ni controla los contenidos publicados por el USUARIO, por ende la publicación en el SITIO
                                        de cualquier contenido por parte del USUARIO no implica el conocimiento del SITIO, ni la aceptación, conformidad, apoyo o relación de este con el
                                        contenido publicado, ni tampoco se responsabiliza de la exactitud y veracidad de lo publicado.

                                        Es responsabilidad del USUARIO el buen uso y custodia de las contraseñas o demás datos de acceso que en cualquier momento le pueda facilitar el SITIO
                                        para la utilización de los servicios ofrecidos por este. El uso indebido o fraudulento por parte del mismo o de terceros de todos esos datos de
                                        acceso es única y exclusiva responsabilidad del USUARIO.

                                        Es responsabilidad del USUARIO el hacer un buen uso de los servicios que el SITIO le ofrece, por buen uso se entiende al uso adecuado dentro de los
                                        principios de honestidad y buena fe, y concretamente un buen uso significa:
                                        <ul>
                                            <li>
                                                No  utilizar abusivamente los servicios, No introducir contenido falso o que induzca al error. En este punto mención especial es para el
                                                material fotográfico que se publique, el mismo deberá ser siempre original y autentico, en caso de detectarse irregularidad, será bloqueado
                                                el contenido publicado hasta la completa subsanación de lo publicado indebidamente.
                                            </li>
                                            <li>
                                                No interferir en el buen y correcto funcionamiento del sitio web, expresamente en este caso se prohíben repetir contenido publicado
                                                (anuncios ya insertados), publicar de una forma errónea o mal formada el contenido, etc. etc.
                                                Queda completamente prohibido utilizar sistemas informáticos o de cualquier índole que sirvan para burlar los sistemas de seguridad
                                                implantados por el SITIO.
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Cualquier usuario de este directorio debe observar todas las leyes y regulaciones aplicables. Eso significa que tienen responsabilidades
                                        y regulaciones en cuanto a su uso de este sitio web y los servicios proporcionados. También es importante tener en cuenta que cada
                                        usuario es responsable de las consecuencias de divulgar información de identificación personal y no personal a este sitio web.
                                    </p>
                                    <span>Cada usuario es responsable de:</span>
                                    <ul>
                                        <li>
                                            Cumplimiento de las normas, leyes y regulaciones aplicables en relación con este sitio web, su contenido y los servicios proporcionados.
                                        </li>
                                        <li>
                                            Actividades que se llevan a cabo en o a través de este sitio web bajo su información de identificación personal, ya sea no
                                            autorizada o autorizada por ellos.
                                        </li>
                                        <li>
                                            Transacción (es) e interacción (es) con el (los) usuario (s) final (es) de este sitio.
                                        </li>
                                        <li>
                                            Cooperación con este sitio web en relación con la prestación del servicio (s).
                                        </li>
                                        <li>
                                            La información de voluntariado que podría requerirse para los servicios solicitados se proporcionará sin problemas.
                                        </li>
                                        <li>
                                            Asegurándose de que compartan contenido de forma segura con este sitio web. Esto incluye garantizar que el contenido compartido
                                            esté libre de virus,
                                            gusanos y troyanos, así como de códigos maliciosos.
                                        </li>
                                        <li>
                                            Asegurarse de que el servicio solicitado se adapte a sus necesidades.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                <h3><i class="fa-regular fa-hand-point-right"></i> TÉRMINOS DEL CLIENTE</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                            <p>
                                Este sitio web tiene términos que cubren el alcance de los servicios, las tarifas y los pagos, las obligaciones del cliente, la cancelación
                                del servicio y la limitación de responsabilidad. Es responsabilidad del cliente o usuario leer y comprender los términos antes de usar
                                este sitio web.
                            </p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                                <h3><i class="fa-solid fa-laptop"></i> COMUNICACIONES</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne2" data-bs-parent="#accordionFlushExample">
                            <p>
                                La plataforma CariñosasHub puede enviarle información a través de los datos de contacto que proporcione. Tal información puede incluir anuncios de servicio y
                                boletines electrónicos. Tenga en cuenta que acepta y entiende que esta comunicación está de acuerdo con el uso continuado acordado de los servicios
                                del directorio. Si desea finalizar esta comunicación, siga amablemente el procedimiento de exclusión que se proporciona en los materiales de comunicación
                                o en la Política de privacidad del directorio.
                            </p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                                <h3><i class="fa-solid fa-book-open-reader"></i> MODIFICACIONES</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne3" data-bs-parent="#accordionFlushExample">
                            <p>
                                Existe un término legalmente vinculante que otorga a CariñosasHub la discreción de cambiar, reemplazar o actualizar todos los acuerdos expresados
                                en este documento en cualquier momento. Es la visita o la responsabilidad del usuario visitar este sitio y tomar nota de las modificaciones;
                                luego decide visitar y utilizar este sitio web o no. Los cambios, reemplazos o actualizaciones realizados se harán efectivos y vinculantes a partir de
                                la publicación. Los usuarios que no aceptan ni acatan los cambios, reemplazos o actualizaciones hechos dejan de estar autorizados para visitar o utilizar
                                este sitio web, así como sus servicios.
                            </p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne5">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne5" aria-expanded="false" aria-controls="flush-collapseOne5">
                                <h3><i class="fa-solid fa-book-bookmark"></i> DERECHOS DEL SITIO</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne5" class="accordion-collapse collapse" aria-labelledby="flush-headingOne5" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        El SITIO podrá dejar de prestar sus servicios sin previo aviso, sin que ello de compensación ni derecho de ningún tipo. A la par que al derecho
                                        de supresión, el SITIO se reserva en todo momento el derecho de modificación total o parcial, definitiva o temporal de cualquier servicio por
                                        él ofrecido.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de rechazar la petición de alta de cualquier nuevo usuario o si lo estima conveniente dar de baja a alguno existente
                                        con todos sus datos asociados. A la vez se reserva el derecho de bloquear cualquier cuenta de usuario que considere sospechosa de estar infringiendo
                                        estas normas o que contravenga la legislación y se reserva el derecho de limitar o restringir total o parcialmente a cualquier usuario el uso de
                                        cualquier funcionalidad, así como de eliminar inmediatamente contenido indebido publicado por el USUARIO, sin perjuicio de que además tome las medidas
                                        legales oportunas.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de poner en conocimiento de las autoridades competentes cualquier fraude, delito o irregularidad que sospeche se esté
                                        cometiendo a través del sitio por parte del USUARIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de dar acceso y uso a terceros del contenido publicado por el USUARIO en el sitio, sin que ello de lugar a
                                        contraprestación alguna, en ningún caso ello conllevara la cesión de información personal del USUARIO que no esté estipulada en la política
                                        de privacidad. El uso fraudulento o indebido de terceros del contenido publicado por el USUARIO será responsabilidad del que actúe fraudulenta
                                        o indebidamente, sin que el SITIO tenga ninguna relación ni responsabilidad, ya sea directa o indirecta.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de la libre manipulación, transformación o adaptación del contenido publicado por el usuario, para darle el uso
                                        adicional que estime conveniente (publicitarlo en otros medios, etcétera) o para adaptarlo técnicamente dentro del mismo sitio. En el caso de los
                                        “anuncios de pago destacados” que publique el USUARIO, los mismos antes de su efectiva publicación deberán pasar por el filtro y moderación del SITIO,
                                        para que el mismo se adapte y ajuste a la política de calidad, diseño y contenido del sitio.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de insertar publicidad u otros recursos que generen beneficios económicos al sitio en el contenido que el USUARIO haya
                                        publicado en el sitio, ello no dará derecho a contraprestación alguna al USUARIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva la única y exclusiva potestad de actuar técnicamente sobre el sitio, el USUARIO en ningún caso estará autorizado a ninguna otra
                                        función en el sitio que las funciones públicas y las que tenga activas como usuario del mismo, ninguna más. La vulneración de esta clausula podrá
                                        dar lugar a las reclamaciones pertinentes y el SITIO lo considerara en todo caso como un ataque no autorizado a sus sistemas.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO se reserva el derecho de modificar sin previo aviso y total o parcialmente el contenido de estas condiciones generales, el USUARIO
                                        podrá consultar siempre la última versión actualizada en www.sexykines.com, en el caso de en algún momento el USUARIO no aceptar alguna de las
                                        condiciones del SITIO podrá ejercer libremente su derecho a darse de baja del sitio.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne6">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne6" aria-expanded="false" aria-controls="flush-collapseOne6">
                                <h3><i class="fa-solid fa-laptop-file"></i> PRESTACION DEL SERVICIO</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne6" class="accordion-collapse collapse" aria-labelledby="flush-headingOne6" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        La no aceptación por parte del USUARIO de cualquiera de las condiciones aquí expuestas imposibilita al mismo para utilizar los servicios que el SITIO
                                        presta y queda desautorizado a acceder o utilizar los mismos.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El servicio que presta el SITIO es prestado “tal cual” y “según disponibilidad”. El servicio paralelamente también se presta bajo la modalidad “freemium”,
                                        es decir con servicios ofrecidos por el SITIO de forma gratuita y otros ofrecidos bajo la modalidad de pago, ello en ningún caso significa un distinto
                                        condicionado de este marco regulador, el mismo es el común para todos los servicios prestados por el SITIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El SITIO no queda comprometido al buen funcionamiento, calidad, disponibilidad, seguridad o fiabilidad de los servicios prestados. En consecuencia no
                                        asume responsabilidad alguna en caso de fallos en el servicio ni en daños informáticos o de cualquier índole que por el uso de sus servicios se pudieran
                                        causar, más allá de los de imperativo legal que la legislación establezca donde radica EL SITIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        En la prestación del servicio el SITIO puede dar acceso a enlaces o recursos de terceros, en ningún caso el SITIO será responsable de los servicios o
                                        contenido de estos, tampoco será el SITIO responsable de los posibles prejuicios que terceros u otros usuarios del sitio pudieran causarle al USUARIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Las tarifas de los servicios que el SITIO presta en modalidad de pago están sujetas a cambio unilateral por parte del SITIO y sin previo aviso, pero
                                        siempre se anunciaran las actualizadas debidamente en www.sexykines.com y también podrán (sin que esto último sea vinculante) ser anunciadas a
                                        través de correo electrónico a los usuarios del sitio.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne7">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne7" aria-expanded="false" aria-controls="flush-collapseOne7">
                                <h3><i class="fa-solid fa-handshake-simple"></i> CONDICIONES ADICIONALES</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne7" class="accordion-collapse collapse" aria-labelledby="flush-headingOne7" data-bs-parent="#accordionFlushExample">
                            <ul>
                                <li>
                                    <p>
                                        En caso de que alguna de las condiciones aquí estipuladas no se ajustase a derecho dará lugar a la nulidad de la misma, en ningún caso a la nulidad
                                        del condicionado en general.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Las partes ante controversia en la interpretación y desarrollo de este condicionado acuerdan someterse a los tribunales y órganos administrativos
                                        competentes,fucu renunciando así a cualquier otro fuero que por cualquier otra causa o naturaleza pueda o pudiera corresponderles.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne4">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                <h3><i class="fa-solid fa-gavel"></i> DERECHOS DE AUTOR</h3>
                            </button>
                        </h2>
                        <div id="flush-collapseOne4" class="accordion-collapse collapse" aria-labelledby="flush-headingOne4" data-bs-parent="#accordionFlushExample">
                            <p>
                                Todo el contenido publicado en el SITIO está protegido por la legislación sobre propiedad intelectual correspondiente o la que en cada momento le sea de aplicación,
                                el uso indebido de material de cualquier tipo del SITIO dará sitio a la persecución legal correspondiente para preservar los derechos conculcados.

                                El contenido en su totalidad y vaya o no con el símbolo grafiado correspondiente © (Copyright) u análogo se entiende protegido en la modalidad de todos los derechos
                                reservados a favor de los legales propietarios y titulares del SITIO. En ningún caso la reserva de derechos significara que el SITIO es autor o responsable del
                                contenido, el autor o responsable es el USUARIO que publicando el contenido en el sitio cede al SITIO sin limitaciones, permanente e irrevocablemente todos los
                                derechos intelectuales y patrimoniales que le pudieran corresponder. Por ello el usuario en el momento de la publicación reconoce que tiene todos los derechos
                                para hacerlo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legalidad