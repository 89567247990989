import { Route, Redirect} from 'react-router-dom'


/*
const PrivateRouter = (props) => {
    const firstLogin = localStorage.getItem('firstLogin')
    return firstLogin ? <Route {...props} /> : <Redirect to="/" />
}

export default PrivateRouter*/

const PrivateRouter = ({ isPrivate, ...rest }) => {
    const firstLogin = localStorage.getItem('firstLogin');
  
    if (isPrivate) {
      // Ruta privada
      //return firstLogin ? <Route {...rest} /> : <Redirect to="/" />;
      return firstLogin ? <Route {...rest} /> : null;

    } else {
      // Ruta pública
      return <Route {...rest} />;
    }
  };
  
  export default PrivateRouter;
