import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCommentforo } from '../../../redux/actions/commentForoAction';
import Icons from '../../Icons';

import { GLOBALTYPES } from '../../../redux/actions/globalTypes'

const InputCommentForo = ({ children, forum, onReply, setOnReply }) => {

    const [content, setContent] = useState(''); // Contenido del textarea
    const { auth, socket, theme } = useSelector(state => state); // Obtener el estado global
    const dispatch = useDispatch(); // Dispatch para realizar acciones
    const textareaRef = useRef(null);

    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        if (content.length > 1200)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Supero el maximo de caracteres" }
            })

        if (!content.trim()) {
            if (setOnReply) return setOnReply(false);
            return;
        }

        setContent('');

        // Crear el nuevo comentario
        const newComment = {
            content,
            likes: [],
            user: auth.user,
            createdAt: new Date().toISOString(),
            reply: onReply && onReply.commentId,
            tag: onReply && onReply.user
        };

        // Despachar la acción para crear el comentario
        dispatch(createCommentforo({ forum, newComment, auth, socket }));

        if (setOnReply) return setOnReply(false);
    };

    // Función para manejar el cambio en el contenido del textarea
    const handleChange = (e) => {
        setContent(e.target.value);
    };

    // Función para agregar el formulario preestablecido seleccionado al contenido del textarea
    const handleAddTemplate = (template) => {
        setContent(template);
    };

    // Ajustar automáticamente la altura del textarea al contenido
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [content]);

    return (
        <form className="cardfooter comment_input" onSubmit={handleSubmit}>
            {children}
            {/* Textarea */}
            <div className='content_footer_replay'>

                <div className='content_btn_form_'>
                    <button type="button" onClick={() => handleAddTemplate("")} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                        Limpiar
                    </button>
                    <button type="button" onClick={() => handleAddTemplate(
                        "1.Descripción fisica: \n2.Edad: \n3.Descripción Culo/tetas: \n4.Señas Particulares: \n5.Ajuste Vaginal: \n6.Sexo Anal: \n7.Sexo Oral: \n8.Descripción completa del servicio: \n\n9.Tiempo de servicio: \n10.Limpieza: \n11.Ubicación: \n12.Puntaje por el servicio:  \n13.Información adicional:"
                        )} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}  >
                        Usar plantilla
                    </button>
                </div>

                <textarea
                    ref={textareaRef}
                    type="text"
                    placeholder="Responder al tema..."
                    value={content}
                    onChange={handleChange} // Manejar el cambio en el contenido del textarea
                    style={{
                        filter: theme ? 'invert(1)' : 'invert(0)',
                        color: theme ? 'white' : '#111',
                        background: theme ? 'rgba(0,0,0,.03)' : '',
                        backgroundColor: content.length > 1200 ? '#fba3a3bd' : '',

                    }}
                />

                {/* Botones para seleccionar el formulario preestablecido */}

            </div>

            {/* Botón de enviar */}
            <Icons setContent={setContent} content={content} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}/>
            <button type="submit" className="postBtn forum" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                Enviar
            </button>
        </form>
    );
};

export default InputCommentForo;
