import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';


function Lamolina() {


  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicmolina
  /*-----------*/

  if (loading) return <div><Loading /></div>

  const tituloDinamico = "Kinesiologas La molina- CARIÑOSASHUB";

  return (
    <>
      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="Kinesiologas Escorts en La Molina, lindas anfitrionas, kines y putas La Molina venezolanas en La Molina, colombianas con departamendo y delivery, putas y damas de compañia con whatsapp. Servicio completo sexo anal y oral natural"
        ></meta>

        <meta name="keywords"
          content="Pkines LA MOLINA, kinesiologas LA MOLINA, venezolanas LA MOLINA, putas LA MOLINA, escorts LA MOLINA, peru, sexo, prostitutas, whatsapp, kinesiologas en LA MOLINA, Escorts peruanas, kines venezolanas, damas de compañia, relax, photokinesiologas LA MOLINA, extranjeras LA MOLINA, 24 horas, con depa, 100 soles, kines en lima, cholotube, perutops, lolitas"></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-la-molina" />

      </Helmet>

      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Lamolina