import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const Modal = ({ show, handleClose }) => {
    const { theme } = useSelector(state => state)

    return (
        <>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0', filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <div id="alerta-titulo">
                        <h4> IMPORTANTE!!!! <br />
                            Para acceder a tus mensajes, simplemente suscribete, No necesitas una tarjeta y es muy sencillo,
                            solo atravez de nuestras redes sociales.
                        </h4>
                        <i class="fa-solid fa-comments"></i>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button onClick={handleClose} type="button" class="outline-danger" >Suscribirse</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
