import React from 'react'
import { Link } from 'react-router-dom'


function HeaderVer() {
    return (
        <nav className='rj-navbar'>
            <div className='Logo-apps'>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <h1 className="gradient-text" id='logo_css'
                        onClick={() => window.scrollTo({ top: 0 })}>
                        CariñosasHub
                    </h1>
                </Link>
            </div>
            <div className='App-lugares'>
                <h1>UBICACION</h1>

                <Link to={'/kinesiologas-en-provincias'}>
                    <div className='Lugar-app'>

                        <span>
                            <svg viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M509.76 194.64c-6.15-18.86-22.95-31.04-42.78-31.04H339.06L298.78 41.27c-6.2-18.8-22.95-30.93-42.73-30.93h-.2c-19.85.08-36.6 12.35-42.67 31.26l-39.1 122H45.09c-19.88 0-36.69 12.22-42.81 31.14-6.12 18.92.33 38.68 16.45 50.33L123.1 320.5l-40.17 122c-6.21 18.87.14 38.65 16.18 50.39a44.86 44.86 0 0026.56 8.84c9.17 0 18.35-2.9 26.37-8.7l104.54-75.56 103.3 75.44c16.03 11.7 36.78 11.76 52.87.15s22.58-31.33 16.52-50.23L390.04 320.5l103.48-75.56c16.02-11.7 22.4-31.44 16.24-50.3z" />
                            </svg>
                            PROVINCIAS
                        </span><i class="fa-solid fa-chevron-right"></i>
                        <div class="popover-content">
                            <div className='popover-content-one'>
                                <div className='popover-content-prov'>
                                    <Link to={'/kinesiologas-en-provincias'} >Amazonas</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Ancash</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Apurímac</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Arequipa</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Ayacucho</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Cajamarca</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Callao</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Cusco</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Huancavelica</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Huanuco</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Ica</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Junin</Link>
                                </div>
                                <div className='popover-content-prov'>
                                    <Link to={'/kinesiologas-en-provincias'} >La Libertad</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Lambayeque</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Lima</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Loreto</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Moquegua</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Pasco</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Piura</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Puno</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >San Martin</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Tacna</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Tumbes</Link>
                                    <Link to={'/kinesiologas-en-provincias'} >Ucayali</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to={'/kinesiologas-en-lima-Centro'}>
                    <div className='Lugar-app'>
                        <span>
                            <svg viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M509.76 194.64c-6.15-18.86-22.95-31.04-42.78-31.04H339.06L298.78 41.27c-6.2-18.8-22.95-30.93-42.73-30.93h-.2c-19.85.08-36.6 12.35-42.67 31.26l-39.1 122H45.09c-19.88 0-36.69 12.22-42.81 31.14-6.12 18.92.33 38.68 16.45 50.33L123.1 320.5l-40.17 122c-6.21 18.87.14 38.65 16.18 50.39a44.86 44.86 0 0026.56 8.84c9.17 0 18.35-2.9 26.37-8.7l104.54-75.56 103.3 75.44c16.03 11.7 36.78 11.76 52.87.15s22.58-31.33 16.52-50.23L390.04 320.5l103.48-75.56c16.02-11.7 22.4-31.44 16.24-50.3z" />
                            </svg>
                            LIMA CENTRO
                        </span>
                        <i class="fa-solid fa-chevron-right"></i>
                        <div class="popover-content">
                            <Link to={'/kinesiologas-cercado'} >Cercado</Link>
                            <Link to={'/kinesiologas-jesus-maria'} >Jesús María</Link>
                            <Link to={'/kinesiologas-san-luis'} >San Luis</Link>
                            <Link to={'/kinesiologas-pueblo-libre'} >Pblo. Libre</Link>
                            <Link to={'/kinesiologas-lince'} >Lince</Link>
                            <Link to={'/kinesiologas-san-miguel'} >San Miguel</Link>
                            <Link to={'/kinesiologas-brena'} >Breña</Link>
                        </div>
                    </div>
                </Link>

                <Link to={'/kinesiologas-en-lima-Sur'}>
                    <div className='Lugar-app'>
                        <span>
                            <svg viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M509.76 194.64c-6.15-18.86-22.95-31.04-42.78-31.04H339.06L298.78 41.27c-6.2-18.8-22.95-30.93-42.73-30.93h-.2c-19.85.08-36.6 12.35-42.67 31.26l-39.1 122H45.09c-19.88 0-36.69 12.22-42.81 31.14-6.12 18.92.33 38.68 16.45 50.33L123.1 320.5l-40.17 122c-6.21 18.87.14 38.65 16.18 50.39a44.86 44.86 0 0026.56 8.84c9.17 0 18.35-2.9 26.37-8.7l104.54-75.56 103.3 75.44c16.03 11.7 36.78 11.76 52.87.15s22.58-31.33 16.52-50.23L390.04 320.5l103.48-75.56c16.02-11.7 22.4-31.44 16.24-50.3z" />
                            </svg>
                            LIMA SUR
                        </span>
                        <i class="fa-solid fa-chevron-right"></i>
                        <div class="popover-content">
                            <Link to={'/kinesiologas-barranco'} >Barranco</Link>
                            <Link to={'/kinesiologas-chorrillos'} >Chorrillos</Link>
                            <Link to={'/kinesiologas-san-borja'} >San Borja</Link>
                            <Link to={'/kinesiologas-surquillo'} >Surquillo</Link>
                            <Link to={'/kinesiologas-san-isidro'} >San Isidro</Link>
                            <Link to={'/kinesiologas-miraflores'} >Miraflores</Link>
                            <Link to={'/kinesiologas-san-juan-de-miraflores'} >S. J. de Miraflores</Link>
                            <Link to={'/kinesiologas-surco'} >Surco</Link>
                            <Link to={'/kinesiologas-lurin-pachacamac'} >Lúrin y Pachacamac</Link>
                            <Link to={'/kinesiologas-villa-el-salvador'} >Villa el Salvador</Link>
                            <Link to={'/kinesiologas-villa-maria-del-triunfo'} >V.M. de Triunfo</Link>
                            <Link to={'/kinesiologas-magdalena'} >Magdalena</Link>
                        </div>
                    </div>
                </Link>

                <Link to={'/kinesiologas-en-lima-Norte'}>
                    <div className='Lugar-app'>
                        <span>
                            <svg viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M509.76 194.64c-6.15-18.86-22.95-31.04-42.78-31.04H339.06L298.78 41.27c-6.2-18.8-22.95-30.93-42.73-30.93h-.2c-19.85.08-36.6 12.35-42.67 31.26l-39.1 122H45.09c-19.88 0-36.69 12.22-42.81 31.14-6.12 18.92.33 38.68 16.45 50.33L123.1 320.5l-40.17 122c-6.21 18.87.14 38.65 16.18 50.39a44.86 44.86 0 0026.56 8.84c9.17 0 18.35-2.9 26.37-8.7l104.54-75.56 103.3 75.44c16.03 11.7 36.78 11.76 52.87.15s22.58-31.33 16.52-50.23L390.04 320.5l103.48-75.56c16.02-11.7 22.4-31.44 16.24-50.3z" />
                            </svg>
                            LIMA NORTE
                        </span>
                        <i class="fa-solid fa-chevron-right"></i>
                        <div class="popover-content">
                            <Link to={'/kinesiologas-comas'} >Comas</Link>
                            <Link to={'/kinesiologas-puente-piedra'} >Pte. Piedra</Link>
                            <Link to={'/kinesiologas-san-martin-de-porras'} >S. M. Porres</Link>
                            <Link to={'/kinesiologas-los-olivos'} >Los Olivos</Link>
                            <Link to={'/kinesiologas-independencia'} >Independencia</Link>
                            <Link to={'/kinesiologas-ancon'} >Ancon</Link>
                            <Link to={'/kinesiologas-rimac'} >Rimac</Link>
                        </div>

                    </div>
                </Link>

                <Link to={'/kinesiologas-en-lima-Este'}>
                    <div className='Lugar-app'>
                        <span>
                            <svg viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M509.76 194.64c-6.15-18.86-22.95-31.04-42.78-31.04H339.06L298.78 41.27c-6.2-18.8-22.95-30.93-42.73-30.93h-.2c-19.85.08-36.6 12.35-42.67 31.26l-39.1 122H45.09c-19.88 0-36.69 12.22-42.81 31.14-6.12 18.92.33 38.68 16.45 50.33L123.1 320.5l-40.17 122c-6.21 18.87.14 38.65 16.18 50.39a44.86 44.86 0 0026.56 8.84c9.17 0 18.35-2.9 26.37-8.7l104.54-75.56 103.3 75.44c16.03 11.7 36.78 11.76 52.87.15s22.58-31.33 16.52-50.23L390.04 320.5l103.48-75.56c16.02-11.7 22.4-31.44 16.24-50.3z" />
                            </svg>
                            LIMA ESTE
                        </span><i class="fa-solid fa-chevron-right"></i>
                        <div class="popover-content">
                            <Link to={'/kinesiologas-santa-anita'} >Sta. Anita</Link>
                            <Link to={'/kinesiologas-san-juan-de-lurigancho'} >S. J. de Lurigancho</Link>
                            <Link to={'/kinesiologas-ate-vitarte'} >Ate Vitarte</Link>
                            <Link to={'/kinesiologas-carabayllo'} >Carabayllo</Link>
                            <Link to={'/kinesiologas-la-molina'} >La Molina</Link>
                            <Link to={'/kinesiologas-chaclacayo'} >Chaclacayo</Link>
                            <Link to={'/kinesiologas-el-agustino'} >El Agustino</Link>
                            <Link to={'/kinesiologas-lurigancho'} >Lurigancho</Link>
                            <Link to={'/kinesiologas-cieneguilla'} >Cieneguilla</Link>
                        </div>

                    </div>
                </Link>
            </div>
            <div className='App-servicios'>
                <h1>INTERES</h1>
                <div className='Servicios-app'>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg enable-background="new 0 0 12 12" version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5,1C7.5206299,1,6.6352539,1.4022217,6,2.0504761C5.3648071,1.4022827,4.4793701,1,3.5,1  C1.5670166,1,0,2.5670166,0,4.5S2,8,6,11c4-3,6-4.5670166,6-6.5S10.4329834,1,8.5,1z" fill="#1D1D1B" />
                        </svg>
                        Damas Kinesiologas
                    </span>
                </div>

                <div className='Servicios-app'>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg enable-background="new 0 0 12 12" version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5,1C7.5206299,1,6.6352539,1.4022217,6,2.0504761C5.3648071,1.4022827,4.4793701,1,3.5,1  C1.5670166,1,0,2.5670166,0,4.5S2,8,6,11c4-3,6-4.5670166,6-6.5S10.4329834,1,8.5,1z" fill="#1D1D1B" />
                        </svg>
                        Busca Parejas
                    </span>
                </div>

                <div className='Servicios-app'>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg enable-background="new 0 0 12 12" version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5,1C7.5206299,1,6.6352539,1.4022217,6,2.0504761C5.3648071,1.4022827,4.4793701,1,3.5,1  C1.5670166,1,0,2.5670166,0,4.5S2,8,6,11c4-3,6-4.5670166,6-6.5S10.4329834,1,8.5,1z" fill="#1D1D1B" />
                        </svg>
                        Chicas busca Chicos
                    </span>
                </div>

                <div className='Servicios-app'>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg enable-background="new 0 0 12 12" version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5,1C7.5206299,1,6.6352539,1.4022217,6,2.0504761C5.3648071,1.4022827,4.4793701,1,3.5,1  C1.5670166,1,0,2.5670166,0,4.5S2,8,6,11c4-3,6-4.5670166,6-6.5S10.4329834,1,8.5,1z" fill="#1D1D1B" />
                        </svg>
                        Masajes Tantricos
                    </span>
                </div>

                <div className='Servicios-app'>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg enable-background="new 0 0 12 12" version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5,1C7.5206299,1,6.6352539,1.4022217,6,2.0504761C5.3648071,1.4022827,4.4793701,1,3.5,1  C1.5670166,1,0,2.5670166,0,4.5S2,8,6,11c4-3,6-4.5670166,6-6.5S10.4329834,1,8.5,1z" fill="#1D1D1B" />
                        </svg>
                        Chicas Diferentes
                    </span>
                </div>
            </div>
            <Link to={'/mapaubicacion'}>
                <div className='App-mapa'>
                    <i class="fa-solid fa-location-dot"></i>
                    <span className="" id="navbarDropdown"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        MAPA
                    </span>
                </div>
            </Link>
        </nav>
    )
}

export default HeaderVer