
import React, { useState, useContext } from 'react'
import Send from '../../images/send.svg'
import Carousel from '../publicidad/Carousel'
import { GlobalState } from '../../GlobalState'
import moment from 'moment'
import Avatar from '../Avatar'
import { Link } from 'react-router-dom'
import Footer from '../compInicio/footer'
import { TikTok } from 'react-tiktok'

const PostNew = () => {
    const state = useContext(GlobalState)

    const [postnew] = state?.postAPI?.postnew || []
    const [linkstiktok] = state?.postAPI?.linktiktok || []

    const [readMore, setReadMore] = useState(false)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' })
    }

    const handleButtonClick = () => {
        handleShow()
        handleClick()
    }


    // Crear un array combinado alternando 3 de `postnew` y 1 de `linkstiktok`
    const combinedPosts = []
    let postIndex = 0
    let linkIndex = 0

    while (postIndex < postnew.length || linkIndex < linkstiktok.length) {
        // Agregar hasta 3 elementos de postnew
        for (let i = 0; i < 3 && postIndex < postnew.length; i++) {
            combinedPosts.push({ type: 'post', data: postnew[postIndex] })
            postIndex++
        }
        // Agregar 1 elemento de linkstiktok si disponible
        if (linkIndex < linkstiktok.length) {
            combinedPosts.push({ type: 'link', data: linkstiktok[linkIndex] })
            linkIndex++
        }
    }

    return (
        <>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i className="fa-solid fa-user"></i>
                        <h4>
                            Para poder interactuar con los usuarios, ver su perfil, dar like, comentar o seguir, tienes que unirte a nuestra red social "CariñosasHub"...
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" className="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" className="outline-danger">Iniciar Sesión</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <h1 className='title_publici_cardsHome'>Últimas Publicaciones de tus Cariñositas:</h1>

            <div className='main_content_public'>
                <div className='posts'>
                    {combinedPosts.map((item, index) => {
                        if (item.type === 'post') {
                            const post = item.data
                            return (
                                <div className='content_cardN' key={post._id || index}>
                                    <div className='card_header'>
                                        <div className="d-flex">
                                            {post.user ? (
                                                <>
                                                    <Avatar src={post.user.avatar} size="big-avatar" onClick={handleButtonClick} />
                                                    <div className="card_name">
                                                        <h6 className="m-0" onClick={handleButtonClick}>
                                                            {post.user.username}
                                                        </h6>
                                                        <small className="text-muted">
                                                            {moment(post.createdAt).fromNow()}
                                                        </small>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="card_name">
                                                    <h6 className="m-0">Usuario Desconocido</h6>
                                                    <small className="text-muted">Fecha Desconocida</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="nav-item dropdown" style={{ display: 'block' }}>
                                            <span className="material-icons" id="moreLink" data-toggle="dropdown">menu</span>
                                            <div className="dropdown-menu">
                                                <div className="dropdown-item" onClick={handleButtonClick}>
                                                    <span className="material-icons">delete_outline</span> Ver Post
                                                </div>
                                                <div className="dropdown-item" onClick={handleButtonClick}>
                                                    <span className="material-icons">content_copy</span> Copiar Link
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card_bodyN'>
                                        <div className="card_body-content">
                                            <span>
                                                {post.content.length < 60
                                                    ? post.content
                                                    : readMore ? post.content + ' ' : post.content.slice(0, 60) + '.....'
                                                }
                                            </span>
                                            {post.content.length > 60 && (
                                                <span className="readMore" onClick={() => setReadMore(!readMore)}>
                                                    {readMore ? 'Ocultar' : 'Lee más'}
                                                </span>
                                            )}
                                        </div>

                                        {post.images.length > 0 && (
                                            <div className='content-carousel-img'>
                                                <Carousel images={post.images} id={post._id} />
                                            </div>
                                        )}
                                    </div>

                                    <div className='card_footer'>
                                        <div className="card_icon_menu">
                                            <div onClick={handleButtonClick}>
                                                <i className="fa-regular fa-heart"></i>
                                                <a>
                                                    <i className="far fa-comment" />
                                                </a>
                                                <img src={Send} alt="Send" />
                                            </div>
                                            <i className="far fa-bookmark" />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <h6 style={{ padding: '0 25px', cursor: 'pointer' }} onClick={handleButtonClick}>
                                                {post.likes.length} likes
                                            </h6>
                                            <h6 style={{ padding: '0 25px', cursor: 'pointer' }} onClick={handleButtonClick}>
                                                {post.comments.length} comentarios
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (item.type === 'link') {
                            const link = item.data
                            return (
                                <div className="content_linktiktok">
                                    <TikTok key={link.urlcont || index} url={link.urlcont} />
                                </div>
                            )
                        }
                        return null
                    })}
                </div>
            </div>

            <div className='btn_profilenew__div'>
                <button className='btn_profilenew_' onClick={handleButtonClick}>Cargar Más</button>
            </div>

            <Footer />
        </>
    )
}

export default PostNew