import React, { useState } from 'react'
import moment from 'moment'


const ForumFooter = ({ forum, theme }) => {

    return (
        <div class="col-forum-info">
            <span class="views-number">
                {forum.likes.length} <small>Likes</small>
            </span>
            <span class="views-number">
                {forum.comments.length} <small>Comentarios</small>
            </span>
        </div>
    )
}

export default ForumFooter