import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { GlobalState } from '../../../../GlobalState'

import { useSelector } from 'react-redux'


function BtnRenderCata({ anuncio, deleteProduct }) {

    const state = useContext(GlobalState)
    const [isAdmin] = state.userAPI.isAdmin
    const addCart = state.userAPI.addCart

    const { theme } = useSelector(state => state)


    /*----------------*/
    /*const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        // 👇️ toggle
        setIsActive(current => !current);
        // 👇️ or set to true
        // setIsActive(true);
    };**/

    const [active, setActive] = useState(false);
    const handleClick = () => {
        setActive(!active);
    };


    return (
        <div className="row_btnCatalogo">
            {
                isAdmin ?
                    <>
                        <Link id="btn_buy" to="#!"
                            onClick={() => deleteProduct(anuncio._id, anuncio.images.public_id)}>
                            Delete
                        </Link>
                        <Link id="btn_view" to={`/edit_product/${anuncio._id}`}>
                            Edit
                        </Link>
                    </>
                    :
                    <>
                        <Link id="btn_guardar" to="#!" onClick={() => addCart(anuncio)}>
                            <span class="material-icons" data-md-tooltip="Guardar"
                                onClick={handleClick}
                                style={{ color: active ? "red" : "#CCD1D1" }}
                            >favorite</span>
                        </Link>
                    </>
            }

        </div>
    )
}

export default BtnRenderCata