import { GLOBALTYPES } from './globalTypes'
import { imageUpload } from '../../utils/imageUpload'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'

export const PERFILCOMER_TYPES = {
    CREATE_PERFILCOMER: 'CREATE_PERFILCOMER',
    LOADING_PERFILCOMER: 'LOADING_PERFILCOMER',
    GET_PERFILCOMERS: 'GET_PERFILCOMERS',
    UPDATE_PERFILCOMER: 'UPDATE_PERFILCOMER',
    GET_PERFILCOMER: 'GET_PERFILCOMER',
    DELETE_PERFILCOMER: 'DELETE_PERFILCOMER'
}

export const createPerfil = ({ linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images, auth, socket }) => async (dispatch) => {
    let media = []
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (images.length > 0) media = await imageUpload(images)

        const res = await postDataAPI('perfilcomers', { linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images: media }, auth.token)

        dispatch({
            type: PERFILCOMER_TYPES.CREATE_PERFILCOMER,
            payload: { ...res.data.newPerfil, user: auth.user }
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

        // Notify
        const msg = {
            id: res.data.newPerfil._id,
            text: 'added a new post.',
            recipients: res.data.newPerfil.user.followers,
            url: `/post/${res.data.newPerfil._id}`,
            //content,
            image: media[0].url
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const getPerfils = (token) => async (dispatch) => {
    try {
        dispatch({ type: PERFILCOMER_TYPES.LOADING_PERFILCOMER, payload: true })
        const res = await getDataAPI('perfilcomers', token)

        dispatch({
            type: PERFILCOMER_TYPES.GET_PERFILCOMERS,
            payload: { ...res.data, page: 2 }
        })

        dispatch({ type: PERFILCOMER_TYPES.LOADING_PERFILCOMER, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const updatePerfil = ({
    linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images, auth, statusPerfil
}) => async (dispatch) => {
    let media = []
    const imgNewUrl = images.filter(img => !img.url)
    const imgOldUrl = images.filter(img => img.url)

    if (statusPerfil.description === description
        && imgNewUrl.length === 0
        && imgOldUrl.length === statusPerfil.images.length
    ) return;

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (imgNewUrl.length > 0) media = await imageUpload(imgNewUrl)

        const res = await patchDataAPI(`perfilcomer/${statusPerfil._id}`, {
            linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images: [...imgOldUrl, ...media]
        }, auth.token)

        dispatch({ type: PERFILCOMER_TYPES.UPDATE_PERFILCOMER, payload: res.data.newPerfil })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const likePerfil = ({ post, auth, socket }) => async (dispatch) => {
    const newPerfil = { ...post, likes: [...post.likes, auth.user] }
    dispatch({ type: PERFILCOMER_TYPES.UPDATE_PERFILCOMER, payload: newPerfil })

    socket.emit('likePost', newPerfil)

    try {
        await patchDataAPI(`post/${post._id}/like`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'like your post.',
            recipients: [post.user._id],
            url: `/post/${post._id}`,
            content: post.content,
            image: post.images[0].url
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const unLikePerfil = ({ post, auth, socket }) => async (dispatch) => {
    const newPerfil = { ...post, likes: post.likes.filter(like => like._id !== auth.user._id) }
    dispatch({ type: PERFILCOMER_TYPES.UPDATE_PERFILCOMER, payload: newPerfil })

    socket.emit('unLikePost', newPerfil)

    try {
        await patchDataAPI(`post/${post._id}/unlike`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'like your post.',
            recipients: [post.user._id],
            url: `/post/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const getPerfil = ({ detailPerfil, id, auth }) => async (dispatch) => {
    if (detailPerfil.every(perfil => perfil._id !== id)) {
        try {
            const res = await getDataAPI(`perfilcomer/${id}`, auth.token)
            dispatch({ type: PERFILCOMER_TYPES.GET_PERFILCOMER, payload: res.data.perfil })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}

export const deletePerfil = ({ perfil, auth, socket }) => async (dispatch) => {
    dispatch({ type: PERFILCOMER_TYPES.DELETE_PERFILCOMER, payload: perfil })

    try {
        const res = await deleteDataAPI(`perfilcomer/${perfil._id}`, auth.token)

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const savePerfil = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: [...auth.user.saved, post._id] }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`savePost/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const unSavePerfil = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: auth.user.saved.filter(id => id !== post._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`unSavePost/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}