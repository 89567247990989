import React from 'react';
import Contenidoshub from '../components/mainpages/contenidoshub/Contenidohub';

const ContenidosView = () => {
    return (
        <>
            <Contenidoshub />
        </>
    );
}

export default ContenidosView;