import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDataAPI } from '../../utils/fetchData';
import { useSelector, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom';
import CardInfo from '../profile/CardInfo';
import Suggestions from '../home/Suggestions';

import Forums from './Forums'
import Forumcard from './Forumcard';
import LoadIcon from '../../images/loading.gif'
import Statuforum from './Statuforum';
import Carrosel from '../slickcarrosel/Carrosel';


const CategoriaPage = () => {
    const { category, name } = useParams();
    const [forums, setForums] = useState([]);
    const [loading, setLoading] = useState(false);

    const { auth } = useSelector(state => state)


    useEffect(() => {
        const fetchDataByCategoria = async () => {
            setLoading(true);
            try {
                // Realizar la solicitud a la API utilizando la categoría
                const res = await getDataAPI(`searchforum?category=${category}`, auth.token);
                // Actualizar el estado con los resultados de la búsqueda
                setForums(res.data.forums);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                setLoading(false);
            }
        };

        fetchDataByCategoria(); // Llamar a la función al cargar el componente
    }, [category]);


    return (
        <div>

            <div className="home">
                <div className="main_left" >
                    <button class="main_left_search">
                        <Link to={'/buscador'} >
                            <span><i class="fa-solid fa-magnifying-glass"></i> Buscar</span>
                        </Link>
                    </button>
                    <CardInfo />
                    <button class="custom-btn btn-8"  >
                        <Link to={'/precios'}>
                            <span>Anunciar</span>
                        </Link>
                    </button>
                </div>
                <div className="main_content" >

                    <Carrosel />

                    <h2 className="title_forums_">Temas de conversación : {name}</h2>

                    <Statuforum />

                    {forums.map(forum => (
                        <Forumcard key={forum._id} forum={forum} />
                    ))}

                    {
                        loading && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                    }


                    {/*{loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <>
                            {forums.map(forum => (
                                <Forumcard key={forum._id} forum={forum} />
                            ))}
                        </>

                            )}*/}

                </div>
                <div className="main_rigth" >
                    <div className='main_rigth_sugeVip'>
                        <h1>Sugerencias</h1>
                        <div className='main_rigth_sugeVip_content'>
                            <Suggestions />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CategoriaPage;
