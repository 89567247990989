import React from 'react'

const ErrorFont = () => {
    return (
        <div>
            <div id="mai_error">
                <div class="fof__">
                    <h1>Error 404</h1>
                </div>
            </div>
        </div>
    )
}

export default ErrorFont