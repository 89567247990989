import React, { useContext } from 'react'
import LeftSide from '../../components/message/LeftSide'

import { GlobalState } from '../../GlobalState'
import NotFound from '../../components/NotFound'

import logo from '../../images/lobo.png'

const Message = () => {
    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //


    return (
        <>
            {
                isUservip
                    ? <div className="message d-flex">
                        <div className="col-md-4 border-right px-0">
                            <LeftSide />
                        </div>
                        <div className="col-md-8 px-0 right_mess">
                            <div className="d-flex justify-content-center 
                    align-items-center flex-column h-100">
                        <img src={logo}/>

                                {/*<i className="fab fa-facebook-messenger text-primary"
                                    style={{ fontSize: '5rem' }} />*/}
                                <h4 style={{color:'#fb4802'}} >Mensajes</h4>
                            </div>
                        </div>
                    </div>
                    : <NotFound />
            }
        </>
    )
}

export default Message
