import React, { useContext, useState, useEffect } from 'react'
import { GlobalState } from '../GlobalState'
import ListItem from '../components/anuncios/anuncioutils/ListItem'

function filterByCategory(categoryName, categoryFilters, item) {
  if (!categoryFilters.length) return true;
  return categoryFilters.some((filter) => item[categoryName] === filter);
}

function Listanuncios() {
  const state = useContext(GlobalState)
  const [anuncios] = state.anunciosAPI.anuncio

  /*-------------------*/
  const [item, setItem] = useState(anuncios);

  const getItem = (cat) => {
    var upadatedValue = anuncios.filter((items) => {
      return items.provincia === cat;
    });
    setItem(upadatedValue);
  };
  /*--------------*/
  const appTypeFilters = [...new Set(item.map((d) => d.distrito))];
  const nacionalidadFilters = [...new Set(anuncios.map((d) => d.nacionalidad))];

  let [appTypes, setAppTypes] = useState([]);
  let [nacionalidads, setNacionalidads] = useState([]);


  let filteredDevelopers = anuncios.filter((dev) => {
    return (
      filterByCategory("distrito", appTypes, dev) &&
      filterByCategory("nacionalidad", nacionalidads, dev)
    )
  });

  /*---filter---*/

  /*------*/

  return (
    <>
      <div className="depart">
        <h2>Provincia</h2>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Puno")}
        >
          Puno
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Amazonas")}
        >
          Amazonas
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Lima")}
        >
          Lima
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Callao")}
        >
          Callao
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Amazonas")}
        >
          Amazonas
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => getItem("Loreto")}
        >
          Loreto
        </button>
        <button className="btn btn-outline-secondary" onClick={() => setItem(anuncios)}>
          All
        </button>
      </div>

      <div className="contenedor">
        {appTypeFilters.map((type) => (
          <div class="checkbox">
            <input
              name="appType"
              type="checkbox"
              value={type}
              onClick={(e) => {
                if (e.target.checked) {
                  setAppTypes([...appTypes, type]);
                } else {
                  setAppTypes(
                    appTypes.filter((distrito) => distrito !== type)
                  );
                }
              }}
            />
            <label>{type}</label>
          </div>
        ))}
      </div>
      <div>
        {nacionalidadFilters.map((filter) => (
          <label>
            {filter}
            <input
              name="nacionalidad"
              type="checkbox"
              value={filter}
              onClick={(e) => {
                if (e.target.checked) {
                  setNacionalidads([...nacionalidads, filter]);
                } else {
                  setNacionalidads(
                    nacionalidads.filter((nacionalidad) => nacionalidad !== filter)
                  );
                }
              }}
            />
          </label>
        ))}
      </div>

      <div className="developers">
        {filteredDevelopers.map((d) => (
          <ListItem key={d._id} anuncio={d} />
        ))}
      </div>

    </>
  )
}

export default Listanuncios