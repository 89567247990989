import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { Marker as LeafletMarker, icon } from 'leaflet'
import L from 'leaflet'
import LocateControl from "../mapaubicacion/Geo-local"

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
//import ShareModal from '../ShareModal';
import { BASE_URL } from '../../utils/config'

import moment from 'moment'
import ShareRed from '../ShareModalRed';


LeafletMarker.prototype.options.icon = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});

const ContePublic = ({ detailAnuncio, theme }) => {
    /*---------pruebasNewdetail-----*/
    const [sliderIndex, setSliderIndex] = useState(1);
    const [width, setWidth] = useState(0);
    const [start, setStart] = useState(0);
    const [change, setChange] = useState(9);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCopyUrl = (url) => {
        navigator.clipboard.writeText(url);
        alert('Se copio con éxito el enlace');
    };

    const slideRef = useRef();

    useEffect(() => {
        if (!slideRef.current) return;
        const scrollWidth = slideRef.current.scrollWidth;
        const childrenElementCount = slideRef.current.childElementCount;
        const width = scrollWidth / childrenElementCount;
        setWidth(width);
    }, []);

    function plusSlides(n) {
        setSliderIndex((prev) => prev + n);
        slideShow(sliderIndex + n);
    }

    function slideShow(n) {
        if (n > detailAnuncio.images.length) {
            setSliderIndex(1);
        }
        if (n < 1) {
            setSliderIndex(detailAnuncio.images.length);
        }
    }

    function dragStart(e) {
        setStart(e.clientX);
    }

    function dragOver(e) {
        let touch = e.clientX;
        setChange(start - touch);
    }

    function dragEnd(e) {
        if (change > 0) {
            slideRef.current.scrollLeft += width;
        } else {
            slideRef.current.scrollLeft -= width;
        }
    }

    useEffect(() => {
        if (!slideRef.current || !width) return;
        let numOfthumb = Math.round(slideRef.current.offsetWidth / width);
        slideRef.current.scrollLeft =
            sliderIndex > numOfthumb ? (sliderIndex - 1) * width : 0;
    }, [width, sliderIndex]);

    //------------
    const posicion1 = detailAnuncio.latitud;
    const posicion2 = detailAnuncio.longitud;
    const ubicacion = (posicion1 !== null && posicion2 !== null) ? [posicion1, posicion2] : null;

    if (detailAnuncio.length === 0) return null;

    return (
        <div>
            <div class="book-store">
                <div class="book-slide">
                    <div
                        class="book js-flickity"
                        data-flickity-options='{ "wrapAround": true }'
                    >
                        <div class="book-cell">
                            <div className="book-cell-imges" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                <img src="https://res.cloudinary.com/dtbxszqkz/image/upload/v1688169456/samples/Sin_t%C3%ADtulo-1_tdefk4.jpg" />
                            </div>
                            <div class="book-img">
                                <Link to={detailAnuncio.user ? `/profile/${detailAnuncio.user._id}` : '/'}>
                                    <img
                                        src={detailAnuncio.user && detailAnuncio.user.avatar ? detailAnuncio.user.avatar : 'https://cdn.icon-icons.com/icons2/2942/PNG/512/profile_icon_183860.png'}
                                        alt=""
                                        class="book-photo" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                    />
                                </Link>
                            </div>
                            <div class="book-content">
                                <div class="book-title" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <h1>{detailAnuncio.nombre}</h1>
                                </div>
                                <div class="book-author" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <h2>{detailAnuncio.fullnombre} | {detailAnuncio.edad} añitos | {detailAnuncio.nacionalidad}</h2>
                                </div>
                                <div class="rate" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <fieldset class="rating">
                                        <input type="checkbox" id="star5" name="rating" value="5" />
                                        <label class="full" for="star5"></label>
                                        <input type="checkbox" id="star4" name="rating" value="4" />
                                        <label class="full" for="star4"></label>
                                        <input type="checkbox" id="star3" name="rating" value="3" />
                                        <label class="full" for="star3"></label>
                                        <input type="checkbox" id="star2" name="rating" value="2" />
                                        <label class="full" for="star2"></label>
                                        <input type="checkbox" id="star1" name="rating" value="1" />
                                        <label class="full" for="star1"></label>
                                    </fieldset>
                                    <span class="book-voters">1.987 votos</span>
                                </div>
                                <div class="book-sum" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <p>
                                        {detailAnuncio.dcorta}
                                    </p>
                                </div>
                                <div class="book-see" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <div className="buttons-panels">
                                        <span class="buttos-panel">
                                            <i class="fa-solid fa-location-dot"></i>{detailAnuncio.pais}
                                        </span>
                                        <span class="buttos-panel">
                                            15 Post<i class="fa-solid fa-camera"></i>
                                        </span>
                                        <span class="buttos-panel">16 Seguidores</span>
                                        <span class="buttos-perfil">
                                            <Link to={detailAnuncio.user ? `/profile/${detailAnuncio.user._id}` : '/'} className="logo" style={{ color: 'white' }}>
                                                <i class="fa-solid fa-user"></i>Ver Perfil
                                            </Link>
                                        </span>
                                        <span class="buttos-seguir">
                                            <Link to={detailAnuncio.user ? `/profile/${detailAnuncio.user._id}` : '/'} className="logo" style={{ color: 'white' }}>
                                                <i class="fa-solid fa-plus"></i>Seguir
                                            </Link>
                                        </span>
                                        <span class="buttos-llamar">
                                            <i class="fa-solid fa-phone"></i>
                                            <a href={`tel:+${detailAnuncio.telefono}`} style={{ color: '#C0392B' }}>Llamar</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pruebasdetail' >
                <React.Fragment>
                    <section className="product-details">
                        <div className="product-page-img">
                            <div className="big-images" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                {detailAnuncio.images.map((image, index) => (
                                    <div
                                        key={index}
                                        className="mySlides"
                                        style={{
                                            display: index + 1 === sliderIndex ? "block" : "none"
                                        }}
                                    >
                                        <div className="numbertext">
                                            {index + 1} / {detailAnuncio.images.length}
                                        </div>
                                        <img src={image.url} alt="" />
                                    </div>

                                ))}
                                <a href="#!" className="prev" onClick={() => plusSlides(-1)}>
                                    <i class="fa-solid fa-angle-left"></i>
                                </a>
                                <a href="#!" className="next" onClick={() => plusSlides(1)}>
                                    <i class="fa-solid fa-angle-right"></i>
                                </a>
                            </div>

                            <div
                                className="slider-img"
                                draggable={true}
                                ref={slideRef}
                                onDragStart={dragStart}
                                onDragOver={dragOver}
                                onDragEnd={dragEnd}
                            >
                                {detailAnuncio.images.map((image, index) => (
                                    <div style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                        key={index}
                                        className={`slider-box ${index + 1 === sliderIndex ? "active" : ""
                                            }`}
                                        onClick={() => setSliderIndex(index + 1)}
                                    >
                                        <img src={image.url} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="product-page-details" >
                            <strong>{detailAnuncio.titulo}</strong>
                            {/*<Link to="/cart" className=""
                                onClick={() => addCart(detailProduct)}>
                                <i class="fa-regular fa-heart"></i>
    </Link>*/}
                            <p className="product-category" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                {detailAnuncio.fullnombre} - {detailAnuncio.nacionalidad} - {detailAnuncio.distrito} - {detailAnuncio.provincia}
                            </p>

                            <p className="product-price" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                <h3 style={{ color: 'crimson' }}>S/{detailAnuncio.phora}</h3> <smoll>.Hr</smoll>
                            </p>

                            <h1 className='detailt_descripcort'>{detailAnuncio.dcorta}</h1>

                            <p className="small-desc" style={{ color: '#626567' }}>{detailAnuncio.content}</p>
                            <div className='detail-adition'>
                                <div className='detail-edad-pais'>
                                    <span >
                                        {detailAnuncio.edad} años
                                    </span>
                                    <span className='i-locate'>
                                        <i class="fa-sharp fa-solid fa-location-dot" id='fa-location-dot'></i> {detailAnuncio.pais}
                                    </span>
                                    <span className='i-locate_span_' onClick={toggleDropdown}>
                                        <i class="fa-regular fa-share-from-square" style={{ margin: '0' }}></i>
                                    </span>
                                </div>
                                <div>
                                    <div className='detail-clock-span'>
                                        <i class="fa-solid fa-clock" style={{ marginRight: '4px' }}></i>
                                        <span style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >{moment(detailAnuncio.createdAt).fromNow()}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-redes-options">
                                <div class='demo-content'>
                                    <div class="nshare">
                                        <span>Redes:</span>
                                        <div style={{ display: `${detailAnuncio.tiktok.length === 0 ? 'none' : 'block'}` }}>
                                            <a class="nshare-item nshare-tb" href={detailAnuncio.tiktok} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                <i class="fa-brands fa-tiktok"></i>
                                            </a>
                                        </div>
                                        <div style={{ display: `${detailAnuncio.instagram.length === 0 ? 'none' : 'block'}` }}>
                                            <a class="nshare-item nshare-rd" href={detailAnuncio.instagram} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                <i class="fa-brands fa-instagram"></i>
                                            </a>
                                        </div>
                                        <div className='wassap_detail'>
                                            <a class="nshare-item nshare-ws" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                                href={`https://api.whatsapp.com/send?phone=+${detailAnuncio.telefono}&text=hola%20qué%20tal,%20té%20vi%20en%20CariñosasHub?`}>
                                                <i class="fab fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <div style={{ display: `${detailAnuncio.telegram.length === 0 ? 'none' : 'block'}` }}>
                                            <a class="nshare-item nshare-tlg" href={detailAnuncio.telegram} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                <i class="fa-brands fa-telegram"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='vinculo-web-aviso'>
                                <p>
                                    <i class="fa-solid fa-earth-americas"></i><a href={detailAnuncio.only} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >{detailAnuncio.only}</a>.
                                </p>
                            </div>

                            <div className="product-sold">
                                <i class="fa-sharp fa-solid fa-phone" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} > {detailAnuncio.telefono}</i>
                            </div>

                            <div className="cart-btns">
                                <a href={`tel:+${detailAnuncio.telefono}`} className="add-cart" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <i class="fa-solid fa-phone"></i>Llamar
                                </a>
                                <a href={`https://api.whatsapp.com/send?phone=${detailAnuncio.telefono}&text=hola%20qué%20tal,%20té%20vi%20en%20"CariñosasHub"?`} className="add-cart buy-now" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <i class="fa-brands fa-whatsapp"></i> Whatsapp
                                </a>
                            </div>
                        </div>
                    </section>

                    <div className="container-tables-info">
                        <div className="pricing-table">
                            <div className="table-1">
                                <div>
                                    <h1 className="table-title"><b><i class="fa-solid fa-face-grin-hearts"></i> Sobre Mí :</b></h1>
                                </div>
                                <div className='detail-especificos' >
                                    <p><b>EDAD: </b><span> {detailAnuncio.edad} años</span></p>
                                    <p><b>NACIONALIDAD: </b><span> {detailAnuncio.nacionalidad}</span> </p>
                                    <p><b>CONTEXTURA: </b><span> {detailAnuncio.contextura}</span> </p>
                                    <p><b>ESTATURA: </b> <span> Normal</span></p>
                                    <p><b>COLOR CABELLO: </b> <span> {detailAnuncio.cabello}</span></p>
                                    <p><b>COLOR OJOS: </b> <span> {detailAnuncio.ojos}</span></p>
                                    <p><b>COLOR PIEL: </b> <span> {detailAnuncio.piel}</span></p>
                                </div>
                            </div>
                            <div className="table-2">
                                <table class="styled-table">
                                    <thead>
                                        <tr>
                                            <th>TIEMPO</th>
                                            <th>TARIFA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="active-row">
                                            <td>1/2 Hr</td>
                                            <td>S/.{detailAnuncio.pmediahora}</td>
                                        </tr>
                                        <tr class="active-row">
                                            <td>1 Hr</td>
                                            <td>S/.{detailAnuncio.phora}</td>
                                        </tr>
                                        <tr class="active-row">
                                            <td>2 Hr</td>
                                            <td>S/.{detailAnuncio.phora}</td>
                                        </tr>
                                        <tr class="active-row">
                                            <td>Video Llamada</td>
                                            <td>S/.{detailAnuncio.pvideollamada}</td>
                                        </tr>
                                        <tr class="active-row">
                                            <td>Pack De Videos/fotos</td>
                                            <td>S/.{detailAnuncio.ppack}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-3">
                                <div className="table-etiquetas">
                                    <h1><i class="fa-solid fa-hand-point-right"></i>Servicios :</h1>
                                    <button><span>{detailAnuncio.servicio1}</span></button>
                                    <button><span>{detailAnuncio.servicio2}</span></button>
                                    <button><span>{detailAnuncio.servicio3}</span></button>
                                    <button><span>{detailAnuncio.servicio4}</span></button>
                                    <button><span>{detailAnuncio.servicio5}</span></button>
                                    <button><span>{detailAnuncio.servicio6}</span></button>
                                    <button><span>{detailAnuncio.servicio7}</span></button>
                                    <button><span>{detailAnuncio.servicio8}</span></button>
                                </div>
                                <div className="table-etiquetas">
                                    <h1><i class="fa-solid fa-hand-point-right"></i>Servicios Especiales :</h1>
                                    <button><span>{detailAnuncio.especial1}</span></button>
                                    <button><span>{detailAnuncio.especial2}</span></button>
                                    <button><span>{detailAnuncio.especial3}</span></button>
                                    <button><span>{detailAnuncio.especial4}</span></button>
                                    <button><span>{detailAnuncio.especial5}</span></button>
                                    <button><span>{detailAnuncio.especial6}</span></button>
                                    <button><span>{detailAnuncio.especial7}</span></button>
                                    <button><span>{detailAnuncio.especial8}</span></button>
                                </div>
                                <div className="table-etiquetas">
                                    <h1><i class="fa-solid fa-location-dot"></i>Lugar De Encuentro :</h1>
                                    <button><span>{detailAnuncio.lugar1}</span></button>
                                    <button><span>{detailAnuncio.lugar2}</span></button>
                                    <button><span>{detailAnuncio.lugar3}</span></button>
                                    <button><span>{detailAnuncio.lugar4}</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container-descripcion-detail'>
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        <strong style={{ color: '#D98880', filter: theme ? 'invert(1)' : 'invert(0)' }} >Mis Lugares</strong>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                    <div class="accordion-body">
                                        <strong style={{ color: '#909497', filter: theme ? 'invert(1)' : 'invert(0)' }}>Los lugares donde me gusta frecuentar...</strong><p>{detailAnuncio.dlugares}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        <strong style={{ color: '#D98880', filter: theme ? 'invert(1)' : 'invert(0)' }}>Mis Gustos</strong>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div class="accordion-body">
                                        <strong style={{ color: '#909497', filter: theme ? 'invert(1)' : 'invert(0)' }}>Mis gustos son...</strong> <p>{detailAnuncio.dgustos}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        <strong style={{ color: '#D98880', filter: theme ? 'invert(1)' : 'invert(0)' }}>Mi Fisico</strong>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                    <div class="accordion-body">
                                        <strong style={{ color: '#909497', filter: theme ? 'invert(1)' : 'invert(0)' }}>Mi fisico...</strong><p>{detailAnuncio.descripcionfi}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                        <strong style={{ color: '#D98880', filter: theme ? 'invert(1)' : 'invert(0)' }}>Mi Servicio</strong>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                    <div class="accordion-body">
                                        <strong style={{ color: '#909497', filter: theme ? 'invert(1)' : 'invert(0)' }}>El servicio que doy es...</strong> <p>{detailAnuncio.dservicio}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='seccion-ubicacion-aviso'>
                        <h1>Ubicación :</h1>
                        <div className='container-mapa-ubicacion'>
                            <Map center={ubicacion} zoom={16} style={{ width: "100%", height: "100%", filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                />
                                
                                {ubicacion ? (
                                    <Marker
                                        position={ubicacion}
                                        icon={L.divIcon({
                                            className: "custom-div-icon",
                                            html: `
              <div class='marker-pin'>
              <div class='marker-state'>
              <img src=${detailAnuncio.images[0].url} />
              </div>
              </div>
          `,
                                            iconSize: [30, 42],
                                            iconAnchor: [20, 50]
                                        })}
                                    >
                                        <Popup className="popup"
                                            position={ubicacion}>
                                            {detailAnuncio.nombre}
                                        </Popup>
                                    </Marker>
                                ) : (
                                    <img  src='https://www.yaskawa-pillar.mx/img/categorias/no-disponible.jpg' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                )}

                                <LocateControl />
                            </Map>
                        </div>
                    </section>

                    <div className='detalles-particulares'>
                        <span >ID DEL ANUNCIO: {detailAnuncio._id}</span>

                        <span>
                            <Link to={"/contacto"}>
                                DENUNCIAR ANUNCIO
                            </Link>
                        </span>
                        <span>
                            <Link to={"/precios"}>
                                PUBLICAR ANUNCIO
                            </Link>
                        </span>
                    </div>

                </React.Fragment>
                {isOpen && (
                    <div className='dropdows_menu_'>
                        <div className='dropdows_menu_compart'>
                            <i class="fa-regular fa-rectangle-xmark" onClick={toggleDropdown} ></i>
                            <div>
                                <div className='icon_compart_content'>
                                    <div className='icon_compart_i' onClick={() => handleCopyUrl(`${BASE_URL}/publicidad/${detailAnuncio._id}`)}>
                                        <i className="fa-solid fa-link"></i>
                                        <small>Copiar</small>
                                    </div>
                                </div>
                                <label>Compartir</label>
                                <ShareRed url={`${BASE_URL}/kines/${detailAnuncio._id}`}
                                   text={`
                                   ${'👋'}${'👋'} Hola, mi nombre es ${detailAnuncio.nombre}
                                   ${'🎂'} mi edad es ${detailAnuncio.edad} años
                                   ${'🌎'} soy ${detailAnuncio.nacionalidad}
                                   ${'📍'} me ubico en ${detailAnuncio.distrito}, ${detailAnuncio.provincia}
                                   **${detailAnuncio.dcorta}...**
                                   conoce más de mí en...
                               `}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ContePublic