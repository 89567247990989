import React from 'react'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'


function ImgCarousel({ anuncio, isAdmin, handleCheck }) {
    const { theme } = useSelector(state => state)

    return (
        <div className="product_img">
            {
                isAdmin && <input type="checkbox" checked={anuncio.checked}
                    onChange={() => handleCheck(anuncio._id)}  />
            }
            <img src={anuncio.images[0].url} alt="" style={{ opacity: 1, filter: theme ? 'invert(1)' : 'invert(0)' }} />

            <Link id="view" to={`/publicidad/${anuncio._id}`}>
                <h2>{anuncio.nombre}</h2>
            </Link>
        </div>
    );
}

export default ImgCarousel;
