import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


const Tipeusuario = () => {
    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    /*-------------------*/
  const [usernew, setUsernew] = useState('')

  useEffect(() => {
    if (auth.user.role === 'admin') setUsernew("Administrador")
    if (auth.user.role === 'moderador') setUsernew("Moderador")
    if (auth.user.role === 'uservip') setUsernew("Usuario Suscrito")
  }, [auth.user])

  /*-------------------*/

  return (
    <div>
      {
        auth.user.role === 'user'
        ? <span></span>
        :<button class="btn-hover_new color-2" style={{filter: theme ? 'invert(1)' : 'invert(0)'}} >{usernew}</button>
      }
    </div>
  )
}

export default Tipeusuario