import React from 'react'
import Avatar from '../Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

const StatusCom = () => {
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <>
            <button class="btn_crear_profileC" 
                onClick={() => dispatch({ type: GLOBALTYPES.STATUSCOMERS, payload: true })} >
                <span>
                    <i class="fa-solid fa-plus"></i> Crear Perfil
                </span>
            </button>
        </>

    )
}

export default StatusCom
