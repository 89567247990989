import React, { useEffect, useState } from 'react'
import Status from '../components/home/Status'
import Panelvip from '../components/headerPro/Panelvip'
import { useSelector } from 'react-redux'
import LoadIcon from '../images/loading.gif'

import Carrosel from '../components/slickcarrosel/Carrosel'
import CarroselSuges from '../components/slickcarrosel/CarroselSuges'
import ComponetsHome from '../components/componesthome/ComponetsHome'
import Tipeusuario from '../components/Tipeusuario'
import { Link } from 'react-router-dom'
import Suggestions from '../components/home/Suggestions'
import CardInfo from '../components/profile/CardInfo'


let scroll = 0;

const Home = () => {
    const { theme } = useSelector(state => state)

    window.addEventListener('scroll', () => {
        if (window.location.pathname === '/') {
            scroll = window.pageYOffset
            return scroll;
        }
    })
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: scroll, behavior: 'smooth' })
        }, 100)
    }, [])


    return (

        <div className="home">

            <div className="main_left">
                <button class="main_left_search">
                    <Link to={'/buscador'} >
                        <span><i class="fa-solid fa-magnifying-glass"></i> Buscar</span>
                    </Link>
                </button>
                <CardInfo />
                <button class="custom-btn btn-8" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <Link to={'/precios'}>
                        <span>Anunciar</span>
                    </Link>
                </button>
            </div>

            <div className="main_content" >
                <div><Panelvip /></div>

                <CarroselSuges />
                <Carrosel />
                <Tipeusuario />
                <Status />

                {/*<Link to={'/forum'} className='link_forum_acces' style={{ textDecoration: 'none', position: 'relative' }}>
                    <button className='Btn_enlace_forum'>
                        <i class="animation"></i>Temas de Conversación<i class="animation"></i>
                    </button>
                </Link>*/}

               <Link to={'/contenidos-hots'} className='link_forum_acces' style={{ textDecoration: 'none', position: 'relative' }}>
                    <button className='Btn_enlace_forum'>
                        <i class="animation"></i>Contenidos Hot<i class="animation"></i>
                    </button>
                </Link>


                <div className='link_content_btn'>
                    <Link to={'/publicidad'} >
                        <button className='btn_public_acce'>
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                            Panel Publicitario
                        </button>
                    </Link>
                </div>

                <ComponetsHome />
            </div>

            <div className="main_rigth" >
                <div className='main_rigth_sugeVip'>
                    <h1>Sugerencias</h1>
                    <div className='main_rigth_sugeVip_content'>
                        <Suggestions />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Home