import React, { useContext, useState, useEffect, Component } from 'react'
import { useParams, Link } from 'react-router-dom'
import { GlobalState } from '../../GlobalState'
import ProductItem from '../../components/mainpages/utils/productItem/ProductItem'
import axios from 'axios'
import PaypalButton from '../../components/mainpages/cart/PaypalButton'
import VideoPlayer from '../../components/mainpages/utiliscontenthub/VideoPlayerHub'
import { TikTok } from "react-tiktok";
import Cardhub from '../../components/mainpages/utiliscontenthub/Conthubcard'


function DetailHub() {
    const params = useParams()
    const state = useContext(GlobalState)
    const [products] = state.productsAPI.products
    const addCart = state.userAPI.addCart
    const [detailProduct, setDetailProduct] = useState([])
    const [paidDescription, setPaidDescription] = useState(null);
    const [error, setError] = useState(null);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [detailadd, setDetaiadd] = useState()
    
    ////modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = "hidden"; // Desactiva el scroll del fondo
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto"; // Restaura el scroll del fondo
    };

    // Asegúrate de que el scroll del body se restaure incluso si el componente se desmonta
    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    ///eleccion moneda

    // Estado para rastrear la moneda seleccionada
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const [selectedPrice, setSelectedPrice] = useState(detailProduct.price); // Inicialmente en USD

    // Función para manejar el cambio de moneda
    const handleCurrencyChange = (e) => {
        const currency = e.target.value;
        setSelectedCurrency(currency);

        // Ajustar el precio según la moneda seleccionada
        switch (currency) {
            case "USD":
                setSelectedPrice(detailProduct.price);
                break;
            case "EUR":
                setSelectedPrice(detailProduct.price * 0.85);
                break;
            case "PEN":
                setSelectedPrice(detailProduct.price * 3.8);
                break;
            default:
                setSelectedPrice(detailProduct.price);
        }
    };

    const [expandedPlan, setExpandedPlan] = useState(null); // null indica que ningún plan está expandido
    const toggleExpand = (planId) => {
        setExpandedPlan((prevPlan) => (prevPlan === planId ? null : planId));
    };


    ////zoom en la imagen
    const [selectedImage, setSelectedImage] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);

    const openImage = (image) => {
        setSelectedImage(image);
        setZoomLevel(1);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    const handleZoom = (direction) => {
        setZoomLevel((prevZoom) => Math.max(0.5, prevZoom + direction));
    };

    //

    useEffect(() => {
        if (params.id) {
            const selectedProduct = products.find(product => product._id === params.id);
            if (selectedProduct) setDetailProduct(selectedProduct);
        }
    }, [params.id, products]);


    useEffect(() => {
        const checkIfPaid = async () => {
            try {
                const res = await axios.get(`/api/products/${params.id}/checkPaid`, {
                    headers: { Authorization: state.token[0] }
                });
                if (res.data.paid) {
                    // Si el usuario ya pagó, se muestra la descripción completa
                    setPaidDescription(detailProduct.description);
                    setDetaiadd(prev => ({
                        ...prev,
                        urlsmovies: res.data.urlsmovies,
                        arrayimages: res.data.arrayimages
                    }));
                    setError(null);
                } else {
                    // Si no ha pagado, muestra el mensaje de error
                    setError("No puede ver todo el contenido de este perfil.");
                }
            } catch (error) {
                setError("Todavia no tiene acceso a todo el contenido.");
            }
        };

        if (detailProduct._id) {
            checkIfPaid();
        }
    }, [detailProduct._id, state.token, params.id]);

    const tranSuccess = async (paymentDetails) => {
        try {
            const res = await axios.post('/api/payment', {
                productId: detailProduct._id,
                paymentID: paymentDetails.id,
                address: paymentDetails.payer.address
            }, {
                headers: { Authorization: state.token[0] }
            });

            setPaymentSuccessful(true);
            setError(null);
            setPaidDescription(detailProduct.description);  // Actualiza para mostrar la descripción completa
            setDetaiadd(prev => ({
                ...prev,
                urlsmovies: res.data.urlsmovies,
                arrayimages: res.data.arrayimages
            })); // Incluye los nuevos campos
        } catch (err) {
            setError("Hubo un problema al registrar el pago.");
        }
    };

    //////////

    if (!detailProduct || Object.keys(detailProduct).length === 0) return null;


console.log(detailProduct.urltiktoks)
    return (
        <div className='content_detail_hubmovie'>
            <div class="">
                <section class="member-details">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-4">
                                <div class="img-container">
                                    <img src={detailProduct.images.url} alt="team member" class="img-full" />
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-8">
                                <div class="member_designation">
                                    <h2><i class="fa-solid fa-star"></i> {detailProduct.title}</h2>
                                    <span>
                                        <Link>
                                            <i class="fa-solid fa-arrow-up-right-from-square"></i> {detailProduct.nombre}
                                        </Link>
                                    </span>
                                </div>
                                <div class="member_desc">
                                    <p>
                                        {detailProduct.content}
                                    </p>
                                    <ul class="styled_list">
                                        <li class=""><i class="fa fa-chevron-circle-right" aria-hidden="true"></i> {detailProduct.nombre}</li>
                                    </ul>
                                </div>
                                <div class="bg-image ">
                                    {/*<img src={detailProduct.images.url} atl='' />*/}
                                    <img src={detailProduct.urluno} atl='' />
                                    <div class="member_contact">
                                        <div class="row">
                                            <div class="col-lg-4  mb-3 mb-lg-0">
                                                <div class="media-box">
                                                    <div class="media-icon">
                                                        <i class="fa-solid fa-user"></i>
                                                    </div>
                                                    <div class="media-content">
                                                        <h5>Perfil</h5>
                                                        <p><a href="callto">(+1) 251-235-3256</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  mb-3 mb-lg-0">
                                                <div class="media-box">
                                                    <div class="media-icon">
                                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="media-content">
                                                        <h5>Email</h5>
                                                        <p><a href="mailto">info@example.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="social-icons">
                                                    <button class="btn btn-social outlined"><i class="fa-brands fa-facebook"></i></button>
                                                    <button class="btn btn-social outlined"><i class="fa-brands fa-instagram"></i></button>
                                                    <button class="btn btn-social outlined"><i class="fa-brands fa-tiktok"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <h2 className='vid_tiktok_title'>Videos Populares</h2>

            <div className='content_tiktok_vid'>
            {Array.isArray(detailProduct.urltiktoks) && detailProduct.urltiktoks.length > 0 ? (
                detailProduct.urltiktoks.map((vid, index) => (
                    <div key={index}>
                        <TikTok url={vid} />
                    </div>
                ))
            ) : (
                <p>No hay tiktoks disponibles</p>
            )}
        </div>
        

            <div>
                <div className="gallery-container">
                    {/* Sección de Imágenes */}
                    <div className="image-gallery">
                        {detailProduct?.arrayimagesfakes?.map((img, index) => (
                            <div
                                key={`img-${index}`}
                                className="gallery-item"
                                onClick={() => openImage(img.url)}
                            >
                                <img src={img.url} alt={`Image ${index}`} />
                            </div>
                        ))}
                    </div>
                    {/* Sección de Videos */}
                    <div className="video-gallery">
                        {detailProduct?.urlsmoviesfakes?.map((video, index) => (
                            <div key={`video-${index}`} className="video-container">
                                <video src={video} controls />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {selectedImage && (
                <div className="custom-image-viewer" onClick={closeImage}>
                    <div
                        className="custom-image-viewer-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ transform: `scale(${zoomLevel})` }}
                        />
                        <div className="custom-image-controls">
                            <button onClick={() => handleZoom(0.2)}>+</button>
                            <button onClick={() => handleZoom(-0.2)}>-</button>
                            <button onClick={closeImage}>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}


            {paymentSuccessful ? (
                <h3>Usted tiene acceso a este contenido</h3>
            ) : (
                paidDescription ? (
                    <div>
                        <h3>Usted Puede ver el contenido completo</h3>
                    </div>
                ) : (
                    <>
                        {/*<span>No tiene acceso</span>*/}
                    </>
                )
            )}


            {paidDescription ? (
                <>
                    {/*<p>Descripción completa: {paidDescription}</p>*/}
                    <div className="main-container">
                        <div>
                            <div className="gallery-container">
                                {/* Sección de Imágenes */}
                                <div className="image-gallery">
                                    {detailadd?.arrayimages?.map((img, index) => (
                                        <div
                                            key={`img-${index}`}
                                            className="gallery-item"
                                            onClick={() => openImage(img.url)}
                                        >
                                            <img src={img.url} alt={`Image ${index}`} />
                                        </div>
                                    ))}
                                </div>

                                {/* Sección de Videos */}
                                <div className="video-gallery">
                                    {detailadd?.urlsmovies?.map((video, index) => (
                                        <div key={`video-${index}`} className="video-container">
                                            <video controls>
                                                <source src={video} type="video/mp4">
                                                </source>
                                            </video>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h3 className="error">{error}</h3>
                    <div style={{ margin: '20px auto' }}>
                        <div className='conten_btn_hubpaypal' >
                            <div className='conten__hubpaypal_he'>
                                <span>Accede a todo el contenido a solo..
                                    <br />
                                    <span id='nombre_multimet_cantidad'>{detailProduct.multimediaCount} ,entre imagenes y videos</span>
                                </span>
                            </div>
                            <div className='preci_content_hub' >
                                <h2>S/. {detailProduct.price}</h2>
                                {/*<small>mes</small>*/}
                            </div>
                        </div>
                        <div className='btn_content_hub_utls' >

                            <div className='content-private-mediahub'>
                                <i class="fa-solid fa-lock"></i>
                                <button onClick={openModal} className="open-modal-button">
                                    Abrir Publicaciones
                                </button>
                            </div>

                            <div>
                                <div className="gallery-container">
                                    {/* Sección de Imágenes */}
                                    <div className="image-gallery">
                                        {detailProduct?.arrayimagesfakes?.map((img, index) => (
                                            <div
                                                key={`img-${index}`}
                                                className="gallery-item"
                                                onClick={() => openImage(img.url)}
                                            >
                                                <img src={img.url} alt={`Image ${index}`} />
                                            </div>
                                        ))}
                                    </div>

                                    <div className="video-gallery">
                                            <div className="video-container">
                                                <video src={detailProduct.urlsmoviesfakes[0]} controls />
                                            </div>
                                            <div className="video-container">
                                                <video src={detailProduct.urlsmoviesfakes[0]} controls />
                                            </div>
                                    </div>
                                    

                                    {/*<div className="video-gallery">
                                        {detailProduct?.urlsmoviesfakes?.map((video, index) => (
                                            <div key={`video-${index}`} className="video-container">
                                                <video src={video} controls />
                                            </div>
                                        ))}
                                    </div>*/}
                                </div>
                            </div>

                            {isModalOpen && (
                                <div className="modal-overlay" onClick={closeModal}>
                                    <div className={`modal-content_hub ${expandedPlan ? "expanded" : ""}`} onClick={(e) => e.stopPropagation()}>
                                        <button className="close-button" onClick={closeModal}>
                                            &times;
                                        </button>
                                        <div className="con-items">
                                            <div className="item item1">
                                                <header>
                                                    <h3>Solo este Contenido</h3>
                                                    <p><b>S/. {detailProduct.price}</b></p>
                                                </header>
                                                <ul>
                                                    <li><i class="bx bx-check"></i> Acceso a visualizaciones de <b>Publicaciones</b></li>
                                                    <li><i class="bx bx-check"></i> Descarga de <b>Videos e Imágenes</b> <small>disponibles</small></li>
                                                    <li><i class="bx bx-check"></i> Aplicable solo a <b>{detailProduct.nombre}</b></li>
                                                </ul>
                                                <button
                                                    onClick={() => toggleExpand("plan1")}
                                                    className={expandedPlan === "plan1" ? "btn_plans_medianClose" : "btn_plans_medianOpen"}
                                                >
                                                    {expandedPlan === "plan1" ? "Cerrar" : "Elegir Pago"}
                                                </button>
                                                {expandedPlan === "plan1" && (
                                                    <div className="extra-info">
                                                        <p>Selecciona tu moneda:</p>
                                                        <div className='content_money_hub'>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="currency"
                                                                    value="USD"
                                                                    checked={selectedCurrency === "USD"}
                                                                    onChange={handleCurrencyChange}
                                                                />
                                                                USD: ${detailProduct.price}
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="currency"
                                                                    value="EUR"
                                                                    checked={selectedCurrency === "EUR"}
                                                                    onChange={handleCurrencyChange}
                                                                />
                                                                EUR: €{(detailProduct.price * 0.85).toFixed(2)}
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="currency"
                                                                    value="PEN"
                                                                    checked={selectedCurrency === "PEN"}
                                                                    onChange={handleCurrencyChange}
                                                                />
                                                                PEN: S/. {(detailProduct.price * 3.8).toFixed(2)}
                                                            </label>
                                                        </div>
                                                        <span>Seleciones una moneda:  {selectedPrice} </span>
                                                        <div className="paypal-button-container" style={{ marginTop: '20px' }}>
                                                            <PaypalButton
                                                                total={selectedPrice} // Pasamos el precio seleccionado
                                                                tranSuccess={tranSuccess}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="item color item2">
                                                <span className="badge">Planes Mensual</span>
                                                <header>
                                                    <h3>Standares</h3>
                                                </header>
                                                <ul>
                                                    <li><i class="bx bx-check"></i> Visualización ilimitada de <b>Todas las Publicaciones</b></li>
                                                    <li><i class="bx bx-check"></i> Descarga de <b>Videos e Imágenes</b></li>
                                                    <li><i class="bx bx-check"></i> Acceso completo a todas las publicaciones en la <b>Lista</b></li>
                                                </ul>
                                                <button
                                                    onClick={() => toggleExpand("plan2")}
                                                    className={expandedPlan === "plan2" ? "btn_plans_medianClose" : "btn_plans_medianOpen"}
                                                >
                                                    {expandedPlan === "plan2" ? "Cerrar" : "Planes"}
                                                </button>
                                                {expandedPlan === "plan2" && (
                                                    <div className="extra-info">
                                                        <p>Elegir planes para poder tener acceso a todas las publicas de tus creadoras de contenido y cariñositas.</p>

                                                        <Link to={'/precios'} >Elegir Planes</Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            <div>
                <h2 className='conten_relacion_detailhub'>Contenido Relacionado</h2>
                <div className="products">
                    {
                        products.map(product => {
                            return product.category === detailProduct.category
                                ? <Cardhub key={product._id} product={product} /> : null
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default DetailHub