import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../redux/actions/authAction'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import Avatar from '../Avatar'

import { GlobalState } from '../../GlobalState'

import Modal from './Modal'



const Menu = () => {
    const navLinks = [
        { label: 'Home', icon: 'home', path: '/', text: 'Home' },
        { label: 'Discover', icon: 'explore', path: '/discover', text: 'Descubrir' },
        { label: 'Catalogo', icon: 'favorite', path: '/catalogo', text: 'Anuncio' },
        //{ label: 'Message', icon: 'textsms', path: '/message', text: 'Mensaje' }
    ]

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //

    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const isActive = (pn) => {
        if (pn === pathname) return 'active'
    }

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Modal show={show} handleClose={handleClose} />

            <div className="menu">
                <ul className="navbar-nav flex-row">

                    {
                        navLinks.map((link, index) => (
                            <li className={`nav-item px-2 ${isActive(link.path)}`} key={index} >
                                <Link className="nav-link " to={link.path} id='menu_nav_item' >
                                    <span className="material-icons">{link.icon} </span>
                                    <smoll className='menu_nav_item_smoll'>{link.text}</smoll>
                                </Link>
                            </li>
                        ))
                    }

                    {
                        isUservip
                            ? <li className={`nav-item px-2 ${isActive('/message')}`} style={{ cursor: 'pointer' }}>
                                <Link className="nav-link " to={'/message'} id='menu_nav_item' >
                                    <span className="material-icons">textsms</span>
                                    <smoll className='menu_nav_item_smoll'>Mensaje</smoll>
                                </Link>
                            </li>
                            : <li className="nav-item px-2" onClick={handleShow} style={{ cursor: 'pointer' }}>
                                <a className="nav-link " id='menu_nav_item' >
                                    <span className="material-icons">textsms</span>
                                    <smoll className='menu_nav_item_smoll'>Mensaje</smoll>
                                </a>
                            </li>

                    }

                    <li className="nav-item dropdown" style={{ opacity: 1, display: 'block' }}>
                        <span className="nav-link dropdown-toggle" id="navbarDropdown"
                            role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <Avatar src={auth.user.avatar} size="medium-avatar" />
                        </span>

                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <label className="dropdown-item">

                                Hola <span style={{ color: 'red' }}>{auth.user.username}</span>
                            </label>
                            <div className="dropdown-divider" ></div>
                            <Link className="dropdown-item" to={`/profile/${auth.user._id}`} >Perfil</Link>
                            <label htmlFor="theme" className="dropdown-item"
                                onClick={() => dispatch({
                                    type: GLOBALTYPES.THEME, payload: !theme
                                })}>

                                {theme ? 'Modo Claro' : 'Modo Oscuro'}
                            </label>

                            <div className="dropdown-divider" ></div>
                            <Link className="dropdown-item" to="/"
                                onClick={() => dispatch(logout())}>
                                Salir
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </>


    )
}

export default Menu
