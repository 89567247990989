import React, { useState, useEffect, useContext } from 'react'
import PostThumb from '../PostThumb'
import LoadIcon from '../../images/loading.gif'
import LoadMoreBtn from '../LoadMoreBtn'
import { getDataAPI } from '../../utils/fetchData'
import { PROFILE_TYPES } from '../../redux/actions/profileAction'
import { Link } from 'react-router-dom'

import { GlobalState } from '../../GlobalState'
import { useSelector } from 'react-redux'

const Posts = ({ auth, id, dispatch, profile }) => {
    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    const { theme } = useSelector(state => state)
    //
    const [posts, setPosts] = useState([])
    const [result, setResult] = useState(9)
    const [page, setPage] = useState(0)
    const [load, setLoad] = useState(false)

    const [nvideo, setNvideo] = useState()
    const [nposts, setNposts] = useState()
    const [nmedia, setNmedia] = useState()

    useEffect(() => {
        profile.posts.forEach(data => {
            if (data._id === id) {
                setPosts(data.posts)
                setResult(data.result)
                setNposts(data.totalPosts)
                setNvideo(data.totalVideos)
                setNmedia(data.totalMedia)
                setPage(data.page)
            }
        })
    }, [profile.posts, id])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`user_posts/${id}?limit=${page * 9}`, auth.token)
        const newData = { ...res.data, page: page + 1, _id: id }
        dispatch({ type: PROFILE_TYPES.UPDATE_POST, payload: newData })
        setLoad(false)
    }

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    return (
        <div>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px', filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-photo-film"></i>
                        <h4>
                            Para poder acceder a más contenido del usuario tiene que suscribirse, 
                            es facil y no necesita de tarjeta, solo a travez de nuestras
                            Redes Sociales.
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <div className='cantidad_content'>
                <h4>{nposts} <span>Post</span></h4>
                <h4>{nmedia} <span>Fotos</span></h4>
                <h4>{nvideo} <span>Videos</span></h4>
            </div>

            <PostThumb posts={posts} result={result} />

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }
            {
                posts.length > 8 ? (
                    <>
                        {auth.user._id === id || isUservip ? (
                            <LoadMoreBtn
                                result={result}
                                page={page}
                                load={load}
                                handleLoadMore={handleLoadMore}
                            />
                        ) : (
                            <div id='btn_LoadMoreBtn'>
                                <button onClick={handleButtonClick}>Cargar más</button>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )
            }
        </div>
    )
}

export default Posts
