import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { login } from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

//import MainPages from '../components/mainpages/Pages'


const Login = () => {

    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData

    const [typePass, setTypePass] = useState(false)

    const { auth } = useSelector(state => state)
    const dispath = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (auth.token) history.push("/")
    }, [auth.token, history])

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        dispath(login(userData))
    }

    //login con google



    useEffect(() => {
        // Inicializa el cliente de Google Identity Services (GIS) después de que el script se cargue
        window.google.accounts.id.initialize({
            client_id: "426851379081-oajkgll6eusqkvs38gcb2jpo815om88v.apps.googleusercontent.com", // Asegúrate de usar el nuevo Client ID de la consola de Google
            callback: handleCredentialResponse,
        });

        // Renderiza el botón de Google
        window.google.accounts.id.renderButton(
            document.getElementById("google-signin-btn"),
            { theme: "outline", size: "large" }  // Puedes personalizar el botón aquí
        );
    }, []);

    const handleCredentialResponse = async (response) => {
        console.log(response);
        // Aquí puedes enviar el token al backend para verificar el inicio de sesión
        try {
            const res = await axios.post('/api/google_login', { tokenId: response.credential })

            setUserData({ ...userData, error: '', success: res.data.msg })
            localStorage.setItem('firstLogin', true)

            dispath(login(userData))
            history.push('/')
        } catch (err) {
            err.response.data.msg &&
                setUserData({ ...userData, err: err.response.data.msg, success: '' })
        }
    };



    return (
        <div className="auth_page-login">
            <form onSubmit={handleSubmit} >
                <div className="titulo_login_">
                    <Link to="/" style={{ textDecoration: 'none' }} >
                        <h1 className="gradient-text" id='logo_css'
                            onClick={() => window.scrollTo({ top: 0 })}>
                            Cariñosas Hub
                        </h1>
                    </Link>
                </div>
                <div class="social-container">
                    <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                    <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                    <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email" aria-describedby="emailHelp"
                        onChange={handleChangeInput} value={email} />
                    <small id="emailHelp" className="form-text text-muted">
                        Su correo se mantendra en estricto privado
                    </small >
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>

                    <div className="pass">
                        <input type={typePass ? "text" : "password"} className="form-control" id="exampleInputPassword1"
                            onChange={handleChangeInput} value={password.replace(/ /g, '')} name="password" />
                        <small onClick={() => setTypePass(!typePass)} >
                            {typePass ? <i class="fa-solid fa-eye-slash"></i> : <i class="fa-solid fa-eye"></i>}
                        </small>
                    </div>
                </div>
                <button type="submit" className="btn-dark-bt"
                    disabled={email && password ? false : true}>
                    Entrar
                </button>
                <p className="my-2" style={{ margin: '10px auto' }} >
                    no tienes una cuenta? <Link to="/register" style={{ color: "crimson" }} >Regístrate ahora</Link>
                </p>

                <div id="google-signin-btn"></div> {/* Aquí se renderizará el botón de Google */}

                {/*<p className="my-2">
                    productos<Link to="/productos" style={{ color: "crimson" }} >productos</Link>
    </p>*/}
            </form>
        </div>
    )
}

export default Login