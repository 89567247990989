import { FORUM_TYPES } from '../actions/forumAction'
import { EditData, DeleteData } from '../actions/globalTypes'


const initialState = {
    loading: false,
    forums: [],
    result: 0,
    page: 2
}

const forumReducer = (state = initialState, action) => {
    switch (action.type){
        case FORUM_TYPES.CREATE_FORUM:
            return {
                ...state,
                forums: [action.payload, ...state.forums]
            };
        case FORUM_TYPES.LOADING_FORUM:
                return {
                    ...state,
                    loading: action.payload
                };
        case FORUM_TYPES.GET_FORUMS:
                return {
                    ...state,
                    forums: action.payload.forums,
                    result: action.payload.result,
                    page: action.payload.page
                    };
        case FORUM_TYPES.UPDATE_FORUM:
                return {
                    ...state,
                    forums: EditData(state.forums, action.payload._id, action.payload)
                    };
        case FORUM_TYPES.DELETE_FORUM:
                return {
                    ...state,
                    forums: DeleteData(state.forums, action.payload._id)
                    };
        
        default:
            return state;
    }
}

export default forumReducer