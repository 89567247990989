import { PUBLIC_TYPES } from '../actions/publicAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    publics: [],
    result: 0,
    page: 2
}

const publicReducer = (state = initialState, action) => {
    switch (action.type) {
        case PUBLIC_TYPES.CREATE_PUBLIC:
            return {
                ...state,
                publics: [action.payload, ...state.publics]
            };
        case PUBLIC_TYPES.LOADING_PUBLIC:
            return {
                ...state,
                loading: action.payload
            };
        case PUBLIC_TYPES.GET_PUBLICS:
            return {
                ...state,
                publics: action.payload.publics,
                result: action.payload.result,
                page: action.payload.page
            };
        case PUBLIC_TYPES.UPDATE_PUBLIC:
            return {
                ...state,
                publics: EditData(state.publics, action.payload._id, action.payload)
            };
        case PUBLIC_TYPES.DELETE_PUBLIC:
            return {
                ...state,
                publics: DeleteData(state.publics, action.payload._id)
            };

        default:
            return state;
    }
}

export default publicReducer