import React from 'react'
import Avatar from '../../Avatar'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { deletePost } from '../../../redux/actions/postAction'
import { BASE_URL } from '../../../utils/config'

const CardHeaderGet = ({post}) => {

    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const history = useHistory()


    const handleEditPost = () => {
        dispatch({ type: GLOBALTYPES.STATUS, payload: { ...post, onEdit: true } })
    }

    const handleDeletePost = () => {
        if (window.confirm("Seguro que quieres eliminar esta publicación?")) {
            dispatch(deletePost({ post, auth, socket }))
            return history.push("/")
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${BASE_URL}/post/${post._id}`)
    }


    return (
        <div className="card_header_getuser">
            <div className="d-flex">
                <Avatar src={post.user.avatar} size="big-avatar" />

                <div className="card_name">
                    <h6 className="m-0">
                        <Link to={`/profile/${post.user._id}`} className="text-dark">
                            {post.user.username}
                        </Link>
                    </h6>
                    <small className="text-muted">
                        {moment(post.createdAt).fromNow()}
                    </small>
                </div>
            </div>

            <div className="nav-item dropdown" style={{ display: 'block' }} >
                
                <i class="fa-solid fa-ellipsis" id="moreLink" data-toggle="dropdown"></i>
                <div className="dropdown-menu" id="dropdown-item-headerget">

                    {
                        auth.user._id === post.user._id &&
                        <>
                            <div className="dropdown-item" onClick={handleEditPost} >
                                <span className="material-icons">create</span> Editar Post
                            </div>
                            <div className="dropdown-item" onClick={handleDeletePost} >
                                <span className="material-icons">delete_outline</span> Remover Post
                            </div>
                        </>
                    }

                    <div className="dropdown-item" onClick={handleCopyLink} >
                        <span className="material-icons">content_copy</span> Copiar Link
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CardHeaderGet