import React, { useContext, useState, useEffect, useRef } from 'react';
import { GlobalState } from '../../GlobalState';
import Loading from '../mainpages/utils/loading/Loading';
import LoadMore from '../mainpages/products/LoadMore';
import moment from 'moment';

const ContentPublic = () => {
    const state = useContext(GlobalState)
    const [products, setProducts] = state.productsAPI.productpublic
    const [loading, setLoading] = useState(false)
    const [readMore, setReadMore] = useState(false)

    const videoRef = useRef(null);

    useEffect(() => {
        const adjustVideoContainer = () => {
            const video = videoRef.current;
            if (video) {
                const { videoWidth, videoHeight } = video;
                video.parentElement.style.aspectRatio = `${videoWidth} / ${videoHeight}`;
            }
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('loadedmetadata', adjustVideoContainer);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('loadedmetadata', adjustVideoContainer);
            }
        };
    }, []);

    console.log(products)


    const [text] = useState('Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrytypesetting industry. Lorem Ipsum has been the industry')

    if (loading) return <div><Loading /></div>

    return (
        <>
            <div className='Conten_card_public_hub'>
                {
                    products.map((product) => (
                        <div className="cart_contethub_public">
                            <div className="header_cart_contenthub">
                                <img src="https://res.cloudinary.com/dshcgnvss/image/upload/v1698183353/logos/pngwing_32x32_pujh1e.png" alt="User Avatar" />
                                <div>
                                    <h1>{product.title}</h1>
                                    <small>{moment(product.createdAt).fromNow()}</small>
                                </div>
                            </div>

                            <div className="content_cart_description">
                                <p>
                                    {
                                        product.description.length < 100
                                            ? product.description
                                            : readMore ? product.description + ' ' : product.description.slice(0, 100) + '.....'
                                    }
                                </p>
                                {
                                    product.description.length > 100 &&
                                    <p className="readMore" onClick={() => setReadMore(!readMore)}>
                                        {readMore ? 'Ocultar' : 'Lee mas'}
                                    </p>
                                }
                            </div>

                                <div className="content_img_contenhub">
                                    <video ref={videoRef} controls>
                                        <source src={product.urlsmoviesfakes[0]} type="video/mp4" />
                                    </video>
                                </div>
                        </div>
                    ))
                }


            </div>

            <LoadMore />
            {products.length === 0 && <Loading />}

        </>

    );
}

export default ContentPublic;