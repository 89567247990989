import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getPublic } from '../../redux/actions/publicAction';
import LoadIcon from '../../images/loading.gif'
import ContePublic from '../../components/publicidad/ContePublic';
import CatalogoItem from '../../components/mainpages/utils/productItem/CatalogoItem';
import { GlobalState } from '../../GlobalState'
import { Link } from 'react-router-dom'
import ScrollToTop from '../../utils/ScrollToTop';

const Publicidad = () => {
    const state = useContext(GlobalState)
    const [anuncios] = state.publicidadAPI.publicidad

    const { id } = useParams()
    const [publicidad, setPublicidad] = useState([])

    const { auth, detailPublic, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPublic({ detailPublic, id, auth }))
        if (detailPublic.length > 0) {
            const newArr = detailPublic.filter(post => post._id === id)
            setPublicidad(newArr)
        }
    }, [detailPublic, dispatch, id, auth])

    if (detailPublic.length === 0) return null;

    return (
        <div className="posts">
            <ScrollToTop />
            {
                publicidad.length === 0 &&
                <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
            }
            {
                publicidad.map(detailAnuncio => (
                    <>
                        <ContePublic detailAnuncio={detailAnuncio} theme={theme} />
                        <h1 id='title_anuncio_relative'>Anuncion Relacionados</h1>

                        <div className='btn_filtro_catgori' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                            <Link to={'/precios'} class="btnp btn-3p" style={{ textDecoration: 'none' }}>
                                Publicar Anuncio
                            </Link>
                        </div>

                        <div className='relative_anuncios'>
                            {
                                anuncios.map(anuncio => {
                                    return anuncio.distrito === detailAnuncio.distrito
                                        ?
                                        <CatalogoItem key={anuncio._id} anuncio={anuncio} />
                                        : null
                                })
                            }
                        </div>
                    </>
                ))
            }
        </div>
    )
}

export default Publicidad