import React, { useEffect } from 'react'

import RightSideBar from '../components/home/RightSideBar'
import Publicidad from '../components/publicidad/Publicidad'
import StatusP from '../components/publicidad/StatusP'

import { useSelector } from 'react-redux'
import LoadIcon from '../images/loading.gif'
import { Link } from 'react-router-dom'

let scroll = 0;

const Publicidaduno = () => {

    const { homePublic } = useSelector(state => state)

    window.addEventListener('scroll', () => {
        if (window.location.pathname === '/') {
            scroll = window.pageYOffset
            return scroll;
        }
    })

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: scroll, behavior: 'smooth' })
        }, 100)
    }, [])

    return (
        <div className="avisoshome" >
            <div className="col-md-8">
                <StatusP />
                {
                    homePublic.loading
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        : (homePublic.result === 0 && homePublic.publics.length === 0)
                            ? <h2 className="text-center">No hay Anuncios</h2>
                            : <Publicidad />
                }

            </div>
            <div className="col-md-4" >
                <RightSideBar />
            </div>
        </div>
    )
}

export default Publicidaduno