import { ANUNCIO_TYPES } from "../actions/anuncioAction"
import { DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    anuncios: [],
    result: 0,
    page: 2
}

const anuncioposReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANUNCIO_TYPES.CREATE_ANUNCIO:
            return {
                ...state,
                anuncios: [action.payload, ...state.anuncios]
            };
        case ANUNCIO_TYPES.LOADING_ANUNCIO:
            return {
                ...state,
                loading: action.payload
            };
        case ANUNCIO_TYPES.GET_ANUNCIO:
            return {
                ...state,
                anuncios: action.payload.anuncios,
                result: action.payload.result,
                page: action.payload.page
            };
        case ANUNCIO_TYPES.DELETE_ANUNCIO:
            return {
                ...state,
                anuncios: DeleteData( state.anuncios, action.payload._id )
            };
        default:
            return state;
    }
}

export default anuncioposReducer