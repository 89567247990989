import { FORUM_TYPES } from '../actions/forumAction'
import { EditData } from '../actions/globalTypes'

const detailForumReducer = (state = [], action) => {
    switch (action.type){
        case FORUM_TYPES.GET_FORUM:
            return [...state, action.payload]
        case FORUM_TYPES.UPDATE_FORUM:
            return EditData(state, action.payload._id, action.payload)
        default:
            return state;
    }
}


export default detailForumReducer