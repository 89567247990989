import { GLOBALTYPES } from "./globalTypes"
import { imageUpload } from "../../utils/imageUpload"
import { postDataAPI, getDataAPI, deleteDataAPI } from "../../utils/fetchData"

export const ANUNCIO_TYPES = {
    CREATE_ANUNCIO: 'CREATE_ANUNCIO',
    LOADING_ANUNCIO: 'LOADING_ANUNCIO',
    GET_ANUNCIO: 'GET_ANUNCIO',
    UPDATE_ANUNCIO: 'UPDATE_ANUNCIO',
    GETANUNCIO: 'GETANUNCIO',
    DELETE_ANUNCIO: 'DELETE_ANUNCIO'
}

export const createAnuncio = ({ nombre, images, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura,
    titulo, phora, pmediahora, ppack, pvideollamada, horario, telefono, dcorta, descripcion,
    pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot,
    lugar1, lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6,
    servicio7, servicio8, especial1, especial2, especial3, especial4, especial5, especial6, especial7,
    especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader, categoria, auth }) => async (dispatch) => {

        let media = []

        try {
            dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
            if (images.length > 0) media = await imageUpload(images)

            const res = await postDataAPI('anuncio', {
                nombre, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura,
                titulo, phora, pmediahora, ppack, pvideollamada, horario, telefono, dcorta, descripcion,
                pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot,
                lugar1, lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6,
                servicio7, servicio8, especial1, especial2, especial3, especial4, especial5, especial6, especial7,
                especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader, categoria, images: media
            }, auth.token)

            dispatch({ 
                type: ANUNCIO_TYPES.CREATE_ANUNCIO, 
                payload: {...res.data.newAnuncio, user: auth.user} 
            })

            dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

        } catch (err) {

            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })

        }
    }

export const getAnuncio = (token) => async (dispatch) => {
    try {
        dispatch({ type: ANUNCIO_TYPES.LOADING_ANUNCIO , payload: true })
        const res = await getDataAPI('anuncio', token)
        
        dispatch({
            type: ANUNCIO_TYPES.GET_ANUNCIO,
            payload: {...res.data, page: 2}
        })
        
        dispatch({ type: ANUNCIO_TYPES.LOADING_ANUNCIO , payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const deleteAnuncio = ({anuncio, auth}) => async (dispatch) => {
    dispatch({ type: ANUNCIO_TYPES.DELETE_ANUNCIO, payload: anuncio })
    try {
        deleteDataAPI(`anuncio/${anuncio._id}`, auth.token)
        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }

} 
