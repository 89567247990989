import React, { useEffect, useContext, useState } from 'react'

import { useSelector } from 'react-redux'
import Forums from '../components/forum/Forums';
import Statuforum from '../components/forum/Statuforum';
import LoadIcon from '../images/loading.gif'

import { Link } from 'react-router-dom'
import CardInfo from '../components/profile/CardInfo';
import Suggestions from '../components/home/Suggestions';
import Categories from '../components/mainpages/categories/Categories';

import { GlobalState } from '../GlobalState'
import ModalCategory from '../components/forum/ModalCategory';
import Carrosel from '../components/slickcarrosel/Carrosel';

let scroll = 0;

const Forum = () => {
    const state = useContext(GlobalState)
    const [categories] = state.categoriesAPI.categories

    const { homeForums, theme } = useSelector(state => state)

    window.addEventListener('scroll', () => {
        if (window.location.pathname === '/') {
            scroll = window.pageYOffset
            return scroll;
        }
    })

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: scroll, behavior: 'smooth' })
        }, 100)
    }, [])

    const categoriasFiltradas = categories.filter(categoria => categoria.idtemaprincipal.startsWith("CHP"));
    const categoriasFiltradas2 = categories.filter(categoria => categoria.idtemaprincipal.startsWith("CHO"));


    // Ejemplo de uso
    const titulos = [
        {
            name: 'CHONGOS EN LIMA',
            temas: categoriasFiltradas2.map(categoria => ({
                category: categoria.idtemaprincipal,
                nombre: categoria.name,
                distrito: categoria.distrito,
                provincia: categoria.provincia,
                link: categoria.name.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/\s+/g, "-") // Reemplazar espacios en blanco con guiones
                    .toLowerCase()
            })),
        },
        {
            name: 'CHONGOS EN PROVINCIA',
            temas: categoriasFiltradas.map(categoria => ({
                category: categoria.idtemaprincipal,
                nombre: categoria.name,
                distrito: categoria.distrito,
                provincia: categoria.provincia,
                link: categoria.name.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/\s+/g, "-") // Reemplazar espacios en blanco con guiones
                    .toLowerCase()
            })),
        },
        {
            name: 'HOTELES CALETA',
            temas: ['Tema 2.1', 'Tema 2.2'],
        },
        {
            name: 'CARIÑOSAS INDEPENDIENTE',
            temas: ['Tema 2.1', 'Tema 2.2'],
        }
    ];

    ///modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="home">
            <div className="main_left" >
                <button class="main_left_search">
                    <Link to={'/buscador'} >
                        <span><i class="fa-solid fa-magnifying-glass"></i> Buscar</span>
                    </Link>
                </button>
                <CardInfo />
                <button class="custom-btn btn-8" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <Link to={'/precios'}>
                        <span>Anunciar</span>
                    </Link>
                </button>
            </div>
            <div className="main_content" >

                <Link to={'/elminar'}>Aqui eliminar</Link>
                <Link to={'/probando'}>probado</Link>
                <Link to={'/foros-cassanova'}>foros casaova</Link>

                <Carrosel />
                <Statuforum />

                <div className='form_category_create'>
                    <button onClick={handleShow} className="btn_form_create_cat" >Crear tema</button>
                    {show && (
                        <div className='form_category_formulary'>
                            <div id="modal_form_category">
                                <Categories />
                                <button onClick={handleClose} type="button" class="outline-danger_form"><i class="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    )}
                </div>

                { /*{
                    titulos.map((forum, index) => (
                        <div class="accordion" id={`accordionExample-${index}`} key={index}>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                        <i class="fa-solid fa-align-left"></i> {forum.name}
                                    </button>
                                </h2>
                                <div id={`collapse${index}`} class="accordion-collapse collapse show" data-bs-parent={`#accordionExample-${index}`}>
                                    {
                                        homeForums.loading
                                            ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                                            : (homeForums.result === 0 && homeForums.forums.length === 0)
                                                ? <h2 className="text-center">No Post</h2>
                                                : <Forums />
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }*/}

                {titulos.map((forum, index) => (
                    <div className="accordion" id={`accordionExample-${index}`} key={index}>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                    <i className="fa-solid fa-align-left"></i> {forum.name}
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse show" data-bs-parent={`#accordionExample-${index}`}>
                                <div className="accordion-body forum">
                                    {forum.temas.map((tema, temaIndex) => (
                                        <ol className="activity-feed" key={temaIndex}>
                                            <li className="feed-item">
                                                {/*<span class="text"> <i class="fa-solid fa-comments"></i> <Link to={`/forum-${tema.link}`} > {tema.nombre} </Link> <small>de {tema.distrito}</small>  </span>*/}
                                                <span class="text" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}> <i class="fa-solid fa-comments"></i> <Link key={tema._id} to={`/categoria/${tema.category}/${tema.link}`} > {tema.nombre} </Link> <small>de {tema.distrito}</small>  </span>
                                                <time class="date" datetime="9-25">{tema.provincia}</time>
                                            </li>
                                            <div>
                                                <i class="fa-regular fa-comment-dots"></i>
                                            </div>
                                        </ol>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className="main_rigth" >
                <div className='main_rigth_sugeVip'>
                    <h1>Sugerencias</h1>
                    <div className='main_rigth_sugeVip_content'>
                        <Suggestions />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forum
