import React from 'react';
import moment from 'moment'
import {Link} from 'react-router-dom'

const Cardhub = ({ product }) => {
    console.log(product)
    return (
        <div class="cardhub">
            <div class="card-imagehub">
                <img src={product.images.url} />
            </div>
            <div class="categoryhub"> {product.title}</div>
            <div class="headinghub">
                <h3 id="headinghub_h3">
                    <Link to={`/detalles-contenidohub/${product._id}`} >
                        {product.description}
                    </Link>
                </h3>
                <div class="authorhub"> <span class="namehub">{moment(product.createdAt).fromNow()}</span> </div>
            </div>
        </div>
    );
}

export default Cardhub;