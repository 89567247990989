import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/compInicio/footer'


function Redsocial() {
    return (
        <>
            <div className='content_info_red'>
                <div className='header_redsocial'>
                </div>
                <div className='content_redsocial'>

                    <div className='content_img_'>
                        <div className='content_img_imagen'>
                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1695054071/iconos/q1_cf3km3_yiy6mf.gif' alt='cariñosashub app' />
                        </div>
                        <div className='content_img_content'>
                            <h3>
                                <Link to="/" style={{ textDecoration: 'none' }} >
                                    <h1 className="gradient-text" id='logo_css_header_'
                                        onClick={() => window.scrollTo({ top: 0 })}>
                                        Cariñosas Hub
                                    </h1>
                                </Link>
                            </h3>
                            <p>
                                CariñosasHub es una red social para todos los amantes de lo hot, donde podras ver
                                fotografías y publicaciones de tú cariñosa favorita.
                            </p>
                            <p>
                                Podras seguir a tú cariñosa favorita, ver sus ultimas publicaciones, sus fotos más hot y estar en contacto con ellas.
                            </p>
                            <p>
                                Podras ver anuncios y publicar anucios de tus contenidos más Hot.
                            </p>
                        </div>
                    </div>

                    <div className='content_info_img contenet'>
                        <div className='content_uno_img'>
                            <h1>
                                <Link to="/" style={{ textDecoration: 'none' }} >
                                    <h1 className="gradient-text" id='logo_css_header_'
                                        onClick={() => window.scrollTo({ top: 0 })}>
                                        CariñosasHub
                                    </h1>
                                </Link>
                            </h1>
                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1695056242/iconos/bn1_udn7wu.jpg' alt='cariñosashub app' />
                        </div>
                        <div className='content_uno_info'>
                            <div style={{ margin: '15px' }}>
                                <h2 id='info_redsocial_span1'>
                                    tambien puedes Conéctarte
                                    con  creadores de contenido
                                    travieso...
                                </h2>
                                <br />
                                <h3 id='info_redsocial_span2'>
                                    Los creadores de contenido están en línea todo el día, todos los días,
                                    conectándose con su audiencia de pago de forma privada.
                                </h3>
                                <br />
                                <span id='info_redsocial_span3'>
                                    Debe tener más de 18 años para unirse
                                </span>
                            </div>
                            <Link to={'/register'} >
                                <span className='content_btn_log'>
                                    Crear cuenta gratis
                                </span>
                            </Link>
                        </div>
                    </div>

                    <div className='content_img_'>
                        <div className='content_img_imagen'>
                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1695056161/iconos/cari%C3%B1osas6_tue4av.gif' alt='cariñosashub app' />
                        </div>
                        <div className='content_img_content'>
                            <h3>
                                <Link to="/" style={{ textDecoration: 'none' }} >
                                    <h1 className="gradient-text" id='logo_css_header_'
                                        onClick={() => window.scrollTo({ top: 0 })}>
                                        CariñosasHub
                                    </h1>
                                </Link>
                            </h3>
                            <p>
                                Se acabó la cuestión de entrar a una red social a ver si el chico que te gusta se sacó una foto sin remera, o si la chica
                                que te gusta tiene una foto en la pileta. CariñosasHub es una red social nueva que va directamente a los bifes: se saltea
                                todo el otro contenido para que los usuarios puedan mostrarse en paños menores – o sin paños. Esta es una propuesta
                                para atender al público que cada vez que quiere poner algo subido de tono, las redes convencionales les censuran sus publicaciones.
                            </p>
                            <p>
                                Para nadie es un secreto que compartir fotos de desnudos en cualquier forma imaginable es una práctica muy común.
                                Sin embargo, las estrictas políticas de obscenidad en las distintas redes sociales
                                han hecho que sea cada vez más difícil hacerlo. Pero ahora finalmente hay una opción, se trata de nuestra red social CariñosasHub, parecida a una red social conocida
                                . Así que si quieres saber más sobre esta red social, suscribete.
                            </p>
                        </div>
                    </div>

                    <div className='content_info_img'>
                        <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1695056230/iconos/bn_d9p2x1.jpg' alt='cariñosashub app' />
                        <h2 id='content_h3_img'>
                            <h1 id='content_title_img'>
                                <Link to="/" style={{ textDecoration: 'none' }} >
                                    <h1 className="gradient-text" id='logo_css_header_'
                                        onClick={() => window.scrollTo({ top: 0 })}>
                                        CariñosasHub
                                    </h1>
                                </Link>
                            </h1>
                            <h3>
                                Conéctese con el creador de contenido de su elección
                            </h3>
                            <span id='content_span_img'>
                                Obtenga contenido exclusivo hecho solo para "usted"
                            </span>
                        </h2>
                    </div>

                    <div className='icons_redsocial_img'>
                        <h3>
                            Inicie su cuenta gratuita y comience a conectarse con los mejores creadores de contenido en línea ahora mismo
                        </h3>
                        <ul class="wrapper">
                            <li class="icon facebook">
                                <span class="tooltip">Facebook</span>
                                <span><i class="fab fa-facebook-f"></i></span>
                            </li>
                            <a href='https://www.tiktok.com/@fanshots'>
                                <li class="icon twitter">
                                    <span class="tooltip">Tiktok</span>
                                    <span><i class="fa-brands fa-tiktok"></i></span>
                                </li>
                            </a>
                            <a href='https://www.instagram.com/fans_hot_/?igshid=MzNlNGNkZWQ4Mg%3D%3D'>
                                <li class="icon instagram">
                                    <span class="tooltip">Instagram</span>
                                    <span><i class="fab fa-instagram"></i></span>
                                </li>
                            </a>
                            <a href='https://api.whatsapp.com/send?phone=51913926030&text=Hola'>
                                <li class="icon github">
                                    <span class="tooltip">Whatsapp</span>
                                    <span><i class="fa-brands fa-whatsapp"></i></span>
                                </li>
                            </a>
                            <a href='https://t.me/fanshots'>
                                <li class="icon youtube">
                                    <span class="tooltip">Telegram</span>
                                    <span><i class="fa-brands fa-telegram"></i></span>
                                </li>
                            </a>
                        </ul>
                        <h4>
                            Solo "CariñosasHub" tiene los creadores de contenido más populares y traviesos, contenido de entretenimiento para adultos.
                        </h4>
                    </div>

                </div>
            </div>
            <div className='footer_redsocial_infos'>
                <Footer />
            </div>
        </>

    )
}

export default Redsocial