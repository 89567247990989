import React from 'react';
import Login from '../../pages/login';
import { Link } from 'react-router-dom'
import ContentPublic from '../home/ContentPublic';

const ContetDesc = () => {
    return (
        <div className='Contenthub_inicio_destacat'>
            <div class="container" id="container">
                <div class="overlay-container">
                    <div class="overlay-panel">
                        <h2>Bienvenido a </h2>
                        <h1>
                            <div class="heart">&#x2665;</div>
                            CariñosasHub
                            <div class="heart left">&#x2665;</div>

                        </h1>
                        <p>Para ver todo el contenido, UNETE!!!</p>
                        <Link to={'/register'} >
                            <button class="button_registerhub">
                                Registrar
                                <div class="hoverEffect">
                                    <div></div>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
                <div class="form-container">
                    <Login />
                </div>
            </div>
            <h2 className='h2_containerhub' >Publicaciones Destacadas</h2>
            <div>
                <ContentPublic />
            </div>
        </div>
    );
}

export default ContetDesc;