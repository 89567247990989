import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

function Tiktok() {
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

  return (
    <div className='btn_anuncio_create'>
        <button 
        onClick={() => dispatch({ type: GLOBALTYPES.TIKTOKLINK, payload: true })} >
            {auth.user.username}, Pega tú link de Tiktok....
        </button>
    </div>
  )
}

export default Tiktok