import React from 'react'
import CardHeaderGet from './home/post_card/CardHeaderGet'
import CardBodyGet from './home/post_card/CardBodyGet'
import CardFooterGet from './home/post_card/CardFooterGet'

import Comments from './home/Comments'
import InputComment from './home/InputComment'

const PostCardGet = ({ post, theme }) => {

    return (
        <div className="card my-3">
            <CardHeaderGet post={post} />
            <CardBodyGet post={post} theme={theme} />
            <CardFooterGet post={post} />

            <Comments post={post} />
            <InputComment post={post} />
        </div>
    )
}

export default PostCardGet