import React, { useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import HeaderVer from '../components/compInicio/headerVertical'
import BarraDer from '../components/compInicio/barraDerech'
import { GlobalState } from '../GlobalState'
import Footer from '../components/compInicio/footer'
import LoadMoreAnu from '../components/mainpages/products/LoadMoreAnu'

import Loading from '../components/mainpages/utils/loading/Loading'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CategoriaPublic from '../components/publicidad/CategoriaPublic'
import ScrollToTop from '../utils/ScrollToTop'

import { Helmet } from 'react-helmet-async';

/*------*/
function filterByCategory(categoryName, categoryFilters, item) {
    if (!categoryFilters.length) return true;
    return categoryFilters.some((filter) => item[categoryName] === filter);
}
/*--------*/

function Cateprovincia() {
    const [loading, setLoading] = useState(false)
    const directoryRef = useRef()

    const state = useContext(GlobalState)
    const [anuncios] = state.publicidadAPI.publicidad

    /*-------------*/

    const Provinciafilter = anuncios.filter(
        (dep) => dep.provincia === "Amazonas" || dep.provincia === "Ancash" || dep.provincia === "Apurímac" || dep.provincia === "Ayacucho" || dep.provincia === "Cajamarca"
            || dep.provincia === "Callao" || dep.provincia === "Cusco" || dep.provincia === "Huancavelica" || dep.provincia === "Huanuco" || dep.provincia === "Ica" || dep.provincia === "Junin"
            || dep.provincia === "La Libertad" || dep.provincia === "Lambayeque" || dep.provincia === "Lima" || dep.provincia === "Loreto" || dep.provincia === "Madre De Dios" || dep.provincia === "Moquegua"
            || dep.provincia === "Pasco" || dep.provincia === "Piura" || dep.provincia === "Puno" || dep.provincia === "San Martin" || dep.provincia === "Tacna" || dep.provincia === "Tumbes" || dep.provincia === "Ucayali"
    );

    /*--------------*/
    const appTypeFilters = [...new Set(Provinciafilter.map((d) => d.provincia))];
    const nacionalidadFilters = [...new Set(Provinciafilter.map((d) => d.nacionalidad))];

    let [appTypes, setAppTypes] = useState([]);
    let [nacionalidads, setNacionalidads] = useState([]);


    let filteredDevelopers = Provinciafilter.filter((dev) => {
        return (
            filterByCategory("provincia", appTypes, dev) &&
            filterByCategory("nacionalidad", nacionalidads, dev)
        )
    });
    /*-----------*/

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    if (loading) return <div><Loading /></div>

    const tituloDinamico = "Kinesiologas en provincia - CARIÑOSASHUB";

    return (
        <>

            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="Tenemos kines, putas en provincia. Anuncios eróticos de kinesiologas y sexo en provincia, jovencitas maduras y bellas putas en provincia, Kinesiologas, escorts y prostitutas en provincia. Prositutas peruanas y extranjeras en provincia, Anuncios Kinesiologas en provincia, encuentra Kines Putas y prostitutas de provincia. Anuncios eróticos de escorts y sexo en provincia"
                ></meta>

                <meta name="keywords"
                    content="Kinesiologas provincia, puta provincia, putas por provincia, kinesiologas provincia, putas provincia, kinesiologas en provincia, kines provincia, kinesiologas provincia, kines metro provincia, sexo provincia"></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/" />

            </Helmet>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder guardar ó darle like a un anuncio, ienes que unirte a nuestra red social "CariñosasHub", es gratis!!!
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <div className='rj-container-sections'>
                <div className='rj-izquierda'>
                    <HeaderVer />
                </div>
                <div className='rj-central'>
                    <div className='container-pruebaPP'>
                        <ScrollToTop />
                        <CategoriaPublic />
                        <h2>Filtro</h2>
                        <div className="contenedor_tags">
                            <h3>Distrito</h3>
                            <ul className="boxs-container" >
                                {appTypeFilters.map((type) => (
                                    <li className='content_dist'>
                                        <input
                                            className="inp-cbx" id={type}
                                            name="appType"
                                            type="checkbox"
                                            value={type}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    setAppTypes([...appTypes, type]);
                                                } else {
                                                    setAppTypes(
                                                        appTypes.filter((distrito) => distrito !== type)
                                                    );
                                                }
                                            }}
                                        />
                                        <label for={type} href="#lancha" >{type}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="contenedor_tags">
                            <h3>Nacionalidad</h3>
                            <ul className="boxs-container2">
                                {nacionalidadFilters.map((filter) => (
                                    <li className='content_dist'>
                                        <input
                                            className="inp-cbx" id={filter}
                                            name="nacionalidad"
                                            type="checkbox"
                                            value={filter}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    setNacionalidads([...nacionalidads, filter]);
                                                } else {
                                                    setNacionalidads(
                                                        nacionalidads.filter((nacionalidad) => nacionalidad !== filter)
                                                    );
                                                }
                                            }}
                                        />
                                        <label for={filter}>{filter}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <h2 ref={directoryRef}>Directorio De Chicas</h2>
                        <div className='main-container'>
                            <div class="card-principal">
                                {
                                    filteredDevelopers.map(anuncio => {
                                        return <div class="card-secun">
                                            <div className="img-card">
                                                <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                                    <img src={anuncio.images[0].url} alt="" />
                                                </Link>
                                                <span className='material-icons' onClick={handleButtonClick}>favorite</span>
                                            </div>
                                            <div className='card-footer1'>
                                                <div className='card-nombre'>
                                                    <div className='card-name-nombre'>
                                                        <i class="fa-solid fa-star"></i>
                                                        <h1 style={{ marginLeft: '6px' }}>{anuncio.nombre}</h1>
                                                    </div>
                                                    <span>{anuncio.edad} <small>años</small></span>
                                                </div>
                                                <div className='card-footer-nacio'>
                                                    <img src={anuncio.iconBanader} />
                                                    <span>{anuncio.nacionalidad}</span>
                                                </div>
                                                <div className='card-lugar'>
                                                    <div>
                                                        <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                                                        <span>{anuncio.distrito}</span>
                                                    </div>
                                                    <small>{anuncio.provincia}</small>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <LoadMoreAnu directoryRef={directoryRef} />
                        </div>
                        <Footer />
                    </div>
                </div>
                <div className='rj-derecha'>
                    <BarraDer />
                </div>
            </div>
        </>
    )
}

export default Cateprovincia