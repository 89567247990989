export const checkImage = (file) => {
    let err = ""
    if (!file) return err = "File does not exist."

    if (file.size > 1024 * 1024) // 1mb
        err = "The largest image size is 1mb."

    let file1 = file.type;
    const [ext, ...fileName] = file1.split('.').reverse();
    if (!file) return err = "El archivo no existe."

    if (file1 !== "image/jpeg" && file1 !== "image/png") {
        return err = "Su extension no es valido"
    }

    if (file.type !== 'image/jpeg' && file.type !== 'image/png' )
        err = "Image format is incorrect."

    return err;
}

export const imageUpload = async (images) => {
    let imgArr = [];
    for (const item of images) {
        const formData = new FormData()

        if (item.camera) {
            formData.append("file", item.camera)
        } else {
            formData.append("file", item)
        }

        formData.append("upload_preset", "pqyxbs1e");
        formData.append("cloud_name", "dvsrc90m2");

        const res = await fetch("https://api.cloudinary.com/v1_1/dvsrc90m2/upload", {
            method: "POST",
            body: formData
        })

        const data = await res.json()
        imgArr.push({ public_id: data.public_id, url: data.secure_url })
    }
    return imgArr;
}

export const imageUploadP = async (images) => {
    let imgArr = [];
    for (const item of images) {
        const formData = new FormData()

        if (item.camera) {
            formData.append("file", item.camera)
        } else {
            formData.append("file", item)
        }

        formData.append("upload_preset", "zcohczlw");
        formData.append("cloud_name", "dshcgnvss");

        const res = await fetch("https://api.cloudinary.com/v1_1/dshcgnvss/upload", {
            method: "POST",
            body: formData
        })

        const data = await res.json()
        imgArr.push({ public_id: data.public_id, url: data.secure_url })
    }
    return imgArr;
}

/*
export const handleDeleteImages = async (publicIds) => {
    try {
      for (const publicId of publicIds) {
        const formData = new FormData()

        formData.append("upload_preset", "l0mbsomx")
        formData.append("cloud_name", "geocivil")

        const response = await fetch(`https://api.cloudinary.com/v1_1/<cloud_name>/image/upload/${publicId}`, {
          method: 'DELETE',
          body: formData
          
        });
        const data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };*/