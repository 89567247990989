import React from 'react'
import { Link } from 'react-router-dom'

const Preguntasfrecuentes = () => {
    return (
        <div className='politicas_condiciones_page' >
            <div className='terminos_condiciones'>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Que es "CariñosasHub"?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            "CariñosasHub" es una red social con esencia hot creada, justamente, con el objetivo de brindar un espacio en el que las personas y parejas puedan
                                compartir sus fotos sexys, atrevidas o eróticas, entre otras. tambien es una plataforma donde se pueda hacer alguna publicidad relacionada a lo erotico,
                                tonto el que solicita la publicidad como el usuario que observa la publidad, tienen que regirse a las normas de la plataforma.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Cuando me suscribo a la Plataforma
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Puede suscribirse a la plataforma para poder tener acceso a más contenido, cumpliendo la normas establecidas, tener en cuenta que
                                Una vez realizada la suscripcion, tiene que reiniciar la pagina para activar la suscripcion, y tambien al momento de realizar el login.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingsex">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesex" aria-expanded="false" aria-controls="flush-collapsesex">
                                Que es "CariñosasHub" en la Actualidad?
                            </button>
                        </h2>
                        <div id="flush-collapsesex" class="accordion-collapse collapse" aria-labelledby="flush-headingsex" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Fanas hot actualmente es una plataforma de prueba, debido a eso puede presentar ciertas fallas en algunos componentes, y con lo cual causar cierta incomodidad al usuario, 
                                agradeceremos la comprención del usuario y trataremos de corregir todas las fallas para brindar un mejor servicio.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingsev">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesev" aria-expanded="false" aria-controls="flush-collapsesev">
                            "CariñosasHub" a futuro
                            </button>
                        </h2>
                        <div id="flush-collapsesev" class="accordion-collapse collapse" aria-labelledby="flush-headingsev" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            "CariñosasHub" actualmente es una app web de prueba, a futuro el equipo pretende que "CariñosasHub" sea una plataforma donde los usuarios puedan vender su contenido a otros
                                usuarios interesados, tambien convertirse en una plataforma donde las personas puedan expresarse de una forma distinta a las aplicaciones que actualmente
                                existen, el objetivo es convertirse en una opción diferente para el usuario.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingeig">
                            <button class="accordion-button collapeeig" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeig" aria-expanded="false" aria-controls="flush-collapseeig">
                            "CariñosasHub" y la publicidad
                            </button>
                        </h2>
                        <div id="flush-collapseeig" class="accordion-collapse collapse" aria-labelledby="flush-headingeig" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            "CariñosasHub" no tiene NINGUNA responsabilidad con la publicidad que realizan los usuarios, "CariñosasHub" no es una agencia ni tiene relación con los usuarios
                                personalmente, cada usuario es reponsable de su publicidad y los usuarios que observan la publidad son responsables de adquerir ó tomar como cierta
                                dicha publidad. "CariñosasHub" no se responsabiliza de actos que puedan tomar los usuarios apartados de la plataforma.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingdos">
                            <button class="accordion-button collapeeig" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsedos" aria-expanded="false" aria-controls="flush-collapsedos">
                                Denunciar publicidad
                            </button>
                        </h2>
                        <div id="flush-collapsedos" class="accordion-collapse collapse" aria-labelledby="flush-headingdos" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Cualquier usuario puede hacer una queja o denunciar sobre una publidad que concidere que sea maliciosa o sea falsa ( <Link to={'/contacto'}>Aqui</Link> )
                                 , nosotros recibiremos la queja y trataremos de atenderlo de enseguida para evaluar dicha queja, una vez evaluada y bajo nuestro criterio, procederemos a cancelar
                                  la publidad, Importante!!! si la publidad no cumple con lo establecido en los terminos y condiciones ( <Link to={'terminos'}>Aqui</Link> ), procederemos a eliminarlo y no habra reenvolso de ninguna forma.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headinguno">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseuno" aria-expanded="false" aria-controls="flush-collapseuno">
                            "CariñosasHub" y Los POST
                            </button>
                        </h2>
                        <div id="flush-collapseuno" class="accordion-collapse collapse" aria-labelledby="flush-headinguno" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            "CariñosasHub" tiene tolerancia cero con publicaciones que no cumplan con nuestras normas (<Link to={'/terminos'}>Terminos y Condiciones</Link>)
                                , cada usuario se hace responsable de sus post, "CariñosasHub" no se responsabiliza de publicaciones maliciosamente que se realizan sin cumplir las normas,
                                y usando diferentes metodos no permitidos por la app, cualquier post que no cumpla con la norma seran borradas, y es necesario acudiremos a las entidades legales correspondientes.
                                importante!!! cualquier usuario que haya infringido los <Link to={ "/terminos" }>Terminos y condiciones</Link> sera suspendido y no podra acceder a su cuenta de "CariñosasHub",
                                por un tiempo o indefinidamente, segun sea nuestro criterio.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Presentar alguna queja o denuncia
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                Para poder realizar alguna queja o denuncia sobre alguna publicidad, usuario, o anuncio, tiene que ponerse en contacto con nosotros
                                a travez de alguna de nuestra redes. <Link to={'/contacto'}> Aqui </Link>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingfor">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefor" aria-expanded="false" aria-controls="flush-collapsefor">
                                Puedo publicar cualquier post?
                            </button>
                        </h2>
                        <div id="flush-collapsefor" class="accordion-collapse collapse" aria-labelledby="flush-headingfor" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                No, si es cierto puedes publicar post relacionado a lo erotico o sexual, hay publicaciones que no estan permitidas, y bajo nuestro criterios concideraremos si son aptas para 
                                la plataforma o no, puede leer nuestra politica de terminos y condiciones, <Link to={'/terminos'}>Aqui</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preguntasfrecuentes