import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'
import { Helmet } from 'react-helmet-async';

function Ancon() {

  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicancon
  /*-----------*/

  if (loading) return <div><Loading /></div>
  const tituloDinamico = "Kinesiologas Ancon - CARIÑOSASHUB";

  return (
    <>
    <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="Kinesiologas, escorts y prostitutas en Ancon. Servicio de sexo en 150102. Prositutas peruanas y extranjeras en Ancon"
        ></meta>

        <meta name="keywords"
          content="kines en Ancon, kinesiologas en Ancon, escorts en Ancon, prostitutas en Ancon, putas en Ancon, servicio de sexo en Ancon. Prostitutas peruanas en Ancon. Prostitutas extranjeras en Ancon. Prostitutas venezolanas en Ancon. Prostitutas colombianas en Ancon"></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-ancon" />

      </Helmet>
      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
          < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio ={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Ancon