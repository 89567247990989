import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { register } from '../redux/actions/authAction'



const Register = () => {
    const { auth, alert } = useSelector(state => state)
    const dispath = useDispatch()

    const history = useHistory()

    const initialState = {
        fullname: '', username: '', email: '', password: '', cf_password: '', gender: 'male', edad: '', pais: ''
    }
    const [userData, setUserData] = useState(initialState)
    const { fullname, username, email, password, cf_password, edad, pais } = userData

    const [typePass, setTypePass] = useState(false)
    const [typeCfPass, setTypeCfPass] = useState(false)


    const [onehabilita, setOnehabilita] = useState(true); // Inicialmente deshabilitado
    const [twohabilita, setTwohabilita] = useState(true); // Inicialmente deshabilitado

    const botonDeshabilitado = onehabilita || twohabilita;

    useEffect(() => {
        if (auth.token) history.push("/")
    }, [auth.token, history])


    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispath(register(userData))
    }

    return (
        <div className="auth_page-register">

            <form onSubmit={handleSubmit} >

                <div className='logo-register'>
                    <Link to="/" style={{ textDecoration: 'none' }} >
                        <h1 className="gradient-text" id='logo_css'
                            onClick={() => window.scrollTo({ top: 0 })}>
                            Cariñosas Hub
                        </h1>
                    </Link>
                </div>

                <div className="form-group">
                    <label htmlFor="fullname">Nombre Completo</label>
                    <input type="text" className="form-control" id="fullname" name="fullname"
                        onChange={handleChangeInput} value={fullname}
                        style={{ background: `${alert.fullname ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.fullname ? alert.fullname : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="username">Nombre de Usuario</label>
                    <input type="text" className="form-control" id="username" name="username"
                        onChange={handleChangeInput} value={username.toLowerCase().replace(/ /g, '')}
                        style={{ background: `${alert.username ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.username ? alert.username : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="pais">Pais donde te encuentras</label>
                    <select className="form-select" id="select-pais" aria-label="País" value={pais} onChange={handleChangeInput} name="pais" required
                        style={{ background: `${alert.pais ? '#fd2d6a14' : ''}` }} >
                        <option selected hidden>Pais</option>
                        <option value="Alemania">Alemania</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brasil">Brasil</option> 
                        <option value="Canada">Canada</option>
                        <option value="Chile">Chile</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Estados Unidos">Estados Unidos</option>
                        <option value="España">España</option>
                        <option value="Francia">Francia</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Italia">Italia</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Panama">Panamá</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                    </select>
                    <small className="form-text text-danger">
                        {alert.pais ? alert.pais : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="edad">Edad:</label>
                    <input type="number" className="form-control" id="edad" name="edad"
                        onChange={handleChangeInput} value={Math.trunc(edad)}
                        style={{ background: `${alert.edad ? '#fd2d6a14' : ''}` }} min="18" max="100" placeholder='años' />
                    <small className="form-text text-danger">
                        {alert.edad ? alert.edad : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Dirección Email</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email"
                        onChange={handleChangeInput} value={email}
                        style={{ background: `${alert.email ? '#fd2d6a14' : ''}` }} />
                    <small className="form-text text-danger">
                        {alert.email ? alert.email : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <div className="pass">
                        <input type={typePass ? "text" : "password"} className="form-control" id="exampleInputPassword1"
                            onChange={handleChangeInput} value={password.replace(/ /g, '')} name="password"
                            style={{ background: `${alert.password ? '#fd2d6a14' : ''}` }} />
                        <small onClick={() => setTypePass(!typePass)} >
                            {typePass ? 'Esconder' : 'Mostrar'}
                        </small>
                    </div>
                    <small className="form-text text-danger">
                        {alert.password ? alert.password : ''}
                    </small >
                </div>

                <div className="form-group">
                    <label htmlFor="cf_password">Confirmar Password</label>
                    <div className="pass">
                        <input type={typeCfPass ? "text" : "password"} className="form-control" id="cf_password"
                            onChange={handleChangeInput} value={cf_password.replace(/ /g, '')} name="cf_password"
                            style={{ background: `${alert.cf_password ? '#fd2d6a14' : ''}` }} />

                        <small onClick={() => setTypeCfPass(!typeCfPass)} >
                            {typeCfPass ? 'Esconder' : 'Mostrar'}
                        </small>
                    </div>
                    <small className="form-text text-danger">
                        {alert.cf_password ? alert.cf_password : ''}
                    </small >
                </div>

                <div className="justify-content-between" id='content-register'>
                    <label htmlFor="male">
                        Masculino: <input type="radio" id="male" name="gender"
                            value="male" defaultChecked onChange={handleChangeInput} />
                    </label>

                    <label htmlFor="female">
                        Femenino: <input type="radio" id="female" name="gender"
                            value="Female" onChange={handleChangeInput} />
                    </label>
                </div>

                <div className='condiciones-check'>
                    <div className='condiciones-check-uno' >
                        <h6>Soy mayor de Edad</h6>
                        <input
                            id="check1"
                            name="check1"
                            type="checkbox"
                            onChange={() => setOnehabilita(!onehabilita)} />
                    </div>
                    <div className='condiciones-check-uno' >
                        <Link to={'/terminos-condiciones'} style={{ color: 'red' }}>
                            <h6>Terminos y Condiciones</h6>
                        </Link>
                        <input
                            id="check2"
                            name="check2"
                            type="checkbox"
                            onChange={() => setTwohabilita(!twohabilita)} />
                    </div>
                </div>

                <button
                    type="submit"
                    className={`btn_check-bt ${botonDeshabilitado ? 'btn-disabled-bt' : 'btn-dark-bt'}`}
                    disabled={botonDeshabilitado}
                >
                    Registrar
                </button>

                <p className="my-2">
                    Ya tienes una cuenta? <Link to="/login" style={{ color: "crimson" }} >Inicia sesión</Link>
                </p>
            </form>

        </div>
    )
}

export default Register