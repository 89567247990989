import React, { useState, useEffect } from 'react'
import Avatar from '../Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

const Statuforum = () => {
    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div className="status my-3 d-flex forumsta">
            <Avatar src={auth.user.avatar} size="big-avatar" />
            
            <button className="statusBtn flex-fill" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
            onClick={() => dispatch({ type: GLOBALTYPES.STATUSFORUM, payload: true })}>
                {auth.user.username}, Crear un tema de conversacion?
            </button>
        </div>
    )
}

export default Statuforum
