import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDiscoverPosts, DISCOVER_TYPES } from '../redux/actions/discoverAction'
import LoadIcon from '../images/loading.gif'
import PostThumb from '../components/PostThumb'
import LoadMoreBtn from '../components/LoadMoreBtn'
import { getDataAPI } from '../utils/fetchData'

import { GlobalState } from '../GlobalState'
import { Link } from 'react-router-dom'



const Discover = () => {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //

    const { auth, discover } = useSelector(state => state)
    const dispatch = useDispatch()

    const [load, setLoad] = useState(false)


    useEffect(() => {
        if (!discover.firstLoad) {
            dispatch(getDiscoverPosts(auth.token))
        }
    }, [dispatch, auth.token, discover.firstLoad])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`post_discover?num=${discover.page * 12}`, auth.token)
        dispatch({ type: DISCOVER_TYPES.UPDATE_POST, payload: res.data })
        setLoad(false)
    }

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    

    return (
        <div>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <h4>
                            Suscribase para poder acceder a más contenido..
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="btn btn-outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button type="button" class="btn btn-outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {
                discover.loading
                    ? <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
                    : <PostThumb posts={discover.posts} result={discover.result} />
            }

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }

            <>
                {
                    !discover.loading &&
                    <LoadMoreBtn result={discover.result} page={discover.page}
                        load={load} handleLoadMore={handleLoadMore} />
                }
            </>

        </div>
    )
}

export default Discover
