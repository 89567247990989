import React, { useRef, useState, useEffect, useContext } from "react";
import { Map, TileLayer, Popup, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

import { Link } from 'react-router-dom'
import { GlobalState } from '../GlobalState'
import { Helmet } from 'react-helmet-async';

import LocateControl from '../components/mapaubicacion/Geo-local'

import { geosearch } from "esri-leaflet-geocoder";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import ScrollToTop from "../utils/ScrollToTop"

const defaultCenter = [-12.069475, -77.022161];
const defaultZoom = 14;


function Mapaubicacion() {
    /******/
    const state = useContext(GlobalState)
    //const [anuncios] = state.anunciosAPI.anuncio
    const [anuncios] = state.publicidadAPI.publicidad
    /*****/

    const mapRef = useRef();
    const [item, setItem] = useState(anuncios);

    /* show siderbar */

    const [show, setShow] = useState(true);

    const open = "sidebar";
    const closed = "sidebar sbclosed";

    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;

        if (!map) return;

        const control = geosearch();

        control.addTo(map);

        control.on("results", handleOnSearchResuts);

        return () => {
            control.off("results", handleOnSearchResuts);
        };
    }, []);

    /**
     * handleOnSearchResuts
     * @param {object} data Results object from esri-leaflet-geocoder
     */

    function handleOnSearchResuts(data) {
        console.log("Search results", data);
    }

    /*
    const content = divIcon({
      className: "custom-div-icon",
      html: `
      <div class='marker-pin'><img src='https://www.quever.news/u/fotografias/m/2021/6/1/f768x1-10605_10732_102.jpg'/></div>
  `,
      iconSize: [30, 42],
      iconAnchor: [20, 50]
  
      
    });*/

    const tituloDinamico = "Ubicación Kinesiologas - CARIÑOSASHUB";


    return (
        <div>
            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="ubicacion de Kinesiologas en Peru, ubicacion Putas en Lima, ubicacion Kines Peru, ubicacion Escorts maduras y chicas, ubicacion Peruanas y Extranjeras Venezolanas, ubicacion de Colombianas que ofrecen sexo en Lima."
                ></meta>

                <meta name="keywords"
                    content="ubicacion Kinesiólogas, ubicacion Kinesiólogas en perú, ubicacion kines en perú, ubicacion putas en perú, ubicacion sexo en perú, ubicacion escorts en perú, ubicacion Prostitutas en perú, ubicacion Putas peruanas, ubicacion putas extranjeras en perú, ubicacion kines Perú. ubicacion de Kines, ubicacion maduras en perú, jovencitas en perú, kines lima perú. kines en lince, KINESIOLOGAS LINCE, KINES LOS OLIVOS, KINESIOLOGAS LOS OLIVOS, PUTAS LINCE, KINESIOLOGAS PUENTE PIEDRA, KINES MIRAFLORES."></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/" />

            </Helmet>
            <ScrollToTop />
            <div className="Ubicate-map" >
                <Map ref={mapRef} center={defaultCenter} zoom={defaultZoom} >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="http://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                    />
                    {item.map(({ latitud, longitud, nombre, images, dcorta, telefono, _id }) => {
                        if (latitud !== undefined && longitud !== undefined && latitud !== null && longitud !== null && latitud !== '' && longitud !== '') {
                            return (
                                <Marker
                                    key={_id}
                                    position={[latitud, longitud]}
                                    icon={L.divIcon({
                                        className: "custom-div-icon",
                                        html: `
<div class='marker-pin'>
<div class='marker-state'>
<img src=${images[0].url} />
</div>
</div>
`,
                                        iconSize: [30, 42],
                                        iconAnchor: [20, 50]
                                    })}
                                >
                                    <Popup className="request-popup" >
                                        <div className="content-popup">
                                            <h1>{nombre}</h1>
                                            <div>
                                                <div className="content-ppopu-img">
                                                    <img src={images[0].url} width="150" height="150" alt="no img" />
                                                </div>
                                                <span>
                                                    {dcorta}
                                                </span>
                                                <div className="cart-btns">
                                                    <a href={`tel:+${telefono}`} className="add-cart">
                                                        <i class="fa-solid fa-phone"></i>Llamar
                                                    </a>
                                                    <Link
                                                        className="add-cart buy-now" to={`/kines/${_id}`}
                                                    >
                                                        <i class="fa-solid fa-user"></i> Perfil
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </Marker>
                            );
                        } else {
                            return null; // O simplemente omitir el marcador si no hay coordenadas
                        }
                    })}
                    <LocateControl />
                </Map>
                <button
                    type="button"
                    className="btn-hide-siderbar"
                    onClick={() => setShow(!show)}
                >
                    {show === true ? <i class="fa-solid fa-x"></i> : <i class="fa-solid fa-bars-staggered"></i>}
                </button>
                {show && (
                    <div className={show === true ? open : closed}>
                        {item.map((data, index) => {
                            const latitude = data.latitud;
                            const longitude = data.longitud;

                            // Comprobar si latitude y longitude son valores válidos antes de continuar
                            if (latitude != null && longitude != null && latitude !== '' && longitude !== '') {
                                const latlong = [latitude, longitude];

                                function handleOnFlyTo() {
                                    const { current = {} } = mapRef;
                                    const { leafletElement: map } = current;

                                    map.flyTo(latlong, 18, {
                                        duration: 4
                                    });
                                }

                                return (
                                    <div className="sbcontent">
                                        <div className="sbcontent-img-cont">
                                            <img src={data.images[0].url} />
                                        </div>
                                        <div>
                                            <div className="sbcontent-info-uno">
                                                <span className="info-titulo">{data.nombre}</span>
                                                <span className="info-precio">S/.{data.phora}</span>
                                            </div>
                                            <div className="sbcontent-info-dos">
                                                <span className="info-nacionalidad">{data.nacionalidad}</span>
                                                <span className="info-edad">{data.edad} <smoll>años</smoll></span>
                                            </div>
                                            <div className="sbcontent-info-parra">
                                                <p>
                                                    {data.dcorta}
                                                </p>
                                            </div>
                                            <span className="info-distrito"><i class="fa-solid fa-location-dot"></i> {data.distrito}, <smoll>{data.provincia}</smoll> </span>
                                            <div className="info-btn">
                                                <div className="info-btn-whatsapp">
                                                    <a href={`https://api.whatsapp.com/send?phone=${data.telefono}&text=hola,%20té%20vi%20en%20CariñosasHub`}>
                                                        <i class="fab fa-whatsapp"></i>
                                                    </a>
                                                </div>
                                                <button className="info-btn-perfil" type="button">
                                                    <Link id="btn_ver_list" to={`/kines/${data._id}`} >
                                                        <i class="fa-solid fa-user"></i> Perfil
                                                    </Link>
                                                </button>
                                                <button
                                                    className="info-btn-location"
                                                    onClick={handleOnFlyTo}
                                                >
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                // Datos de latitud o longitud no válidos, omite la creación del marcador
                                return null;
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Mapaubicacion