import React, { useState } from 'react';

const ChatButton = () => {
    const [showChat, setShowChat] = useState(false);
    const [message, setMessage] = useState('');

    const toggleChat = () => {
        setShowChat(!showChat);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Aquí puedes hacer la lógica para enviar el mensaje al API de WhatsApp
        //https://api.whatsapp.com/send?phone=51913926030&text=Hola%2C%20deseo%20anunciar%20...%F0%9F%98%80
        // Reiniciar el mensaje

        // Construir el enlace de WhatsApp con el mensaje codificado
        const encodedMessage = encodeURIComponent(message);
        const whatsappLink = `https://api.whatsapp.com/send?phone=51913926030&text=${encodedMessage}`;

        // Abrir una nueva pestaña o ventana del navegador con el enlace de WhatsApp
        window.open(whatsappLink, '_blank');
        setMessage('');
    };

    const handleChange = (e) => {
        setMessage(e.target.value);
    };


    return (
        <div>
            <button onClick={toggleChat} className='chatfloat-btn'><i class="fa-solid fa-paper-plane"></i></button>
            {showChat && (
                <div className="chat-float">
                    <form onSubmit={handleSubmit}>
                        <textarea
                            type="text"
                            value={message}
                            onChange={handleChange}
                            placeholder="Deseo anunciar en CariñosasHub"
                            className='btn_input_chat'
                        />
                        <button type="submit" className='sumit_form_chat' >Enviar</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ChatButton;
