import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getPerfil } from '../../redux/actions/perfilAction'
import LoadIcon from '../../images/loading.gif'
import ShareModal from '../../components/ShareModal'
import logo from '../../images/only.png'
import { BASE_URL } from '../../utils/config'

import { Link } from 'react-router-dom'


const Perfilc = () => {
  const { id } = useParams()
  const [perfil, setPerfil] = useState([])

  const { auth, detailPerfil } = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPerfil({ detailPerfil, id, auth }))

    if (detailPerfil.length > 0) {
      const newArr = detailPerfil.filter(perfil => perfil._id === id)
      setPerfil(newArr)
    }
  }, [detailPerfil, dispatch, id, auth])

  /**** */
  const [isOpen, setIsOpen] = useState(false);
  const [readMore, setReadMore] = useState(false)
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="posts">
      {
        perfil.length === 0 &&
        <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
      }

      {
        perfil.map(datos => (
          <div className='Perfil_COMER'>

            <div className='Perfil_comercio_img'>
              <img src={datos.images[0].url} />
            </div>
            <div className='perfil_comercio_content' id='comercio_content' >

              <div className='perfil_img_avatar'>
                <img src={datos.user.avatar ? datos.user.avatar : 'https://s2.glbimg.com/i90u1AMCupgLE6HH5_9rGkcAZZ4=/smart/e.glbimg.com/og/ed/f/original/2020/04/24/captura_de_tela_2020-04-24_as_09.16.02.png'} />
              </div>

              <div className='perfil_present_content'>
                <p>
                  {
                    datos.description.length < 80
                      ? datos.description
                      : readMore ? datos.description + ' ' : datos.description.slice(0, 80) + '.....'
                  }
                </p>
                {
                  datos.description.length > 80 &&
                  <p className="readMore" onClick={() => setReadMore(!readMore)}>
                    {readMore ? 'Ocultar' : 'Lee mas'}
                  </p>
                }
              </div>

              <h2
                className={datos.preciocontenido ? "Perfil_comer_precio" : "button_btn_co"}
              >
                Precio Contenido: <span>{datos.preciocontenido}</span>
              </h2>

              <div className='Perfil_comercio_button'>

                <button
                  className={datos.linkInstagram ? "button_btn_comerc instagram" : "button_btn_co"}
                >
                  <div className='svg_logo'>
                    <i class="fa-brands fa-instagram"></i>
                  </div>
                  Instagram
                  <div className='menu_comers_logo' >
                    <a href={`${datos.linkInstagram}`} target="_blank" rel="noopener noreferrer">
                      <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                    </a>
                  </div>
                </button>

                <button
                  className={datos.linkTelegram ? "button_btn_comerc telegram" : "button_btn_co"}
                >
                  <div className='svg_logo'>
                    <i class="fa-brands fa-telegram" style={{ color: '#ffffff' }} ></i>
                  </div>
                  Telegram
                  <div className='menu_comers_logo' >
                    <a href={`${datos.linkTelegram}`} target="_blank" rel="noopener noreferrer">
                      <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                    </a>
                  </div>
                </button>

                <button
                  className={datos.linkTiktok ? "button_btn_comerc tiktok" : "button_btn_co"}
                >
                  <div className='svg_logo'>
                    <i class="fa-brands fa-tiktok"></i>
                  </div>
                  Tiktok
                  <div className='menu_comers_logo' >
                    <a href={`https://www.tiktok.com/@${datos.linkTiktok}`} target="_blank" rel="noopener noreferrer">
                      <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                    </a>
                  </div>
                </button>

                <button
                  className={datos.linkOnly ? "button_btn_comerc only" : "button_btn_co"}
                >
                  <div className='svg_logo'>
                    <img src={logo} alt={logo} />
                  </div>
                  OnlyFans
                  <div className='menu_comers_logo' >
                    <a href={`${datos.linkOnly}`} target="_blank" rel="noopener noreferrer">
                      <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                    </a>
                  </div>
                </button>

                <button
                  className={datos.linkOther ? "button_btn_comerc link" : "button_btn_co"}
                >
                  <div className='svg_logo'>
                    <i class="fa-solid fa-link"></i>
                  </div>
                  Link
                  <div className='menu_comers_logo' >
                    <a href={`${datos.linkOther}`} target="_blank" rel="noopener noreferrer">
                      <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                    </a>
                  </div>
                </button>

                <button class="cssbuttons-io-button" onClick={toggleDropdown}
                > Compartir
                  <div class="icon" onClick={toggleDropdown} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
                  </div>
                </button>
              </div>

              {isOpen && (
                <div className='dropdows_menu_'>
                  <div className='dropdows_menu_compart'>
                    <i class="fa-regular fa-rectangle-xmark" onClick={toggleDropdown} ></i>
                    <div>
                      <div className='icon_compart_content'>

                        <a href={`${datos.linkOther}`} target="_blank" rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}>
                          <div className='icon_compart_i'
                          >
                            <i class="fa-solid fa-link"></i>
                            <small>Copiar</small>
                          </div>
                        </a>


                        <a href={`${datos.linkInstagram}`} target="_blank" rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}>
                          <div
                            className={datos.linkInstagram ? "icon_compart_i" : "button_btn_co"}
                          >
                            <i class="fa-brands fa-square-instagram"></i>
                            <small>Abrir</small>
                          </div>
                        </a>

                        <a href={`${datos.linkTelegram}`} target="_blank" rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}>
                          <div
                            className={datos.linkTelegram ? "icon_compart_i" : "button_btn_co"}
                          >
                            <i class="fa-brands fa-telegram"></i>
                            <small>Abrir</small>
                          </div>
                        </a>

                        <a href={`${datos.linkTiktok}`} target="_blank" rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}>
                          <div
                            className={datos.linkTiktok ? "icon_compart_i" : "button_btn_co"}
                          >
                            <i class="fa-brands fa-tiktok"></i>
                            <small>Abrir</small>
                          </div>
                        </a>

                      </div>
                      <label>Compartir</label>
                      <ShareModal url={`${BASE_URL}/perfil/${datos._id}`} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='btn_id_perfil_comer'>
              <Link to={'/'}>
                <div>
                  <svg class="heart_comerk" viewBox="0 0 32 29.6">
                    <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
                  </svg>
                </div>
                <span>
                  CariñosasHub
                </span>
              </Link>
            </div>

          </div>
        ))
      }
    </div>
  )
}

export default Perfilc
