import { POSTHOT_TYPES } from '../actions/posthotAction'
import { POST_TYPES } from '../actions/postAction'

import { EditData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    posts: [],
    result: 9,
    page: 2,
    firstLoad: false
}

const posthotReducer = (state = initialState, action) => {
    switch (action.type) {
        case POSTHOT_TYPES.LOADING_POSTHOT:
            return {
                ...state,
                loading: action.payload
            };
        case POSTHOT_TYPES.GET_POSTSHOT:
            return {
                ...state,
                posts: action.payload.posts,
                result: action.payload.result,
                page: action.payload.page
            };
        case POST_TYPES.UPDATE_POST:
            return {
                ...state,
                posts: EditData(state.posts, action.payload._id, action.payload)
            };

        default:
            return state;
    }

}

export default posthotReducer