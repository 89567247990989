import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'

import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

import MenuDos from './MenuDos'
import Logo from '../../images/lobo.png'

const Header = () => {
    const { theme } = useSelector(state => state)
    const dispatch = useDispatch()


    return (
        <div className="header">
            <div className='header_encabezado' style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} ></div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link to="/" style={{ textDecoration: 'none' }} >
                    <h1 className="gradient-text" id='logo_css' style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }}
                        onClick={() => window.scrollTo({ top: 0 })}>
                        Cariñosas Hub
                    </h1>
                </Link>
                <div className='menus-header'>
                    <Menu />
                    <MenuDos />
                </div>
                <div className='menus-header'>
                    <Link to={'/catalogo'}>
                        <button className='header_btn_anuncios' style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }}>
                            Anuncios
                        </button>
                    </Link>
                    <label htmlFor="theme" className="toggle"
                        onClick={() => dispatch({
                            type: GLOBALTYPES.THEME, payload: !theme
                        })}>
                        <i class="fa-regular fa-lightbulb"></i>
                    </label>
                </div>
            </nav>
        </div>
    )
}

export default Header
