import React from 'react'

import Cardheader from './Cardheader'
import Cardbody from './Cardbody'
import Cardfooter from './Cardfooter'

function Cardflowers({post}) {
  return (
    <div className='card-content-info'>
        <Cardheader post={post} />
        <Cardbody post={post} />
        <Cardfooter post={post} />
    </div>
  )
}

export default Cardflowers