const valid = ({fullname, username,  email, password, cf_password, edad, pais }) => {
    const err = {}

    if(!fullname) {
        err.fullname = "Agregue su nombre completo"
    }else if(fullname.length > 25){
        err.fullname = "Nombre completo hasta 25 caracteres."
    }

    if(!username) {
        err.username = "Por favor agregue nombre de usuario"
    }else if(username.replace(/ /g, '').length > 25){
        err.username = "Nombre de usuario puede tener hasta 25 caracteres."
    }

    if(!pais) {
        err.pais = "Falto el Pais"
    }
    
    if (!edad) {
        err.edad = "Por favor agregue su edad"
    } else if (edad < 18) {
        err.edad = "Tienes que ser mayor de 18 años"
    }
/*
    if (isNaN(edad)){
        err.edad = "Ups... " + edad + " no es un número.";
    } else {
        if (edad % 1 == 0) {
            err.edad = "";
        } else {
            err.edad = "Tiene que ser una edad completa";
        }
    }*/

    if(!email) {
        err.email = "Por favor agregue su email"
    }else if(!validateEmail(email)){
        err.email = ""
    }
    if(!password) {
        err.password = "Por favor agregue su password"
    }else if(password.length<6){
        err.password = "La contraseña debe tener al menos 6 caracteres"
    }

    if(password !== cf_password) {
        err.cf_password = "La confirmacion de contraseña no coincide"
    }
    return {
        errMsg: err,
        errLength: Object.keys(err).length
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  export default valid