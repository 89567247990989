import React, { useContext, useState } from 'react'
import Avatar from '../Avatar'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { deleteAnuncio } from '../../redux/actions/anuncioAction'
import { GlobalState } from '../../GlobalState'

import Editpublic from '../profile/Editpublic'

const CardAnHeader = ({ anuncio }) => {
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const state = useContext(GlobalState)
    const [isAdmin] = state.userAPI.isAdmin

    const history = useHistory()

    //
    const [onEdit, setOnEdit] = useState(false)
    const [edituser, setEdituser] = useState(anuncio)
    //

    /*****para eliminar array img**** */
    const number = anuncio.images.length
    const publicIds = Array.from({ length: number }, (_, index) => anuncio.images[index].public_id)
    /**** */

    const handleEditAnuncio = () => {
        dispatch({ type: GLOBALTYPES.ANUNCIO, payload: { ...anuncio, onEdit: true } })
    }

    const handleDeleAnuncio = () => {
        dispatch(deleteAnuncio({ anuncio, auth }))
    }


    return (
        <div className='caranuncio_header'>
            {
                onEdit &&
                <Editpublic setOnEdit={setOnEdit} edituser={edituser} />
            }
            <div className='d-flex'>
                <Avatar src={anuncio.user && anuncio.user.avatar ? anuncio.user.avatar : 'https://cdn.icon-icons.com/icons2/2942/PNG/512/profile_icon_183860.png'} size="big-avatar" />
                <div>
                    <h6 className='m-0'>
                        <Link to={anuncio.user ? `/profile/${anuncio.user._id}` : '/'} className="text-dark">
                            {anuncio.user ? anuncio.user.username : 'Usuario Desconocido'}
                        </Link>
                    </h6>
                    <small className='text-muted'>
                        {moment(anuncio.createdAt).fromNow()}
                    </small>
                </div>
            </div>
            <div className='nav-item dropdown' style={{ display: 'block', cursor: 'pointer' }}>
                <span className='material-icons' id='moreLink' data-toggle="dropdown">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                </span>
                <div className='dropdown-menu'>
                    {
                        (auth.user && anuncio.user && (auth.user._id === anuncio.user._id || isAdmin)) &&
                        <>
                            <div className='dropdown-item' onClick={() => setOnEdit(true)}>
                                <span className='material-icons'>create</span> Editar Anuncio
                            </div>
                            <div className='dropdown-item' onClick={handleDeleAnuncio} >
                                <span className='material-icons'>delete_outline</span> Remover anuncio
                            </div>
                        </>
                    }
                    <div className='dropdown-item'>
                        <span className='material-icons'>content_copy</span>Copiar Link
                    </div>
                    {
                        (auth.user && anuncio.user) &&
                        <div className='dropdown-item' onClick={() => setOnEdit(true)}>
                            <span className='material-icons'>create</span> Editar Anuncio
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CardAnHeader