import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { BASE_URL } from '../../../../utils/config'

//import { GlobalState } from '../../../../App.test'

import Avatar from '../../../Avatar'
import { deletePost } from '../../../../redux/actions/postAction'


const Cardheader = ({ post }) => {

    /*/tipo de usuario
    const state = useContext(GlobalState)
    const [isModerador] = state.userAPI.isModerador
    /*/

    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const history = useHistory()


    const handleEditPost = () => {
        dispatch({ type: GLOBALTYPES.STATUS, payload: { ...post, onEdit: true } })
    }

    const handleDeletePost = () => {
        if (window.confirm("Seguro que quieres eliminar esta publicación?")) {
            dispatch(deletePost({ post, auth, socket }))
            window.location.reload()
            return history.push("/");
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${BASE_URL}/post/${post._id}`)
    }

    return (
        <div >
            <div className="card_headerflowers">
                <div className="d-flex">
                    <Avatar src={post.user.avatar} size="big-avatar" />

                    <div className="card_name">
                        <h6 className="m-0">
                            <Link to={`/profile/${post.user._id}`} >
                                {post.user.username}
                            </Link>
                        </h6>
                        <small >
                            {moment(post.createdAt).fromNow()}
                        </small>
                    </div>
                </div>
                <div className="nav-item dropdown" style={{ display: 'block' }} >
                    <span className="material-icons" id="moreLink" data-toggle="dropdown">
                        menu
                    </span>

                    <div className="dropdown-menu">

                        {
                            auth.user._id === post.user._id &&
                            <>
                                <div className="dropdown-item" onClick={handleEditPost}>
                                    <span className="material-icons">create</span> Editar Post
                                </div>
                                <div className="dropdown-item" onClick={handleDeletePost} >
                                    <span className="material-icons">delete_outline</span> Remover Post
                                </div>
                            </>
                        }

                        {/* {
                        isModerador
                            ? <>
                                <div className="dropdown-item" onClick={handleEditPost}>
                                    <span className="material-icons" style={{ color: 'red' }}>create</span> Editar Post
                                </div>
                                <div className="dropdown-item" onClick={handleDeletePost} >
                                    <span className="material-icons" style={{ color: 'red' }}>delete_outline</span> Remover Post
                                </div>
                                <div className="dropdown-item" onClick={handleCopyLink} >
                                    <span className="material-icons">content_copy</span> Copiar Link
                                </div>
                            </>
                            : <span></span>
                    }*/}

                        {
                            auth.user._id !== post.user._id &&
                            <>
                                <div className="dropdown-item" onClick={handleCopyLink} >
                                    <span className="material-icons">content_copy</span> Copiar Link
                                </div>
                                <div className="dropdown-item">
                                    <a style={{ color: 'red', textDecoration: 'none' }}
                                        href={`https://api.whatsapp.com/send?phone=+51913926030&text=HOLA,%20Quiero%20Denunciar%20Este%20Post:${post._id}%20Del%20Usuario${post.user.fullname}`}>
                                        <i class="fa-solid fa-eye-slash"></i> Denunciar Post
                                    </a>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
            
        </div>
    )
}

export default Cardheader