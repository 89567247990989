import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { updateUsuario } from '../../redux/actions/profileAction'
import axios from 'axios'


function EditUser({ setOnEdit, edituser }) {

    const dispatch = useDispatch()

    /****constantes para editar****/
    const [verificacion, setVerificacion] = useState(edituser.verificacion)
    const [activopago, setActivopago] = useState(edituser.activopago)
    const [fechaincio, setFechaincio] = useState(edituser.fechaincio)
    const [username] = useState(edituser.username)
    const [role, setRole] = useState(edituser.role)
    const [rolecomercian, setRolecomercian] = useState(edituser.rolecomercian)

    ////

    // const handleSubmit = e => {
    //  e.preventDefault()
    //dispatch(updateProfileUser({ userData, auth }))
    // }
    const enviar = { verificacion, activopago, fechaincio, role }
    /****fechas */
    const fechinciosus = moment(fechaincio).format('DD-MM-YYYY')
    const sumafecha = moment(fechaincio).add(31, 'd').format('DD-MM-YYYY')

    ///////////
    const ids = edituser._id

    const handleSubmit = async e => {
        e.preventDefault()
        dispatch(updateUsuario({ verificacion, activopago, fechaincio, role, rolecomercian, ids }))
    }

    ////comparar fechas

    var x = new Date(moment(fechaincio).add(30, 'd').format('YYYY-MM-DD'));
    var y = new Date(moment().format('YYYY-MM-DD'));

    const igualar = moment(y).isSameOrAfter(x) //false si es menor a la fecha

    return (
        <div className="edit_profile">
            <button className="btn btn-danger btn_close"
                onClick={() => setOnEdit(false)} >
                close
            </button>
            <form onSubmit={handleSubmit}>
                <div className="form-group" >
                    <h3>{username}</h3>
                </div>

                <div className="form-group" >
                    <label htmlFor="verificacion" > Estado Actual :
                        {
                            verificacion === true ? ' VERIFICADO' : ' NO VERIFICADO'
                        }
                    </label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="verificacion" id="flexRadioDefault1"
                            onChange={e => setVerificacion(e.target.value)} value='true' />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Verificar
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="verificacion" id="flexRadioDefault2"
                            onChange={e => setVerificacion(e.target.value)} value='false' />
                        <label class="form-check-label" for="flexRadioDefault2">
                            No Verificar
                        </label>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="activopago" >Estado de Suscripción :
                        {
                            activopago === true ? ' SUSCRITO' : ' NO SUSCRITO'
                        }
                    </label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="activopago" id="flexRadioDefault1"
                            onChange={e => setActivopago(e.target.value)} value='true' />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Suscribir
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="activopago" id="flexRadioDefault2"
                            onChange={e => setActivopago(e.target.value)} value='false' />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Cancelar Suscripción
                        </label>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="fechaincio" style={ igualar === true ? {background: 'red'} : {background:'#FCF3CF'}} >Fecha De Hoy: {moment().format('DD-MM-YYYY')} </label><br />
                    <label htmlFor="fechaincio" >Fecha Suscripción: {fechinciosus} </label><br />
                    <label htmlFor="fechaincio" style={ igualar === true ? {background: 'red'} : {background:'#FCF3CF'}}>Fecha Final: {sumafecha} </label><br />
                    <label htmlFor="fechaincio" >Nueva Fecha Suscripción:</label>
                    <div className="position-relative" >
                        <input type="date" className="form-control" id="fechaincio" min="2023-01-01" max="2025-12-31"
                            name="fechaincio" value={fechaincio} onChange={e => setFechaincio(e.target.value)} />
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="fechafinal" >Fecha Final Suscripción</label>
                    <div className="position-relative" >
                        <span>{sumafecha}</span>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="rolecomercian" >Estado comercial actual: {rolecomercian}</label>
                    <div className="position-relative" >
                        <select class="form-select" aria-label="Default select example" id="rolecomercian"
                            name="rolecomercian" value={rolecomercian} onChange={e => setRolecomercian(e.target.value)} >
                            <option value="Normal">Normal</option>
                            <option value="Preferencial">Preferencial</option>
                            <option value="Vip">Vip</option>
                        </select>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="role" >Rol de Usuario actual: {role}</label>
                    <div className="position-relative" >
                        <select class="form-select" aria-label="Default select example" id="role"
                            name="role" value={role} onChange={e => setRole(e.target.value)} >
                            <option value="user">usuario</option>
                            <option value="uservip">Usuario Suscrito</option>
                            <option value="moderador">Moderador</option>
                        </select>
                    </div>
                </div>

                <div className='anuncio_footer'>
                    <button className='btn btn-secondary w-100' type='submit' >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditUser