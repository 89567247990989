import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet-async';

function Nuestrapolitica() {

  const tituloDinamico = "Kinesiologas en Perú - CARIÑOSASHUB";

  return (
    <div className='politicas_condiciones_page'>

      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="Anuncios de Kinesiologas en Peru, Putas en Lima, Kines Peru, Escorts maduras y chicas, Peruanas y Extranjeras Venezolanas, Colombianas ofrecen sexo en Lima."
        ></meta>

        <meta name="keywords"
          content="Kinesiólogas, Kinesiólogas en perú, kines en perú, putas en perú, sexo en perú, escorts en perú, Prostitutas en perú, Putas peruanas, putas extranjeras en perú, kines Perú. Kines, maduras en perú, jovencitas en perú, kines lima perú. kines en lince, KINESIOLOGAS LINCE, KINES LOS OLIVOS, KINESIOLOGAS LOS OLIVOS, PUTAS LINCE, KINESIOLOGAS PUENTE PIEDRA, KINES MIRAFLORES."></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/" />

      </Helmet>

      <div className='terminos_condiciones'>
        <div className='div_title_poli'>
          <h3><i class="fa-solid fa-folder-open"></i>Categoría: Políticas De Privacidad</h3>
          <Link to={'/informacion-legal'}>
            <i class="fa-regular fa-circle-right" style={{ color: 'red' }} ><span style={{ color: 'red' }}>Conozca las Reglas de "CariñosasHub" y reporte violaciones... (IMPORTANTE!!....)</span></i><br />
          </Link>
        </div>
      </div>

      <div className='terminos_politicas'>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseone1" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-lock"></i>PRIVACIDAD</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseone1" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingone1">
              <div class="accordion-body">
                <p>
                  La plataforma "CariñosasHub" está comprometido a proteger la privacidad de los datos desde los cuales usted puede ser identificado personalmente.
                  Sólo recogemos, tratamos, cedemos y conservamos dicha información de acuerdo con la Protección de Datos y este documento que menciona sobre
                  la política de privacidad de la nuestra plataforma.
                </p>
                <p>
                  "CariñosasHub" es el responsable del tratamiento con la finalidad de tratar y proteger sus datos, para cualquier pregunta o solicitud referente a sus datos, nuestro
                  tratamiento de la misma y esta política de privacidad.
                </p>
                <p>
                  <p style={{ color: 'red' }}>Para el objetivo de esta "política de privacidad"...</p>La "política de privacidad" de "CariñosasHub" se refiere al Reglamento de Protección de Datos,
                  cualquier ley local en su pais que implemente, complemente y aplique las excepciones acordadas y todos los códigos de práctica, las normas y las directrices emitidas por
                  cualquier regulador aplicable con potestad conforme, junto con la normativa sobre conservación de datos del tráfico en las comunicaciones electrónicas o cualquier
                  disposición que la modifique o la sustituya. La Política de Privacidad, junto con los Términos de Uso en Términos y condiciones y cualquier otro documento mencionado
                  en él, establece la base sobre la cual protegeremos y trataremos sus datos.
                  Tenga en cuenta que, cuando contacta con un Usuario Anunciante (según se describe en los Términos de Uso), utilizando la información de contacto que figura en
                  cualquier tipo de material insertado por un Usuario en el sitio, no nos hacemos responsables de esa información ni de cómo los terceros elijan procesar cualquier
                  dato transferida a ellos. Los terceros se convierten en los controladores de datos con respecto a tales transferencias de datos y de ninguna manera somos capaces de
                  controlar o ser responsables de su datos en sus manos.
                </p>
                <h3>Qué Información recogemos</h3>
                <p>
                  Recogemos la información que nos ha sido enviada por:
                  <ul>
                    <li>(1) Usuarios suscritos, los cuales crean un perfil y hacen diferentes publicaciones. </li>
                    <li>(2) Usuarios Anunciantes, los cuales desean poner anuncios o crear perfiles en el Sitio. </li>
                    <li>(3) Usuarios Libres,los cuales desean navegar por el Sitio y contactar con el Usuario Anunciante.</li>
                  </ul>
                  Con respecto a los Usuarios Anunciantes, la base legal para la recopilación de datos realizada por nosotros, es la necesaria para realizar un contacto entre el
                  Sitio y el Usuario Anunciante. Con respecto a los Usuarios Finales, la base principal para la recopilación de datos es el consentimiento del Usuario Final. Además,
                  el sitio tiene un interés legítimo en recoger y tratar datos, teniendo debidamente en cuenta los derechos y libertades de los interesados, fines comerciales adecuados
                  y de conformidad con la prevención de fraudes.
                </p>
                Cómo Recogemos y Tratamos la Información
                <h3>Archivos de Registro</h3>
                <p>
                  Cada vez que visita el Sitio, recogemos cierta información sobre el uso de nuestro sitio web para analizar tendencias, administrar el sitio, rastrear el movimiento
                  de visitantes en conjunto y recopilar información demográfica para uso global. Por tanto, garantizamos que los datos tratados son adecuados, pertinentes y limitados
                  a lo necesario para el fin. No vinculamos ninguna de la información de nuestros archivos de registro a ninguna otra información que nos permita determinar su identidad
                  real. Esto significa que la sesión de un usuario será rastreada, pero el usuario será anónimo. No compartimos externamente sus archivos de registro.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsetwo2" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-address-card"></i>RESPONSABILIDAD DEL USUARIO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsetwo2" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingtwo2">
              <div class="accordion-body">
                <p>El USUARIO es el único responsable del contenido de cualquier tipo publicado por él en el SITIO, eximiendo el USUARIO al SITIO o bien a terceros de cualquier
                  responsabilidad que pudiera emanar de un contenido indebido publicado por él en el SITIO. El SITIO declara expresamente que en virtud de esta responsabilidad
                  del USUARIO el SITIO trata de supervisar y controlar los contenidos publicados por el USUARIO, pero no en su totalidad, por ende la publicación en el SITIO de
                  cualquier contenido por parte del USUARIO no implica el conocimiento del SITIO, ni la aceptación, conformidad, apoyo o relación de este con el contenido publicado,
                  ni tampoco se responsabiliza de la exactitud y veracidad de lo publicado.

                  Es responsabilidad del USUARIO el buen uso y custodia de las contraseñas o demás datos de acceso que en cualquier momento le pueda facilitar el SITIO para la
                  utilización de los servicios ofrecidos por este. El uso indebido o fraudulento por parte del mismo o de terceros de todos esos datos de acceso es única y
                  exclusiva responsabilidad del USUARIO.

                  Es responsabilidad del USUARIO el hacer un buen uso de los servicios que el SITIO le ofrece, por buen uso se entiende al uso adecuado dentro de los principios de
                  honestidad y buena fe, y concretamente un buen uso significa:

                  No  utilizar abusivamente los servicios, No introducir contenido falso o que induzca al error. En este punto mención especial es para el material fotográfico
                  que se publique, el mismo deberá ser siempre original y autentico, en caso de detectarse irregularidad, será bloqueado el contenido publicado hasta la completa
                  subsanación de lo publicado indebidamente.

                  No interferir en el buen y correcto funcionamiento del sitio web, expresamente en este caso se prohíben repetir contenido publicado (anuncios ya insertados),
                  publicar de una forma errónea o mal formada el contenido, etc. etc.
                  Queda completamente prohibido utilizar sistemas informáticos o de cualquier índole que sirvan para burlar los sistemas de seguridad implantados por el SITIO</p>

              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsetwo3" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-book"></i>OBLIGACIONES Y COMPORTAMIENTO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsetwo3" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingtwo3">
              <div class="accordion-body">
                <p>
                  En el ámbito de esta sección (Términos y condiciones de uso), se hace referencia tanto a los usuarios demandantes como a los
                  usuarios oferentes. El servicio podrá ser usado exclusivamente por mayores de dieciocho años (18). La plataforma "CariñosasHub" comunica que:
                </p>
                <ol>
                  <li>
                    Los anuncios no están controlados por "CariñosasHub" en su totalidad, ya que al tratarse de un portal de anuncios clasificados dirigido a un público adulto, los anuncios
                    pueden incluir contenidos e imágenes de carácter erótico y/o pornográfico no aptos a personas sensibles o que no soportan imágenes de carácter sexual explícito.
                    Por ello, la plataforma "CariñosasHub" previamente al acceso a la website, informa al usuario sobre dichas circunstancias exigiendo a éste que certifique que es una
                    persona adulta.
                  </li>
                  <li>
                    Debido a la imposibilidad de controlar la publicación de los anuncios, "CariñosasHub" no puede verificar si se trata de un anuncio real. En las condiciones de
                    uso, "CariñosasHub" informa que no puede garantizar la veracidad del contenido de los mismos.
                  </li>
                </ol>
                <p>
                  Siendo conocedores de ello, "CariñosasHub", facilita a los usuarios diferentes vías de contacto y establece de manera exclusiva el correo alekeydeveloper@gmail.com para las
                  peticiones de violación de privacidad. Fan-Hot es un portal que tiene como objetivo ser una red social opcional exclusiva para personas adultas donde puedan hacer diferentes
                  publicaciones, también da la opción para publicar anuncios clasificados de calidad, por ello, establece todos los mecanismos posibles para lograr este fin, por lo que
                  se agradece la colaboración de los usuarios.

                  Publicando su anuncio en "CariñosasHub", el usuario autoriza a la plataforma a utilizarlo con fines publicitarios para darle mayor visibilidad y que sea más accesible a
                  los usuarios de la red incluso señalándolo en buscadores mediante publicaciones en otras páginas web con los que "CariñosasHub" mantenga una relación de colaboración
                  (como, por ejemplo, otras páginas de anuncios clasificados o buscadores verticales o generales) con la finalidad de promover con mayor eficacia las insercciones de
                  sus propios usuarios.

                  El Usuario se compromete a no usar los servicios proporcionados por la plataforma "CariñosasHub" de manera impropia o contraria a las disposiciones de Ley y a las normas de
                  Ética y de Buena conducta de los servicios en red. El usuario, en particular, se compromete a no transmitir, mediante "CariñosasHub", material de naturaleza ofensiva
                  calumniosa, difamatoria, pornográfica, pedófila, vulgar, oscena, blasfema o que, de algún modo, sea contraria a los principios del orden público y de las buenas costumbres.

                  El anunciante es consciente que la dirección de correo electrónico que proporciona en "CariñosasHub" podrá servir como medio de comunicación a distancia. Además, en el
                  caso de las respuestas a los anuncios y/o la divulgación de los anuncios, el correo electrónico que se muestra podrá ser utilizado para enviar boletines informativos
                  y material promocional

                  Por la presente confirma que tiene la edad legal conforme la jurisdicción aplicable y no ha sido forzado de ninguna manera a crear este perfil Por la presente
                  confirma que no ofrecerá ningún servicio contrario a la ley del país.
                </p>
                <h3>Atención</h3>
                <ul>
                  <li>
                    La inserción de material pedófilo conllevará la inminente denuncia a las autoridades competentes incluyendo todos los datos de acceso a la página con el fin de
                    poder llegar hasta los responsables.
                  </li>
                  <li>
                    insertando el anuncio en "CariñosasHub" el usuario certifica que puede disponer con total derecho del contenido; además, declara que las imágenes, eventualmente agregadas,
                    son de personas mayores de edad que han manifestado su consentimiento a la publicación de las imágenes en "CariñosasHub".
                  </li>
                  <li>
                    No está permitido insertar en los anuncios ningún tipo de link que se pueda pinchar o no.
                  </li>
                  <li>
                    Tampoco está autorizado insertar textos o imágenes vulgares u ofensivos.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsetwo4" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-square-check"></i>ACEPTACIÓN DE LAS CONDICIONES DE USO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsetwo4" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingtwo4">
              <div class="accordion-body">
                <p>
                  El usuario, antes de gozar de ningún servicio, está obligado a informarse de las eventuales modificaciones y/o actualizaciones llevados a cabo en las condiciones
                  de uso. Estas modificaciones y/o actualizaciones formarán, de hecho, parte integrante de las presentes condiciones generales y constituirán el modo de acuerdo
                  entre "CariñosasHub" y el usuario.

                  "CariñosasHub" no interviene entre las relaciones de los usuarios y anunciantes.

                  "CariñosasHub" no tiene una sede física en este país. Si tiene dudas sobre quién se comunicó con usted, escriba a alekeydeveloper@gmail.com.

                  Eliminado del sistema (bloqueado automáticamente).

                  El usuario, que perciba dentro de plazo la suspensión del servicio de visibilidad, puede solicitar al staff una explicación sobre los motivos que han causado la
                  suspensión Por lo tanto, el anuncio eliminado no es nunca reembolsado por completo si ha disfrutado por un tiempo del servicio de visibilidad. La visibilidad
                  disfrutada no se cuenta desde el momento de la adquisición / pago, cuenta desde el momento de la activación del producto sobre el anuncio específico. "CariñosasHub"
                  reembolsa los créditos restantes tras la suspensión voluntaria de la promoción por parte del usuario, por ello le aconsejamos que adquiera únicamente la cantidad
                  necesaria para el tiempo que le vaya a resultar útil.

                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsetwo5" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-hand"></i>EXCEPCIONES</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsetwo5" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingtwo5">
              <div class="accordion-body">
                <p>
                  Los casos particulares donde el error se produzca de forma unequívoca y absoluta por parte del Staff, da lugar a las siguientes excepciones:
                </p>

                <h4>Reembolso total del crédito :</h4>
                <p>
                  Si el usuario demuestra que ha subido el anuncio por error e inmediatamente lo suspende y lo comunica al staff de la web. Las subidas al Top aparecen
                  reflejadas en el calendario del anuncio, si el staff observa que hay buena fe por parte de usuario y que ha cometido un error al subir el anuncio
                  (por ejemplo subirlo en un ciudad equivocada) puede devolver el importe total, si el reclamo se da antes de 48 horas.
                </p>
                <h4>Moneda del Reembolso(sol) :</h4>
                <p>
                  Si el staff de la plataforma logra verificar el reclamo del usuario, y si por criterio solo y unicamente de la misma plataforma, se hara el reembolsado
                  necesario y en la moneda por la cual realizo el pago. siendo el reclamo antes de las 48 horas de la publicación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='terminos_politicas'>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseone" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-handshake"></i>CONDICIONES DE USO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseone" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingone">
              <div class="accordion-body">
                <p>El acceso y uso de los servicios de la plataforma "CariñosasHub" (en adelante “EL  SITIO”), suponen la completa y total aceptación de las condiciones que aquí se
                  exponen por parte del usuario o usuaria (en adelante “EL USUARIO”), siendo este el responsable del incumplimiento de las mismas, respondiendo según convenga en derecho del
                  quebrantamiento o mal uso de las mismas o de la legislación aplicable de cualquier ámbito y localización, respondiendo ante terceros y también ante los legítimos propietarios
                  titulares de este dominio web por los daños de cualquier índole que un mal uso del USUARIO les pudiera ocasionar.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsetwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-book-open-reader"></i>RESPONSABILIDAD DEL USUARIO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsetwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingtwo">
              <div class="accordion-body">
                <p>El USUARIO es el único responsable del contenido de cualquier tipo publicado por él en el SITIO, eximiendo el USUARIO al SITIO o bien a terceros de cualquier
                  responsabilidad que pudiera emanar de un contenido indebido publicado por él en el SITIO. El SITIO declara expresamente que en virtud de esta responsabilidad
                  del USUARIO el SITIO no supervisa ni controla los contenidos publicados por el USUARIO, por ende la publicación en el SITIO de cualquier contenido por parte del
                  USUARIO no implica el conocimiento del SITIO, ni la aceptación, conformidad, apoyo o relación de este con el contenido publicado, ni tampoco se responsabiliza de
                  la exactitud y veracidad de lo publicado

                  Es responsabilidad del USUARIO el buen uso y custodia de las contraseñas o demás datos de acceso que en cualquier momento le pueda facilitar el SITIO para la
                  utilización de los servicios ofrecidos por este. El uso indebido o fraudulento por parte del mismo o de terceros de todos esos datos de acceso es única y
                  exclusiva responsabilidad del USUARIO.

                  Es responsabilidad del USUARIO el hacer un buen uso de los servicios que el SITIO le ofrece, por buen uso se entiende al uso adecuado dentro de los principios de
                  honestidad y buena fe, y concretamente un buen uso significa:
                  No  utilizar abusivamente los servicios, No introducir contenido falso o que induzca al error. En este punto mención especial es para el material fotográfico
                  que se publique, el mismo deberá ser siempre original y autentico, en caso de detectarse irregularidad, será bloqueado el contenido publicado hasta la completa
                  subsanación de lo publicado indebidamente.

                  No interferir en el buen y correcto funcionamiento del sitio web, expresamente en este caso se prohíben repetir contenido publicado (anuncios ya insertados),
                  publicar de una forma errónea o mal formada el contenido, etc. etc.
                  Queda completamente prohibido utilizar sistemas informáticos o de cualquier índole que sirvan para burlar los sistemas de seguridad implantados por el SITIO</p>

              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsethree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <h3><i class="fa-solid fa-handshake-simple"></i>CONDICIONES DE PRIVACIDAD</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsethree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingthree">
              <div class="accordion-body">
                <p>
                  El SITIO garantiza en todo momento el cumplimiento integro y escrupuloso de lo establecido por la legislación que le es aplicable en lo relativo a la
                  protección de datos personales y la privacidad de los mismos, la política de privacidad articulada de forma completa se encuentra en texto en el mismo
                  sitio "CariñosasHub".

                  Además de los derechos que la legislación otorga al USUARIO en la protección de sus datos personales, el USUARIO en las condiciones y opciones que así
                  ofrezca en cada momento el SITIO podrá configurar la relación interna que le vincula con el SITIO (comunicaciones por correo electrónico,…)

                  El USUARIO acepta que todo el contenido publicado por su persona en el sitio será visible públicamente sin más restricciones que las que estipule libremente el SITIO
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-book-bookmark"></i>DERECHOS DE AUTOR</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-collapsefour">
              <div class="accordion-body">
                <p>
                  Todo el contenido publicado en el SITIO está protegido por la legislación sobre propiedad intelectual correspondiente o la que en cada momento le sea de aplicación,
                  el uso indebido de material de cualquier tipo del SITIO dará sitio a la persecución legal correspondiente para preservar los derechos conculcados.

                  El contenido en su totalidad y vaya o no con el símbolo grafiado correspondiente © (Copyright) u análogo se entiende protegido en la modalidad de todos los
                  derechos reservados a favor de los legales propietarios y titulares del SITIO. En ningún caso la reserva de derechos significara que el SITIO es autor o
                  responsable del contenido, el autor o responsable es el USUARIO que publicando el contenido en el sitio cede al SITIO sin limitaciones, permanente e
                  irrevocablemente todos los derechos intelectuales y patrimoniales que le pudieran corresponder. Por ello el usuario en el momento de la publicación
                  reconoce que tiene todos los derechos para hacerlo
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-book-bookmark"></i>DERECHOS DEL SITIO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-collapsefive">
              <div class="accordion-body">
                <p>
                  El SITIO podrá dejar de prestar sus servicios sin previo aviso, sin que ello de compensación ni derecho de ningún tipo. A la par que al derecho de supresión,
                  el SITIO se reserva en todo momento el derecho de modificación total o parcial, definitiva o temporal de cualquier servicio por él ofrecido

                  El SITIO se reserva el derecho de rechazar la petición de alta de cualquier nuevo usuario o si lo estima conveniente dar de baja a alguno existente con todos
                  sus datos asociados. A la vez se reserva el derecho de bloquear cualquier cuenta de usuario que considere sospechosa de estar infringiendo estas normas o que
                  contravenga la legislación y se reserva el derecho de limitar o restringir total o parcialmente a cualquier usuario el uso de cualquier funcionalidad, así como
                  de eliminar inmediatamente contenido indebido publicado por el USUARIO, sin perjuicio de que además tome las medidas legales oportunas

                  El SITIO se reserva el derecho de poner en conocimiento de las autoridades competentes cualquier fraude, delito o irregularidad que sospeche se esté cometiendo
                  a través del sitio por parte del USUARIO

                  El SITIO se reserva el derecho de dar acceso y uso a terceros del contenido publicado por el USUARIO en el sitio, sin que ello de lugar a contraprestación alguna,
                  en ningún caso ello conllevara la cesión de información personal del USUARIO que no esté estipulada en la política de privacidad. El uso fraudulento o indebido de
                  terceros del contenido publicado por el USUARIO será responsabilidad del que actúe fraudulenta o indebidamente, sin que el SITIO tenga ninguna relación ni
                  responsabilidad, ya sea directa o indirecta

                  El SITIO se reserva el derecho de la libre manipulación, transformación o adaptación del contenido publicado por el usuario, para darle el uso adicional que
                  estime conveniente (publicitarlo en otros medios, etcétera) o para adaptarlo técnicamente dentro del mismo sitio. En el caso de los “anuncios de pago destacados”
                  que publique el USUARIO, los mismos antes de su efectiva publicación deberán pasar por el filtro y moderación del SITIO, para que el mismo se adapte y ajuste a la
                  política de calidad, diseño y contenido del sitio

                  El SITIO se reserva el derecho de insertar publicidad u otros recursos que generen beneficios económicos al sitio en el contenido que el USUARIO haya publicado en el sitio,
                  ello no dará derecho a contraprestación alguna al USUARIO

                  El SITIO se reserva la única y exclusiva potestad de actuar técnicamente sobre el sitio, el USUARIO en ningún caso estará autorizado a ninguna otra función en el
                  sitio que las funciones públicas y las que tenga activas como usuario del mismo, ninguna más. La vulneración de esta clausula podrá dar lugar a las reclamaciones
                  pertinentes y el SITIO lo considerara en todo caso como un ataque no autorizado a sus sistemas

                  El SITIO se reserva el derecho de modificar sin previo aviso y total o parcialmente el contenido de estas condiciones generales, el USUARIO podrá consultar
                  siempre la última versión actualizada en www.kinesiologasenperu.com, en el caso de en algún momento el USUARIO no aceptar alguna de las condiciones del SITIO
                  podrá ejercer libremente su derecho a darse de baja del sitio
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-users-rays"></i>PRESTACION DEL SERVICIO</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-collapsesix">
              <div class="accordion-body">
                <p>
                  La no aceptación por parte del USUARIO de cualquiera de las condiciones aquí expuestas imposibilita al mismo para utilizar los servicios que el SITIO presta y queda
                  desautorizado a acceder o utilizar los mismos

                  El servicio que presta el SITIO es prestado “tal cual” y “según disponibilidad”. El servicio paralelamente también se presta bajo la modalidad “freemium”, es
                  decir con servicios ofrecidos por el SITIO de forma gratuita y otros ofrecidos bajo la modalidad de pago, ello en ningún caso significa un distinto condicionado
                  de este marco regulador, el mismo es el común para todos los servicios prestados por el SITIO

                  El SITIO no queda comprometido al buen funcionamiento, calidad, disponibilidad, seguridad o fiabilidad de los servicios prestados. En consecuencia no asume
                  responsabilidad alguna en caso de fallos en el servicio ni en daños informáticos o de cualquier índole que por el uso de sus servicios se pudieran causar,
                  más allá de los de imperativo legal que la legislación establezca donde radica EL SITIO

                  En la prestación del servicio el SITIO puede dar acceso a enlaces o recursos de terceros, en ningún caso el SITIO será responsable de los servicios o contenido de estos,
                  tampoco será el SITIO responsable de los posibles prejuicios que terceros u otros usuarios del sitio pudieran causarle al USUARIO

                  Las tarifas de los servicios que el SITIO presta en modalidad de pago están sujetas a cambio unilateral por parte del SITIO y sin previo aviso, pero siempre se
                  anunciaran las actualizadas debidamente en www.kinesiologasenperu.com y también podrán (sin que esto último sea vinculante) ser anunciadas a través de correo
                  electrónico a los usuarios del sitio
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseseven" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-book-open"></i>CONDICIONES ADICIONALES</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseseven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-collapseseven">
              <div class="accordion-body">
                <p>
                  En caso de que alguna de las condiciones aquí estipuladas no se ajustase a derecho dará lugar a la nulidad de la misma, en ningún caso a la nulidad
                  del condicionado en general

                  Las partes ante controversia en la interpretación y desarrollo de este condicionado acuerdan someterse a los tribunales y órganos administrativos competentes,
                  fucu renunciando así a cualquier otro fuero que por cualquier otra causa o naturaleza pueda o pudiera corresponderles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='terminos_politicas'>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-regular fa-circle-check"></i>USUARIOS VERIFICADOS</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <p>Para promocionar los perfiles de aficionados reales sobre las cuentas de spam, "CariñosasHub" creó un estado
                  " verificado ". Solo los usuarios verificados pueden compartir su contenido e interactuar en la
                  plataforma</p>
                <h4> ¿Cómo puedo convertirme en un usuario verificado?</h4>
                <p>Puede convertirse en un usuario certificado cargando su foto en la sección " Verifique su cuenta "
                  disponible en su configuración:</p>
                <ul>
                  <li>Esta foto debe mostrar la parte superior de tu cuerpo y un papel con tu pseudo y la fecha del día .</li>
                  <li>Solo nuestro equipo de moderadores  podrá ver esta foto.</li>
                </ul>
                <p>Otra forma es a travez de un pago y cumpliendo ciertos riquisitos dados por la plataforma.</p>

                <p>
                  Debido a muchas solicitudes, este proceso puede demorar algunos días. Tenga en cuenta que suscribirse a una cuenta Básica,
                  Premium o VIP lo convierte en un "usuario verificado" dentro de un máximo de 24 horas.
                </p>

                <p>La misma plataforma "CariñosasHub" dara la verificacion a un usuario tomando en cuenta el numeros de seguidores del usuario y de propio criterio. </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                <h3><i class="fa-solid fa-user-check"></i>MI CUENTA HA SIDO HACKEADA</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">
                <span>Conozca las Reglas de "CariñosasHub" y reporte violaciones</span>
                <p>Si cree que ha sido pirateado y no puede iniciar sesión con su nombre de usuario y contraseña, siga estos dos pasos:</p>
                <h4>1. Solicite un restablecimiento de contraseña.</h4>
                <span>Restablezca su contraseña solicitando un correo electrónico desde el  formulario de restablecimiento de contraseña. Intente ingresar tanto su nombre de usuario como
                  su dirección de correo electrónico, y asegúrese de buscar el correo electrónico de restablecimiento en la dirección asociada con su cuenta Fan-Hot. Si puede iniciar sesión
                  después de restablecer la contraseña, vuelva a proteger su cuenta siguiendo los pasos que se indican aquí.</span>
                <h4>2. Comuníquese con Soporte si todavía necesita ayuda.</h4>
                <span>Si aún no puede iniciar sesión, contáctenos por correo electrónico a alekeydeveloper@gmail.com. Indique "Cuenta pirateada" en el asunto.  Asegúrese de usar la dirección de
                  correo electrónico que asoció con la cuenta "CariñosasHub" pirateada; luego enviaremos información e instrucciones adicionales a esa dirección de correo electrónico. Incluya
                  tanto su nombre de usuario como la fecha en que tuvo acceso por última vez a su cuenta. Si ha perdido el acceso a la cuenta de correo electrónico real asociada con su
                  cuenta de "CariñosasHub", consulte esta página de ayuda.</span>
                <span>
                  Para diferentes sucesos, solo podra recuperar la cuenta si el correo que brindo al momento de la suscripcion es correcta y esta relacionada con el usuario, de lo contrario seria
                  complicado recuperar la cuenta, la plataforma "CariñosasHub" no se hace responsable del error del usuario.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                <h3><i class="fa-solid fa-eye"></i>REPORTAR SPAM EN "CariñosasHub"</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body">
                <span>“Spam” describe una variedad de comportamientos prohibidos que violan los Términos y Condiciones. Los comportamientos que constituyen "spamming" seguirán
                  evolucionando a medida que respondamos a las nuevas tácticas de los spammers. ¿Qué es el correo no deseado? Aquí hay algunas tácticas comunes que las cuentas
                  de spam usan a menudo: Publicar enlaces dañinos (incluidos enlaces a sitios de phishing o malware) Comportamiento de seguimiento agresivo</span>
                <h4>¿Qué es el correo no deseado?</h4>
                <span>Aquí hay algunas tácticas comunes que las cuentas de spam usan a menudo:</span>
                <ul>
                  <li>Publicar enlaces dañinos (incluidos enlaces a sitios de phishing o malware)</li>
                  <li>Comportamiento de seguimiento agresivo (seguimiento masivo y des-seguimiento masivo para llamar la atención)</li>
                  <li>Abusar de la función @reply o @mention para publicar mensajes no deseados a los usuarios</li>
                  <li>Crear múltiples cuentas (ya sea manualmente o usando herramientas automatizadas)</li>
                  <li>Publicar repetidamente en temas de actualidad para tratar de llamar la atención.</li>
                  <li>Publicar repetidamente actualizaciones duplicadas</li>
                  <li>Publicar enlaces con imágenes no relacionadas</li>
                </ul>
                <h3>Cómo reportar spam:</h3>
                <span>Si cree que ha encontrado un perfil de spam, siga estos pasos para informarnos:</span>
                <ol>
                  <li>Visita el perfil de la cuenta de spam.</li>
                  <li>Haga clic en el  icono de engranaje . Esto abre un menú desplegable de acciones (vea la imagen a continuación).</li>
                  <li>Haga clic en  Reportar @nombredeusuario como spam .</li>
                </ol>
                <span>Si desea bloquear a un usuario, pero no denunciarlo como spam, puede averiguar cómo  hacerlo en nuestra página de ayuda Cómo bloquear usuarios en "CariñosasHub".
                  Esta página también le muestra cómo desbloquear una cuenta si ha cambiado de opinión.</span>
                <h3>¿Qué sucede con las cuentas reportadas?</h3>
                <span>Una vez que haga clic en el enlace "reportar como spam", bloquearemos al usuario para que no pueda seguirlo o responderle. Reportar una cuenta como spam
                  no resulta automáticamente en la suspensión.</span>
                <h3>Cómo reportar otras cuentas dañinas:</h3>
                <span>Si encuentra otro contenido dañino, molesto o ilegal en "CariñosasHub", consulte nuestra página de ayuda para obtener información. ¡Gracias!</span>
                <h3>Informe de imágenes individuales</h3>
                <span>También puede denunciar imágenes individuales que infrinjan los  Términos y condiciones de Uplust . Esto incluye spam, acoso, suplantación de identidad,
                  derechos de autor o violaciones de marcas registradas.</span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                <h3><i class="fa-solid fa-lock"></i>SOBRE CUENTAS PÚBLICAS Y PRIVADAS</h3>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-collapseFour">
              <div class="accordion-body">
                <span>¿Cuál es la diferencia entre cuentas públicas y privadas? Cuando se registra en "CariñosasHub", su cuenta sera publica a todos los usuarios
                  cuando la plataforma logre un numero determinado(de doncideracion de la propia plataforma) se abilitara un opcion para mantener la cuenta pribada, pero el por el momento
                  no, y el usuario tiene conociemiento al momento de su suscripcion.
                </span>
                <h3>Sobre cuentas "CariñosasHub" públicas y privadas (a futuro)</h3>
                <h3>¿Cuál va ser la diferencia entre cuentas públicas y privadas?</h3>
                <ul>
                  <li>Cuando se registra en "CariñosasHub", tiene la opción de mantener público su perfil de "CariñosasHub" (la configuración de cuenta predeterminada)
                    o hacer que su cuenta sea privada.</li>
                  <li>
                    Las cuentas privadas requieren la aprobación manual de todas y cada una de las personas que pueden ver las imágenes de esa cuenta.
                  </li>
                </ul>
                <p>
                  Nota: si alguna vez tuvo imágenes públicas, esas imágenes ya no serán públicas; sus imágenes solo serán visibles
                  para usted y sus seguidores aprobados. Si desprotege su perfil, cualquier imagen previamente privada se hará pública.
                </p>
                <h3>¿Por qué haría que mi cuenta fuera privada?</h3>
                <span>Si desea controlar quién ve sus imágenes, puede optar por hacer que su cuenta sea privada. Siempre puedes cambiar de
                  opinión y hacerlo público más tarde.</span>
                <h3>Cuando hace que su cuenta sea privada, se aplican las siguientes restricciones:</h3>
                <ul>
                  <li>La gente tendrá que solicitar seguirte; cada solicitud de seguimiento necesitará aprobación.</li>
                  <li>Tus imágenes solo serán visibles para los usuarios que hayas aprobado.</li>
                  <li>La cuenta privada no aparecerá en la búsqueda de Google.</li>
                </ul>
                <span style={{ color: 'red' }}>
                  tener en cuenta que el perfil privado y perfil publico recien se abilitara a futuro en concideración de la plataforma.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nuestrapolitica