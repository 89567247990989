import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { GlobalState } from '../../../GlobalState'
import Loading from '../utils/loading/Loading'
import { useHistory, useParams } from 'react-router-dom'

const initialState = {
    product_id: '',
    title: '',
    nombre: '',
    urluno: '',
    urldos: '',
    price: 0,
    description: 'How to and tutorial videos of cool CSS effect, Web Design ideas,JavaScript libraries, Node.',
    content: 'Welcome to our channel Dev AT. Here you can learn web designing, UI/UX designing, html css tutorials, css animations and css effects, javascript and jquery tutorials and related so on.',
    _id: '',
    creators: '',
    modo: 'gratis'
}

function CreateProduct() {
    const state = useContext(GlobalState)
    const [product, setProduct] = useState(initialState)
    const [categories] = state.categoriesAPI.categories
    const [images, setImages] = useState(false)
    const [loading, setLoading] = useState(false)

    const [isAdmin] = state.userAPI.isAdmin
    const [token] = state.token

    const history = useHistory()
    const param = useParams()

    //
    const [arrayimages, setArrayimages] = useState([]);
    const [arrayimagesfakes, setArrayimagesfakes] = useState([]);
    //

    const [products] = state.productsAPI.products
    const [onEdit, setOnEdit] = useState(false)
    const [callback, setCallback] = state.productsAPI.callback

    useEffect(() => {
        if (param.id) {
            setOnEdit(true)
            products.forEach(product => {
                if (product._id === param.id) {
                    setProduct(product)
                    setImages(product.images)
                }
            })
        } else {
            setOnEdit(false)
            setProduct(initialState)
            setImages(false)
        }
    }, [param.id, products])

    const handleUpload = async e => {
        e.preventDefault();
        try {
            if (!isAdmin) return alert("You're not an admin");

            const file = e.target.files[0];

            if (!file) return alert("File not exist.");
            if (file.size > 1024 * 1024) return alert("Size too large!");
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') return alert("File format is incorrect.");

            let formData = new FormData();
            formData.append('file', file);

            setLoading(true);

            const res = await axios.post('/api/upload', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: token
                }
            });

            setLoading(false);
            setImages(res.data);

        } catch (err) {
            alert(err.response.data.msg);
        }
    };

    //multi imagenes
    const handleUploadmulti = async (e) => {
        e.preventDefault();
        try {
            if (!isAdmin) return alert("You're not an admin");

            const files = e.target.files; // Obtiene todos los archivos seleccionados

            if (files.length === 0) return alert("No files selected.");

            let formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Validación del tamaño y formato de cada archivo
                if (file.size > 1024 * 1024) return alert(`File ${file.name} is too large!`);
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') return alert(`File format of ${file.name} is incorrect.`);

                formData.append('file', file); // Añade cada archivo con el nombre 'file'
            }

            setLoading(true);

            const res = await axios.post('/api/uploadmulti', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token
                }
            });

            setLoading(false);
            setArrayimages(res.data); // Configura las imágenes con la respuesta del servidor

        } catch (err) {
            alert(err.response.data.msg);
        }
    };

    const handleUploadmultifake = async (e) => {
        e.preventDefault();
        try {
            if (!isAdmin) return alert("You're not an admin");

            const files = e.target.files; // Obtiene todos los archivos seleccionados

            if (files.length === 0) return alert("No files selected.");

            let formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Validación del tamaño y formato de cada archivo
                if (file.size > 1024 * 1024) return alert(`File ${file.name} is too large!`);
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') return alert(`File format of ${file.name} is incorrect.`);

                formData.append('file', file); // Añade cada archivo con el nombre 'file'
            }

            setLoading(true);

            const res = await axios.post('/api/uploadmultifake', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token
                }
            });

            setLoading(false);
            setArrayimagesfakes(res.data); // Configura las imágenes con la respuesta del servidor

        } catch (err) {
            alert(err.response.data.msg);
        }
    };


    // Eliminar todas las imágenes o una imagen específica según el índice
    const handleDestroymulti = async (public_id) => {
        try {
            if (!isAdmin) return alert("You're not an admin");
            setLoading(true);

            await axios.post('/api/destroy', { public_ids: [public_id] }, { // enviar el array de public_ids
                headers: { Authorization: token }
            });

            setLoading(false);
            setArrayimages(arrayimages.filter(image => image.public_id !== public_id)); // Remover la imagen del estado

        } catch (err) {
            setLoading(false);
            alert(err.response.data.msg);
        }
    };
    //

    //inputs para ingresar array
    const [urltiktoks, setUrltiktoks] = useState([""]); // Inicia con un array con un string vacío.
    const [urlsmoviesfakes, setUrlsmoviesfakes] = useState([""]); // Inicia con un array con un string vacío.
    const [urlsmovies, setUrlsmovies] = useState([""]); // Inicia con un array con un string vacío.


    // Manejar el cambio de valor de cada input
    const handletiktokChange = (index, value) => {
        const newInputs = [...urltiktoks];
        newInputs[index] = value; // Actualizar el valor del input específico
        setUrltiktoks(newInputs);
    };
    const handleAddtiktok = () => {
        setUrltiktoks([...urltiktoks, ""]); // Agregar un string vacío al final
    };
    const handleRemovetiktok = (index) => {
        const newInputs = urltiktoks.filter((_, i) => i !== index); // Filtrar el input a eliminar
        setUrltiktoks(newInputs);
    };
    //
    const handlemovieChange = (index, value) => {
        const newInputs = [...urlsmovies];
        newInputs[index] = value; // Actualizar el valor del input específico
        setUrlsmovies(newInputs);
    };
    const handleAddmovie = () => {
        setUrlsmovies([...urlsmovies, ""]); // Agregar un string vacío al final
    };
    const handleRemovemovie = (index) => {
        const newInputs = urlsmovies.filter((_, i) => i !== index); // Filtrar el input a eliminar
        setUrlsmovies(newInputs);
    };
    //
    const handlemoviefakeChange = (index, value) => {
        const newInputs = [...urlsmoviesfakes];
        newInputs[index] = value; // Actualizar el valor del input específico
        setUrlsmoviesfakes(newInputs);
    };
    const handleAddmoviefake = () => {
        setUrlsmoviesfakes([...urlsmoviesfakes, ""]); // Agregar un string vacío al final
    };
    const handleRemovemoviefake = (index) => {
        const newInputs = urlsmoviesfakes.filter((_, i) => i !== index); // Filtrar el input a eliminar
        setUrlsmoviesfakes(newInputs);
    };
    //

    const handleDestroy = async () => {
        try {
            if (!isAdmin) return alert("You're not an admin")
            setLoading(true)
            await axios.post('/api/destroy', { public_id: images.public_id }, {
                headers: { Authorization: token }
            })
            setLoading(false)
            setImages(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleChangeInput = e => {
        const { name, value } = e.target
        setProduct({ ...product, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            if (!images) return alert("No Image Upload")

            if (onEdit) {
                await axios.put(`/api/products/${product._id}`, { ...product, images, arrayimages, arrayimagesfakes, urltiktoks, urlsmoviesfakes, urlsmovies }, {
                    headers: { Authorization: token }
                })
            } else {
                await axios.post('/api/products', { ...product, images, arrayimages, arrayimagesfakes, urltiktoks, urlsmoviesfakes, urlsmovies }, {
                    headers: { Authorization: token }
                })
            }
            setCallback(!callback)
            history.push("/products")
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const styleUpload = {
        display: images ? "block" : "none"
    }

    console.log(product)



    return (
        <div className="create_product">
            <div className="upload">
                <input type="file" name="file" id="file_up" onChange={handleUpload} />
                {
                    loading ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUpload}>
                            <img src={images ? images.url : ''} alt="" />
                            <span onClick={handleDestroy}>X</span>
                        </div>
                }

            </div>

            {/*<div>
                <input type="file" name="file" id="file_up" onChange={handleUploadmulti} multiple />

                {loading && <div id="file_img"><Loading /></div>}

                {!loading && arrayimages.length > 0 && (
                    <div id="file_img">
                        {arrayimages.map((image, index) => (
                            <div key={index} style={{ display: 'inline-block', position: 'relative', margin: '10px' }}>
                                <img src={image.url} alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                                <span onClick={() => handleDestroy(image.public_id)} style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', color: 'red' }}>X</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>*/}

            <div>
                <h2>Imagenes de la actriz</h2>
                <input
                    type="file"
                    multiple
                    onChange={handleUploadmulti}
                    accept="image/jpeg, image/png"
                    placeholder="Las imagenes de pago"
                />
            </div>

            <div>
                <h2>Imganes falsas de la actriz</h2>
                <input
                    type="file"
                    multiple
                    onChange={handleUploadmultifake}
                    accept="image/jpeg, image/png"
                    placeholder="Las imagenes gratis"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <label htmlFor="product_id">Product ID</label>
                    <input type="text" name="product_id" id="product_id" required
                        value={product.product_id} onChange={handleChangeInput} disabled={onEdit} />
                </div>

                <div className="row">
                    <label htmlFor="title">Title</label>
                    <input type="text" name="title" id="title" required
                        value={product.title} onChange={handleChangeInput} placeholder="titulo de la publicacion" />
                </div>

                <div className="row">
                    <label htmlFor="nombre">Nombre</label>
                    <input type="text" name="nombre" id="nombre" required
                        value={product.nombre} onChange={handleChangeInput} placeholder="nombre de la Actriz" />
                </div>

                <div className="row">
                    <label htmlFor="estado">Estado gratuito o Pago</label>
                    <select
                        name="estado"
                        value={product.estado} // Vincular el valor al estado
                        onChange={handleChangeInput} // Actualizar el estado al cambiar
                    >
                        <option value="gratis">gratis</option>
                        <option value="pago">pago</option>
                    </select>
                </div>


                <div className="row">
                    <label htmlFor="price">Precio de acceso</label>
                    <input type="number" name="price" id="price" required
                        value={product.price} onChange={handleChangeInput} placeholder="Precio de acceso" />
                </div>

                <div className="row">
                    <label htmlFor="urluno">Url portada</label>
                    <input type="text" name="urluno" id="urluno" required
                        value={product.urluno} onChange={handleChangeInput} placeholder="imagen portada" />
                </div>

                <div className="row">
                    <label htmlFor="urldos">Url portada numero dos</label>
                    <input type="text" name="urldos" id="urldos"
                        value={product.urldos} onChange={handleChangeInput} placeholder="Algun dato adicional" />
                </div>

                <div className="row">
                    <label htmlFor="description">Description</label>
                    <textarea type="text" name="description" id="description" required
                        value={product.description} rows="5" onChange={handleChangeInput} placeholder="Descripcion Corta de la publicaion" />
                </div>

                <div className="row">
                    <label htmlFor="creators">La creadora de contenido</label>
                    <input type="text" name="creators" id="creators" required
                        value={product.creators} onChange={handleChangeInput} placeholder="Creadora de contenido" />
                </div>
                <div className="row">
                    <label htmlFor="tags">Etiquetas</label>
                    <textarea type="text" name="tags" id="tags" required
                        value={product.tags} rows="5" onChange={handleChangeInput} placeholder="Etiquetas" />
                </div>

                <div className="row">
                    <label htmlFor="content">Contenido completo</label>
                    <textarea type="text" name="content" id="content" required
                        value={product.content} rows="7" onChange={handleChangeInput} placeholder="contenido completo de la publicacion" />
                </div>
                <div>
                    <h1>Crear un arreglo par tiktok</h1>
                    {urltiktoks.map((input, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => handletiktokChange(index, e.target.value)}
                                placeholder={`Elemento ${index + 1}`}
                                style={{ marginRight: "10px" }}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemovetiktok(index)}
                                disabled={urltiktoks.length === 1} // Deshabilita si queda solo un input
                            >
                                Eliminar
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddtiktok}>
                        Agregar otro campo
                    </button>
                    <br />
                    <br />
                    <h2>Array Resultante:</h2>
                    <ul>
                        {urltiktoks.map((item, index) => (
                            <li key={index}>{item || "(vacío)"}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h1>Crear un arreglo para videos verdaderos</h1>
                    {urlsmovies.map((input, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => handlemovieChange(index, e.target.value)}
                                placeholder={`Elemento ${index + 1}`}
                                style={{ marginRight: "10px" }}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemovemovie(index)}
                                disabled={urlsmovies.length === 1} // Deshabilita si queda solo un input
                            >
                                Eliminar
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddmovie}>
                        Agregar otro campo
                    </button>
                    <br />
                    <br />
                    <h2>Array Resultante:</h2>
                    <ul>
                        {urlsmovies.map((item, index) => (
                            <li key={index}>{item || "(vacío)"}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h1>Crear un arreglo par videos falsos</h1>
                    {urlsmoviesfakes.map((input, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => handlemoviefakeChange(index, e.target.value)}
                                placeholder={`Elemento ${index + 1}`}
                                style={{ marginRight: "10px" }}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemovemoviefake(index)}
                                disabled={urlsmoviesfakes.length === 1} // Deshabilita si queda solo un input
                            >
                                Eliminar
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddmoviefake}>
                        Agregar otro campo
                    </button>
                    <br />
                    <br />
                    <h2>Array Resultante:</h2>
                    <ul>
                        {urlsmoviesfakes.map((item, index) => (
                            <li key={index}>{item || "(vacío)"}</li>
                        ))}
                    </ul>
                </div>




                <button type="submit">{onEdit ? "Update" : "Create"}</button>
            </form>
        </div>
    )
}

export default CreateProduct
