import React, { useContext, useState } from 'react'
import { GlobalState } from '../../GlobalState'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Footer from '../compInicio/footer'

const ProfileNew = () => {

    const state = useContext(GlobalState)
    const [usernew] = state.userAPI.userfamele

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    return (
        <>

            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder interactuar con nuestros usuarios, ver sus perfiles, dar likes, comentar o seguir, debes unirte a nuestra red social 'CariñosasHub'.
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <h1 className='title_publici_cardsHome'>Damos la Bienvenida a Nuestras Nuevas Cariñosas:</h1>
            <div className="cardsHome">
                {usernew && usernew.length > 0 ? (
                    usernew.map((user) => (
                        <div key={user._id}>
                            <div className="card">
                                <img src={user.avatar} className="card__image" alt="" onClick={handleButtonClick} />
                                <div className="card__overlay">
                                    <div className="card__header">
                                        <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                                        <img className="card__thumb" src={user.avatar} alt="" onClick={handleButtonClick} />
                                        <div className="card__header-text">
                                            <h3 className="card__title" onClick={handleButtonClick}>{user.username}</h3>
                                            <span className="card__status">{moment(user.createdAt).fromNow()}</span>
                                        </div>
                                    </div>
                                    <p className="card__description">{user.story}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No hay nuevos usuarios para mostrar.</p>
                )}
            </div>
            <div className='btn_profilenew__div'>
                <button className='btn_profilenew_' onClick={handleButtonClick}>Cargar Más</button>
            </div>

            <Footer />
        </>
    )
}

export default ProfileNew