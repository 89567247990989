import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserCardSug from '../components/UserCardSug'

import LoadIcon from '../images/loading.gif'
import { getSuggestions } from '../redux/actions/suggestionsAction'

import Search from '../components/header/Search'

function Buscador() {
    const { auth, suggestions } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div className='conteinerBus'>
            <Search />
            <div className="d-flex justify-content-between align-items-center my-2" >
                <h5 className="header__subtitle">Sugerencias para ti</h5>
                {
                    !suggestions.loading &&
                    <i className="fas fa-redo" style={{ cursor: 'pointer', 'margin-right': '30px' }}
                        onClick={() => dispatch(getSuggestions(auth.token))} />
                }
            </div>
            <div className='Sugerencias'>
                {
                    suggestions.loading
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
                        : <div className='Cardsugerecias'>
                            {
                                suggestions.users.map(user => (
                                    <UserCardSug key={user._id} user={user} >
                                    </UserCardSug>
                                ))
                            }
                        </div>
                }
            </div>
        </div>

    )
}

export default Buscador