import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';


function Cieneguilla() {

  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publiccieneguilla
  /*-----------*/

  if (loading) return <div><Loading /></div>
  const tituloDinamico = "Kinesiologas Cieneguilla- CARIÑOSASHUB";

  return (
    <>
      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="Kinesiologas, escorts y prostitutas en Cieneguilla. Servicio de sexo en 150109. Prositutas peruanas y extranjeras en Cieneguilla"
        ></meta>

        <meta name="keywords"
          content="kines en Cieneguilla, kinesiologas en Cieneguilla, escorts en Cieneguilla, prostitutas en Cieneguilla, putas en Cieneguilla, servicio de sexo en Cieneguilla. Prostitutas peruanas en Cieneguilla. Prostitutas extranjeras en Cieneguilla. Prostitutas venezolanas en Cieneguilla. Prostitutas colombianas en Cieneguilla"></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-cieneguilla" />

      </Helmet>
      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Cieneguilla