import React, { useContext } from 'react'
import LeftSide from '../../components/message/LeftSide'
import RightSide from '../../components/message/RightSide'

import { GlobalState } from '../../GlobalState'
import NotFound from '../../components/NotFound'

const Conversation = () => {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //

    return (
        <>
            {
                isUservip
                    ? <div className="message d-flex">
                        <div className="col-md-4 border-right px-0 left_mess">
                            <LeftSide />
                        </div>

                        <div className="col-md-8 px-0">
                            <RightSide />
                        </div>
                    </div>
                    : <NotFound />
            }

        </>
    )
}

export default Conversation