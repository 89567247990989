import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

import { GlobalState } from '../../GlobalState'

import NotifyModal from '../NotifyModal'


const MenuDos = () => {
    const navLinks = [
        //{ label: 'Home', icon: 'queue', path: '/discover' },
        //{ label: 'Discover', icon: 'bookmarks', path: '/' },
        //{ label: 'Message', icon: 'notifications_none', path: '/message' }
    ]

    const state = useContext(GlobalState)
    const [isAdmin] = state.userAPI.isAdmin
    const [cart] = state.userAPI.cart


    const { auth, theme, notify } = useSelector(state => state)
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const isActive = (pn) => {
        if (pn === pathname) return 'active'
    }

    return (
        <div className="menuDos">
            <div className="navbar-nav flex-row">
                <div className="listaDos" style={{ opacity: 0.6, cursor: "pointer", top: "9px" }} >
                    <Link to="/buscador" >
                        <span className="material-icons">search</span>
                    </Link>
                </div>
                <div className="listaDos" style={{ opacity: 0.6, cursor: "pointer", top: "9px" }}
                    onClick={() => dispatch({ type: GLOBALTYPES.STATUS, payload: true })}>
                    <span className="material-icons">queue</span>
                </div>
                <div className="listaDos" >
                    <span className='numeroC' style={{
                        display: cart.length > 0 ? 'block' : 'none'
                    }}
                    >{cart.length}</span>
                    <Link className="nav-link " to="/save">
                        <span className="material-icons"
                            style={{
                                color: cart.length > 0 ? '#4D5656' : 'none'
                            }}
                        >bookmarks </span>
                    </Link>
                </div>
                <div className="listaDos" style={{ opacity: 1 }} >
                    <span className="nav-link position-relative" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span id="notify_length"
                            style={{ display: notify.data.length > 0 ? 'block' : 'none' }}
                        >
                            {notify.data.length}
                        </span>
                        <span className="material-icons"
                            style={{
                                color: notify.data.length > 0 ? '#4D5656' : 'none'
                            }}>
                            notifications
                        </span>
                    </span>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown"
                        style={{ transform: 'translateX(55%)' }}>
                        <NotifyModal />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MenuDos
