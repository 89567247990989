import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ScrollToTop from '../utils/ScrollToTop';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Precios() {
    const { theme } = useSelector(state => state)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false)

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    return (
        <div className='container_publicar_anuncio'>
            <ScrollToTop />
            <section class="pricing-section">
                <div class="container">
                    <div class="sec-title text-center">
                        <span class="title">Planes de pago</span>
                        <h2>Elige un Plan Perfil</h2>
                    </div>

                    <div class="outer-box">
                        <div class="row">
                            <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                                <div class="inner-box">
                                    <div class="icon-box" >
                                        <div class="icon-outer"><i class="fa-solid fa-user"></i></div>
                                    </div>
                                    <div class="price-box">
                                        <div class="title"> Perfil Preferencial</div>
                                        <h4 class="price"> $3 - S/.7</h4>
                                        <span>30 dias</span>
                                    </div>
                                    <ul class="features">
                                        <li class="true">Acceso a todas los Post</li>
                                        <li class="true">Chat/Charlar</li>
                                        <li class="true">4 Publicaciones Por Dia</li>
                                        <li class="true">4 Imagenes Por Publicacion</li>
                                        <li class="true">Acceso a Redes Directo</li>
                                        <li class="true">Perfil Publico</li>
                                        <li class="false">Perfil Comercial para Contenido</li>
                                        <li class="false">Envio de Imagenes por Chat</li>
                                    </ul>
                                    <div class="btn-box">
                                        <a onClick={handleShow1}
                                            class="theme-btn">
                                            Seleccionar plan
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                <div class="inner-box">
                                    <div class="icon-box">
                                        <div class="icon-outer"><i class="fa-solid fa-dumpster-fire"></i></div>
                                    </div>
                                    <div class="price-box">
                                        <div class="title">Perfil Vip</div>
                                        <h4 class="price">$4 - S/.10</h4>
                                        <span>30 dias</span>
                                    </div>
                                    <ul class="features">
                                        <li class="true">Acceso a todas los Post</li>
                                        <li class="true">Chat/Charlar</li>
                                        <li class="true">8 Publicaciones Por Dia</li>
                                        <li class="true">8 Imagenes Por Publicacion</li>
                                        <li class="true">Acceso a Redes Directo</li>
                                        <li class="true">Perfil Publico</li>
                                        <li class="true">Perfil Comercial para Contenido</li>
                                        <li class="true">Envio de Imagenes por Chat</li>
                                    </ul>
                                    <div class="btn-box">
                                        <a onClick={handleShow1}
                                            class="theme-btn">
                                            Seleccionar plan
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <h2 className='title_publicación'>Planes Para Anuncios</h2>
            <div class="wrap">
                <div class="pricing-wrap">
                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one basico">
                                <div class="pricing-table-head">
                                    <h2>GRATIS</h2>
                                    <h3><sup>S/. </sup>0<sub>7/Dias</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>2 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>0 </span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>No Accesero Directo otras </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>No Nostrar </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span>1 Subida </span><span style={{ color: 'red' }}> Por diá </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span>......... </span><span style={{ color: 'red' }}> ........ </span></li>
                                </ul>
                                
                                <Link to={"/publicidad"} class="pricing-table-button" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                    Seleccionar plan
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one">
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>10<sub>/7 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>15<sub>/15 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>35<sub>/MES</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>5 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>...</span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>Se Mostrara una </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span> Directorio  </span><span style={{ color: 'red' }}> de anuncios </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span> 3 subidas por dia </span><span style={{ color: 'red' }}> ........ </span></li>
                                </ul>
                                <a onClick={handleShow}
                                    class="pricing-table-button estandar" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                    Seleccionar plan
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one">
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>15<sub>/7 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>20<sub>/15 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>45<sub>/MES</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>8 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>... </span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>Se Mostrara una </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span>4 subidas por dia </span><span style={{ color: 'red' }}> Paginas </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span>Icono de </span><span style={{ color: 'red' }}> Verificación </span></li>
                                </ul>
                                <a onClick={handleShow}
                                    class="pricing-table-button" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                    Seleccionar plan
                                </a>
                            </div>
                        </div>
                    </div>
                    {show &&
                        (<div class="miswitch_model">
                            <span id='infor_public_anuncio'>Contactenos por Nuestras Redes, Mas facil y sin tarjetas</span>

                            <i class="fa-regular fa-circle-xmark" id='close_btn_anuncios' onClick={handleClose}></i>

                            <div class="miswitch_model_btn">
                                <div className='btn_red_precio1'
                                    onClick={e => window.open('https://t.me/fanshots', '_blank')}
                                >
                                    <i class="fa-brands fa-telegram"></i>
                                </div>
                                <div className='btn_red_precio2'
                                    onClick={e => window.open('https://api.whatsapp.com/send?phone=+51913926030&text=Hola%20quiero%20anunciar%20en%20CariñosasHub%F0%9F%99%82', '_blank')}
                                >
                                    <i class="fa-brands fa-whatsapp"></i>
                                </div>
                            </div>
                        </div>)
                    }
                    {show1 &&
                        (<div class="miswitch_model">
                            <span id='infor_public_anuncio'>Contactenos por Nuestras Redes, Mas facil y sin tarjetas</span>

                            <i class="fa-regular fa-circle-xmark" id='close_btn_anuncios' onClick={handleClose1}></i>

                            <div class="miswitch_model_btn">
                                <div className='btn_red_precio1'
                                    onClick={e => window.open('https://t.me/fanshots', '_blank')}
                                >
                                    <i class="fa-brands fa-telegram"></i>
                                </div>
                                <div className='btn_red_precio2'
                                    onClick={e => window.open('https://api.whatsapp.com/send?phone=+51913926030&text=Hola%20deseo%20obtener%20un%20plan%Perfil%', '_blank')}
                                >
                                    <i class="fa-brands fa-whatsapp"></i>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
                <ul className='header_precios' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <li><i class="fa-solid fa-check"></i> Todos los anuncios se visualizaran de forma aleatoria.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios de paga tendran mayor presencia y visualización.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios Vip se visualizaran al incio de pagina en los caruseles.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios de paga tedran subidas automaicas y aleatorias.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios Vip tienen un maximo de usuarios (solo puedes haber 20 anuncios).</li>
                </ul>
            </div>

        </div>
    )
}

export default Precios