import React, { useState, useEffect } from 'react'

const DetailBody = ({ forum, theme }) => {
    const [content, setContent] = useState(forum.content)
    const [readMore, setReadMore] = useState(false)
    //probando
    const lines = content.split('\n');
    // Mapear cada línea para crear un elemento <p> en JSX

    return (
        <div className='contet_card_forum_'>
            <h2><i class="fa-solid fa-check-double"></i> {forum.titulo} </h2>
            <span>
                {/*{forum.content}*/}

                <span>
                    {
                        content.includes('\n') // Verificar si hay saltos de línea
                            ?
                            (
                                lines.length > 8 && !readMore
                                    ? lines.slice(0, 7).map((line, index) => (
                                        <div key={index}>
                                            {line}
                                        </div>
                                    ))
                                    : lines.map((line, index) => (
                                        <div key={index}>
                                            {line}
                                        </div>
                                    ))
                            )
                            :
                            content.length < 100
                                ? content
                                : readMore ? content + ' ' : content.slice(0, 100) + '....'
                    }
                </span>
                {
                    content.length > 100 &&
                    <span className="readMore" onClick={() => setReadMore(!readMore)}>
                        {readMore ? 'Ocultar contenido' : 'Leer más'}
                    </span>
                }
            </span>
        </div>
    )
}

export default DetailBody