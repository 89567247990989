import React, { useEffect, useState } from 'react'

import Info from '../../components/profile/Info'
import Posts from '../../components/profile/Posts'
import Saved from '../../components/profile/Saved'
import Publicids from '../../components/profile/Publicids'

import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../../images/loading.gif'
import { getProfileUsers } from '../../redux/actions/profileAction'
import { useParams } from 'react-router-dom'

import Getpost from '../../components/profile/Getpost'
import PerfilComer from '../../components/profile/PerfilComer'

const Profile = () => {
    const { profile, auth } = useSelector(state => state)
    const dispatch = useDispatch()

    const { id } = useParams()

    const { homePosts } = useSelector(state => state)

    useEffect(() => {
        if (profile.ids.every(item => item !== id)) {
            dispatch(getProfileUsers({ id, auth }))
        }
    }, [id, auth, dispatch, profile.ids])

    //////////////
    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    // Definir el estado de los botones
    const [botones, setBotones] = useState([
        { id: 1, activo: true },
        { id: 2, activo: false },
        { id: 3, activo: false },
        { id: 4, activo: false },
        { id: 5, activo: false }
    ]);
    // Función para activar un botón y desactivar los demás
    const handleClick = (indice) => {
        const nuevosBotones = [...botones];
        nuevosBotones.forEach((boton, i) => {
            if (i === indice) {
                boton.activo = true;
            } else {
                boton.activo = false;
            }
        });
        setBotones(nuevosBotones);
    }
    /*-----------*/

    return (
        <div className="profile">
            <Info auth={auth} profile={profile} dispatch={dispatch} id={id} />
            {
                auth.user._id === id ?
                    <div className="profile_tab">
                        <button onClick={() => { handleButtonClick(1); handleClick(0) }} id={botones[0].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-photo-film"></i></button>
                        <button onClick={() => { handleButtonClick(2); handleClick(1) }} id={botones[1].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-house-user"></i></button>
                        <button onClick={() => { handleButtonClick(3); handleClick(2) }} id={botones[2].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-floppy-disk"></i></button>
                        <button onClick={() => { handleButtonClick(4); handleClick(3) }} id={botones[3].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-dumpster-fire"></i></button>
                        <button onClick={() => { handleButtonClick(5); handleClick(4) }} id={botones[4].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-fire"></i></button>
                    </div>
                    : <div className="profile_tab" >
                        <button onClick={() => { handleButtonClick(1); handleClick(0) }} id={botones[0].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-photo-film"></i></button>
                        <button onClick={() => { handleButtonClick(2); handleClick(1) }} id={botones[1].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-house-user"></i></button>
                        <button onClick={() => { handleButtonClick(5); handleClick(4) }} id={botones[4].activo ? 'activebuttoP' : ''}><i class="fa-solid fa-fire"></i></button>
                    </div>
            }
            {
                profile.loading
                    ? <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
                    : <>
                        <div>
                            {activeButton === 1 && <Posts auth={auth} profile={profile} dispatch={dispatch} id={id} />

                            }
                        </div>
                        <div>
                            {activeButton === 2 && <div>
                                {
                                    homePosts.loading
                                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                                        : (homePosts.result === 0 && homePosts.posts.length === 0)
                                            ? <h2 className="text-center text-danger">Ultimos Posts</h2>
                                            : <Getpost auth={auth} profile={profile} dispatch={dispatch} id={id} />
                                }
                            </div>}
                        </div>
                        <div>
                            {activeButton === 3 && <Saved auth={auth} dispatch={dispatch} id={id} />}
                        </div>
                        <div>
                            {activeButton === 4 && <Publicids auth={auth} profile={profile} dispatch={dispatch} id={id} />}
                        </div>
                        <div>
                            {activeButton === 5 && <PerfilComer auth={auth} profile={profile} dispatch={dispatch} id={id} />}
                        </div>
                    </>
            }
        </div>
    )
}

export default Profile
