import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { updatePublicadm } from '../../redux/actions/publicAction'

const Editpublic = ({ setOnEdit, edituser }) => {
    const dispatch = useDispatch()

    /****constantes para editar****/
    const [planduracion, setPlanduracion] = useState(edituser.planduracion)
    const [condicion, setCondicion] = useState(edituser.condicion)
    const [categoria, setCategoria] = useState(edituser.categoria)
    ////

    const ids = edituser._id
    const handleSubmit = async e => {
        e.preventDefault()
        dispatch(updatePublicadm({ condicion, categoria, planduracion, ids }))
    }


    return (
        <div className="edit_profile">
            <button className="btn btn-danger btn_close"
                onClick={() => setOnEdit(false)} >
                close
            </button>
            <form onSubmit={handleSubmit}>
                <h3>{edituser.nombre}</h3>
                <div className='content_info_user'>
                    <h2>Datos Usuario:</h2>
                    <div className='content_info_public'>
                        <h4><span>Nombre:</span> {edituser.user.username}</h4>
                        <h4><span>Rol:</span> {edituser.user.role}</h4>
                    </div>
                </div>

                <div className='content_info_user'>
                    <h2>Datos Actuales anuncio:</h2>
                    <div className='content_info_public'>
                        <h4><span>Tiempo duracion:</span> {planduracion}</h4>
                        <h4><span>Situacion:</span> {condicion}</h4>
                        <h4><span>Categoria:</span> {categoria}</h4>
                    </div>
                </div>
                
                <div className="form-group" >
                    <label htmlFor="condicion" >Condicion Actual: {condicion}</label>
                    <div className="position-relative" >
                        <select class="form-select" aria-label="Default select example" id="condicion"
                            name="condicion" value={condicion} onChange={e => setCondicion(e.target.value)} >
                            <option value="Activo">Activo</option>
                            <option value="Desactivo">Desactivar</option>
                        </select>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="categoria" >Lugar de visualizacion: {categoria}</label>
                    <div className="position-relative" >
                        <select class="form-select" aria-label="Default select example" id="categoria"
                            name="categoria" value={categoria} onChange={e => setCategoria(e.target.value)} >
                            <option value="Normal">Normal</option>
                            <option value="Preferencial">Preferencial</option>
                            <option value="Vip">Vip</option>
                        </select>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="planduracion" >Plan de Duracion Actual: {planduracion}</label>
                    <div className="position-relative" >
                        <select class="form-select" aria-label="Default select example" id="planduracion"
                            name="planduracion" value={planduracion} onChange={e => setPlanduracion(e.target.value)} >
                            <option value="gratis">Gratis</option>
                            <option value="estandar">Estandar</option>
                            <option value="vip">Vip</option>
                        </select>
                    </div>
                </div>

                <div className='anuncio_footer'>
                    <button className='btn btn-secondary w-100' type='submit' >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Editpublic