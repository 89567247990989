import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'


const Carousel = ({ images, id }) => {
    const isActive = index => {
        if (index === 0) return "active";
    }

    const { theme } = useSelector((state) => state);
    const videoRefs = useRef([]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Cambia este valor para ajustar el umbral de visibilidad del elemento
        };

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const video = entry.target;
                    video.play();
                } else {
                    const video = entry.target;
                    video.pause();
                    video.currentTime = 0;
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        videoRefs.current.forEach((video) => observer.observe(video));

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div id={`image${id}`} className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators" style={{ zIndex: 1 }}>
                {
                    images.map((img, index) => (
                        <li key={index} data-target={`#image${id}`}
                            data-slide-to={index} className={isActive(index)} />
                    ))
                }

            </ol>

            <div className="carousel-inner">
                {
                    images.map((img, index) => (
                        <div key={index} className={`carousel-item ${isActive(index)}`}>
                            {
                                img.url.match(/video/i) ? (
                                    <video
                                        ref={(video) => (videoRefs.current[index] = video)}
                                        controls
                                        muted
                                        src={img.url}
                                        className="d-block w-100"
                                        alt={img.url}
                                        style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                    />
                                ) : (
                                    <img
                                        src={img.url}
                                        className="d-block w-100"
                                        alt={img.url}
                                        style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                    />
                                )
                            }

                        </div>
                    ))
                }

            </div>

            {
                images.length > 1 &&
                <>
                    <a
                        className="carousel-control-prev"
                        href={`#image${id}`}
                        role="button"
                        data-slide="prev"
                        style={{ width: '5%' }}
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previo</span>
                    </a>

                    <a
                        className="carousel-control-next"
                        href={`#image${id}`}
                        role="button"
                        data-slide="next"
                        style={{ width: '5%' }}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Próximo</span>
                    </a>
                </>
            }

        </div>
    )
}

export default Carousel
