import React from 'react'
import Carousel from '../Carousel'
import CommentForo from './comments/CommentForo'
import InputCommentForo from './comments/InputCommentForo'
import DetailBody from './detailforum/DetailBody'
import DetailFooter from './detailforum/DetailFooter'
import DetailHeader from './detailforum/DetailHeader'


const DetailForum = ({ forum, theme }) => {

console.log(forum.images.length)
  return (
    <div className="info_list_detail">

      <DetailHeader forum={forum} />
      {
        forum.images.length < 1
        ? <></>
        : <Carousel images={forum.images} id={forum._id} />
      }
           
      
      <DetailBody forum={forum} />
      <DetailFooter forum={forum} />

      <CommentForo forum={forum} />
      <InputCommentForo forum={forum} />
    </div>
  )
}

export default DetailForum