import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
//import ProductItem from '../utils/productItem/ProductItem';
import Loading from '../mainpages/utils/loading/Loading';


function TagPage() {
    const { tag } = useParams(); // Extraer el parámetro 'tag' de la URL
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProductsByTag = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/api/products?tag=${tag}`);
                setProducts(res.data.products);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products by tag:', error);
                setLoading(false);
            }
        };

        fetchProductsByTag();
    }, [tag]);

    if (loading) return <Loading />;

    return (
        <div className="tag-page">
            <h2>Productos con el tag: {tag}</h2>
            <div className="products">
                {products.length === 0 ? (
                    <p>No hay productos con este tag</p>
                ) : (
                    products.map((product) => (
                        <>
                            {/*<ProductItem key={product._id} product={product} />*/}
                            productos tag {tag}
                        </>

                    ))
                )}
            </div>
        </div>
    );
}

export default TagPage;
