import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';

function Cercado() {
    const [loading, setLoading] = useState(false)
    const directoryRef = useRef()

    const state = useContext(GlobalState)
    const [anunciosbrena] = state.publicidadAPI.publiccercado
    /*-----------*/

    if (loading) return <div><Loading /></div>

    const tituloDinamico = "Kinesiologas cercado- CARIÑOSASHUB";

    return (
        <>
            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="kines CERCADO DE LIMA, kinesiologas CERCADO DE LIMA, venezolanas CERCADO DE LIMA, putas CERCADO DE LIMA, escorts CERCADO DE LIMA, kines av wilson, kines av tacna, prostitutas, whatsapp, Escorts peruanas, kines venezolanas, damas de compañia, relax, photokinesiologas CERCADO DE LIMA, extranjeras CERCADO DE LIMA, 24 horas, con depa, 100 soles, kines en lima, cholotube, perutops, lolitas"
                ></meta>

                <meta name="keywords"
                    content="Kkines CERCADO DE LIMA, kinesiologas CERCADO DE LIMA, venezolanas CERCADO DE LIMA, putas CERCADO DE LIMA, escorts CERCADO DE LIMA, kines av wilson, kines av tacna, prostitutas, whatsapp, Escorts peruanas, kines venezolanas, damas de compañia, relax, photokinesiologas CERCADO DE LIMA, extranjeras CERCADO DE LIMA, 24 horas, con depa, 100 soles, kines en lima, cholotube, perutops, lolitas"></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-cercado" />

            </Helmet>
            <div className='rj-container-sections'>
                <div className='rj-izquierda'>
                    <HeaderVer />
                </div>
                <div className='rj-central'>
                    <ScrollToTop />
                    <ChatButton />
                    <div className='container-pruebaPP'>
                        < Portadainicio />
                        <CategoriaPublic />
                        <h2 ref={directoryRef}>Directorio De Chicas</h2>
                        <div className='main-container'>
                            <div class="card-principal">
                                {
                                    anunciosbrena.map(anuncio => {
                                        return < Cardinicio anuncio={anuncio} />
                                    })
                                }
                            </div>
                            <LoadMoreAnu directoryRef={directoryRef} />
                        </div>
                        <Footer />
                    </div>
                </div>
                <div className='rj-derecha'>
                    <BarraDer />
                </div>
            </div>
        </>
    )
}

export default Cercado