import { GLOBALTYPES, EditData, DeleteData } from './globalTypes'
import { FORUM_TYPES } from './forumAction'
import { postDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from '../actions/notifyAction'


export const createCommentforo = ({forum, newComment, auth, socket}) => async (dispatch) => {
    const newForum = {...forum, comments: [...forum.comments, newComment]}

    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })

    try {
        const data = {...newComment, forumId: forum._id, forumUserId: forum.user._id}
        const res = await postDataAPI('commentform', data, auth.token)

        const newData = {...res.data.newComment, user: auth.user}
        const newForum = {...forum, comments: [...forum.comments, newData]}
        dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })

        // Socket
        socket.emit('createComment', newForum)

        // Notify
        const msg = {
            id: res.data.newComment._id,
            text: newComment.reply ? 'mentioned you in a comment.' : 'has commented on your post.',
            recipients: newComment.reply ? [newComment.tag._id] : [forum.user._id],
            url: `/forum/${forum._id}`,
            content: forum.content
        }

        dispatch(createNotify({msg, auth, socket}))
        
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} })
    }
}

export const updateCommentforo = ({comment, forum, content, auth}) => async (dispatch) => {
    const newComments = EditData(forum.comments, comment._id, {...comment, content})
    const newForum = {...forum, comments: newComments}
    
    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })
    try {
        patchDataAPI(`commentform/${comment._id}`, { content }, auth.token)
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} })
    }
}

export const likeCommentforo = ({comment, forum, auth}) => async (dispatch) => {
    const newComment = {...comment, likes: [...comment.likes, auth.user]}

    const newComments = EditData(forum.comments, comment._id, newComment)

    const newForum = {...forum, comments: newComments}
    
    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })

    try {
        await patchDataAPI(`commentform/${comment._id}/like`, null, auth.token)
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} })
    }
}

export const unLikeCommentforo = ({comment, forum, auth}) => async (dispatch) => {

    const newComment = {...comment, likes: DeleteData(comment.likes, auth.user._id)}

    const newComments = EditData(forum.comments, comment._id, newComment)

    const newForum = {...forum, comments: newComments}
    
    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })

    try {
        await patchDataAPI(`commentform/${comment._id}/unlike`, null, auth.token)
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} })
    }
}

export const deleteCommentforo = ({forum, comment, auth, socket}) => async (dispatch) => {
    const deleteArr = [...forum.comments.filter(cm => cm.reply === comment._id), comment]
    
    const newForum = {
        ...forum,
        comments: forum.comments.filter(cm => !deleteArr.find(da => cm._id === da._id))
    }

    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum })

    socket.emit('deleteComment', newForum)
    try {
       deleteArr.forEach(item => {
            deleteDataAPI(`commentform/${item._id}`, auth.token)

            const msg = {
                id: item._id,
                text: comment.reply ? 'mentioned you in a comment.' : 'has commented on your forum.',
                recipients: comment.reply ? [comment.tag._id] : [forum.user._id],
                url: `/forum/${forum._id}`,
            }
    
            dispatch(removeNotify({msg, auth, socket}))
       })
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} })
    }

}