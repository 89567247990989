import { GLOBALTYPES } from './globalTypes'
import { imageUpload } from '../../utils/imageUpload'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'

export const FORUM_TYPES = {
    CREATE_FORUM: 'CREATE_FORUM',
    LOADING_FORUM: 'LOADING_FORUM',
    GET_FORUMS: 'GET_FORUMS',
    UPDATE_FORUM: 'UPDATE_FORUM',
    GET_FORUM: 'GET_FORUM',
    DELETE_FORUM: 'DELETE_FORUM'
}


export const createForum = ({titulo, content, images, tags, category, auth, socket}) => async (dispatch) => {
    let media = []
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        if(images.length > 0) media = await imageUpload(images)

        const res = await postDataAPI('forums', { titulo, content, images: media, tags, category }, auth.token)

        dispatch({ 
            type: FORUM_TYPES.CREATE_FORUM, 
            payload: {...res.data.newForum, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        // Notify
        const msg = {
            id: res.data.newForum._id,
            text: 'added a new FORUM.',
            recipients: res.data.newForum.user.followers,
            url: `/forum/${res.data.newForum._id}`,
            content, 
            //image: media[0].url
        }

        dispatch(createNotify({msg, auth, socket}))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getForums = (token) => async (dispatch) => {
    try {
        dispatch({ type: FORUM_TYPES.LOADING_FORUM, payload: true })
        const res = await getDataAPI('forums', token)
        
        dispatch({
            type: FORUM_TYPES.GET_FORUMS,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: FORUM_TYPES.LOADING_FORUM, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateForum = ({titulo, content, images, tags, category, auth, statusForum}) => async (dispatch) => {
    let media = []
    const imgNewUrl = images.filter(img => !img.url)
    const imgOldUrl = images.filter(img => img.url)

    if(statusForum.content === content && imgNewUrl.length === 0) return;

    /*if(status.content === content 
        && imgNewUrl.length === 0
        && imgOldUrl.length === status.images.length
    ) return;*/

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        if(imgNewUrl.length > 0) media = await imageUpload(imgNewUrl)

        const res = await patchDataAPI(`forum/${statusForum._id}`, { 
            //titulo, content, tags, category, images: [...imgOldUrl, ...media] 
            titulo, content, tags, category, images: [...media] 
        }, auth.token)

        dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: res.data.newForum })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const likeForum = ({forum, auth, socket}) => async (dispatch) => {
    const newForum = {...forum, likes: [...forum.likes, auth.user]}
    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum})
    socket.emit('likeForum', newForum)

    try {
        await patchDataAPI(`forum/${forum._id}/like`, null, auth.token)
        
        // Notify
        const msg = {
            id: auth.user._id,
            text: 'like your forum.',
            recipients: [forum.user._id],
            url: `/forum/${forum._id}`,
            content: forum.content, 
            //image: forum.images[0].url
        }

        dispatch(createNotify({msg, auth, socket}))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unLikeForum = ({forum, auth, socket}) => async (dispatch) => {
    const newForum = {...forum, likes: forum.likes.filter(like => like._id !== auth.user._id)}
    dispatch({ type: FORUM_TYPES.UPDATE_FORUM, payload: newForum})

    socket.emit('unLikeForum', newForum)

    try {
        await patchDataAPI(`forum/${forum._id}/unlike`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'like your post.',
            recipients: [forum.user._id],
            url: `/post/${forum._id}`,
        }
        dispatch(removeNotify({msg, auth, socket}))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getForum = ({detailForum, id, auth}) => async (dispatch) => {
    if(detailForum.every(post => post._id !== id)){
        try {
            const res = await getDataAPI(`forum/${id}`, auth.token)
            dispatch({ type: FORUM_TYPES.GET_FORUM, payload: res.data.forum })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {error: err.response.data.msg}
            })
        }
    }
}

export const deleteForum = ({forum, auth, socket}) => async (dispatch) => {
    dispatch({ type: FORUM_TYPES.DELETE_FORUM, payload: forum })

    try {
        const res = await deleteDataAPI(`forum/${forum._id}`, auth.token)

        // Notify
        const msg = {
            id: forum._id,
            text: 'added a new post.',
            recipients: res.data.newForum.user.followers,
            url: `/post/${forum._id}`,
        }
        dispatch(removeNotify({msg, auth, socket}))
        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const saveForum = ({forum, auth}) => async (dispatch) => {
    const newUser = {...auth.user, saved: [...auth.user.saved, forum._id]}
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`saveForum/${forum._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unSaveForum = ({forum, auth}) => async (dispatch) => {
    const newUser = {...auth.user, saved: auth.user.saved.filter(id => id !== forum._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`unSaveForum/${forum._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}