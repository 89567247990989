import React, { useState, useContext, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../redux/actions/globalTypes'
import { createPost, updatePost } from '../redux/actions/postAction'
import Icons from './Icons'
import { imageShow, videoShow } from '../utils/mediaShow'

import { GlobalState } from '../GlobalState'

import Picker from 'emoji-picker-react'


const StatusModal = () => {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isModerador] = state.userAPI.isModerador
    //

    const { auth, theme, status, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const [content, setContent] = useState('')
    const [images, setImages] = useState([])
    const [categoria, setCategoria] = useState('')

    const [stream, setStream] = useState(false)
    const videoRef = useRef()
    const refCanvas = useRef()
    const [tracks, setTracks] = useState('')

    const [showPicker, setShowPicker] = useState(false)



    const handleChangeImages = e => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {

            /*
            let currentFileType = file.type;
            let checkPng = currentFileType.indexOf("png");
            let checkJpeg = currentFileType.indexOf("jpeg");
            let checkJpg = currentFileType.indexOf("jpg");

            if (checkPng !== -1 || checkJpeg !== -1 || checkJpg !== -1) {
                return err = "formato no adecuado"
            }
*/
            let file1 = file.type;
            const [ext, ...fileName] = file1.split('.').reverse();
            if (!file) return err = "El archivo no existe."

            if (file.length > 2) return err = "Maximo 2 fotos por publicación"

            if (file1 !== "image/jpeg" && file1 !== "image/png") {
                return err = "Su extension no es valido"
            }

            if (file.size > 1024 * 1024 * 1) {
                return err = "La imagen más pequeña"
            }

            return newImages.push(file)
        })

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        setImages([...images, ...newImages])
    }

    const deleteImages = (index) => {
        const newArr = [...images]
        newArr.splice(index, 1)
        setImages(newArr)
    }

    const handleStream = () => {
        setStream(true)
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(mediaStream => {
                    videoRef.current.srcObject = mediaStream
                    videoRef.current.play()

                    const track = mediaStream.getTracks()
                    setTracks(track[0])
                }).catch(err => console.log(err))
        }
    }

    const handleCapture = () => {
        const width = videoRef.current.clientWidth;
        const height = videoRef.current.clientHeight;

        refCanvas.current.setAttribute("width", width)
        refCanvas.current.setAttribute("height", height)

        const ctx = refCanvas.current.getContext('2d')
        ctx.drawImage(videoRef.current, 0, 0, width, height)
        let URL = refCanvas.current.toDataURL()
        setImages([...images, { camera: URL }])
    }

    const handleStopStream = () => {
        tracks.stop()
        setStream(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (images.length === 0)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Por favor agregue su foto." }
            })
        if (images.length > 2)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Maximo 2 fotos por Publicación" }
            })
        if (content.length > 900)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Maximo 900 caracteres" }
            })

        if (status.onEdit) {
            dispatch(updatePost({ content, images, categoria, auth, status }))
        } else {
            dispatch(createPost({ content, images, auth, socket }))
        }

        setContent('')
        setCategoria('')
        setImages([])
        if (tracks) tracks.stop()
        dispatch({ type: GLOBALTYPES.STATUS, payload: false })
    }

    /*-------emoji-----------*/
    const [pickerOpen, togglePicker] = React.useReducer(state => !state, false);

    const onEmojiClick = (event, emojiObject) => {
        setContent(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false)
    }
    /*----------------*/

    useEffect(() => {
        if (status.onEdit) {
            setContent(status.content)
            setImages(status.images)
            setCategoria(status.categoria)
        }
    }, [status])


    return (
        <div className="status_modal" >
            <form onSubmit={handleSubmit}>
                <div className="status_header" >
                    <h5 className="m-0" >Crear Post</h5>
                    <span onClick={() => dispatch({
                        type: GLOBALTYPES.STATUS, payload: false
                    })} >
                        &times;
                    </span>
                </div>

                <div className="status_body" >
                    <textarea name="content" value={content}
                        placeholder={`${auth.user.username}, qué estás pensando?`}
                        onChange={e => setContent(e.target.value)}
                        style={{
                            filter: theme ? 'invert(1)' : 'invert(0)',
                            color: theme ? 'white' : '#111',
                            background: theme ? 'rgba(0,0,0,.03)' : '',
                        }} />

                    <div>
                        {
                            isModerador
                                ? <div class="col-100">
                                    <select class="form-select" type="text" id="categoria" aria-label="categoria" onChange={e => setCategoria(e.target.value)} name="categoria">
                                        <option selected hidden>Categoria</option>
                                        <option value="publica">Publico</option>
                                        <option value="restringido">Restringido</option>
                                        <option value="evaluar">Evaluar</option>
                                    </select>
                                </div>
                                : <span></span>
                        }
                    </div>

                    <div className="show_images">
                        {
                            images.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? <>
                                                    {
                                                        img.url.match(/video/i)
                                                            ? videoShow(img.url, theme)
                                                            : imageShow(img.url, theme)
                                                    }
                                                </>
                                                : <>
                                                    {
                                                        img.type.match(/video/i)
                                                            ? videoShow(URL.createObjectURL(img), theme)
                                                            : imageShow(URL.createObjectURL(img), theme)
                                                    }
                                                </>
                                    }
                                    <span onClick={() => deleteImages(index)} >&times;</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="d-flex">
                        <div className="flex-fill"></div>
                        {/*<Icons setContent={setContent} content={content} theme={theme} />*/}

                        <img
                            src='https://w7.pngwing.com/pngs/407/764/png-transparent-yellow-smiley-emoji-emoji-computer-icons-emoticon-sunglasses-emoji-smiley-sticker-glasses.png'
                            onClick={e => setShowPicker(val => !val)}
                            style={{ width: '15px', height: '15px' }}
                        />
                        {showPicker &&
                            <div className='style-picker-status'><Picker pickerStyle={{ width: '100%' }} onEmojiClick={onEmojiClick} /></div>
                        }
                    </div>

                    {
                        stream &&
                        <div className="stream position-relative"  >
                            <video autoPlay muted ref={videoRef} width="100%" height="100%"
                                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />

                            <span aria-hidden="true" onClick={handleStopStream}>&times;</span>
                            <canvas ref={refCanvas} width="406px" height="300px" style={{ display: 'none' }}></canvas>
                        </div>
                    }

                    <div className="input_images" >
                        {
                            stream
                                ? {/*<i className="fas fa-camera fa-sm" onClick={handleCapture} />*/ }
                                : <>
                                    {/*<i className="fas fa-camera fa-sm" onClick={handleStream} />*/}

                                    <div className="file_upload" >
                                        <i className="fas fa-images" />
                                        <input type="file" name="file" id="file"
                                            multiple accept="image/png, image/gif, image/jpeg,video/*" onChange={handleChangeImages} />

                                    </div>
                                </>
                        }

                    </div>

                </div>

                <div className="status_footer">
                    <button className="btn_btn-secondar-sumit" type="submit">
                        <span>
                            <i class="fa-regular fa-paper-plane"></i> Crear Post
                        </span>
                    </button>
                </div>

            </form>
        </div>
    )
}

export default StatusModal
