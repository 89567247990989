import React, { useContext, useState } from 'react'
import { GlobalState } from '../../../GlobalState'
//import ProductItem from '../utils/productItem/ProductItem'
//import Loading from '../utils/loading/Loading'
import axios from 'axios'
import Cardhub from '../utiliscontenthub/Conthubcard'
//import Filters from './Filters'
//import LoadMore from './LoadMore'
import Suggestions from '../../home/Suggestions'
import moment from 'moment'

function Contenidoshub() {
    const state = useContext(GlobalState)
    const [products, setProducts] = state.productsAPI.products
    const [isAdmin] = state.userAPI.isAdmin
    const [token] = state.token
    const [callback, setCallback] = state.productsAPI.callback
    const [loading, setLoading] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [readMore, setReadMore] = useState(false)

    const handleCheck = (id) => {
        products.forEach(product => {
            if (product._id === id) product.checked = !product.checked
        })
        setProducts([...products])
    }

    const deleteProduct = async (id, public_id) => {
        try {
            setLoading(true)
            const destroyImg = axios.post('/api/destroy', { public_id }, {
                headers: { Authorization: token }
            })
            const deleteProduct = axios.delete(`/api/products/${id}`, {
                headers: { Authorization: token }
            })

            await destroyImg
            await deleteProduct
            setCallback(!callback)
            setLoading(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const checkAll = () => {
        products.forEach(product => {
            product.checked = !isCheck
        })
        setProducts([...products])
        setIsCheck(!isCheck)
    }

    const deleteAll = () => {
        products.forEach(product => {
            if (product.checked) deleteProduct(product._id, product.images.public_id)
        })
    }

    const [text] = useState('Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrytypesetting industry. Lorem Ipsum has been the industry')

    if (loading) return <div>{/*<Loading />*/}</div>
    return (
        <>
            {/* <Filters />*/}

            { /*{
                isAdmin &&
                <div className="delete-all">
                    <span>Select all</span>
                    <input type="checkbox" checked={isCheck} onChange={checkAll} />
                    <button onClick={deleteAll}>Delete ALL</button>
                </div>
            }*/}

            <div className="content_hub_home">
                <div className='content_hub_derach'>
                    <h1 className='content_hub_derach_h1'>Creadoras de Contenido</h1>
                    {
                        products.map((product) => (
                            <div className='content_creadoras_content' >
                                <div className="cart_contethub_public_creadoras">
                                    <div className="header_cart_contenthub_creadoras">
                                        <img src="https://res.cloudinary.com/dshcgnvss/image/upload/v1698183353/logos/pngwing_32x32_pujh1e.png" alt="User Avatar" />
                                        <div>
                                            <h1>{product.nombre}</h1>
                                            <small>{moment(product.createdAt).fromNow()}</small>
                                        </div>
                                    </div>

                                    <div className="content_img_contenhub_creadoras">
                                        <img src={product.images.url} alt="Content Image" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <h1 className='content_hub_derach_h1'>Publicacines Destacadas</h1>

                    <div className='content_hub_medid'>
                        {
                            products.map(product => {
                                return <Cardhub key={product._id} product={product} />
                            })
                        }
                    </div>
                </div>
                <div className='content_hub_izder' >
                    <div className="main_rigth" >
                        <div className='main_rigth_sugeVip'>
                            <h1>Sugerencias</h1>
                            <div className='main_rigth_sugeVip_content'>
                                <Suggestions />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/*<LoadMore />*/}
            {products.length === 0 && <></>}
        </>
    )
}

export default Contenidoshub
