import React, { useContext, useRef } from 'react'
import { GlobalState } from '../../../GlobalState'

function LoadMoreAnu(props) {
  const state = useContext(GlobalState)
  const [anuncios, setAnuncios] = state.anunciosAPI.anuncio
  const [page, setPage] = state.publicidadAPI.page

  const directoryRef = useRef();

  const loadMoreData = () => {
    setPage(prevPage => prevPage + 1) // Incrementa el número de página para obtener más datos
    window.scrollTo({
      top: props.directoryRef.current.offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <div id='btn_LoadMoreBtn'>
      <button onClick={loadMoreData}>
        <i class="fa-solid fa-plus"></i> Cargar más
      </button>
    </div>
  )
}

export default LoadMoreAnu
