import React, { useContext } from 'react'
import Postvip from '../components/componesthome/videoHots/Postvip'
import RightSideBar from '../components/home/RightSideBar'
import Headervip from '../components/Headervip'

import { GlobalState } from '../GlobalState'
import NotFound from '../components/NotFound'

function Posthot() {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //

    return (
        <div className='video-home-page'>

            {
                isUservip
                    ? <>
                        <div className="app-home-page">
                            <Headervip />
                            <Postvip />
                        </div>
                        <div className="col-md-4" style={{ background: 'rgb(46 46 46)' }} id='righSidebar' >
                            <RightSideBar />
                        </div>
                    </>
                    : <div className="app-home-page">
                        <NotFound />
                    </div>
            }

        </div>
    )
}

export default Posthot