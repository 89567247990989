import React, { useContext, useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { GlobalState } from '../../../GlobalState'
import ProductItem from '../utils/productItem/ProductItem'
import axios from 'axios'
import PaypalButton from '../cart/PaypalButton'

function DetailProduct() {
    const params = useParams()
    const state = useContext(GlobalState)
    const [products] = state.productsAPI.products
    const addCart = state.userAPI.addCart
    const [detailProduct, setDetailProduct] = useState([])
    const [paidDescription, setPaidDescription] = useState(null);
    const [error, setError] = useState(null);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);


    useEffect(() => {
        if (params.id) {
            const selectedProduct = products.find(product => product._id === params.id);
            if (selectedProduct) setDetailProduct(selectedProduct);
        }
    }, [params.id, products]);


    useEffect(() => {
        const checkIfPaid = async () => {
            try {
                const res = await axios.get(`/api/products/${params.id}/checkPaid`, {
                    headers: { Authorization: state.token[0] }
                });
                if (res.data.paid) {
                    // Si el usuario ya pagó, se muestra la descripción completa
                    setPaidDescription(detailProduct.description);
                    setError(null);
                } else {
                    // Si no ha pagado, muestra el mensaje de error
                    setError("Acceso denegado. Complete el pago para ver la descripción completa.");
                }
            } catch (error) {
                setError("Hubo un error al verificar el pago.");
            }
        };

        if (detailProduct._id) {
            checkIfPaid();
        }
    }, [detailProduct._id, state.token, params.id]);

    const tranSuccess = async (paymentDetails) => {
        try {
            const res = await axios.post('/api/payment', {
                productId: detailProduct._id,
                paymentID: paymentDetails.id,
                address: paymentDetails.payer.address
            }, {
                headers: { Authorization: state.token[0] }
            });

            setPaymentSuccessful(true);
            setError(null);
            setPaidDescription(detailProduct.description);  // Actualiza para mostrar la descripción completa
        } catch (err) {
            setError("Hubo un problema al registrar el pago.");
        }
    };

    /*
        useEffect(() => {
            if (params.id) {
                products.forEach(product => {
                    if (product._id === params.id) setDetailProduct(product)
                })
            }
        }, [params.id, products])
    
        if (detailProduct.length === 0) return null;*/


    if (!detailProduct || Object.keys(detailProduct).length === 0) return null;


    return (
        <>
            <div className="detail">
                <img src={detailProduct.images.url} alt="" />
                <div className="box-detail">
                    <div className="row">
                        <h2>{detailProduct.title}</h2>
                        <h6>#id: {detailProduct.product_id}</h6>
                    </div>
                    <span>$ {detailProduct.price}</span>
                    <p>{detailProduct.description}</p>
                    <p>{detailProduct.content}</p>
                    <p>Sold: {detailProduct.sold}</p>

                    {paidDescription ? (
                        <p>Descripción completa: {paidDescription}</p>
                    ) : (
                        <p className="error">{error}</p>
                    )}

                    <Link to="/cart" className="cart"
                        onClick={() => addCart(detailProduct)}>
                        Buy Now
                    </Link>

                    {paymentSuccessful ? (
                        <p>¡Pago realizado con éxito! Gracias por su compra.</p>
                    ) : (
                        <div style={{ marginTop: '20px' }}>
                            <PaypalButton
                                total={detailProduct.price}
                                tranSuccess={tranSuccess}
                            />
                        </div>
                    )}

                </div>
            </div>

            <div>
                <h2>Related products</h2>
                <div className="products">
                    {
                        products.map(product => {
                            return product.category === detailProduct.category
                                ? <ProductItem key={product._id} product={product} /> : null
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default DetailProduct