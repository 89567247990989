import React from 'react'
import Avatar from '../Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

const StatusP = () => {
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div className="status my-3 d-flex" id='statusPublicidad' >
            <Avatar src={auth.user.avatar} size="big-avatar" />

            <button className="statusBtn flex-fill" id='statusBtnPubli'
                onClick={() => dispatch({ type: GLOBALTYPES.STATUSPUBLIC, payload: true })} >
                {auth.user.username}, Deseas crear una publicidad?
            </button>
        </div>
    )
}

export default StatusP
