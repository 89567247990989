import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../GlobalState'
import logo from '../../images/only.png'
import StatusCom from '../perfilcomer/StatusCom'
import ShareModal from '../ShareModal'
import { BASE_URL } from '../../utils/config'
import { Link, useHistory } from 'react-router-dom'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { deletePerfil } from '../../redux/actions/perfilAction'

const PerfilComer = ({ auth, id, dispatch, profile, socket }) => {

  //tipo de usuario
  const state = useContext(GlobalState)
  const [isUservip] = state.userAPI.isUservip
  //
  const [perfils, setPerfils] = useState([])
  const [result, setResult] = useState(9)
  const [page, setPage] = useState(0)
  const [load, setLoad] = useState(false)
  const [isShare, setIsShare] = useState(false)
  const [thepage, setThepage] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [readMore, setReadMore] = useState(false)

  const history = useHistory()


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    profile.perfils.forEach(data => {
      if (data._id === id) {
        setPerfils(data.perfils)
        setResult(data.result)
        setPage(data.page)
      }
    })
  }, [profile.perfils, id])


  const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url);
    alert('Se copio con éxito');
  };

  const handleDeletePerfil = (perfil) => {
    if (window.confirm("Seguro que quieres eliminar esta publicación?")) {
      dispatch(deletePerfil({ perfil, auth, socket }));
      // window.location.reload();
      return history.push("/");
    }
  };

  /*
    useEffect(() => {
      const matchingProfile = profile.perfils.find(data => data._id === id);
      if (matchingProfile) {
        setIsShare(matchingProfile.perfils);
        setResult(matchingProfile.result);
        setPage(matchingProfile.page);
      }
    }, [profile.perfils, id]);*/

  /*
  useEffect(() => {
    if (perfils.length > 0) {
      const firstPerfil = perfils[0];
      setThepage(firstPerfil);
      setResult(firstPerfil.result);
      setPage(firstPerfil.page);
    }
  }, [perfils]);*/
  ////modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleButtonClick = () => {
    handleShow();
    handleClick();
  };

  if (perfils.length === 0)
    return <>
      {show && (
        <div className="status_modal_alert_sus" style={{ position: 'fixed', top: '0' }}>
          <div id="alerta-titulo" >
            <i class="fa-solid fa-lock-open"></i>
            <h4>
              Para poder crear tu perfil Suscribete, es facil y sin necesidad de tarjeta
            </h4>
            <div>
              <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
              <Link to={"/precios"}>
                <button type="button" class="outline-danger" >Suscribese</button>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className='content_option_perfil' >
        {
          auth.user._id === id
            ?
            <div className='content_option_content'>
              <h3>Crea tú perfil especial para poder compartir todas tus redes sociales de forma más rapida y sencilla.</h3>

              {isUservip
                ? <StatusCom />
                : <button class="btn_crear_profileC" onClick={handleButtonClick}>
                  <span>
                    <i class="fa-solid fa-plus"></i> Crear Perfil
                  </span>
                </button>
              }

              <div class="social-account-container">
                <h1 className='content_option_title'>Cariñosas Hub</h1>
                <span class="title">Mis redes sociales</span>
                <div class="social-accounts">
                  <button class="social-button google">
                    <i class="fa-brands fa-instagram" style={{ color: '#fff' }}></i>
                  </button>
                  <button class="social-button apple">
                    <i class="fa-brands fa-telegram" style={{ color: '#fff' }}></i>
                  </button>
                  <button class="social-button twitter">
                    <i class="fa-brands fa-tiktok" style={{ color: '#fff' }}></i>
                  </button>
                </div>
              </div>
            </div>
            : <div className='content_option_content'>
              <h2 className='title_save_save'>Perfil Redes Sociales</h2>
              <div class="social-account-container">
                <h1 className='content_option_title'>Cariñosas Hub</h1>
                <span class="title">Mis redes sociales</span>
                <div class="social-accounts">
                  <button class="social-button google">
                    <i class="fa-brands fa-instagram" style={{ color: '#fff' }}></i>
                  </button>
                  <button class="social-button apple">
                    <i class="fa-brands fa-telegram" style={{ color: '#fff' }}></i>
                  </button>
                  <button class="social-button twitter">
                    <i class="fa-brands fa-tiktok" style={{ color: '#fff' }}></i>
                  </button>
                </div>
              </div>
            </div>
        }
      </div>
    </>

  return (
    <>
      {
        perfils.map(perfil => {
          return (
            <div className='Perfil_COMER_user'>

              <div className='Perfil_comercio_img'>
                <img src={perfil.images[0].url} />
              </div>

              <Link to={`/perfil/${perfil._id}`}>
                <div class="uiverse_view">
                  <span class="tooltip">{`${BASE_URL}/perfil/${perfil._id}`}</span>
                  <span>
                    Ver Perfil
                  </span>
                </div>
              </Link>

              {
                auth.user._id === id
                  ? <div className='btn_content_elimupdate'>
                    <button onClick={e => dispatch({ type: GLOBALTYPES.STATUSCOMERS, payload: { ...perfil, onEdit: true } })}>Editar</button>
                    <button onClick={() => handleDeletePerfil(perfil)} key={perfil._id}>Eliminar</button>
                  </div>
                  : <></>
              }

              <div className='perfil_comercio_content' id='comercio_content' >

                <div className='perfil_img_avatar'>
                  <img src={perfil.user.avatar ? perfil.user.avatar : 'https://s2.glbimg.com/i90u1AMCupgLE6HH5_9rGkcAZZ4=/smart/e.glbimg.com/og/ed/f/original/2020/04/24/captura_de_tela_2020-04-24_as_09.16.02.png'} />
                </div>

                <div className='perfil_present_content'>
                  <p>
                    {
                      perfil.description.length < 80
                        ? perfil.description
                        : readMore ? perfil.description + ' ' : perfil.description.slice(0, 80) + '.....'
                    }
                  </p>
                  {
                    perfil.description.length > 80 &&
                    <p className="readMore" onClick={() => setReadMore(!readMore)}>
                      {readMore ? 'Ocultar' : 'Lee mas'}
                    </p>
                  }
                </div>

                <h2
                  className={perfil.preciocontenido ? "Perfil_comer_precio" : "button_btn_co"}
                >
                  Precio Contenido: <span>{perfil.preciocontenido}</span>
                </h2>

                <div className='Perfil_comercio_button'>

                  <button
                    className={perfil.linkInstagram ? "button_btn_comerc instagram" : "button_btn_co"}
                  >
                    <div className='svg_logo'>
                      <i class="fa-brands fa-instagram"></i>
                    </div>
                    Instagram
                    <div className='menu_comers_logo' >
                      <a href={`${perfil.linkInstagram}`} target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                      </a>
                    </div>
                  </button>

                  <button
                    className={perfil.linkTelegram ? "button_btn_comerc telegram" : "button_btn_co"}
                  >
                    <div className='svg_logo'>
                      <i class="fa-brands fa-telegram" style={{ color: '#ffffff' }} ></i>
                    </div>
                    Telegram
                    <div className='menu_comers_logo' >
                      <a href={`${perfil.linkTelegram}`} target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                      </a>
                    </div>
                  </button>

                  <button
                    className={perfil.linkTiktok ? "button_btn_comerc tiktok" : "button_btn_co"}
                  >
                    <div className='svg_logo'>
                      <i class="fa-brands fa-tiktok"></i>
                    </div>
                    Tiktok
                    <div className='menu_comers_logo' >
                      <a href={`https://www.tiktok.com/@${perfil.linkTiktok}`} target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                      </a>
                    </div>
                  </button>

                  <button
                    className={perfil.linkOnly ? "button_btn_comerc only" : "button_btn_co"}
                  >
                    <div className='svg_logo'>
                      <img src={logo} alt={logo} />
                    </div>
                    OnlyFans
                    <div className='menu_comers_logo' >
                      <a href={`${perfil.linkOnly}`} target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                      </a>
                    </div>
                  </button>

                  <button
                    className={perfil.linkOther ? "button_btn_comerc link" : "button_btn_co"}
                  >
                    <div className='svg_logo'>
                      <i class="fa-solid fa-link"></i>
                    </div>
                    Link
                    <div className='menu_comers_logo' >
                      <a href={`${perfil.linkOther}`} target="_blank" rel="noopener noreferrer">
                        <i class="fa-solid fa-angles-right" style={{ color: '#ffffff' }}></i>
                      </a>
                    </div>
                  </button>

                  { /* <button class="cssbuttons-io-button" type="button" id="dropdownMenu2"
data-bs-toggle="dropdown"
aria-expanded="false"
> Compartir
<div class="icon">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
</div>
</button>
<ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
<li><button class="dropdown-item" type="button" onClick={() => setIsShare(!isShare)} > Compartir </button></li>
<li><button class="dropdown-item" type="button">Copiar Link</button></li>
</ul>*/}

                  <button class="cssbuttons-io-button" onClick={toggleDropdown}
                  > Compartir
                    <div class="icon" onClick={toggleDropdown} >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
                    </div>
                  </button>
                </div>

                { /*{
isShare &&
<div className='modal_compartir_' >
<i class="fa-regular fa-rectangle-xmark" onClick={() => setIsShare(!isShare)} ></i>
<ShareModal />
</div>
}*/}

                {isOpen && (
                  <div className='dropdows_menu_'>
                    <div className='dropdows_menu_compart'>
                      <i class="fa-regular fa-rectangle-xmark" onClick={toggleDropdown} ></i>
                      <div>
                        <div className='icon_compart_content'>

                          <div className='icon_compart_i' onClick={() => handleCopyUrl(`${BASE_URL}/perfil/${perfil._id}`)}>
                            <i className="fa-solid fa-link"></i>
                            <small>Copiar</small>
                          </div>

                          <a href={`${perfil.linkInstagram}`} target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <div
                              className={perfil.linkInstagram ? "icon_compart_i" : "button_btn_co"}
                            >
                              <i class="fa-brands fa-square-instagram"></i>
                              <small>Abrir</small>
                            </div>
                          </a>

                          <a href={`${perfil.linkTelegram}`} target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <div
                              className={perfil.linkTelegram ? "icon_compart_i" : "button_btn_co"}
                            >
                              <i class="fa-brands fa-telegram"></i>
                              <small>Abrir</small>
                            </div>
                          </a>

                          <a href={`${perfil.linkTiktok}`} target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <div
                              className={perfil.linkTiktok ? "icon_compart_i" : "button_btn_co"}
                            >
                              <i class="fa-brands fa-tiktok"></i>
                              <small>Abrir</small>
                            </div>
                          </a>

                        </div>
                        <label>Compartir</label>
                        <ShareModal url={`${BASE_URL}/perfil/${perfil._id}`} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })
      }

    </>

  )
}

export default PerfilComer