import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { deleteAnuncio } from '../../redux/actions/anuncioAction'
import { Link } from 'react-router-dom'


function CardAnuncio({ anuncio }) {
    const number = anuncio.images.length
    const numbers = Array.from({ length: number }, (_, index) => index)

    const fechincio = moment(anuncio.createdAt).format('YYYY-MM-DD')
    const fechaactual = moment().format('YYYY-MM-DD')

    const sumafecha = moment(anuncio.createdAt).add(30, 'd').format('YYYY-MM-DD')


    var x = new Date(moment(anuncio.createdAt).add(30, 'd').format('YYYY-MM-DD'));
    var y = new Date(moment().format('YYYY-MM-DD'));

    const igualar = moment(y).isSameOrAfter(x) //false si es menor a la fecha


    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()


    const publicIds = Array.from({ length: number }, (_, index) => anuncio.images[index].public_id)

    const [imagenes, setImagenes] = useState(publicIds);


    const handleDeleAnuncio = async () => {
        dispatch(deleteAnuncio({ anuncio, auth }))
    }

    const [styleuno, setStyleuno] = useState('h1default');
    const [styledos, setStyledos] = useState('h1default');


    useEffect(() => {
        if (anuncio.condicion === 'Activo') {
            setStyleuno('h1activeuno');
        } else if (anuncio.condicion === 'Desactivo') {
            setStyleuno('h1activedos');
        } else {
            setStyleuno('h1default');
        }
    }, [anuncio.condicion]); 

    useEffect(() => {
        if (anuncio.planduracion === 'gratis') {
            setStyledos('h2activetres');
        } else if (anuncio.planduracion === 'estandar') {
            setStyledos('h2activecuatro');
        } else if (anuncio.planduracion === 'vip') {
            setStyledos('h2activecinco');
        } else {
            setStyledos('h1default');
        }
    }, [anuncio.planduracion]); 

    return (
        <div className='cardanuncio_vista_moderador'>
            <div className='card_moderador_img'>
                <img src={anuncio.images[0].url} alt='' />
            </div>
            <div className='card_moderador_info'>
                <div className='card_moderador_info_caja1'>
                    <h3>{anuncio.titulo}</h3>
                    <h4>{anuncio.nombre}</h4>
                    ID: <span>{anuncio._id}</span><br />
                    Categoria:<span style={{ color: 'red', fontWeight: '700' }}> {anuncio.categoria}</span><br />
                    <Link to={`/publicidad/${anuncio._id}`}>Ver anuncio</Link>
                    <div className='card_moderador_remove' onClick={handleDeleAnuncio} >
                        <span><i class="fa-solid fa-trash-can"></i> Eliminar</span>
                    </div>
                </div>
                <div className='card_moderador_info_caja2'>
                    <div className='card_moderador_info_sub1'>
                        <div className='card_moderador_info_sub3'>
                            <h3>Nacionalidad: <span>{anuncio.nacionalidad}</span></h3>
                            <h3>Whatsapp: <a> <span>{anuncio.telefono}</span></a></h3>
                            <h3>Departamento: <span>{anuncio.provincia}</span></h3>
                            <h3>Distrito: <span>{anuncio.distrito}</span></h3>
                        </div>
                        <div className='card_moderador_info_sub4' style={igualar === true ? { background: '#E74C3C' } : { background: '' }}>
                            <h4>
                                tiempo de publicación: <span>{moment(anuncio.createdAt).fromNow()}</span>
                            </h4>
                            Fecha de inicio: <p>{fechincio}</p>
                            Fecha Termino:<p>{sumafecha}</p>
                            Fecha actual: <p>{fechaactual}</p>
                        </div>
                    </div>
                    <div className='card_moderador_info_sub2'>
                        <div  className='card_moderador_info__'>
                            <h1 className={styleuno}><span>Condicion: </span> {anuncio.condicion}</h1>
                            <h1 className={styledos}><span>Plan: </span> {anuncio.planduracion}</h1>
                        </div>
                        <div>
                            Total de fotos: <span>{anuncio.images.length}</span>
                        </div>
                        <div>
                            <p>{numbers.map((numer, index) => (<li key={index}>{anuncio.images[numer].public_id}</li>))}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardAnuncio