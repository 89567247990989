import { GLOBALTYPES } from "./globalTypes"
import { postDataAPI, getDataAPI, deleteDataAPI } from "../../utils/fetchData"

export const TIKTOK_TYPES = {
    CREATE_TIKTOK: 'CREATE_TIKTOK',
    LOADING_TIKTOK: 'LOADING_TIKTOK',
    GET_TIKTOK: 'GET_TIKTOK',
    DELETE_TIKTOK: 'DELETE_TIKTOK'
}

export const createtiktok = ({urlcont, auth}) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })

        const res = await postDataAPI('tiktok', { urlcont }, auth.token)

        console.log(res)
        dispatch({ 
            type: TIKTOK_TYPES.CREATE_TIKTOK, 
            payload: {...res.data.newTiktok, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

    } catch (err) {

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })

    }

}

export const getTiktok = (token) => async (dispatch) => {
   try {
        dispatch({ type: TIKTOK_TYPES.LOADING_TIKTOK , payload: true })
        const res = await getDataAPI('tiktok', token)

        dispatch({ 
            type: TIKTOK_TYPES.GET_TIKTOK,
            payload: {...res.data, page: 2}
         })

        dispatch({ type: TIKTOK_TYPES.LOADING_TIKTOK , payload: false })
   
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const deleteTiktok = ({tik, auth}) => async (dispatch) => {
    dispatch({ type: TIKTOK_TYPES.DELETE_TIKTOK, payload: tik })

    try {
        await deleteDataAPI(`tiktok/${tik._id}`, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }

}



