import React from 'react'
import { Link } from 'react-router-dom'

function ListItem({ anuncio }) {
    return (
        <>
            {/*<div className='lists_card' >
                <img className='img_Portal' src={anuncio.images[0].url} alt='' />
                <h2 title='nombre'>{anuncio.nombre}</h2>
                <img className='img_nacionalidad' src={anuncio.iconBanader} style={{ width: '50px', height: '60px' }} />
                <div className='row_btn_list'>
                    <Link id="btn_buy_list" to="#!" >
                        buy
                    </Link>
                    <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                        ver
                    </Link>
                </div>
    </div>*/}

            <div className='main-container'>
                <div class="card-principal">
                    <div class="card-secun">
                        
                        <div className="img-card">
                            <img src={anuncio.images[0].url} alt="" />
                            <span className='material-icons'>favorite</span>
                        </div>
                        <div className='card-footer1'>
                            <div className='card-nombre'>
                                <div className='card-name-nombre'>
                                    <i class="fa-solid fa-star"></i>
                                    <h1 style={{ marginLeft: '6px' }}>{anuncio.nombre}</h1>
                                </div>
                                <span>{anuncio.edad} <small>años</small></span>
                            </div>
                            <div className='card-footer-nacio'>
                                <img src={anuncio.iconBanader} />
                                <span>{anuncio.nacionalidad}</span>
                            </div>
                            <div className='card-lugar'>
                                <div>
                                    <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                                    <span>{anuncio.distrito}</span>
                                </div>
                                <small>{anuncio.provincia}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ListItem