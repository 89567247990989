import { useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import PageRender from './customRouter/PageRender'
import PrivateRouter from './customRouter/PrivateRouter'

import Home from './pages/home'
import Login from './pages/login'
import Register from './pages/register'

import Alert from './components/alert/Alert'
import Header from './components/header/Header'
import StatusModal from './components/StatusModal'
import Redsocial from './pages/redsocial'
import Publiaciones from './pages/publiacionesred'

import StatusModalP from './components/publicidad/StatusModalP'

import { useSelector, useDispatch } from 'react-redux'
import { refreshToken } from './redux/actions/authAction'
import { getPosts } from './redux/actions/postAction'
import { getSuggestions } from './redux/actions/suggestionsAction'

import io from 'socket.io-client'
import { GLOBALTYPES } from './redux/actions/globalTypes'
import SocketClient from './SocketClient'

import { getNotifies } from './redux/actions/notifyAction'
import CallModal from './components/message/CallModal'
import Peer from 'peerjs'

import { getAnuncio } from './redux/actions/anuncioAction'
import { getTiktok } from './redux/actions/tiktokAction'

import { getDiscoverPosts } from './redux/actions/videoAction'
import { getUserVideos } from './redux/actions/videouserAction'
import { getPostsHot } from './redux/actions/posthotAction'
import { getDiscoverHot } from './redux/actions/videohotAction'
import { getUserhotVideos } from './redux/actions/videouserhotAction'
import { getpostPerfil } from './redux/actions/perfilpostAction'
import { getPublics } from './redux/actions/publicAction'
import { getPerfils } from './redux/actions/perfilAction'

/*---pruebas ---*/

import { DataProvider } from './GlobalState'
import MainPages from './components/mainpages/Pages'
import Inicio from './pages/inicio'
import Mapaubicacion from './pages/mapaubicacion'
import Listanuncios from './pages/listanuncios'
import Listdetail from './pages/kines'
//import Headerpro from './components/headerPro/Headerpro'

import Limacentro from './pages/limacentro'
import Limasur from './pages/limasur'
import Limanorte from './pages/limanorte'
import Limaeste from './pages/limaeste'
import Cateprovincia from './pages/cateprovincia'
import Publicaranuncio from './pageslegal/publicaranuncio'
import Nuestrapolitica from './pageslegal/nuestrapolitica'
import Contactanos from './pageslegal/contactanos'
import Terminoscondiciones from './pageslegal/terminoscondiciones'
import Datoslegales from './pageslegal/datoslegales'

import HeaderInicio from './components/mainpages/start/HeaderInicio'
import AnuncioModal from './components/anuncios/AnuncioModal'
import TiktokModal from './components/apitiktok/TiktokModal'
import StatusModalCom from './components/perfilcomer/StatusModalCom'

import ActivationEmail from './pageslegal/ActivationEmail'

/****paginas kines */

import brena from './pagespublicidad/limacentro/brena'
import cercado from './pagespublicidad/limacentro/cercado'
import jesusmaria from './pagespublicidad/limacentro/jesusmaria'
import lince from './pagespublicidad/limacentro/lince'
import pueblolibre from './pagespublicidad/limacentro/pueblolibre'
import sanluis from './pagespublicidad/limacentro/sanluis'
import sanmiguel from './pagespublicidad/limacentro/sanmiguel'

import atevitarte from './pagespublicidad/limaeste/atevitarte'
import carabayllo from './pagespublicidad/limaeste/carabayllo'
import chaclacayo from './pagespublicidad/limaeste/chaclacayo'
import cieneguilla from './pagespublicidad/limaeste/cieneguilla'
import elagustino from './pagespublicidad/limaeste/elagustino'
import lamolina from './pagespublicidad/limaeste/lamolina'
import lurigancho from './pagespublicidad/limaeste/lurigancho'
import sanjuandelurigancho from './pagespublicidad/limaeste/sanjuandelurigancho'
import santaanita from './pagespublicidad/limaeste/santaanita'

import ancon from './pagespublicidad/limanorte/ancon'
import comas from './pagespublicidad/limanorte/comas'
import independencia from './pagespublicidad/limanorte/independencia'
import losolivos from './pagespublicidad/limanorte/losolivos'
import puentepiedra from './pagespublicidad/limanorte/puentepiedra'
import rimac from './pagespublicidad/limanorte/rimac'
import sanmartindeporras from './pagespublicidad/limanorte/sanmartindeporras'

import barranco from './pagespublicidad/limasur/barranco'
import chorrillos from './pagespublicidad/limasur/chorrillos'
import lurinypachacamac from './pagespublicidad/limasur/lurinypachacamac'
import magdalena from './pagespublicidad/limasur/magdalena'
import miraflores from './pagespublicidad/limasur/miraflores'
import sanborja from './pagespublicidad/limasur/sanborja'
import sanisidro from './pagespublicidad/limasur/sanisidro'
import sanjuandemiraflores from './pagespublicidad/limasur/sanjuandemiraflores'
import surco from './pagespublicidad/limasur/surco'
import surquillo from './pagespublicidad/limasur/surquillo'
import villaelsalvador from './pagespublicidad/limasur/villaelsalvador'
import vmdeltriunfo from './pagespublicidad/limasur/vmdeltriunfo'

///forum
import { getForums } from './redux/actions/forumAction'
import StatusforModal from './components/forum/StatusforModal'
//import Cassanova from './components/forum/paginas/chonlima/Cassanova'
import CategoriaPage from './components/forum/CategoriaPage'
import WarningModal from './utils/WarningModal'
import ContetDesc from './components/componesthome/ContentDesta'
import CategoryPage from './components/filtertag/CategoryPage'
import TagPage from './components/filtertag/TagPage'

function App() {
  const { auth, status, modal, call, anuncio, tiktok, statuPublic, statusPerfil, statusForum, warning } = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(refreshToken())

    const socket = io()
    dispatch({ type: GLOBALTYPES.SOCKET, payload: socket })
    return () => socket.close()
  }, [dispatch])

  useEffect(() => {
    if (auth.token) dispatch(getAnuncio(auth.token))
    if (auth.token) dispatch(getPublics(auth.token))
    if (auth.token) dispatch(getPerfils(auth.token))
  }, [dispatch, auth.token])

  useEffect(() => {
    if (auth.token) dispatch(getTiktok(auth.token))
  }, [dispatch, auth.token])

  //
  useEffect(() => {
    if (auth.token) dispatch(getDiscoverPosts(auth.token))
  }, [dispatch, auth.token])
  //

  //prueba perfil
  useEffect(() => {
    if (auth.token) dispatch(getpostPerfil(auth.token))
  }, [dispatch, auth.token])
  //

  //
  useEffect(() => {
    if (auth.token) dispatch(getUserVideos(auth.token))
    if (auth.token) dispatch(getPostsHot(auth.token))//post hot
    if (auth.token) dispatch(getDiscoverHot(auth.token))//videos hot
    if (auth.token) dispatch(getUserhotVideos(auth.token))//videos hot user

  }, [dispatch, auth.token])
  //

  useEffect(() => {
    if (auth.token) {
      dispatch(getPosts(auth.token))
      dispatch(getForums(auth.token))
      dispatch(getSuggestions(auth.token))
      dispatch(getNotifies(auth.token))
    }
  }, [dispatch, auth.token])

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") { }
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") { }
      });
    }
  }, [])

  useEffect(() => {
    const newPeer = new Peer(undefined, {
      path: '/', secure: true
    })

    dispatch({ type: GLOBALTYPES.PEER, payload: newPeer })
  }, [dispatch])

  /*{auth.token ? <Header /> : <Headerpro/> }*/
  /*{auth.token && <Header />}*/
  return (
    <DataProvider>
      <Router>
        <Alert />
        <MainPages />
        {warning && <WarningModal />}
        <input type="checkbox" id="theme" />
        <div className={`App ${(status || modal) && 'mode'}`}>
          <div className="main" style={{ backgroundColor: 'hsl(228deg 20% 95%)' }}>

            {auth.token ? <Header /> : <HeaderInicio />}
            {status && <StatusModal />}
            {auth.token && <SocketClient />}
            {call && <CallModal />}
            {anuncio && <AnuncioModal />}
            {tiktok && <TiktokModal />}

            {statusForum && <StatusforModal />}

            {statuPublic && <StatusModalP />}
            {statusPerfil && <StatusModalCom />}

            <Route exact rel="canonical" path="/" component={auth.token ? Home : Inicio} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/publicaciones-contenidos" component={ContetDesc} />
            <Route path="/categoria/:categoria" element={<CategoryPage />} />
            <Route path="/tag/:tag" element={<TagPage /> } />

            <Route exact path="/mapaubicacion" component={Mapaubicacion} />
            <Route exact path="/listanuncios" component={Listanuncios} />
            <Route exact path="/kines/:id" component={Listdetail} />
            <Route exact path="/kinesiologas-en-lima-Centro" component={Limacentro} />
            <Route exact path="/kinesiologas-en-lima-Sur" component={Limasur} />
            <Route exact path="/kinesiologas-en-lima-Norte" component={Limanorte} />
            <Route exact path="/kinesiologas-en-lima-Este" component={Limaeste} />
            <Route exact path="/kinesiologas-en-provincias" component={Cateprovincia} />
            <Route exact path="/publicar-anuncios-kinesiologas" component={Publicaranuncio} />
            <Route exact path="/Politica-privacidad" component={Nuestrapolitica} />
            <Route exact path="/contactanos" component={Contactanos} />
            <Route exact path="/terminos-condiciones" component={Terminoscondiciones} />
            <Route exact path="/informacion-legal" component={Datoslegales} />
            <Route exact path="/red-social-kines" component={Redsocial} />
            <Route exact path="/publicaciones-red-social-kines" component={Publiaciones} />
            <Route exact path="/api/activate/:activation_token" component={ActivationEmail} />

            <Route exact path="/kinesiologas-brena" component={brena} />
            <Route exact path="/kinesiologas-cercado" component={cercado} />
            <Route exact path="/kinesiologas-jesus-maria" component={jesusmaria} />
            <Route exact path="/kinesiologas-lince" component={lince} />
            <Route exact path="/kinesiologas-pueblo-libre" component={pueblolibre} />
            <Route exact path="/kinesiologas-san-luis" component={sanluis} />
            <Route exact path="/kinesiologas-san-miguel" component={sanmiguel} />

            <Route exact path="/kinesiologas-ate-vitarte" component={atevitarte} />
            <Route exact path="/kinesiologas-carabayllo" component={carabayllo} />
            <Route exact path="/kinesiologas-chaclacayo" component={chaclacayo} />
            <Route exact path="/kinesiologas-cieneguilla" component={cieneguilla} />
            <Route exact path="/kinesiologas-el-agustino" component={elagustino} />
            <Route exact path="/kinesiologas-la-molina" component={lamolina} />
            <Route exact path="/kinesiologas-lurigancho" component={lurigancho} />
            <Route exact path="/kinesiologas-san-juan-de-lurigancho" component={sanjuandelurigancho} />
            <Route exact path="/kinesiologas-santa-anita" component={santaanita} />

            <Route exact path="/kinesiologas-ancon" component={ancon} />
            <Route exact path="/kinesiologas-comas" component={comas} />
            <Route exact path="/kinesiologas-independencia" component={independencia} />
            <Route exact path="/kinesiologas-los-olivos" component={losolivos} />
            <Route exact path="/kinesiologas-puente-piedra" component={puentepiedra} />
            <Route exact path="/kinesiologas-rimac" component={rimac} />
            <Route exact path="/kinesiologas-san-martin-de-porras" component={sanmartindeporras} />

            <Route exact path="/kinesiologas-barranco" component={barranco} />
            <Route exact path="/kinesiologas-chorrillos" component={chorrillos} />
            <Route exact path="/kinesiologas-lurin-pachacamac" component={lurinypachacamac} />
            <Route exact path="/kinesiologas-magdalena" component={magdalena} />
            <Route exact path="/kinesiologas-miraflores" component={miraflores} />
            <Route exact path="/kinesiologas-san-borja" component={sanborja} />
            <Route exact path="/kinesiologas-san-isidro" component={sanisidro} />
            <Route exact path="/kinesiologas-san-juan-de-miraflores" component={sanjuandemiraflores} />
            <Route exact path="/kinesiologas-surco" component={surco} />
            <Route exact path="/kinesiologas-surquillo" component={surquillo} />
            <Route exact path="/kinesiologas-villa-el-salvador" component={villaelsalvador} />
            <Route exact path="/kinesiologas-villa-maria-del-triunfo" component={vmdeltriunfo} />


            <Route path="/categoria/:category/:name" component={CategoriaPage} />

            <div style={{ marginBottom: '60px', padding: '10px 0px', backgroundColor: 'hsl(228deg 20% 95%)' }} >
              <PrivateRouter isPrivate={true} exact path="/:page" component={PageRender} />
              <PrivateRouter isPrivate={true} exact path="/:page/:id" component={PageRender} />
            </div>

          </div>
        </div>
      </Router>
    </DataProvider>

  );
}

export default App;
