import { VIDEOSHOT_TYPES } from '../actions/videohotAction'
import { POST_TYPES } from '../actions/postAction'

import { EditData } from '../actions/globalTypes'


const initialState = {
    loading: false,
    posts: [],
    result: 9,
    page: 2,
    firstLoad: false
}

const videohotReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIDEOSHOT_TYPES.LOADING_VIDEOSHOT:
            return {
                ...state,
                loading: action.payload
            };
        case VIDEOSHOT_TYPES.GET_VIDEOSHOT:
            return {
                ...state,
                posts: [...state.posts, ...action.payload.posts],
                result: action.payload.result,
                page: action.payload.page
            };
        case POST_TYPES.UPDATE_POST:
            return {
                ...state,
                posts: EditData(state.posts, action.payload._id, action.payload)
            };

        default:
            return state;
    }
}

export default videohotReducer