import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import UserCarousel from '../../components/UserCarousel'


function CarroselSuges() {
    /*const state = useContext(GlobalState)
    const [products] = state.productsAPI.products*/

    const { auth, suggestions } = useSelector(state => state)
    const dispatch = useDispatch()

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    return (
        <div className='carrousel_usuarios_avisos' >
            <Slider {...settings} id="slider-carousel" >
                {
                    suggestions.users.map(user => (
                        <UserCarousel key={user._id} user={user} >
                        </UserCarousel>
                    ))
                }
            </Slider>
        </div>
    )
}

export default CarroselSuges;
