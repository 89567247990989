import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async'

function Sanmiguel() {

  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicsanmiguel
  /*-----------*/

  if (loading) return <div><Loading /></div>

  const tituloDinamico = "Kinesiologas San Miguel - CARIÑOSASHUB";

  return (
    <>
      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="PUTAS en SAN MIGUEL, encuentra todas las kinesiologas y Prostitutas en cariñosashub, anuncios de escorts y sexo en SAN MIGUEL."
        ></meta>

        <meta name="keywords"
          content="Kinesiólogas, Kinesiólogas en SAN MIGUEL, kines en SAN MIGUEL, putas en SAN MIGUEL, sexo en SAN MIGUEL, escorts en SAN MIGUEL, Prostitutas en SAN MIGUEL, Putas peruanas, putas extranjeras en SAN MIGUEL, kines SAN MIGUEL. Kines, maduras en SAN MIGUEL, jovencitas en SAN MIGUEL, kines SAN MIGUEL perú."></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-san-miguel" />

      </Helmet>
      
      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Sanmiguel