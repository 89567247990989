import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';


function Surquillo() {

  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicsurquillo
  /*-----------*/

  if (loading) return <div><Loading /></div>
  const tituloDinamico = "Kinesiologas Surquillo - CARIÑOSASHUB";

  return (
    <>
      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content=" *** Kinesiologas en Surquillo *** Escorts y kines en Surquillo. Venezolanas, Colombianas, Extranjeras y Peruanas. Photokines surquillo"
        ></meta>

        <meta name="keywords"
          content="kines Surquillo, kinesiologas Surquillo, VENEZOLANAS Surquillo, putas Surquillo, escorts Surquillo, skokka surquillo, photokines surquillo, peru, sexo, prostitutas, whatsapp, kinesiologas web cam, Escorts peruanas, kines venezolanas, damas de compañia, relax, photokinesiologas, 24 horas, con depa, 100 soles, kines en lima, cholotube, perutops, lolitas, Surquillo, San Isidro, Magdalena, San Miguel, Pueblo Libre, Breña, Jesus María"></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-surquillo" />

      </Helmet>
      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Surquillo