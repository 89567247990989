import { useState, useEffect } from 'react'
import axios from 'axios'


function UserAPI(token) {
    const [isLogged, setIsLogged] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [cart, setCart] = useState([])

    //Tipo de usuario perfil

    const [isUservip, setIsUservip] = useState(false)
    const [isModerador, setIsModerador] = useState(false)
    const [isPreferencial, setIsPreferencial] = useState(false)

    //Tipo de usuario perfil comercial

    const [ isUsercomerPrefer, setIsUsercomerPrefer ] = useState(false)
    const [ isUsercoVip, setIsUsercoVip ] = useState(false)

    //
    const [user, setUser] = useState([])
    const [userfamele, setUserfamele] = useState([])
    const [result, setResult] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    /*******get user total */
    useEffect(() => {
        const getUser = async () => {
            setLoading(true)
            const res = await axios.get(`/api/user`)
            setUser(res.data.user)
            setResult(res.data.result)
            setLoading(false)
        }
        getUser()
    }, [])

    /************* */

    useEffect(() => {
        if (token) {
            const getUser = async () => {
                try {
                    const res = await axios.get('/api/infor', {
                        headers: { Authorization: token }
                    })

                    setIsLogged(true)
                    //res.data.role === "admin" ? setIsAdmin(true) : setIsAdmin(false)
                    if (res.data.role === "admin") {
                        setIsAdmin(true)
                        setIsUservip(true)
                        setIsModerador(true)
                        setIsPreferencial(true)
                    } else if (res.data.role === "moderador") {
                        setIsAdmin(false)
                        setIsUservip(false)
                        setIsModerador(true)
                        setIsPreferencial(false)
                    } else if (res.data.role === "uservip") {
                        setIsAdmin(false)
                        setIsUservip(true)
                        setIsModerador(false)
                        setIsPreferencial(false)
                    } else if (res.data.role === "preferencial") {
                        setIsAdmin(false)
                        setIsUservip(false)
                        setIsModerador(false)
                        setIsPreferencial(true)
                    }

                    setCart(res.data.cart)

                } catch (err) {
                    alert(err.response.data.msg)
                }
            }
            getUser()
        }
    }, [token])

    /*********Tipo de usuario comercial********** */

    useEffect(() => {
        if (token) {
            const getUser = async () => {
                try {
                    const res = await axios.get('/api/infor', {
                        headers: { Authorization: token }
                    })

                    setIsLogged(true)
                    if (res.data.rolecomercian === "admin") {
                        setIsUsercoVip(true)
                        setIsUsercomerPrefer(false)
                    } else if (res.data.rolecomercian === "Preferencial") {
                        setIsUsercoVip(false)
                        setIsUsercomerPrefer(true)
                    } else if (res.data.rolecomercian === "Vip") {
                        setIsUsercoVip(true)
                        setIsUsercomerPrefer(false)
                    } 

                    setCart(res.data.cart)

                } catch (err) {
                    alert(err.response.data.msg)
                }
            }
            getUser()
        }
    }, [token])

    /****get 10 usuarios femeninos ****/
    useEffect(() => {
        const getUsernew = async () => {
            setLoading(true)
            const res = await axios.get(`/api/usernew`)
            setUserfamele(res.data.users)
            setResult(res.data.result)
            setLoading(false)
        }
        getUsernew()
    }, [])

    /*------cart anuncio----*/
    const addCart = async (anuncio) => {
        if (!isLogged) return alert("Inicia sesión para poder guardar, o actualize su pagina")

        const check = cart.every(item => {
            return item._id !== anuncio._id
        })

        if (check) {
            setCart([...cart, { ...anuncio, quantity: 1 }])

            await axios.patch('/api/addcart', { cart: [...cart, { ...anuncio, quantity: 1 }] }, {
                headers: { Authorization: token }
            })

        } else {
            alert("Este anuncio ya ha sido guardado")
        }
    }
    /*--------------*/

    return {
        isLogged: [isLogged, setIsLogged],
        isAdmin: [isAdmin, setIsAdmin],
        isUservip: [isUservip, setIsUservip],
        isModerador: [isModerador, setIsModerador],
        isPreferencial: [isPreferencial, setIsPreferencial],

        isUsercomerPrefer: [isUsercomerPrefer, setIsUsercomerPrefer],
        isUsercoVip: [isUsercoVip, setIsUsercoVip],

        cart: [cart, setCart],
        user: [user, setUser],
        page: [page, setPage],
        result: [result, setResult],
        userfamele: [userfamele, setUserfamele],
        addCart: addCart
    }

}

export default UserAPI