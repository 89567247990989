import React, { useContext, useState } from 'react'
import { GlobalState } from '../../GlobalState'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { Link } from "react-router-dom"

const CategoriaPublic = () => {
    const state = useContext(GlobalState)

    const [anunciosvip] = state.publicidadAPI.publicvip
    const [anunciospref] = state.publicidadAPI.publicpref

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    return (
        <>

            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder guardar ó darle like a un anuncio, tienes que unirte a nuestra red social "CariñosasHub", es gratis!!!
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <h2>ESCORTS DE LUJO</h2>
            <div className='settings2'>
                <Slider {...settings2}>
                    {anunciosvip.map(anuncio => {
                        return <div className='img_carousel_'>
                            <div className='img_segun'>
                                <div className='img_segun_favorite'
                                    onClick={handleButtonClick}>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                    <img src={anuncio.images[0].url} alt="" />
                                </Link>
                                <div className='img_segun_footer'>
                                    <div className='title_footer'>
                                        <h4>{anuncio.nombre}</h4>
                                        <span>S/.{anuncio.phora}</span>
                                    </div>
                                    <div className='content_footer'>
                                        <img src={anuncio.iconBanader} />
                                        <span>{anuncio.nacionalidad}</span>
                                    </div>
                                    <div className='lugar_footer'>
                                        <div className='lugar_footer_img'>
                                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691444670/point3_zu3a86.png' id='img_footer_img' />
                                            <span>{anuncio.distrito}</span>
                                        </div >
                                        <div className='lugar_footer_span'>
                                            <span className='material-icons'
                                                style={{ color: '#D4AC0D' }}
                                            >grade</span>
                                            <small
                                                style={{ color: '#7E5109' }}
                                            >
                                                3.1k</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    }
                </Slider>
            </div>
            <h2>CHICAS VIP</h2>
            <div className='settings2'>
                <Slider {...settings2}>
                    {
                        anunciospref.map(anuncio => {
                            return <div className='img_carousel_'>
                                <div className='img_segun_vip'>
                                    <div className='img_segun_favorite'
                                        onClick={handleButtonClick}
                                    >
                                        <i class="fa-solid fa-heart"></i>
                                    </div>
                                    <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                        <img src={anuncio.images[0].url} alt="" />
                                    </Link>
                                    <div className='img_segun_footer'>
                                        <div className='title_footer'>
                                            <h4>{anuncio.nombre}</h4>
                                            <span>S/. {anuncio.phora}</span>
                                        </div>
                                        <div className='content_footer'>
                                            <img src={anuncio.iconBanader} />
                                            <span>{anuncio.nacionalidad}</span>
                                        </div>
                                        <div className='lugar_footer'>
                                            <div className='lugar_footer_img'>
                                                <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691444670/point3_zu3a86.png' id='img_footer_img' />
                                                <span>{anuncio.distrito}</span>
                                            </div >
                                            <div className='lugar_footer_span'>
                                                <span className='material-icons'
                                                    style={{ color: '#D4AC0D' }}
                                                >grade</span>
                                                <small
                                                    style={{ color: '#7E5109' }}
                                                >
                                                    3.1k</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </>
    )
}

export default CategoriaPublic