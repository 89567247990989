import React, { useContext, useState, useEffect } from 'react'
import LoadIcon from '../../images/loading.gif'
import LoadMoreBtn from '../LoadMoreBtn'
import { getDataAPI } from '../../utils/fetchData'
import PostCardGet from '../PostCardGet'

import { Link } from 'react-router-dom'

import { GlobalState } from '../../GlobalState'
import { useSelector } from 'react-redux'
import { POST_TYPES } from '../../redux/actions/postAction'

const Getpost = ({ auth, id, dispatch }) => {
    //usando el api podran ver todas las personas
    const state = useContext(GlobalState)
    ///
    const [isUservip] = state.userAPI.isUservip
    const { theme } = useSelector(state => state)
    //si musamos el objeto homePosts se podra ver solo a personas que siguen
    const { homePosts } = useSelector(state => state)
    //
    const [posts, setPosts] = useState([])
    const [result, setResult] = useState(9)
    const [page] = useState(0)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        const filterdata = homePosts.posts.filter(data => data.user._id === id);
        setPosts(filterdata)
        setResult(filterdata.length)
    }, [homePosts.posts, id])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`posts?limit=${homePosts.page * 9}`, auth.token)

        dispatch({
            type: POST_TYPES.GET_POSTS,
            payload: { ...res.data, page: homePosts.page + 1 }
        })
        setLoad(false)
    }
    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    return (
        <div>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <div id="alerta-titulo">
                        <h4>
                            Suscribase para poder acceder a más contenido..
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }

            {
                posts.length === 0
                    ? <>
                        <h4 style={{ textAlign: 'center', color: 'crimson', fontZise: '16px' }}>
                            No hay post Nuevos
                        </h4>
                    </>
                    : <>
                        {
                            load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        }
                        {
                            posts.map(post => (
                                <div className='postcarget_style' >
                                    <PostCardGet post={post} />
                                </div>
                            ))
                        }
                    </>
            }
            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }

            {
                posts.length > 16 ? (
                    <>
                        {auth.user._id === id || isUservip ? (
                            <LoadMoreBtn
                                result={result}
                                page={page}
                                load={load}
                                handleLoadMore={handleLoadMore}
                            />
                        ) : (
                            <div id='btn_LoadMoreBtn'>
                                <button onClick={handleButtonClick}>Cargar más</button>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )
            }

        </div>
    )
}

export default Getpost