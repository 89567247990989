import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { checkImage } from '../../utils/imageUpload'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { updateProfileUser } from '../../redux/actions/profileAction'

import Icons from '../Icons'

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditProfile = ({ setOnEdit }) => {
    const initState = {
        fullname: '', sobrenombre: '', nacionalidad: '', mipresentacion: '', informacionuno: '', informaciondos: '',
        informaciontres: '', orientacionsex: '', intereses: '', address: '', website: '', eninstagram: '', entelegram: '',
        entiktok: '', enyoutube: '', story: ''
    }
    const [userData, setUserData] = useState(initState)
    const { fullname, sobrenombre, nacionalidad, mipresentacion, informacionuno, informaciondos, informaciontres, intereses,
        orientacionsex, address, website, eninstagram, entelegram, entiktok, enyoutube, story } = userData

    const [avatar, setAvatar] = useState('')

    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [mobile, setMobile] = useState('')

    useEffect(() => {
        setUserData(auth.user)
    }, [auth.user])

    const chageAvatar = (e) => {
        const file = e.target.files[0]
        const err = checkImage(file)
        if (err) return dispatch({
            type: GLOBALTYPES.ALERT, payload: { error: err }
        })
        setAvatar(file)
    }

    const handleInput = e => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(updateProfileUser({ userData, avatar, auth, mobile }))
        //dispatch({ type: GLOBALTYPES.AUTH, payload: false })
    }

    return (
        <div className="edit_profile" >
            <button className="btn btn-danger btn_close"
                onClick={() => setOnEdit(false)} >
                close
            </button>

            <form onSubmit={handleSubmit} >
                <div className="info_avatar">
                    <img src={avatar ? URL.createObjectURL(avatar) : auth.user.avatar}
                        alt="avatar" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />
                    <span>
                        <i className="fas fa-camera" />
                        <p>Cambiar</p>
                        <input type="file" name="file" id="file_up"
                            accept="image/*" onChange={chageAvatar} />
                    </span>
                </div>

                <div className="form-group" >
                    <label htmlFor="fullname" >Nombre completo</label>
                    <div className="position-relative" >
                        <input type="text" className="form-control" id="fullname"
                            name="fullname" value={fullname} onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {fullname.length}/25
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="sobrenombre">Tú sobrenombre<span style={{ color: 'red', fontSize: '12px' }}>/Este campo es necesario</span></label>
                    <div className="position-relative" >
                        <input type="text" id="sobrenombre" name="sobrenombre" value={sobrenombre} placeholder="Puedes ingresar un sobrenombre"
                            className="form-control" onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {sobrenombre.length}/20
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="nacionalidad">Nacionalidad</label>
                    <select class="form-select" id="nacionalidad" aria-label="nacionalidad" value={nacionalidad}
                        onChange={handleInput} name="nacionalidad">
                        <option selected hidden>Nacionalidad</option>
                        <option value="Alemania">Alemania</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brasil">Brasil</option>
                        <option value="Canada">Canada</option>
                        <option value="Chile">Chile</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Estados Unidos">Estados Unidos</option>
                        <option value="España">España</option>
                        <option value="Francia">Francia</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Italia">Italia</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Panama">Panamá</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                    </select>
                </div>

                <div className="form-group" >
                    <label htmlFor="mobile" >Móvil <span style={{ color: 'red', fontSize: '12px' }}>/Verificar antes de Guardar</span></label>
                    <PhoneInput id="mobile" name="mobile" value={mobile} country='pe'
                        className="form-control" onChange={setMobile} />
                </div>

                <div className="form-group" >
                    <label htmlFor="address">Dirección</label>
                    <div className="position-relative">
                        <input type="text" name="address" value={address} placeholder="Puedes ingresar tú direccion de Ubicación"
                            className="form-control" onChange={handleInput} />
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="mipresentacion">Mi presentación</label>
                    <div className="position-relative" >
                        <input type="text" id='mipresentacion' name="mipresentacion" value={mipresentacion} placeholder="Una breve presentación de tí"
                            className="form-control" onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {mipresentacion.length}/35
                        </small>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="informacionuno">Información Uno</label>
                    <div className="position-relative">
                        <input type="text" name="informacionuno" value={informacionuno} id="informacionuno" placeholder="Una información tuya"
                            className="form-control" onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {informacionuno.length}/35
                        </small>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="informaciondos">Información Dos</label>
                    <div className="position-relative">
                        <input type="text" name="informaciondos" value={informaciondos} id="informaciondos" placeholder="Una información tuya"
                            className="form-control" onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {informaciondos.length}/35
                        </small>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="informaciontres">Información Tres</label>
                    <div className="position-relative">
                        <input type="text" name="informaciontres" value={informaciontres} id="informaciontres" placeholder="Una información tuya"
                            className="form-control" onChange={handleInput} />
                        <small className="text-danger position-absolute"
                            style={{ top: '50%', right: '5px', transform: 'translateY(-50%)' }} >
                            {informaciontres.length}/35
                        </small>
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="intereses">Tú interes</label>
                    <div className="position-relative" >
                        <select name="intereses" id="intereses" value={intereses}
                            className="custom-select text-capitalize"
                            onChange={handleInput}>
                            <option selected hidden>Elige Tú Interes</option>
                            <option value="buscarpareja">Buscar Pareja</option>
                            <option value="haceramigos">Hacer Amigos</option>
                            <option value="citacasual">Cita Casual</option>
                        </select>
                        
                    </div>
                </div>

                <div className="form-group" >
                    <label htmlFor="website">Tú Website</label>
                    <input type="text" name="website" value={website} placeholder="Enlace directo a tú web"
                        className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group" >
                    <label htmlFor="eninstagram">Tú Instagram</label>
                    <input type="text" name="eninstagram" value={eninstagram} placeholder="Enlace directo a tú instagram"
                        className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group" >
                    <label htmlFor="entelegram">Tú Telegram</label>
                    <input type="text" name="entelegram" value={entelegram} placeholder="Enlace directo a tú telegram"
                        className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group" >
                    <label htmlFor="entiktok">Tú TikTok</label>
                    <input type="text" name="entiktok" value={entiktok} placeholder="Enlace directo a tú tiktok"
                        className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group" >
                    <label htmlFor="enyoutube">Tú Canal de Youtube</label>
                    <input type="text" name="enyoutube" value={enyoutube} placeholder="Enlace directo a tú canal de Youtube"
                        className="form-control" onChange={handleInput} />
                </div>

                <div className="form-group" >
                    <label htmlFor="enyoutube">Tú whatsapp</label>
                    <input type="text" name="enyoutube" placeholder="Tú whatsapp sera con el numero mobil" disabled="disabled"
                        className="form-control" />
                    <p style={{ color: '#FF968A', fontSize: '12px' }}>Verificar tu numero de mobil antes de enviar el formulario</p>
                </div>

                <div className="form-group" >
                    <label htmlFor="story">Historia</label>
                    <textarea name="story" value={story} cols="30" rows="4" placeholder="Cuentanos un poco de tí"
                        className="form-control" onChange={handleInput} />
                    <small className="text-danger d-block text-right">
                        {story.length}/100
                    </small>
                </div>

                <label htmlFor="gender">Tú orientación sex</label>
                <div className="input-group-prepend px-0 mb-4" >
                    <select name="orientacionsex" id="orientacionsex" value={orientacionsex}
                        className="custom-select text-capitalize"
                        onChange={handleInput}>
                        <option selected hidden>Orientación</option>
                        <option value="masculino">Masculino</option>
                        <option value="femenino">Femenino</option>
                        <option value="bisexual">Bisexual</option>
                        <option value="homosexual">Homosexual</option>
                        <option value="nomostrar">No mostrar</option>
                    </select>
                </div>

                <button className="btn btn-info w-100" type="submit">Guardar</button>
            </form>
        </div>
    )
}

export default EditProfile
