import { useState, useEffect } from 'react'
import axios from 'axios'

function AnunciosAPI() {
  const [anuncio, setAnuncio] = useState([])
  const [callback, setCallback] = useState(false)
  const [category, setCategory] = useState('')
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [result, setResult] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getAnuncio = async () => {
      setLoading(true)
      const res = await axios.get(`/api/listanuncio?limit=${page * 12}&${sort}&nombre[regex]=${search}`)
      setAnuncio(res.data.anuncio)
      setResult(res.data.result)
      setLoading(false)
    }
    getAnuncio()
  }, [callback, category, sort, search, page])

  return {
    anuncio: [anuncio, setAnuncio],
    callback: [callback, setCallback],
    category: [category, setCategory],
    sort: [sort, setSort],
    search: [search, setSearch],
    page: [page, setPage],
    result: [result, setResult],
    loading: [loading, setLoading]
  }
}

export default AnunciosAPI
