import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LikeButton from '../../../LikeButton'
import ShareModal from '../../../ShareModal'
import { BASE_URL } from '../../../../utils/config'
import { likePost, unLikePost, savePost, unSavePost } from '../../../../redux/actions/videouserAction'

import Commentpost from './flootercard/Commentpost'
import InputCommentpost from './flootercard/InputCommentpost'

const Cardfooter = ({ post }) => {

    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)

    const [isShare, setIsShare] = useState(false)
    const [isShareinput, setIsShareinput] = useState(false)


    const { auth, theme, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const [saved, setSaved] = useState(false)
    const [saveLoad, setSaveLoad] = useState(false)


    // Likes
    useEffect(() => {
        if (post.likes.find(like => like._id === auth.user._id)) {
            setIsLike(true)
        } else {
            setIsLike(false)
        }
    }, [post.likes, auth.user._id])

    const handleLike = async () => {
        if (loadLike) return;

        setLoadLike(true)
        await dispatch(likePost({ post, auth, socket }))
        setLoadLike(false)
    }

    const handleUnLike = async () => {
        if (loadLike) return;

        setLoadLike(true)
        await dispatch(unLikePost({ post, auth, socket }))
        setLoadLike(false)
    }

    // Saved
    useEffect(() => {
        if (auth.user.saved.find(id => id === post._id)) {
            setSaved(true)
        } else {
            setSaved(false)
        }
    }, [auth.user.saved, post._id])

    const handleSavePost = async () => {
        if (saveLoad) return;

        setSaveLoad(true)
        await dispatch(savePost({ post, auth }))
        setSaveLoad(false)
    }

    const handleUnSavePost = async () => {
        if (saveLoad) return;

        setSaveLoad(true)
        await dispatch(unSavePost({ post, auth }))
        setSaveLoad(false)
    }

    return (
        <div className="card-footer-page">

            <div className="page-content-between">
                <h6 style={{ padding: '0 25px', cursor: 'pointer' }}>
                    <i class="fa-regular fa-heart"></i> {post.likes.length}  likes
                </h6>

                <h6 style={{ padding: '0 25px', cursor: 'pointer' }} onClick={() => setIsShareinput(!isShareinput)}>
                    {post.comments.length} comentarios
                </h6>
            </div>

            <div className="card-icon-page">
                <div className="card-page-footer">
                    <LikeButton
                        isLike={isLike}
                        handleLike={handleLike}
                        handleUnLike={handleUnLike}
                    />

                    <i className="far fa-comment" onClick={() => setIsShareinput(!isShareinput)} ></i>
                    <i class="fa-solid fa-reply-all" onClick={() => setIsShare(!isShare)} ></i>
                </div>

                {
                    saved
                        ? <i className="fas fa-bookmark text-info"
                            onClick={handleUnSavePost} />

                        : <i className="far fa-bookmark"
                            onClick={handleSavePost} />
                }

            </div>

            {
                isShareinput && <>
                    <Commentpost post={post} />
                    <InputCommentpost post={post} />
                </>
            }

            {
                isShare && <ShareModal url={`${BASE_URL}/post/${post._id}`} theme={theme} />
            }

        </div>
    )
}

export default Cardfooter