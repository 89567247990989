import React, { useState, useEffect } from 'react'
import Avatar from '../Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { createPerfil, updatePerfil } from '../../redux/actions/perfilAction'
import Icons from '../Icons'
import { imageShow, videoShow } from '../../utils/mediaShow'

const StatusModalCom = () => {

  const { auth, theme, statusPerfil, socket } = useSelector(state => state)
  const dispatch = useDispatch()

  const [images, setImages] = useState([])
  const [linkTiktok, setLinkTiktok] = useState('')
  const [linkInstagram, setLinkInstagram] = useState('')
  const [linkOnly, setLinkOnly] = useState('')
  const [linkTelegram, setLinkTelegram] = useState('')
  const [linkOther, setLinkOther] = useState('')
  const [tracks, setTracks] = useState('')
  const [description, setDescription] = useState('')

  const [preciocontenido, setPreciocontenido] = useState('')
  const [precioData, setPrecioData] = useState('');
  const [simbolpre, setSimbolpre] = useState('');

  const [hasError, setHasError] = useState(false);
  const [errorStyle, setErrorStyle] = useState('estyle_back_')

  useEffect(() => {
    // Combina los valores de preciocontenido y simbolprecio automáticamente
    const newCombinedPrice = `${simbolpre} ${precioData}`;
    setPreciocontenido(newCombinedPrice);
  }, [simbolpre, precioData]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Verificar si el valor ingresado es un número
    if (!isNaN(inputValue)) {
      setPrecioData(inputValue);
      setHasError(false); // Restablecer el estado de error si el valor es válido
    } else {
      setHasError(true); // Establecer el estado de error si el valor no es válido
    }
  };

  const inputStyle = {
    backgroundColor: hasError ? '#ffd3d396' : 'white', // Cambiar el color de fondo en caso de error
  };

  const handleInputdescrip = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 180) {
      alert('120 letras como maximo');
      setErrorStyle('estyle_back_err')
    } else {
      setDescription(inputValue);
      setErrorStyle('estyle_back_')
    }
  };

  const handleChangeImages = e => {
    const files = [...e.target.files]
    let err = ""
    let newImages = []

    files.forEach(file => {
      if (!file) return err = "File does not exist."

      if (file.size > 1024 * 1024 * 5) {
        return err = "The image/video largest is 5mb."
      }

      return newImages.push(file)
    })

    if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
    setImages([...images, ...newImages])
  }

  const deleteImages = (index) => {
    const newArr = [...images]
    newArr.splice(index, 1)
    setImages(newArr)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (images.length === 0)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "Agregar una foto por favor." }
      })

    if (description.length > 180)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "excedio el limite permitido de letras de su descripción" }
      })

    if (linkTiktok.length > 200)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "verifique el link de su Tiktok" }
      })

    if (linkInstagram.length > 200)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "verifique el link de su Instagram" }
      })

    if (linkTelegram.length > 200)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "verifique el link de su Telegram" }
      })

    if (linkOnly.length > 200)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "verifique el link de su Onlyfans" }
      })

    if (linkOther.length > 200)
      return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: "verifique el link proporcionado" }
      })

    if (statusPerfil.onEdit) {
      dispatch(updatePerfil({ 
        linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images, auth, statusPerfil 
      }))
    } else {
      dispatch(createPerfil({ linkTiktok, linkInstagram, linkOnly, linkTelegram, linkOther, description, preciocontenido, images, auth, socket }))
    }
    setDescription('')
    setPreciocontenido('')
    setLinkTiktok('')
    setLinkInstagram('')
    setLinkOnly('')
    setLinkTelegram('')
    setLinkOther('')
    setImages([])
    if (tracks) tracks.stop()
    dispatch({ type: GLOBALTYPES.STATUSCOMERS, payload: false })
  }

    /*--------editar----------*/

  useEffect(() => {
    if (statusPerfil.onEdit) {
      setImages(statusPerfil.images)
      setDescription(statusPerfil.description)
      setPreciocontenido(statusPerfil.preciocontenido)
      setLinkOther(statusPerfil.linkOther)
      setLinkTelegram(statusPerfil.linkTelegram)
      setLinkInstagram(statusPerfil.linkInstagram)
      setLinkTiktok(statusPerfil.linkTiktok)
      setLinkOnly(statusPerfil.linkOnly)
    }
  }, [statusPerfil])



  return (
    <div className="anuncio_modal" >
      <form onSubmit={handleSubmit} >

        <div className="status_header">
          <h5 className="m-0">Create Perfil Redes</h5>
          <span onClick={() => dispatch({
            type: GLOBALTYPES.STATUSCOMERS, payload: false
          })}>
            &times;
          </span>
        </div>

        <div className="anuncio_body">

          <div className='content_textarea_co'>
            <textarea name="description" value={description} id={errorStyle}
              placeholder={`${auth.user.username}, agrega una descripción`}
              onChange={handleInputdescrip}
            />
            <small style={description.length < 180 ? { color: '' } : { color: 'red' }}>{description.length} de 120 permitidos</small>
          </div>


          <div className='input_contet_form'>

            <small>Precio: </small>
            <input
              className='input_contet_CO'
              type="text"
              value={precioData}
              onChange={handleInputChange}
              style={inputStyle}
              placeholder='Agregar un precio'
            />
            <select
              id="simbolprecio"
              aria-label="simbolprecio"
              name="simbolprecio"
              onChange={e => setSimbolpre(e.target.value)}
              value={simbolpre}
            >
              <option disabled hidden value="">
                Moneda
              </option>
              <option value="S/.">Soles</option>
              <option value="$">Dólar</option>
              <option value="€">Euros</option>
            </select>
            <label>*solo numeros</label>
          </div>

          <h6>Redes:</h6>
          <div className='content_redes'>
            <div id='input-group_mb-3'>
              <label>*Pegar solo sú nombre de usuario de Tiktok</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">Tiktok</span>
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="basic-addon3"
                  id="linkTiktok"
                  name="linkTiktok"
                  placeholder='Ejm: nombreusuario'
                  value={linkTiktok}
                  onChange={e => setLinkTiktok(e.target.value)} />
              </div>
            </div>

            <div id='input-group_mb-3'>
              <label>*Pegar tú link completo de Instagram</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">Instagram</span>
                <input type="text" class="form-control" aria-describedby="basic-addon3"
                  id="linkInstagram" name="linkInstagram" placeholder='Tú Link Instagram' value={linkInstagram}
                  onChange={e => setLinkInstagram(e.target.value)} />
              </div>
            </div>

            <div id='input-group_mb-3'>
              <label>*Pegar tú link completo de Telegram</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">Telegram</span>
                <input type="text" class="form-control" aria-describedby="basic-addon3"
                  id="linkTelegram" name="linkTelegram" placeholder='Tú Link Telegram' value={linkTelegram}
                  onChange={e => setLinkTelegram(e.target.value)} />
              </div>
            </div>

            <div id='input-group_mb-3'>
              <label>*Pegar tú link completo de OnlyFans</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">Onlyfans</span>
                <input type="text" class="form-control" aria-describedby="basic-addon3"
                  id="linkOnly" name="linkOnly" placeholder='Tú Link Onlyfans' value={linkOnly}
                  onChange={e => setLinkOnly(e.target.value)} />
              </div>
            </div>

            <div id='input-group_mb-3'>
              <label>*Pegar un Link adicional</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">link</span>
                <input type="text" class="form-control" aria-describedby="basic-addon3"
                  id="linkOther" name="linkOther" placeholder='Tú Link Fans-hot' value={linkOther}
                  onChange={e => setLinkOther(e.target.value)} />
              </div>
            </div>

          </div>

          <div className="show_images">
            {
              images.map((img, index) => (
                <div key={index} id="file_img">
                  {
                    img.camera ? imageShow(img.camera, theme)
                      : img.url
                        ? <>
                          {
                            img.url.match(/video/i)
                              ? videoShow(img.url, theme)
                              : imageShow(img.url, theme)
                          }
                        </>
                        : <>
                          {
                            img.type.match(/video/i)
                              ? videoShow(URL.createObjectURL(img), theme)
                              : imageShow(URL.createObjectURL(img), theme)
                          }
                        </>
                  }
                  <span onClick={() => deleteImages(index)}>&times;</span>
                </div>
              ))
            }
          </div>

          <div className="input_images">
            <div className="file_upload">
              <i className="fas fa-image" />
              <input type="file" name="file" id="file"
                accept="image/*" onChange={handleChangeImages} />
            </div>
          </div>
        </div>

        <div className="status_footer">
          <button className="btn btn-secondary w-100" type="submit">
            Enviar
          </button>
        </div>

      </form>
    </div>
  )
}

export default StatusModalCom