import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';


function Sanjuandelurigancho() {


  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicsjlurigancho
  /*-----------*/

  if (loading) return <div><Loading /></div>

  const tituloDinamico = "Kinesiologas San juan de lurigancho- CARIÑOSASHUB";

  return (
    <>

      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="KINESIOLOGAS en San Juan de Lurigancho, encuentra las PUTAS y prostitutas de SJL. Anuncios eróticos de escorts y sexo en San Juan de Lurigancho. chicas delivery y con depa, putas con fotos reales, anuncios sexuales de las putas, KINESIOLOGAS SJL, KINES en San Juan de Lurigancho."
        ></meta>

        <meta name="keywords"
          content="Kinesiólogas SJL, Kines en San Juan de Lurigancho, puta sjl, putas por la hacienda, kinesiologas sjl, putas sjl, kinesiologas en sjl, kines sjl, kinesiologas san juan de lurigancho, kines metro la hacienda"></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-san-juan-de-lurigancho" />

      </Helmet>

      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Sanjuandelurigancho