import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
//import ProductItem from '../utils/productItem/ProductItem';
//import Loading from '../utils/loading/Loading';
import Loading from '../mainpages/utils/loading/Loading';

function CategoryPage() {
    const { categoria } = useParams(); // Extraer el parámetro 'categoria' de la URL
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/api/products?category=${categoria}`);
                setProducts(res.data.products);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [categoria]);

    if (loading) return <Loading />;

    return (
        <div className="category-page">
            <h2>Productos en la categoría: {categoria}</h2>
            <div className="products">
                {products.length === 0 ? (
                    <p>No hay productos en esta categoría</p>
                ) : (
                    products.map((product) => (
                        <>
                            {/*<ProductItem key={product._id} product={product} />*/}
                            <span>productos {categoria} </span>
                        </>
                    ))
                )}
            </div>
        </div>
    );
}

export default CategoryPage;
