import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <div class="footer">
                <div class="footer-row">
                    <Link to={"/contactanos"}>
                        <button class="button language">CONTACTAR
                        </button>
                    </Link>
                    <div class="footer-links">
                        <a href="#">About</a>
                        <a href="#">Copyright</a>
                        <a href="#">Advertise</a>
                        <a href="#">Developers</a>
                    </div>
                    <Link to={"/"}>
                        <button class="button language">RED SOCIAL
                        </button>
                    </Link>
                </div>
                <div class="footer-row">
                    <button class="button language">Location: PERÚ
                    </button>
                    <div class="footer-links link-footer">
                        <a href="#">Terms</a>
                        <a href="#">Privacy</a>
                        <a href="#">Policy&Safety</a>
                    </div>
                    <Link to={"/Politica-privacidad"}>
                        <button class="button language">Ayuda
                        </button>
                    </Link>
                </div>
            </div>
            <div class="footer-last">
                <div class="footer-year">© Copyright 2023</div>
                <div class="social-media">
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path
                                d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
                        </svg>
                    </a>
                    <a href="#">
                        <svg viewBox="0 0 511 511.9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M510.95 150.5c-1.2-27.2-5.6-45.9-11.9-62.1-6.5-17.2-16.5-32.6-29.6-45.4-12.8-13-28.3-23.1-45.3-29.5-16.3-6.3-34.9-10.7-62.1-11.9C334.65.3 325.95 0 256.45 0s-78.2.3-105.5 1.5c-27.2 1.2-45.9 5.6-62.1 11.9A124.9 124.9 0 0043.45 43c-13 12.8-23.1 28.3-29.5 45.3-6.3 16.3-10.7 34.9-11.9 62.1C.75 177.8.45 186.5.45 256s.3 78.2 1.5 105.5c1.2 27.2 5.6 45.9 11.9 62.1 6.5 17.2 16.6 32.6 29.6 45.4 12.8 13 28.3 23.1 45.3 29.5 16.3 6.3 34.9 10.7 62.1 11.9 27.3 1.2 36 1.5 105.5 1.5s78.2-.3 105.5-1.5c27.2-1.2 45.9-5.6 62.1-11.9 34.4-13.3 61.6-40.5 74.9-74.9 6.3-16.3 10.7-34.9 11.9-62.1 1.2-27.3 1.5-36 1.5-105.5s-.1-78.2-1.3-105.5zm-46.1 209c-1.1 25-5.3 38.5-8.8 47.5-8.6 22.3-26.3 40-48.6 48.6-9 3.5-22.6 7.7-47.5 8.8-27 1.2-35.1 1.5-103.4 1.5s-76.5-.3-103.4-1.5c-25-1.1-38.5-5.3-47.5-8.8a78.77 78.77 0 01-29.4-19.1c-8.5-8.3-15-18.3-19.1-29.4-3.5-9-7.7-22.6-8.8-47.5-1.2-27-1.5-35.1-1.5-103.4s.3-76.5 1.5-103.4c1.1-25 5.3-38.5 8.8-47.5a77.8 77.8 0 0119.2-29.4c8.3-8.5 18.3-15 29.4-19.1 9-3.5 22.6-7.7 47.5-8.8 27-1.2 35.1-1.5 103.4-1.5 68.4 0 76.5.3 103.4 1.5 25 1.1 38.5 5.3 47.5 8.8a78.71 78.71 0 0129.4 19.1c8.5 8.3 15 18.3 19.1 29.4 3.5 9 7.7 22.6 8.8 47.5 1.2 27 1.5 35.1 1.5 103.4s-.3 76.3-1.5 103.3zm0 0" />
                            <path
                                d="M256.45 124.5c-72.6 0-131.5 58.9-131.5 131.5s58.9 131.5 131.5 131.5 131.5-58.9 131.5-131.5-58.9-131.5-131.5-131.5zm0 216.8c-47.1 0-85.3-38.2-85.3-85.3s38.2-85.3 85.3-85.3a85.31 85.31 0 010 170.6zm0 0M423.85 119.3a30.7 30.7 0 11-61.4 0 30.7 30.7 0 0161.4 0zm0 0" />
                        </svg>
                    </a>
                    <a href="#">
                        <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 3.98h2.19V.17a28.3 28.3 0 00-3.2-.17c-3.15 0-5.32 1.99-5.32 5.64V9H6.2v4.27h3.48V24h4.28V13.27h3.34L17.82 9h-3.87V6.06c0-1.23.33-2.08 2.05-2.08z" />
                        </svg>
                    </a>
                </div>
                <div class="policy">
                    <Link to={"/terminos-condiciones"}>
                        Terminos y Condiciones
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer