import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../../images/loading.gif'

import { getForum } from '../../redux/actions/forumAction'
import DetailForum from '../../components/forum/DetailForum'
import CardInfo from '../../components/profile/CardInfo'
import Suggestions from '../../components/home/Suggestions'

import { Link } from 'react-router-dom'


const Forum = () => {

    const { id } = useParams()
    const [forum, setForum] = useState([])

    const { auth, detailForum, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getForum({ detailForum, id, auth }))

        if (detailForum.length > 0) {
            const newArr = detailForum.filter(forum => forum._id === id)
            setForum(newArr)
        }
    }, [detailForum, dispatch, id, auth])


    return (
        <div className="home">
            <div className="main_left">
                <button class="main_left_search">
                    <Link to={'/buscador'} >
                        <span><i class="fa-solid fa-magnifying-glass"></i> Buscar</span>
                    </Link>
                </button>
                <CardInfo />
                <button class="custom-btn btn-8" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <Link to={'/precios'}>
                        <span>Anunciar</span>
                    </Link>
                </button>
            </div>
            <div className="main_content">
                {
                    forum.length === 0 &&
                    <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
                }

                {
                    forum.map(item => (
                        <div>
                            <DetailForum forum={item} theme={theme} />
                        </div>
                    ))
                }
            </div>
            <div className="main_rigth">
                <div className='main_rigth_sugeVip'>
                    <h1>Sugerencias</h1>
                    <div className='main_rigth_sugeVip_content'>
                        <Suggestions />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Forum