import React, { useContext, useState } from 'react'
import Avatar from '../../Avatar'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { deletePost } from '../../../redux/actions/postAction'
import { BASE_URL } from '../../../utils/config'

import { GlobalState } from '../../../GlobalState'


const CardHeader = ({ post }) => {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isModerador] = state.userAPI.isModerador
    //

    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const history = useHistory()


    const handleEditPost = () => {
        dispatch({ type: GLOBALTYPES.STATUS, payload: { ...post, onEdit: true } })
    }

    const handleDeletePost = () => {
        if (window.confirm("Seguro que quieres eliminar esta publicación?")) {
            dispatch(deletePost({ post, auth, socket }))
            //window.location.reload()
            return history.push("/");
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${BASE_URL}/post/${post._id}`)
    }

    return (
        <div className="card_header">
            <div className="d-flex">
                {post.user ? (
                    <Avatar src={post.user.avatar} size="big-avatar" />
                )
                    :
                    <Avatar src='https://www.timandorra.com/wp-content/uploads/2016/11/Imagen-no-disponible-282x300.png' size="big-avatar" />
                }

                <div className="card_name">
                    {post.user ? (
                        <>
                            <h6 className="m-0">
                                <Link to={`/profile/${post.user._id}`} className="text-dark">
                                    {post.user.username}
                                </Link>
                            </h6>
                            <small>
                                {moment(post.createdAt).fromNow()}
                            </small>
                        </>
                    ) : (
                        <>
                            <h6 className="m-0">Usuario Desconocido</h6>
                            <small>Fecha Desconocida</small>
                        </>
                    )}
                </div>
            </div>

            <div className="nav-item dropdown" style={{ display: 'block' }} >
                <i className="fa-solid fa-ellipsis" id="moreLink" data-toggle="dropdown"></i>
                <div className="dropdown-menu">
                    {auth.user && post.user && auth.user._id === post.user._id && (
                        <>
                            <div className="dropdown-item" onClick={handleEditPost}>
                                <span className="material-icons">create</span> Editar Post
                            </div>
                            <div className="dropdown-item" onClick={handleDeletePost} >
                                <span className="material-icons">delete_outline</span> Remover Post
                            </div>
                        </>
                    )}

                    {isModerador && (
                        <>
                            <div className="dropdown-item" onClick={handleEditPost}>
                                <span className="material-icons" style={{ color: 'red' }}>create</span> Editar Post
                            </div>
                            <div className="dropdown-item" onClick={handleDeletePost} >
                                <span className="material-icons" style={{ color: 'red' }}>delete_outline</span> Remover Post
                            </div>
                            <div className="dropdown-item" onClick={handleCopyLink} >
                                <span className="material-icons">content_copy</span> Copiar Link
                            </div>
                        </>
                    )}

                    {auth.user && post.user && auth.user._id !== post.user._id && (
                        <>
                            <div className="dropdown-item" onClick={handleCopyLink} >
                                <span className="material-icons">content_copy</span> Copiar Link
                            </div>
                            <div className="dropdown-item">
                                <a style={{ color: 'red', textDecoration: 'none' }}
                                    href={`https://api.whatsapp.com/send?phone=+51913926030&text=HOLA,%20Quiero%20Denunciar%20Este%20Post:${post._id}%20Del%20Usuario${post.user.fullname}`}>
                                    <i class="fa-solid fa-eye-slash"></i> Denunciar Post
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CardHeader
