import React, { useContext, useState } from 'react'
import { GlobalState } from '../../GlobalState'

import moment from 'moment'

import { Link } from 'react-router-dom'


const PostUsers = () => {

    const state = useContext(GlobalState)
    const [posts] = state.postAPI.posts

    //const sortedData = posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

    const filteredPosts = posts.filter(post => {
        const postDate = new Date(post.createdAt);
        return postDate > fiveDaysAgo;
    });

    const sortedData = filteredPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


    return (
        <>
            <h5>numero de post visual: {filteredPosts.length}</h5>
            <h5>numero de post total: {posts.length}</h5>

            <div className='list_pots_container'>
                {
                    sortedData.map(post => (
                        <div className='card_list_potsu'>
                            <Link to={`/post/${post._id}`}>
                                <span>ver Post</span>
                            </Link>
                            {
                                post.images[0].url.match(/video/i)
                                    ? <>
                                        <video muted controls src={post.images[0].url} alt={post.images[0].url} start="0" end="3" controlsList="nodownload"
                                        />
                                        <div className='tipo_de_archivo'><i class="fa-solid fa-film"></i></div>
                                    </>
                                    : <>
                                        <img src={post.images[0].url} alt={post.images[0].url}
                                        />
                                        {
                                            post.images.length > 1
                                                ? <div className='tipo_de_archivo'><i class="fa-regular fa-images"></i></div>
                                                : <div className='tipo_de_archivo'><i class="fa-regular fa-image"></i></div>
                                        }
                                    </>
                            }
                            <h4>{moment(post.createdAt).fromNow()}</h4>
                            <span style={{ color: 'red', background: 'none' }}>categoria: {post.categoria}</span>
                        </div>
                    ))
                }
            </div>
        </>

    )
}

export default PostUsers