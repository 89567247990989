import React, { useState }  from 'react'
import Avatar from './Avatar'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const UserCarousel = ({ children, user, handleClose, setShowFollowers, setShowFollowing, msg }) => {

    const { theme } = useSelector(state => state)

    const handleCloseAll = () => {
        if (handleClose) handleClose()
        if (setShowFollowers) setShowFollowers(false)
        if (setShowFollowing) setShowFollowing(false)
    }
    

    const showMsg = (user) => {
        return (
            <>
                <div style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    {user.text}
                </div>
                {
                    user.media.length > 0 &&
                    <div>
                        {user.media.length} <i className="fas fa-image" />
                    </div>
                }

                {
                    user.call &&
                    <span className="material-icons">
                        {
                            user.call.times === 0
                                ? user.call.video ? 'videocam_off' : 'phone_disabled'
                                : user.call.video ? 'video_camera_front' : 'call'
                        }
                    </span>
                }
            </>
        )
    }

    return (
        <div className='cards-carousel' >
                <Link to={`/profile/${user._id}`} onClick={handleCloseAll}  >
                    <Avatar src={user.avatar} size="avatar-suger-carousel" style={{ opacity: 1, filter: theme ? 'invert(1)' : 'invert(0)' }}/>
                    <div className="ml-1" style={{ transform: 'translateY(-2px)' }} >
                        <span className="d-block">{/*{user.username}*/}</span>
                        {/*<small style={{ opacity: 0.7 }}>
                            {
                                msg
                                    ? showMsg(user)
                                    : user.fullname
                            }
                        </small>*/}
                    </div>
                </Link>
            {children}
        </div>
    )
}


export default UserCarousel