import React, { useContext } from 'react'
import { GlobalState } from '../../GlobalState'
import ImgCarousel from './ImgCarousel'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from 'react-router-dom'

function Carrosel() {
    const state = useContext(GlobalState)
    //const [products] = state.productsAPI.products
    const [anuncios] = state.publicidadAPI.publicvip

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    
    return (

        <div className='carousel_contactos_inte'>
            <h6 className='title-avisos-home'> <Link to={'/catalogo'} >Anuncios</Link></h6>
            <Slider {...settings}>
                {
                    anuncios.map(anuncio => {
                        return <ImgCarousel key={anuncio._id} anuncio={anuncio} />
                    })
                }
            </Slider>
        </div>
    )
}

export default Carrosel;
