import React from 'react';
import { useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import { useHistory } from 'react-router-dom';


const WarningModal = () => {
    const dispatch = useDispatch()
    const history = useHistory();

    const handleCloseModal = () => {
        // Lógica para salir de la aplicación, como redireccionar a otra página
        history.goBack(); // Navegar a la página anterior // Cambia la URL según sea necesario
    };
    return (
        <div className="modal-overlay_waring">
            <div className="modal-content_waring">
                <h2>Advertencia</h2>
                <p>Para seguir navegando tienes que ser mayor de <span>18 años</span></p>
                <button onClick={handleCloseModal}>Salir</button>
                <button onClick={() => dispatch({type: GLOBALTYPES.WARNING, payload: false})}>Ingresar</button>
            </div>
        </div>
    );
};

export default WarningModal;