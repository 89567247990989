import React, { useContext, useState, useEffect } from 'react'
import { GlobalState } from '../GlobalState'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


function Save() {
    const state = useContext(GlobalState)
    const [cart, setCart] = state.userAPI.cart
    const [token] = state.token
    const [total, setTotal] = useState(0)

    const { theme } = useSelector(state => state)


    useEffect(() => {
        const getTotal = () => {
            const total = cart.reduce((prev, item) => {
                return prev + (item.price * item.quantity)
            }, 0)
            setTotal(total)
        }
        getTotal()
    }, [cart])

    const addToCart = async (cart) => {
        await axios.patch('/api/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }

    const increment = (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity += 1
            }
        })

        setCart([...cart])
        addToCart(cart)
    }

    const decrement = (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity === 1 ? item.quantity = 1 : item.quantity -= 1
            }
        })
        setCart([...cart])
        addToCart(cart)
    }

    const removeProduct = id => {
        if (window.confirm("Desea eliminar este anuncio?")) {
            cart.forEach((item, index) => {
                if (item._id === id) {
                    cart.splice(index, 1)
                }
            })
            setCart([...cart])
            addToCart(cart)
        }
    }


    if (cart.length === 0)
        return <h2 className='title_save_save'>No hay anuncios Guardados</h2>

    return (
        <div>
            {
                cart.map(anuncio => (
                    <div className="cart-detalles" key={anuncio._id}>
                        <Link id="btn_ver" to={`/publicidad/${anuncio._id}`}>
                            <img src={anuncio.images[0].url} alt=""
                                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />
                        </Link>

                        <div className="box-detail-cart">
                            <div className="delete-cart"
                            style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                onClick={() => removeProduct(anuncio._id)}>
                                X
                            </div>
                            <h2 style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>{anuncio.nombre}</h2>
                            <div className='info_cont_sav'>
                                <h3 className='info_cont_tit' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>{anuncio.titulo}</h3>
                                <p id="p_info">{anuncio.dcorta}</p>
                                <div className='info_sav'>
                                    <h3>Nacionalidad: </h3><h4>{anuncio.nacionalidad}</h4>
                                </div>
                                <div className='info_sav'>
                                    <h3>Edad: </h3><h4>{anuncio.edad}</h4>
                                </div>
                                <div className='info_sav'>
                                    <h3>Provincia: </h3><h4>{anuncio.provincia}</h4>
                                </div>
                                <div className='info_sav'>
                                    <h3>Distrito: </h3><h4>{anuncio.distrito}</h4>
                                </div>
                                <div className='info_sav'>
                                    <h3>Precio: </h3><h4>s/.{anuncio.phora} xH</h4>
                                </div>
                            </div>
                            <div className='btn_info_what'>
                                <a href={`https://api.whatsapp.com/send?phone=${anuncio.telefono}&text=hola%20qué%20tal,%20té%20vi%20en%20"Cariñosashub.com"?`}
                                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                    <span className='btn_info_1'><i class="fa-brands fa-whatsapp"></i></span>
                                </a>
                                <a href={`tel:+${anuncio.telefono}`}
                                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                    <span className='btn_info_2'><i class="fa-solid fa-phone"></i></span>
                                </a>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Save