import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createComment } from '../../redux/actions/commentAction'
import Icons from '../Icons'

const InputComment = ({ children, post, onReply, setOnReply }) => {
    const [content, setContent] = useState('')

    const { auth, socket, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const handleKeyDown = (e) => {
        if (content.length >= 200 && e.key !== 'Backspace') {
            e.preventDefault();
            // Muestra una alerta cuando el usuario intenta ingresar más de 500 letras.
            alert('El comentario no puede superar las 200 letras.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!content.trim()) {
            if (setOnReply) return setOnReply(false);
            return;
        }

        if (content.length > 200) {
            // Si se supera el límite de 500 letras, puedes mostrar un mensaje de error o realizar alguna otra acción.
            alert('El comentario no puede superar las 500 letras.');
            return;
        }

        setContent('')

        const newComment = {
            content,
            likes: [],
            user: auth.user,
            createdAt: new Date().toISOString(),
            reply: onReply && onReply.commentId,
            tag: onReply && onReply.user
        }

        dispatch(createComment({ post, newComment, auth, socket }))

        if (setOnReply) return setOnReply(false);
    }

    return (
        <form className="card-footer comment_input" onSubmit={handleSubmit} >
            {children}
            <input
                type="text"
                placeholder="Agregar un comentario..."
                value={content}
                onChange={(e) => {
                    // Limita la longitud del contenido a 500 caracteres.
                    if (e.target.value.length <= 200) {
                        setContent(e.target.value);
                    }
                }}
                onKeyDown={handleKeyDown}
                style={{
                    filter: theme ? 'invert(1)' : 'invert(0)',
                    color: theme ? 'white' : '#111',
                    background: theme ? 'rgba(0,0,0,.03)' : '',
                }} />

            <Icons setContent={setContent} content={content} theme={theme} />

            <button type="submit" className="postBtn">
                Post
            </button>
        </form>
    )
}

export default InputComment