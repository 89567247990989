import React, { useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PostCard from '../PostCard'
import Carduserpro from '../profile/Carduserpro'

import { GlobalState } from '../../GlobalState'
import LoadMore from '../mainpages/products/LoadMore'


function Posttotal() {
    const { homePosts, auth, theme } = useSelector(state => state)

    const state = useContext(GlobalState)
    const [users] = state.userAPI.user

    const [inputVal, setInputVal] = useState("");
    const [searchAnuncio, setSearchAnuncio] = useState(users);

    /*-------------------*/
    const [item, setItem] = useState(users);

    const getItem = (cat) => {
        var upadatedValue = users.filter((items) => {
            return items.activopago === cat;
        });
        setItem(upadatedValue);
    };
    /*-----------------*/


    return (
        <div className='list_anuncio_container'>
            <input
                type="text"
                value={inputVal}
                onChange={(e) => {
                    setInputVal(e.target.value);
                }}
                placeholder="buscar por nombre/Id..." style={{ margin: '5px' }}
            />

            <div className='list_anuncio_filter_btn'>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => getItem(false)}
                    >
                        No Activos
                    </button>
                </div>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => getItem(true)}
                    >
                        Activos
                    </button>
                </div>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setItem(users)}
                    >
                        todos
                    </button>
                </div>
            </div>
            
            <label>Cantidad: {item.length}</label>

            {item
                .filter((usuario) => {
                    if (inputVal === "") {
                        return usuario;
                    }
                    else if (
                        usuario._id
                            .toLowerCase()
                            .includes(inputVal.toLowerCase())
                    ) {
                        return usuario;
                    }

                    else if (
                        usuario.username
                            .toLowerCase()
                            .includes(inputVal.toLowerCase())
                    ) {
                        return usuario;
                    }
                })
                .map(user => {
                    return (
                        <div key={user._id} >
                            <Carduserpro user={user} />
                        </div>
                    );
                })}

                <LoadMore />
        </div>
    )
}

export default Posttotal