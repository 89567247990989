import { useState, useEffect } from 'react'
import axios from 'axios'


function PostAPI(token) {
    const [isLogged, setIsLogged] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [cart, setCart] = useState([])
    const [postnew, setPostnew] = useState()
    const [result, setResult] = useState(0)
    const [loading, setLoading] = useState(false)

    const [posts, setPosts] = useState([])

    /****Link de los tiktoks */

    const [linktiktok, setLintiktok] = useState()

    /*******get user total */
    useEffect(() => {
        const getPost = async () => {
            const res = await axios.get("/api/postotal")
            setPosts(res.data.posts)
        }
        getPost()
    }, [])

    /************* */

    /****get 10 usuarios femeninos ****/
    useEffect(() => {
        const getPostnew = async () => {
            setLoading(true)
            const res = await axios.get(`/api/postnew`)
            setPostnew(res.data.posts)
            setResult(res.data.result)
            setLoading(false)
        }
        getPostnew()
    }, [])

    /****Link tiktoks */

    useEffect(() => {
        const gettiktok = async () => {
            setLoading(true)
            const res = await axios.get(`/api/tiktokpublic`)
            setLintiktok(res.data.tiktoks)
            setResult(res.data.result)
            setLoading(false)
        }
        gettiktok()
    }, [])

    /*------cart anuncio---
    const addCart = async (anuncio) => {
        if (!isLogged) return alert("Inicia sesión para poder guardar, o actualize su pagina")

        const check = cart.every(item => {
            return item._id !== anuncio._id
        })

        if (check) {
            setCart([...cart, { ...anuncio, quantity: 1 }])

            await axios.patch('/api/addcart', { cart: [...cart, { ...anuncio, quantity: 1 }] }, {
                headers: { Authorization: token }
            })

        } else {
            alert("Este anuncio ya ha sido guardado")
        }
    }
    ------------*/
    return {
        isLogged: [isLogged, setIsLogged],
        isAdmin: [isAdmin, setIsAdmin],
        cart: [cart, setCart],
        posts: [posts, setPosts],
        postnew: [postnew, setPostnew],

        linktiktok: [linktiktok, setLintiktok]
    }

}

export default PostAPI