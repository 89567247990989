import React, { useState, useEffect } from 'react'
import Avatar from '../Avatar'
import EditProfile from './EditProfile'
import FollowBtn from '../FollowBtn'
import Followers from './Followers'
import Followig from './Followig'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'


const CardInfo = () => {

    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()



    return (
        <div class="card-container">
            <span class="pro" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >PRO</span>
            <img class="round" src={auth.user.avatar} alt="user" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }}  />
            <h3>{auth.user.username}</h3>
            <h6>{auth.user.fullname}</h6>
            <p>{auth.user.story}</p>
            <div class="buttons">
                <button class="primary" onClick={() => dispatch({ type: GLOBALTYPES.STATUS, payload: true })} style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <i class="fa-solid fa-camera"></i> Subir
                </button>
                <button class="primary ghost" style={{ top: '10px', filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <Link to={`/profile/${auth.user._id}`}>
                        <i class="fa-solid fa-pen-to-square"></i> Editar
                    </Link>
                </button>
            </div>
            <div class="skills">
                <h6>Data</h6>
                <div class="skills_container_">
                    <div class="skills_container_div" >{auth.user.followers.length} <small>Seguidores</small></div>
                    <div class="skills_container_div">{auth.user.following.length} <small>Siguiendo</small></div>
                    <div class="skills_container_div">10 <small>Publicaciones</small></div>
                </div>
            </div>
        </div>

    )
}

export default CardInfo