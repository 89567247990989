import React, { useContext, useState, useRef } from 'react'
import { GlobalState } from '../GlobalState'
import CatalogoItem from '../components/mainpages/utils/productItem/CatalogoItem'
import Loading from '../components/mainpages/utils/loading/Loading'
import LoadMoreAnu from '../components/mainpages/products/LoadMoreAnu'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

/*--carosel---*/
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

/*---para filtrar---*/
function filterByCategory(categoryName, categoryFilters, item) {
    if (!categoryFilters.length) return true;
    return categoryFilters.some((filter) => item[categoryName] === filter);
}
/*--------*/

function Catalogo() {
    const state = useContext(GlobalState)
    const [products, setProducts] = state.productsAPI.products
    const [isAdmin] = state.userAPI.isAdmin
    const [token] = state.token
    const [callback, setCallback] = state.productsAPI.callback
    const [loading, setLoading] = useState(false)
    const [isCheck, setIsCheck] = useState(false)

    const directoryRef = useRef()

    /***- */
    const [anuncios, setAnuncios] = state.publicidadAPI.publicidad
    /*-------------------*/
    const addCart = state.userAPI.addCart

    /*-------------------*/
    const [item, setItem] = useState(anuncios);
    const getItem = (cat) => {
        var upadatedValue = anuncios.filter((items) => {
            return items.provincia === cat;
        });
        setItem(upadatedValue);
    };
    /*-----------------*/
    const { theme } = useSelector(state => state)
    /*--------------------*/
    const [getVip] = state.publicidadAPI.publicvip
    const [getPreferencial] = state.publicidadAPI.publicpref
    /*----------------------*/
    /*--------------*/
    const appTypeFilters = [...new Set(item.map((d) => d.distrito))];
    const nacionalidadFilters = [...new Set(anuncios.map((d) => d.nacionalidad))];

    let [appTypes, setAppTypes] = useState([]);
    let [nacionalidads, setNacionalidads] = useState([]);

    let filteredDevelopers = anuncios.filter((dev) => {
        return (
            filterByCategory("distrito", appTypes, dev) &&
            filterByCategory("nacionalidad", nacionalidads, dev)
        )
    });
    /*-----------*/

    const handleCheck = (id) => {
        anuncios.forEach(anuncio => {
            if (anuncio._id === id) anuncio.checked = !anuncio.checked
        })
        setAnuncios([...anuncios])
    }

    const deleteProduct = async (id, public_id) => {
        try {
            setLoading(true)
            const destroyImg = axios.post('/api/destroy', { public_id }, {
                headers: { Authorization: token }
            })
            const deleteProduct = axios.delete(`/api/products/${id}`, {
                headers: { Authorization: token }
            })

            await destroyImg
            await deleteProduct
            setCallback(!callback)
            setLoading(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }
    const checkAll = () => {
        products.forEach(product => {
            product.checked = !isCheck
        })
        setProducts([...products])
        setIsCheck(!isCheck)
    }
    const deleteAll = () => {
        products.forEach(product => {
            if (product.checked) deleteProduct(product._id, product.images.public_id)
        })
    }

    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        variableWidth: true,
        centerPadding: "60px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    if (loading) return <div><Loading /></div>

    return (
        <>
            {
                isAdmin &&
                <div className="delete-all">
                    <span>Select all</span>
                    <input type="checkbox" checked={isCheck} onChange={checkAll} />
                    <button onClick={deleteAll}>Delete ALL</button>
                </div>
            }

            <div className='btn_filtro_catgori' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                <Link to={'/precios'} class="btnp btn-3p" style={{ textDecoration: 'none' }}>
                    Publicar Anuncio
                </Link>
            </div>

            <div className='btn_filtro_catgori'>

                <Link id='link_btn' to="/ventacontenido" >
                    <div className='contentcat'>
                        <i class="fa-solid fa-photo-film"></i>
                        <span>Venta Contenido</span>
                    </div>
                </Link>

                <Link id='link_btn' to="/pagetinder" >
                    <div className='contentcat'>
                        <i class="fa-solid fa-users-viewfinder"></i>
                        <span>Por Targetas</span>
                    </div>
                </Link>

                <Link id='link_btn' to="/ubication" >
                    <div className='contentcat'>
                        <i class="fa-solid fa-location-dot"></i>
                        <span >Por Ubicación</span>
                    </div>
                </Link>

            </div>

            <div className='anuncios_vip'>
                <h2 style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >ESCORTS DE LUJO</h2>
                <div className='settings2' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <Slider {...settings2}>
                        {getVip.map(anuncio => {
                            return <div className='img_carousel_'>
                                <div className='img_segun'>
                                    <div className='img_segun_favorite'
                                        onClick={() => addCart(anuncio)}>
                                        <i class="fa-solid fa-star"></i>
                                    </div>
                                    <Link id="btn_ver_list" to={`/publicidad/${anuncio._id}`} >
                                        <img src={anuncio.images[0].url} alt="" />
                                    </Link>
                                    <div className='img_segun_footer'>
                                        <div className='title_footer'>
                                            <h4>{anuncio.nombre}</h4>
                                            <span>S/.{anuncio.phora}</span>
                                        </div>
                                        <div className='content_footer'>
                                            <img src={anuncio.iconBanader} />
                                            <span>{anuncio.nacionalidad}</span>
                                        </div>
                                        <div className='lugar_footer'>
                                            <div className='lugar_footer_img'>
                                                <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691444670/point3_zu3a86.png' id='img_footer_img' />
                                                <span>{anuncio.distrito}</span>
                                            </div >
                                            <div className='lugar_footer_span'>
                                                <span className='material-icons'
                                                    style={{ color: '#D4AC0D' }}
                                                >grade</span>
                                                <small
                                                    style={{ color: '#7E5109' }}
                                                >
                                                    3.1k</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        }
                    </Slider>
                </div>
                <h2 style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>CHICAS VIP</h2>
                <div className='settings2' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <Slider {...settings2}>
                        {
                            getPreferencial.map(anuncio => {
                                return <div className='img_carousel_'>
                                    <div className='img_segun_vip'>
                                        <div className='img_segun_favorite'
                                            onClick={() => addCart(anuncio)}>
                                            <i class="fa-solid fa-heart"></i>
                                        </div>
                                        <Link id="btn_ver_list" to={`/publicidad/${anuncio._id}`} >
                                            <img src={anuncio.images[0].url} alt="" />
                                        </Link>
                                        <div className='img_segun_footer'>
                                            <div className='title_footer'>
                                                <h4>{anuncio.nombre}</h4>
                                                <span>S/. {anuncio.phora}</span>
                                            </div>
                                            <div className='content_footer'>
                                                <img src={anuncio.iconBanader} />
                                                <span>{anuncio.nacionalidad}</span>
                                            </div>
                                            <div className='lugar_footer'>
                                                <div className='lugar_footer_img'>
                                                    <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691444670/point3_zu3a86.png' id='img_footer_img' />
                                                    <span>{anuncio.distrito}</span>
                                                </div >
                                                <div className='lugar_footer_span'>
                                                    <span className='material-icons'
                                                        style={{ color: '#D4AC0D' }}
                                                    >grade</span>
                                                    <small
                                                        style={{ color: '#7E5109' }}
                                                    >
                                                        3.1k</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>

            <div className='filtro_anuncio_' >
                <div class="menu-c">
                    <label for="btn-menus" className="abrir-menu"><i class="fa-solid fa-filter"></i></label>
                </div>
                <input type="checkbox" id="btn-menus" />
                <h2>Filtrar: </h2>
                <div id="menus" classNames="menu-c">
                    <div className="Provincias_tags">
                        <h3>Provincia</h3>
                        <Slider {...settings}>
                            <div >
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Amazonas")}
                                >
                                    Amazonas
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Ancash")}
                                >
                                    Ancash
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Apurímac")}
                                >
                                    Apurímac
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Ayacucho")}
                                >
                                    Ayacucho
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Cajamarca")}
                                >
                                    Cajamarca
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Callao")}
                                >
                                    Callao
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Cusco")}
                                >
                                    Cusco
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Huancavelica")}
                                >
                                    Huancavelica
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Huanuco")}
                                >
                                    Huanuco
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Ica")}
                                >
                                    Ica
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Junin")}
                                >
                                    Junin
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("La Libertad")}
                                >
                                    La Libertad
                                </button>
                            </div>
                        </Slider>
                        <Slider {...settings}>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Lambayeque")}
                                >
                                    Lambayeque
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary" style={{ color: 'red' }}
                                    onClick={() => getItem("Lima")}
                                >
                                    Lima
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Loreto")}
                                >
                                    Loreto
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Madre De Dios")}
                                >
                                    Madre De Dios
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Moquegua")}
                                >
                                    Moquegua
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Pasco")}
                                >
                                    Pasco
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Piura")}
                                >
                                    Piura
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Puno")}
                                >
                                    Puno
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("San Martin")}
                                >
                                    San Martin
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Tacna")}
                                >
                                    Tacna
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Tumbes")}
                                >
                                    Tumbes
                                </button>
                            </div>
                            <div>
                                <button
                                    className="outline_secondary"
                                    onClick={() => getItem("Ucayali")}
                                >
                                    Ucayali
                                </button>
                            </div>
                            <div>
                                <button className="outline_secondary" onClick={() => setItem(anuncios)} style={{ background: '#E74C3C', color: '#FDEDEC' }}>
                                    All
                                </button>
                            </div>
                        </Slider>
                        <div>
                            <button className="outline_secondary" onClick={() => setItem(anuncios)}
                                style={{ background: '#E74C3C', color: '#FDEDEC', filter: theme ? 'invert(1)' : 'invert(0)' }}
                            >
                                All
                            </button>
                        </div>
                        <div>
                            <button
                                className="outline_secondary" style={{ color: 'red', filter: theme ? 'invert(1)' : 'invert(0)' }}
                                onClick={() => getItem("Lima")}
                            >
                                Lima
                            </button>
                        </div>
                    </div>
                    <div className="contenedor_tags">
                        <h3>Distrito</h3>
                        <ul className="boxs-container" >
                            {appTypeFilters.map((type) => (
                                <li className='content_dist'>
                                    <input
                                        className="inp-cbx" id={type}
                                        name="appType"
                                        type="checkbox"
                                        value={type}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setAppTypes([...appTypes, type]);
                                            } else {
                                                setAppTypes(
                                                    appTypes.filter((distrito) => distrito !== type)
                                                );
                                            }
                                        }}
                                    />
                                    <label for={type} href="#lancha" >{type}</label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="contenedor_tags">
                        <h3>Nacionalidad</h3>
                        <ul className="boxs-container2">
                            {nacionalidadFilters.map((filter) => (
                                <li className='content_dist'>
                                    <input
                                        className="inp-cbx" id={filter}
                                        name="nacionalidad"
                                        type="checkbox"
                                        value={filter}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setNacionalidads([...nacionalidads, filter]);
                                            } else {
                                                setNacionalidads(
                                                    nacionalidads.filter((nacionalidad) => nacionalidad !== filter)
                                                );
                                            }
                                        }}
                                    />
                                    <label for={filter}>{filter}</label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <label for="btn-menus" id="cerrar-menu">X</label>
                </div>
            </div>

            <h2 className='directorio_h2'
                style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                ref={directoryRef}
            >
                Directorio Chicas:
            </h2>

            <div className="catalogo">
                {
                    filteredDevelopers.map(anuncio => {
                        return <CatalogoItem key={anuncio._id} anuncio={anuncio}
                            isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck} />
                    })
                }
            </div>

            <LoadMoreAnu directoryRef={directoryRef} />
            {anuncios.length === 0 && <Loading />}
        </>
    )
}

export default Catalogo