import React from 'react'
import ForumBody from './forum/ForumBody'
import ForumFooter from './forum/ForumFooter'
import ForumHeader from './forum/ForumHeader'




const Forumcard = ({ forum, theme }) => {
    return (
        <div class="ibox-content forum-container">
            <div class="forum-item active">
                <div class="row-forum-item">
                    <ForumHeader forum={forum} theme={theme} />
                    <div className='content_footer_body_forum'>
                        <ForumBody forum={forum} theme={theme} />
                        <ForumFooter forum={forum} theme={theme} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forumcard