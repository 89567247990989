import { GLOBALTYPES } from '../actions/globalTypes'

const statusPerfilReducer = (state = false, action) => {
    switch (action.type) {
        case GLOBALTYPES.STATUSCOMERS:
            return action.payload;
        default:
            return state;
    }
}

export default statusPerfilReducer