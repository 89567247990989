import React, { useState, useEffect } from 'react'
import Avatar from '../../Avatar'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import LikeButton from '../../LikeButton'
import CommentFoMenu from './CommentFoMenu'
import { updateCommentforo, likeCommentforo, unLikeCommentforo } from '../../../redux/actions/commentForoAction'
import InputCommentForo from './InputCommentForo'

const CommentCard = ({ children, comment, forum, commentId }) => {
    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [content, setContent] = useState('')
    const [readMore, setReadMore] = useState(false)

    const [onEdit, setOnEdit] = useState(false)
    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)

    const [onReply, setOnReply] = useState(false)


    useEffect(() => {
        setContent(comment.content)
        setIsLike(false)
        setOnReply(false)
        if (comment.likes.find(like => like._id === auth.user._id)) {
            setIsLike(true)
        }
    }, [comment, auth.user._id])

    const handleUpdate = () => {
        if (comment.content !== content) {
            dispatch(updateCommentforo({ comment, forum, content, auth }))
            setOnEdit(false)
        } else {
            setOnEdit(false)
        }
    }


    const handleLike = async () => {
        if (loadLike) return;
        setIsLike(true)

        setLoadLike(true)
        await dispatch(likeCommentforo({ comment, forum, auth }))
        setLoadLike(false)
    }

    const handleUnLike = async () => {
        if (loadLike) return;
        setIsLike(false)

        setLoadLike(true)
        await dispatch(unLikeCommentforo({ comment, forum, auth }))
        setLoadLike(false)
    }


    const handleReply = () => {
        if (onReply) return setOnReply(false)
        setOnReply({ ...comment, commentId })
    }

    const styleCard = {
        opacity: comment._id ? 1 : 0.5,
        pointerEvents: comment._id ? 'inherit' : 'none'
    }


    //probando
    const lines = content.split('\n');
    // Mapear cada línea para crear un elemento <p> en JSX

    return (
        <div className="comment_card mt-2" style={styleCard}>
            <Link to={`/profile/${comment.user._id}`} className="d-flex text-dark">
                <Avatar src={comment.user.avatar} size="small-avatar" />
                <h6 className="mx-1">{comment.user.username}</h6>
            </Link>

            <div className="comment_content forums">
                <div className="flex-fill"
                    style={{
                        filter: theme ? 'invert(1)' : 'invert(0)',
                        color: theme ? 'white' : '#111',
                    }}>
                    {
                        onEdit
                            ? <textarea rows="5" value={content}
                                onChange={e => setContent(e.target.value)} />

                            : <div className='content_commet_replay'>
                                {
                                    comment.tag && comment.tag._id !== comment.user._id &&
                                    <Link to={`/profile/${comment.tag._id}`} className="mr-1">
                                        @{comment.tag.username}
                                    </Link>
                                }
                                <span>
                                    {
                                        content.includes('\n') // Verificar si hay saltos de línea
                                            ?
                                            (
                                                lines.length > 8 && !readMore
                                                ? lines.slice(0, 7).map((line, index) => (
                                                    <div key={index}>
                                                        {line}
                                                    </div>
                                                ))
                                                : lines.map((line, index) => (
                                                    <div key={index}>
                                                        {line}
                                                    </div>
                                                ))
                                            )
                                            :
                                            content.length < 50
                                                ? content
                                                : readMore ? content + ' ' : content.slice(0, 50) + '....'
                                    }
                                </span>
                                {
                                    content.length > 50 &&
                                    <span className="readMore" onClick={() => setReadMore(!readMore)}>
                                        {readMore ? 'Ocultar contenido' : 'Leer más'}
                                    </span>
                                }
                            </div>
                    }


                    <div style={{ cursor: 'pointer' }}>
                        <small className="text-muted mr-3">
                            {moment(comment.createdAt).fromNow()}
                        </small>

                        <small className="font-weight-bold mr-3">
                            {comment.likes.length} likes
                        </small>

                        {
                            onEdit
                                ? <>
                                    <small className="font-weight-bold mr-3"
                                        onClick={handleUpdate}>
                                        update
                                    </small>
                                    <small className="font-weight-bold mr-3"
                                        onClick={() => setOnEdit(false)}>
                                        cancel
                                    </small>
                                </>

                                : <small className="font-weight-bold mr-3"
                                    onClick={handleReply}>
                                    {onReply ? 'cancel' : 'reply'}
                                </small>
                        }

                    </div>

                </div>


                <div className="d-flex align-items-center mx-2" style={{ cursor: 'pointer' }}>
                    <CommentFoMenu forum={forum} comment={comment} setOnEdit={setOnEdit} />
                    <LikeButton isLike={isLike} handleLike={handleLike} handleUnLike={handleUnLike} />
                </div>
            </div>

            {
                onReply &&
                <InputCommentForo forum={forum} onReply={onReply} setOnReply={setOnReply} >
                    <Link to={`/profile/${onReply.user._id}`} className="mr-1">
                        @{onReply.user.username}:
                    </Link>
                </InputCommentForo>
            }

            {children}
        </div>
    )
}

export default CommentCard
