import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../../../images/loading.gif'
import LoadMoreBtn from '../../LoadMoreBtn'
import { POSTHOT_TYPES } from '../../../redux/actions/posthotAction'
import { getDataAPI } from '../../../utils/fetchData'
import Cardflowers from '../videoFlowers/cardflowers/Cardflowers'

function Postvip() {
  const { auth, theme, homePostshot } = useSelector(state => state)
  const dispatch = useDispatch()

  const [load, setLoad] = useState(false)

  const handleLoadMore = async () => {
    setLoad(true)
    const res = await getDataAPI(`postshot?limit=${homePostshot.page * 16}`, auth.token)

    dispatch({
      type: POSTHOT_TYPES.GET_POSTSHOT,
      payload: { ...res.data, page: homePostshot.page + 1 }
    })

    setLoad(false)
  }

  return (
    <div className="posts">
      {
        homePostshot.posts.map(post => (
          <Cardflowers key={post._id} post={post} theme={theme} />
        ))
      }

      {
        load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
      }

      <LoadMoreBtn result={homePostshot.result} page={homePostshot.page}
        load={load} handleLoadMore={handleLoadMore} />
    </div>
  )
}

export default Postvip