import React, { useEffect, useRef } from 'react';

const PaypalButton = ({ total, tranSuccess }) => {
    const paypalLoaded = useRef(false);

    useEffect(() => {
        const addPaypalScript = async () => {
            if (paypalLoaded.current) {
                // Limpiar el contenedor antes de renderizar el botón nuevamente
                document.getElementById('paypal-button-container').innerHTML = "";
                
                return window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: total
                                }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then(details => {
                            tranSuccess(details);
                        });
                    },
                    onError: (err) => {
                        console.error("Error en el proceso de pago:", err);
                    }
                }).render('#paypal-button-container');
            }

            // Crear y cargar el script de PayPal
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=AVKTZ9-aBCNf4jg-x_mbX2Wjkm0kicZuXhI6GxuaabyY0YBtZJ1gCVDIAg4KCmFDaWk23RdZJ7dlzeuD&currency=USD`;
            script.async = true;

            script.onload = () => {
                paypalLoaded.current = true; // Marcar que el script ya se ha cargado
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: total
                                }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then(details => {
                            tranSuccess(details);
                        });
                    },
                    onError: (err) => {
                        console.error("Error en el proceso de pago:", err);
                    }
                }).render('#paypal-button-container');
            };

            script.onerror = () => {
                console.error("Hubo un error al cargar el SDK de PayPal");
            };

            document.body.appendChild(script);
        };

        addPaypalScript();
    }, [total, tranSuccess]);

    return (
        <div>
            <div id="paypal-button-container"></div>
        </div>
    );
};

export default PaypalButton;
