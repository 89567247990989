import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import CardAnHeader from './CardAnHeader'
import CardAnBody from './CardAnBody'
import CardAnFooter from './CardAnFooter'
import CardAnuncio from './CardAnuncio'
import axios from 'axios'
import { GlobalState } from '../../GlobalState'
import LoadMore from '../mainpages/products/LoadMore'

function ListAnuncio() {
    const { homeAnuncio } = useSelector(state => state)
    const state = useContext(GlobalState)
    const [anuncios, setAnuncios] = state.publicidadAPI.publicidadtot
    const [inputVal, setInputVal] = useState("");
    const [searchAnuncio, setSearchAnuncio] = useState(anuncios);

    /*-------------------*/
    const [item, setItem] = useState(anuncios);

    const getItem = (cat) => {
        var upadatedValue = anuncios.filter((items) => {
            return items.categoria === cat;
        });
        setItem(upadatedValue);
    };
    /*-----------------*/

    console.log(item)
    console.log(anuncios)

    return (
        <div className='list_anuncio_container'>
            <input
                type="text"
                value={inputVal}
                onChange={(e) => {
                    setInputVal(e.target.value);
                }}
                placeholder="buscar por nombre/Id..."
            />

            <div className='list_anuncio_filter_btn'>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => getItem('Normal')}
                    >
                        Normal
                    </button>
                </div>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => getItem('Preferencial')}
                    >
                        Preferencial
                    </button>
                </div>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => getItem('Vip')}
                    >
                        Vip
                    </button>
                </div>
                <div>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setItem(anuncios)}
                    >
                        Todos
                    </button>
                </div>
            </div>



            <p>cantidad de anuncios: {item.length}</p>
            {item
                .filter((anun) => {
                    if (inputVal === "") {
                        return anun;
                    }
                    else if (
                        anun._id
                            .toLowerCase()
                            .includes(inputVal.toLowerCase())
                    ) {
                        return anun;
                    }

                    else if (
                        anun.nombre
                            .toLowerCase()
                            .includes(inputVal.toLowerCase())
                    ) {
                        return anun;
                    }


                })
                .map(anuncio => {
                    return (
                        <div key={anuncio._id} className="card my-3" id='card_anuncios_'>
                            <CardAnHeader anuncio={anuncio} />
                            <CardAnuncio anuncio={anuncio} />
                        </div>
                    );
                })}

            <LoadMore />
        </div>

    )
}

export default ListAnuncio