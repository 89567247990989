import { PERFILCOMER_TYPES } from '../actions/perfilAction'
import { EditData } from '../actions/globalTypes'

const detailPerfilReducer = (state = [], action) => {
    switch (action.type){
        case PERFILCOMER_TYPES.GET_PERFILCOMER:
            return [...state, action.payload]
        case PERFILCOMER_TYPES.UPDATE_PERFILCOMER:
            return EditData(state, action.payload._id, action.payload)
        default:
            return state;
    }
}


export default detailPerfilReducer