import React from 'react'
//import './notification.css'
import {BASE_URL} from '../config'

export const showErrMsg = (msg) => {
    return <div className="errMsg">{msg}</div>
}

export const showSuccessMsg = (msg) => {
    return <div className="successMsg">{msg} <a className='successMsg_a' href={BASE_URL}>Entrar</a> </div>
}