import React from 'react'
import { Link } from 'react-router-dom'

function Contacto() {
    return (
        <div className='styles_contactanos'>
            <div class="sideMenu" id="sideMenu">
                <nav>
                    <div class="container">
                        <i class="iconsTopMenu pull-right"
                            id="closeMenu" ng-click="">
                        </i>
                        <center id="imgPerfil">
                            <img src="https://res.cloudinary.com/dshcgnvss/image/upload/v1688155155/logos/pngwing.com_12_t6vite.png" class="animated imgMenu img-responsive " alt="logo cariñosashub" width="60%" />
                        </center>
                        <h3 class="myNameMenu">CariñosasHub</h3>
                        <p class="text-center especified" >Red Social</p>
                        <hr class="hrMenu" />
                    </div>
                    <ul class="menuModules">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=+51913926030&text=hola%20quisiera%20hacer%20una%20consulta%20%F0%9F%98%83">
                                <i class="fa-brands fa-square-whatsapp"></i>
                            </a>
                        </li>
                        <li><a href="#myWorks"><i class="fa-brands fa-telegram"></i></a></li>
                        <li><a href="https://mail.google.com/"><i class="fa-solid fa-envelope"></i></a></li>
                        <li><a href="#contactMe"><i class="fa-brands fa-instagram"></i></a></li>
                    </ul>

                    <ul id="menuModules">
                        <li><a href="#aboutMe"><i class=""></i></a>
                        </li>
                        <li><a href="#myWorks"><i class=""></i></a>
                        </li>
                        <li><a href="#myTools"><i class=""></i></a>
                        </li>
                        <li><a href="#contactMe"><i class=""></i></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="container">
                <section id="aboutMe">
                    <div class="containerContent">
                        <div class="col-md-6">
                            <div class="aboutTitle">
                                <h1 class="animatedbounce">INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</h1>
                            </div>
                            <div className='info_contactos_p'>
                                <span>Responsabilidad </span> <p>Contactar con alekeydeveloper@gmail.com</p>
                                <span>Finalidades</span><p>Ponernos en contacto contigo para resolver tus dudas y facilitarte la información solicitada por medios electrónicos.</p>
                                <span>Derechos</span><p>Puedes acceder, rectificar y suprimir tus datos personales, así como ejercer otros derechos dirigiéndote a alekeydeveloper@gmail.com</p>
                                <span>Info. Adicional</span><p>Puede consultar nuestra Política de Privacidad completa <a style={{ color: 'red' }}>aquí.</a></p>
                            </div>
                        </div>
                        <div class="col-md-6" id='cold-md-ou'>
                            <div class="row">
                                <div class="aboutRedDiv" >
                                    <div className='title_fnas'>
                                        <Link to={'/'}>
                                            <h3>CariñosasHub</h3>
                                        </Link>
                                        <center id="imgPerfil">
                                            <img src="https://res.cloudinary.com/dshcgnvss/image/upload/v1688155155/logos/pngwing.com_12_t6vite.png" class="animated imgMenu img-responsive " alt="logo cariñosashub" width="60%" />
                                        </center>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="myWorks">
                    <div className='myWorks_divs'>
                        <div className='myWorks_divs_info'>
                            <h3>Correo:</h3><a href='https://mail.google.com/'><span>alekeydeveloper@gmail.com</span></a>
                        </div>
                        <div className='myWorks_divs_info'>
                            <h3>Whatsapp:</h3>
                            <a href='https://api.whatsapp.com/send?phone=+51913926030&text=hola%20quisiera%20hacer%20una%20consulta%20%F0%9F%98%83'>
                                <span>Escribir Aqui</span>
                            </a>
                        </div>
                        <div className='myWorks_divs_info'>
                            <h3>Telegram:</h3>
                            <span onClick={e => window.open('https://t.me/fanshots', '_blank')}>
                                Abrir Telegram
                            </span>
                        </div>
                    </div>
                </section>
                <section id="contactMe">
                    <div class="container row containerContent finally">
                        <div class="col-md-12 myWorksTitle">
                            <h1 class="pull-right">Enviar Correo:</h1>
                        </div>
                        <div class="col-md-6">
                            <form>
                                <div class="form-group">
                                    <label>Nombre:</label>
                                    <input type="text" class="form-control" required />
                                </div>
                                <div class="form-group">
                                    <label>Tú Email:</label>
                                    <input type="email" class="form-control" required />
                                </div>
                                <div class="form-group">
                                    <label>Titulo:</label>
                                    <input type="text" class="form-control" required />
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label className='label_form_commen'>Comentario:</label>
                                <textarea class="form-control contact" rows="7" required></textarea>
                            </div>
                            <button type="submit" class="btn btn-block btn-success" style={{ background: '#D35400', border: 'none', margin: '0' }}>Enviar mensaje</button>
                        </div>
                        <div className="followSpaceTop">
                            <div className="followSpaceTop_div">
                                <a href='https://api.whatsapp.com/send?phone=+51913926030&text=hola%20quisiera%20hacer%20una%20consulta%20%F0%9F%98%83'>
                                    <i class="fa-brands fa-square-whatsapp"></i>
                                </a>
                            </div>
                            <div className="followSpaceTop_div">
                                <a href=''>
                                    <i class="fa-brands fa-telegram"></i>
                                </a>
                            </div>
                            <div className="followSpaceTop_div">
                                <a href='https://mail.google.com/'>
                                    <i class="fa-solid fa-envelope"></i>
                                </a>
                            </div>
                            <div className="followSpaceTop_div"><i class="fa-brands fa-instagram"></i></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contacto