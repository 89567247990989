import React, { useEffect, useState, useContext } from 'react'
import ComponetsHome from '../components/componesthome/ComponetsHome'
import { Link } from 'react-router-dom'
import PostNew from '../components/publicacioneshome/PostNew';
import ProfileNew from '../components/publicacioneshome/ProfileNew';


const Publiaciones = () => {
    //////////////
    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    // Definir el estado de los botones
    const [botones, setBotones] = useState([
        { id: 1, activo: true },
        { id: 2, activo: false }
    ]);
    // Función para activar un botón y desactivar los demás
    const handleClick = (indice) => {
        const nuevosBotones = [...botones];
        nuevosBotones.forEach((boton, i) => {
            if (i === indice) {
                boton.activo = true;
            } else {
                boton.activo = false;
            }
        });
        setBotones(nuevosBotones);
    }
    /*-----------*/
    return (
        <div className="home_public">

            <div className="profile_tab_public" >
                <button onClick={() => { handleButtonClick(1); handleClick(0) }} id={botones[0].activo ? 'activebuttoPublic' : ''}>
                    <i class="fa-solid fa-user"></i> <span>Nuevas Publicaciones</span> 
                </button>
                <button onClick={() => { handleButtonClick(2); handleClick(1) }} id={botones[1].activo ? 'activebuttoPublic' : ''}>
                    <i class="fa-solid fa-image"></i> <span>Nuevos Perfiles</span>
                </button>
            </div>

            <div>
                {activeButton === 1 && <PostNew />}
            </div>
            <div>
                {activeButton === 2 && <ProfileNew /> }
            </div>

        </div>
    )
}

export default Publiaciones