import React from 'react'
import Avatar from '../../Avatar'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { deletePublic } from '../../../redux/actions/publicAction'
import { BASE_URL } from '../../../utils/config'

const PublicHeader = ({ post }) => {

    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const history = useHistory()


    const handleEditPost = () => {
        dispatch({ type: GLOBALTYPES.STATUSPUBLIC, payload: { ...post, onEdit: true } })
    }

    const handleDeletePost = () => {
        if (window.confirm("Seguro que quieres eliminar esta publicación?")) {
            dispatch(deletePublic({ post, auth, socket }))
            return history.push("/")
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${BASE_URL}/publicidad/${post._id}`)
    }

    return (
        <div className="card_header">

            {
                post.user ?
                    <div className="d-flex">
                        <Avatar src={post.user.avatar} size="big-avatar" />

                        <div className="card_name">
                            <h6 className="m-0">
                                <Link to={`/profile/${post.user._id}`} className="text-dark">
                                    {post.user.username}
                                </Link>
                            </h6>
                            <small className="text-muted">
                                {moment(post.createdAt).fromNow()}
                            </small>
                        </div>
                    </div>
                    :
                    <div className="d-flex">
                        <Avatar src='https://www.timandorra.com/wp-content/uploads/2016/11/Imagen-no-disponible-282x300.png' size="big-avatar" />

                        <div className="card_name">
                            <h6 className="m-0">
                                <a>
                                    Usuario no disponible
                                </a>
                            </h6>
                            <small className="text-muted">
                                {moment(post.createdAt).fromNow()}
                            </small>
                        </div>
                    </div>
            }

            {
                post.user ?
                    <div className="nav-item dropdown" style={{ display: 'block' }}>
                        <span className="material-icons" id="moreLink" data-toggle="dropdown">
                            menu
                        </span>

                        <div className="dropdown-menu">

                            {
                                auth.user._id === post.user._id &&
                                <>
                                    <div className="dropdown-item" onClick={handleEditPost}>
                                        <span className="material-icons">create</span> Editar Post
                                    </div>
                                    <div className="dropdown-item" onClick={handleDeletePost} >
                                        <span className="material-icons">delete_outline</span> Remover Post
                                    </div>
                                </>
                            }

                            <div className="dropdown-item" onClick={handleCopyLink} >
                                <span className="material-icons">content_copy</span> Copiar Link
                            </div>

                        </div>

                    </div>
                    :
                    <div className="nav-item dropdown" style={{ display: 'block' }}>
                        <span className="material-icons" id="moreLink" data-toggle="dropdown">
                            menu
                        </span>

                        <div className="dropdown-menu">

                            <div className="dropdown-item" onClick={handleCopyLink} >
                                <span className="material-icons">content_copy</span> Copiar Link
                            </div>

                        </div>

                    </div>
            }

        </div>
    )
}

export default PublicHeader