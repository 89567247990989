import { VIDEOUSERHOT_TYPES } from '../actions/videouserhotAction'
import { EditData, DeleteData } from '../actions/globalTypes'
import { POST_TYPES } from '../actions/postAction'


const initialState = {
    loading: false,
    posts: [],
    result: 0,
    page: 2
}

const videouserhotReducer = (state = initialState, action) => {
    switch (action.type){
        case VIDEOUSERHOT_TYPES.LOADING_USERVIDEOHOT:
                return {
                    ...state,
                    loading: action.payload
                };
        case VIDEOUSERHOT_TYPES.GET_USERVIDEOHOT:
                return {
                    ...state,
                    posts: action.payload.posts,
                    result: action.payload.result,
                    page: action.payload.page
                    };
        case POST_TYPES.UPDATE_POST:
                return {
                    ...state,
                    posts: EditData(state.posts, action.payload._id, action.payload)
                    };
    
        default:
            return state;
    }
}

export default videouserhotReducer