import React, { useState, useEffect, useContext } from 'react'
import Avatar from '../Avatar'
import EditProfile from './EditProfile'
import FollowBtn from '../FollowBtn'
import Followers from './Followers'
import Followig from './Followig'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../GlobalState'

const Info = ({ id, auth, profile, dispatch }) => {

    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //

    const { theme } = useSelector(state => state)

    const [userData, setUserData] = useState([])
    const [onEdit, setOnEdit] = useState(false)

    const [showFollowers, setShowFollowers] = useState(false)
    const [showFollowing, setShowFollowing] = useState(false)

    /*-----------------------*/
    const [stileinst, setStileinst] = useState('pruebas_stylos_dos')
    const [stilewasap, setStilewasap] = useState('pruebas_stylos_dos')
    const [stileteleg, setStileteleg] = useState('pruebas_stylos_dos')
    const [stiletiktok, setStiletiktok] = useState('pruebas_stylos_dos')
    const [stilemipres, setStilemipres] = useState('pruebas_stylos_dos')
    const [stileinfuno, setStileinfuno] = useState('pruebas_stylos_dos')
    const [stileinfodos, setStileinfodos] = useState('pruebas_stylos_dos')
    const [stileintres, setStileintres] = useState('pruebas_stylos_dos')
    const [stileinteres, setStileinteres] = useState('pruebas_stylos_dos')
    const [stileorient, setStileorient] = useState('pruebas_stylos_dos')
    const [stilemobile, setStilemobile] = useState('pruebas_stylos_dos')
    const [stileaddress, setStileaddress] = useState('pruebas_stylos_dos')
    const [stileweb, setStileweb] = useState('pruebas_stylos_dos')
    const [stilestory, setStilestory] = useState('pruebas_stylos_dos')
    const [stileyoutube, setStileyoutube] = useState('pruebas_stylos_dos')


    useEffect(() => {
        userData.forEach(dat => {
            if (!dat.eninstagram) setStileinst('pruebas_stylos')
            if (!dat.mobile) setStilewasap('pruebas_stylos')
            if (!dat.entelegram) setStileteleg('pruebas_stylos')
            if (!dat.entiktok) setStiletiktok('pruebas_stylos')
            if (!dat.mipresentacion) setStilemipres('pruebas_stylos')
            if (!dat.informacionuno) setStileinfuno('pruebas_stylos')
            if (!dat.informaciondos) setStileinfodos('pruebas_stylos')
            if (!dat.informaciontres) setStileintres('pruebas_stylos')
            if (!dat.intereses) setStileinteres('pruebas_stylos')
            if (dat.orientacionsex === 'nomostrar') setStileorient('pruebas_stylos')
            if (!dat.mobile) setStilemobile('pruebas_stylos')
            if (!dat.address) setStileaddress('pruebas_stylos')
            if (!dat.website) setStileweb('pruebas_stylos')
            if (!dat.story) setStilestory('pruebas_stylos')
            if (!dat.enyoutube) setStileyoutube('pruebas_stylos')

        })
    }, [userData])

    /*------------------------*/

    const [stilorix, setStilorix] = useState('https://ih1.redbubble.net/image.176682250.2427/flat,128x,075,f-pad,128x128,f8f8f8.u4.jpg')
    const [naciostile, setNaciostile] = useState('nacio_stile')

    useEffect(() => {
        userData.forEach(mon => {
            if (mon.orientacionsex === 'femenino') setStilorix("https://thumbs.dreamstime.com/b/textura-de-papel-con-el-color-carmes%C3%AD-liso-del-rosa-perfecto-para-fondo-puro-uniforme-rosado-la-foto-moda-149774968.jpg")
            if (mon.orientacionsex === 'masculino') setStilorix("http://empoderadas.weebly.com/uploads/1/1/8/8/118830901/published/807197792.jpg?1569488514")
            if (mon.orientacionsex === 'bisexual') setStilorix("https://www.banderasvdk.com/blog/wp-content/uploads/Bandera-orgullo-bisexual-300x180.jpg")
            if (mon.orientacionsex === 'homosexual') setStilorix("https://www.banderasvdk.com/blog/wp-content/uploads/bandera-homosexualidad-01-300x203.jpg")
            if (mon.nacionalidad === 'nomostrar') setNaciostile("nacio_stile_none")

        })
    }, [userData])

    /*----------------------*/

    /*-------------------*/
    const [interesnew, setInteresnew] = useState('')

    useEffect(() => {
        userData.forEach(mon => {
            if (mon.intereses === 'buscarpareja') setInteresnew("Buscar Pareja")
            if (mon.intereses === 'heceramigos') setInteresnew("Hacer Amigos")
            if (mon.intereses === 'citacasual') setInteresnew("Cita Casual")
        })
    }, [userData])
    /*-------------------*/

    useEffect(() => {
        if (id === auth.user._id) {
            setUserData([auth.user])
        } else {
            const newData = profile.users.filter(user => user._id === id)
            setUserData(newData)
        }
    }, [id, auth, dispatch, profile.users])

    useEffect(() => {
        if (showFollowers || showFollowing || onEdit) {
            dispatch({ type: GLOBALTYPES.MODAL, payload: true })
        } else {
            dispatch({ type: GLOBALTYPES.MODAL, payload: false })
        }
    }, [showFollowers, showFollowing, onEdit, dispatch])

    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    return (

        <div className="info" >
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0', filter: theme ? 'invert(1)' : 'invert(0)' }}>
                    <div id="alerta-titulo" style={{margin:'0',height:'55vh'}}>
                        <i class="fa-regular fa-message"></i>
                        <h4>IMPORTANTE!!!! <br/> Para poder ver el contenido ó ponerte en contacto con el usuario tiene que estar suscrito, es facil, no necesita tarjeta, 
                            solamente a travez de nuestras Redes sociales.
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/precios"}>
                                <button type="button" class="outline-danger" >Suscribirse</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {
                userData.map(user => (
                    <div className="info_container" key={user._id} >
                        <div className='info_container-img'>
                            <div className='portal-avatar_content'>
                                <Avatar src={user.avatar} size="portal-avatar" />
                            </div>
                            <Avatar src={user.avatar} size="supper-avatar" />
                            <div className='verify' id='verify-user'
                                style={{ display: `${user.verificacion == true ? 'block' : 'none'}` }}>
                                <i class="fa-solid fa-check" ></i>
                            </div>
                        </div>

                        <div className="info_content" >
                            <div className='pais-origen'><i class="fa-solid fa-location-dot" id='icon_location_'></i>
                                {user.pais}
                                <div className={stileorient} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                    <img src={stilorix} className='veri_orientacion' />
                                </div>
                            </div>
                            
                            <div className="info_content_title" >
                                <div className='title-perfil'>
                                    <h2>{user.username}</h2>
                                </div>
                                <div>
                                    {
                                        user._id === auth.user._id
                                            ?
                                            <button className="btn btn-outline-info" style={{ display: 'none' }}>
                                                prueba
                                            </button>
                                            :
                                            <>
                                                {
                                                    isUservip
                                                        ? <Link className="tinder" to={`/message/${user._id}`}>
                                                            <button className="btn btn-info" style={{ filter: theme ? 'invert(1)' : 'invert(0)', background: 'red', border: 'none' }}>
                                                                <i class="far fa-comment-alt"></i> Mensaje
                                                            </button>
                                                        </Link>
                                                        : <a className="tinder">
                                                            <button className="btn btn-info" style={{ filter: theme ? 'invert(1)' : 'invert(0)', background: 'red', border: 'none' }}
                                                                onClick={handleShow} >
                                                                <i class="far fa-comment-alt"></i> Mensaje
                                                            </button>
                                                        </a>
                                                }
                                            </>
                                    }
                                    {
                                        user._id === auth.user._id
                                            ?
                                            <button className="btn btn-danger "
                                                onClick={() => setOnEdit(true)} >
                                                Editar Perfil
                                            </button>
                                            : <FollowBtn user={user} />
                                    }
                                </div>
                            </div>

                            <div className="follow_btn" >
                                <div className='follow_btn-segu' onClick={() => setShowFollowers(true)}>
                                    <span className="mr-seguidor" >{user.followers.length}</span><small>Seguidores</small>
                                </div>
                                <div className='follow_btn-segu' onClick={() => setShowFollowing(true)}>
                                    <span className="mr-seguidor">{user.following.length}</span><small>Siguiendo</small>
                                </div>
                            </div>

                            <div className="btn-redes-options">
                                <div class='demo-content'>
                                    <div class="nshare">
                                        <span style={{ color: '#616A6B' }}>Redes:</span>

                                        {
                                            auth.user._id === id
                                                ? <div className={stileinst} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                    <a class="nshare-item nshare-rd" href={user.eninstagram}>
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>
                                                </div>
                                                : isUservip
                                                    ? <div className={stileinst} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                        <a class="nshare-item nshare-rd" href={user.eninstagram}>
                                                            <i class="fa-brands fa-instagram"></i>
                                                        </a>
                                                    </div>
                                                    : <div className={stileinst} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                        <a class="nshare-item nshare-rd" onClick={handleButtonClick}>
                                                            <i class="fa-brands fa-instagram"></i>
                                                        </a>
                                                    </div>
                                        }

                                        {
                                            auth.user._id === id
                                                ? <div className={stilewasap} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                    <a class="nshare-item nshare-ws" href={`https://api.whatsapp.com/send?phone=${user.mobile}&text=hola,%20té%20vi%20en%20CariñosasHub`}>
                                                        <i class="fab fa-whatsapp"></i>
                                                    </a>
                                                </div>
                                                : isUservip
                                                    ? <div className={stilewasap} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-ws" href={`https://api.whatsapp.com/send?phone=${user.mobile}&text=hola,%20té%20vi%20en%20CariñosasHub`}>
                                                            <i class="fab fa-whatsapp"></i>
                                                        </a>
                                                    </div>
                                                    : <div className={stilewasap} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-ws" onClick={handleButtonClick} >
                                                            <i class="fab fa-whatsapp"></i>
                                                        </a>
                                                    </div>
                                        }

                                        {
                                            auth.user._id === id
                                                ? <div className={stileteleg} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                    <a class="nshare-item nshare-tlg" href={user.entelegram}>
                                                        <i class="fa-brands fa-telegram"></i>
                                                    </a>
                                                </div>
                                                : isUservip
                                                    ? <div className={stileteleg} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-tlg" href={user.entelegram}>
                                                            <i class="fa-brands fa-telegram"></i>
                                                        </a>
                                                    </div>
                                                    : <div className={stileteleg} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-tlg" onClick={handleButtonClick} >
                                                            <i class="fa-brands fa-telegram"></i>
                                                        </a>
                                                    </div>
                                        }

                                        {
                                            auth.user._id === id
                                                ? <div className={stiletiktok} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                    <a class="nshare-item nshare-tb" href={user.entiktok}>
                                                        <i class="fa-brands fa-tiktok"></i>
                                                    </a>
                                                </div>
                                                : isUservip
                                                    ? <div className={stiletiktok} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-tb" href={user.entiktok}>
                                                            <i class="fa-brands fa-tiktok"></i>
                                                        </a>
                                                    </div>
                                                    : <div className={stiletiktok} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-tb" onClick={handleButtonClick}>
                                                            <i class="fa-brands fa-tiktok"></i>
                                                        </a>
                                                    </div>
                                        }

                                        {
                                            auth.user._id === id
                                                ? <div className={stileyoutube} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                    <a class="nshare-item nshare-you" href={user.enyoutube}>
                                                        <i class="fa-brands fa-youtube"></i>
                                                    </a>
                                                </div>
                                                : isUservip
                                                    ? <div className={stileyoutube} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-you" href={user.enyoutube}>
                                                            <i class="fa-brands fa-youtube"></i>
                                                        </a>
                                                    </div>
                                                    : <div className={stileyoutube} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                        <a class="nshare-item nshare-you" onClick={handleButtonClick}>
                                                            <i class="fa-brands fa-youtube"></i>
                                                        </a>
                                                    </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="informe">
                                <div className='informe_fullname'>
                                    <i class="fa-solid fa-user"></i>
                                    <span>{user.fullname}</span>
                                </div>

                                <div className='informe_fullname' id={naciostile}>
                                    <i class="fa-solid fa-earth-americas"></i>
                                    <span>De {user.nacionalidad}</span>
                                </div>

                                <div className='informe_sobrenombre'>
                                    <i class="fa-regular fa-square-check"></i><span>{user.sobrenombre}</span><br />
                                    <div className={stilemipres}>
                                        <small style={{ color: '#78281F', left: '6px', position: 'relative' }}>
                                            {user.mipresentacion}
                                        </small>
                                    </div>
                                </div>

                                <div className='informacion_user'>
                                    <div className={stileinfuno}>
                                        <i class="fa-regular fa-square-check"></i><span>{user.informacionuno}</span>
                                    </div>
                                    <div className={stileinfodos}>
                                        <i class="fa-regular fa-square-check"></i><span>{user.informaciondos}</span>
                                    </div>
                                    <div className={stileintres}>
                                        <i class="fa-regular fa-square-check"></i><span>{user.informaciontres}</span>
                                    </div>
                                    <div className={stileinteres}>
                                        <i class="fa-regular fa-heart"></i>
                                        <span>
                                            {interesnew}
                                        </span>
                                    </div>
                                    <div className={stileorient}>
                                        {/*<i class="fa-regular fa-square-check"></i><span>{user.orientacionsex}</span>*/}

                                        <div className={stileorient} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                            <i class="fa-regular fa-heart"></i><img src={stilorix} className='veri_orientacion' />
                                        </div>
                                    </div>
                                </div>

                                <div className={stileaddress}>
                                    <div className='_info_addres'>
                                        <i class="fa-solid fa-location-crosshairs" style={{ marginRight: '4px', color: '#616A6B' }}></i>
                                        <span className="m-0" style={{ color: '#EC7063' }}>{user.address}</span>
                                    </div>
                                </div>

                                <div className={stileweb}>
                                    <div className='informe_website'>
                                        <i class="fa-solid fa-link" style={{ marginRight: '4px', color: '#616A6B' }}></i>
                                        <a href={user.website} target="_blank" rel="noreferrer">
                                            {user.website}
                                        </a>
                                    </div>
                                </div>

                                <div className={stilestory}>
                                    <div className='informe_history'>
                                        <p>{user.story}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            onEdit &&
                            <EditProfile setOnEdit={setOnEdit} />
                        }

                        {
                            showFollowers &&
                            <Followers
                                users={user.followers}
                                setShowFollowers={setShowFollowers}
                            />
                        }
                        {
                            showFollowing &&
                            <Followig
                                users={user.following}
                                setShowFollowing={setShowFollowing}
                            />
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Info
