import React, { useContext, useState } from 'react'
import { GlobalState } from '../../GlobalState'
import Menu from './icon/menu.svg'
import Close from './icon/close.svg'
import Cart from './icon/cart.svg'
import { Link } from 'react-router-dom'

function Panelvip() {
    const state = useContext(GlobalState)
    const [isLogged] = state.userAPI.isLogged
    const [isAdmin] = state.userAPI.isAdmin
    const [isUservip] = state.userAPI.isUservip
    const [cart] = state.userAPI.cart
    const [menu, setMenu] = useState(false)

    const adminRouter = () => {
        return (
            <>
                <li><Link to="/veranuncio">Crear aviso</Link></li>
            </>
        )
    }

    const loggedRouter = () => {
        return (
            <>

            </>
        )
    }

    const styleMenu = {
        left: menu ? 0 : "-100%"
    }

    return (
        <header className='header-pro'>
            <div className="menupro" onClick={() => setMenu(!menu)}  >
                <img src={Menu} alt="" width="18" />
            </div>

            <ul style={styleMenu} >
                {isAdmin && adminRouter()}

                <li onClick={() => setMenu(!menu)}>
                    <img src={Close} alt="" width="20" className="menupro" />
                </li>
                <li >
                    <Link to="/precios">
                        <i class="fa-solid fa-comments-dollar" style={{ marginRight: '5px' }}></i> Precios
                    </Link>
                </li>
                <li className='li-precios-nlc'>
                    <Link to="/precios">
                        <i class="fa-solid fa-comments-dollar" style={{ marginRight: '5px' }}></i> Publicar Anucio
                    </Link>
                </li>
                <li>
                    <Link to="/politica" >
                        <i class="fa-solid fa-book" style={{ marginRight: '5px' }}></i> Politica
                    </Link>
                </li>
                <li>
                    <Link to="/contacto">
                        <i class="fa-solid fa-user-tie" style={{ marginRight: '5px' }}></i> Contacto
                    </Link>
                </li>
                <li>
                    <Link to="/terminos">
                        <i class="fa-solid fa-handshake-simple" style={{ marginRight: '5px' }}></i> Terminos y condiciones
                    </Link>
                </li>
            </ul>
        </header>
    )
}

export default Panelvip