import React from 'react'
import { Link } from 'react-router-dom'


function Terminos() {
    return (
        <div>
            <div className='terminos_condiciones'>
                <small>Fecha de vigencia: 10 de febrero de 2023</small> <br />
                <small>Última modificación: 10 de Marzo de 2023</small><br />
                <Link to={'/legalidad'}>
                    <i class="fa-regular fa-circle-right" style={{ color: 'red' }} > <span style={{ color: 'red' }}>Información legal general... (IMPORTANTE!!....)</span></i>
                </Link>
                <h2>Sólo para adultos.</h2>
                <p>
                    Hemos colocado este termino por encima de todos nuestros otros términos porque es el más importante (aunque también se menciona a continuación). Tenga en cuenta que la plataforma
                    "CariñosasHub" solo está abierta para adultos que deseen expresarse mediante la publicación de fotos, videos y mensajes, ó para poder hacer un anuncio sobre algun servicio.
                    Tenemos tolerancia cero para cualquier menor en la plataforma "CariñosasHub", ya sea que dichos individuos sean realmente menores o pretendan ser menores, tal vez en un intento equivocado
                    de juego de roles. Además, tenemos tolerancia cero con cualquier imagen de cualquier menor que se cargue en nuestra plataforma por cualquier motivo. Tenga en cuenta que nos reservamos el
                    derecho de informar a todos y cada uno de los menores sospechosos a los centros legales correspondientes.
                    Si cree que un menor está utilizando el sitio, solicitamos y alentamos a los miembros a que informen a todos y cada uno de los usuarios que encuentren y que crean o sospechen que son menores de dieciocho
                    años. Todos los informes se revisan a los pocos minutos de nuestra recepción, si no en segundos. Si es menor de edad, debe abandonar inmediatamente este sitio ahora. No está legalmente
                    permitido en "CariñosasHub" por ningún motivo, y si lo encontramos en este sitio, lo informaremos a la policía y a las autoridades correspondientes como se mencionó anteriormente. y procederemos a
                    cerrar todas las cuentas, y eliminar todas las publicaciones relacionadas a dicha cuenta.
                    Nunca lo reactivaremos por ningún motivo. y lo reportaremos a la policía y autoridades correspondientes, como ya se mencionó.
                    Con respecto a todos los demás asuntos, hemos intentado mantener nuestras reglas al mínimo y solo imponer aquellas que consideramos necesarias para la seguridad de nuestros
                    usuarios y el funcionamiento continuo de "CariñosasHub".com.
                </p>

                <h2>Aceptación</h2>
                <p>
                    Al usar y/o visitar el sitio web de "CariñosasHub" (colectivamente, individualmente, incluidos, entre otros, todo el contenido, las cargas y los envíos de usuarios disponibles a través
                    de fant-hot.com, "CariñosasHub", el sitio web), usted acepta los términos y condiciones aquí contenidos y los términos y Las condiciones de la política de privacidad de "CariñosasHub" se
                    incorporaron aquí, y todas las enmiendas y modificaciones futuras (denominados colectivamente como el "Acuerdo"). Al ingresar, usted acepta estar sujeto a estos términos y condiciones.
                    Si no acepta estar sujeto a los términos y condiciones contenidos en este documento, entonces lo invitamos a no usar la plataforma "CariñosasHub".
                    Los términos y condiciones de este Acuerdo están sujetos a cambios por parte de "CariñosasHub" en cualquier momento a su sola discreción y usted acepta estar sujeto a todos las modificaciones, cambios y/o
                    revisiones futuras. Si no acepta estar sujeto a todas y cada una de las modificaciones esta en la libertad de no usar la plataforma "CariñosasHub".
                </p>

                <h2>Introducción</h2>
                <p>
                    Cuando se registra o utiliza cualquier servicio dentro de la app web Fant-hot.com (colectivamente, el " Sitio ", " nosotros ", " nuestro ", " nosotros " u otros términos apropiados
                    en primera persona), todos de los cuales los servicios se denominarán en lo sucesivo colectivamente como el " Servicio ", usted acepta todos los términos y condiciones de este
                    Acuerdo. Lea atentamente los siguientes términos y condiciones, ya que forman el acuerdo entre usted, el usuario del sitio web (a veces denominado
                    en este documento " Usuario ", " usted ", " su" u otros términos apropiados en segunda persona) y el Sitio (dicho acuerdo se denomina en el presente como el "Acuerdo ”).
                    <p style={{ color: 'red' }}>SI NO ACEPTA ESTOS TÉRMINOS Y CONDICIONES, NO PUEDE UTILIZAR EL SERVICIO Y NO DEBE PROCEDER A REGISTRARSE O UTILIZAR EL SERVICIO DE OTRO MODO. AL UTILIZAR EL SERVICIO, USTED ESTÁ
                        DEMOSTRANDO SU DISPOSICIÓN A ESTAR OBLIGADO POR ESTE ACUERDO, INCLUYENDO TODAS LAS MODIFICACIONES REALIZADAS DE VEZ EN CUANDO.</p>
                </p>
                <h2>Convenio</h2>
                <ol>
                    <li>
                        <span>Derecho de uso.</span> Su derecho a utilizar el Servicio está sujeto a las limitaciones, condiciones y restricciones establecidas por nosotros de vez en cuando, a nuestro exclusivo
                        criterio. Podemos modificar, suspender o descontinuar cualquier aspecto del Servicio o del Sitio en cualquier momento, incluida la disponibilidad de cualquier función, base de
                        datos o contenido del Servicio. También podemos imponer límites a ciertas características y aspectos del Servicio o restringir su acceso a partes o todo el Servicio sin previo
                        aviso ni responsabilidad.
                    </li>
                    <li>
                        <span>¡ ESTE SERVICIO ES SOLO PARA ADULTOS !</span> Usted declara, garantiza y acuerda que tiene al menos 18 años o la mayoría de edad en su jurisdicción, la que sea mayor
                        (la " Mayoría de edad "). El Sitio y el Servicio están destinados únicamente a adultos. Al utilizar el Sitio y el Servicio, usted acepta que ha alcanzado la
                        Mayoría de edad. Nos reservamos el derecho de cancelar su cuenta si, a nuestro exclusivo y absoluto criterio, creemos que está violando esta sección. Además,
                        nos reservamos el derecho de cancelar su cuenta e informarlo a las autoridades correspondientes en caso de que sospechemos, a nuestro exclusivo y absoluto criterio,
                        que alguien que no es mayor de edad ha utilizado su cuenta.
                    </li>
                    <li>
                        CONTAMOS CON UNA POLÍTICA DE TOLERANCIA CERO CON LA PORNOGRAFÍA INFANTIL Y UNA POLÍTICA DE TOLERANCIA CERO CON LOS PEDÓFILOS, PEDERASTOS O CUALQUIER ACTIVIDAD RELACIONADA
                        CON LA PEDÓFILA, PEDERÁSTICA O SIMILAR.
                        <ol>
                            <li>
                                Todas las representaciones de todas las personas en el Sitio se proporcionan bajo la obligación del productor, por lo tanto, de cargar o transmitir videos o imágenes que representen
                                a personas mayores de dieciocho (18) años a partir de la fecha de producción de la representación. Tomamos grandes medidas para garantizar que no aparezcan publicaciones de menores de
                                edad en ningún video o imagen del Sitio. Informamos todas las imágenes de menores a las fuerzas del orden, independientemente del estado de vestimenta de dicho menor o el supuesto propósito
                                de la publicación. No nos comunicaremos con usted antes de informar dichas imágenes. Simplemente cancelaremos su cuenta y proporcionaremos las imágenes sospechosas y toda la información a la policia.
                                NO SUBA NINGUNA IMÁGENES DE NINGÚN MENOR POR NINGUNA RAZÓN.
                            </li>
                            <li>
                                Si busca cualquier forma de pornografía infantil (incluida la denominada pornografía infantil “virtual”), debe salir del Sitio de inmediato. No proporcionamos este tipo de material y
                                no toleramos a quienes proporcionan este tipo de material, ni toleramos a los consumidores de este tipo de material.
                            </li>
                            <li>
                                Para promover nuestra política de tolerancia cero, usted acepta denunciar cualquier imagen que tenga motivos para creer que representa a menores en el Sitio haciendo clic en el enlace
                                "Denunciar" en la parte inferior de cada página de medios en el Sitio. Incluya con su informe cualquier evidencia apropiada, incluyendo la fecha y hora de identificación. Todos los
                                informes serán investigados y se tomarán las medidas adecuadas en función de nuestra capacidad razonable para verificar la evidencia proporcionada.
                            </li>
                            <li>
                                Cooperamos con entusiasmo con cualquier organismo encargado del cumplimiento de la ley que investigue la pornografía infantil. Si sospecha que otros sitios web externos están
                                participando en actividades ilegales que involucran a menores, infórmenos y daremos conocimiento a la policia.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Norma de Conducta . Usted acepta utilizar el Servicio de acuerdo con la siguiente Norma de Conducta:
                        <ol>
                            <li>
                                Usted es el único responsable de cualquier información que publique, muestre o diga a través del Sitio y/o Servicio. Usted acepta mantener toda la
                                información contenida o proporcionada a través del Sitio y/o Servicio como privada y confidencial, y acepta no proporcionar dicha información a nadie
                                sin el permiso de la persona que se la proporcionó;
                            </li>
                            <li>
                                Usted es consciente de que el Servicio contiene materiales explícitos para adultos proporcionados solo por usuario y para usuarios que consienten que tienen al menos
                                la mayoría de edad;
                            </li>
                            <li>
                                En el caso de que solicite, cargue, transmita y/o comparta cualquier imagen de cualquier menor, como se mencionó anteriormente, no nos pondremos en contacto con usted.
                                Simplemente cancelaremos su cuenta y lo informaremos a todas y cada una de las fuerzas del orden que, a nuestro exclusivo criterio, determinemos apropiadas;
                            </li>
                            <li>
                                No utilizará el Servicio para participar en ninguna forma de acoso o comportamiento ofensivo, que incluye, entre otros, la publicación o el intercambio de cualquier mensaje,
                                imagen o grabación que contenga declaraciones calumniosas, calumniosas, acosadoras, abusivas o difamatorias. , o lenguaje racista, obsceno, ofensivo o de otro tipo que no
                                coincida con las leyes locales y los estándares de la comunidad;
                            </li>
                            <li>
                                Los usuarios pueden interactuar libremente con otros usuarios en el Sitio, o pueden optar por no hacerlo;
                            </li>
                            <li>
                                No publicará ningún mensaje, imagen o grabación ni utilizará el Servicio de ninguna manera que:
                                <ol>
                                    <li>
                                        viola, plagia o infringe los derechos de cualquier tercero, incluidos, entre otros, cualquier
                                        ley de derechos de autor o marca registrada, privacidad u otros derechos personales o de propiedad, o
                                    </li>
                                    <li>
                                        es fraudulento o constituye una conducta ilegal en relación con su uso del Servicio o viola cualquier ley.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                No utilizará el Servicio para distribuir, promover o publicar de otro modo ningún material que contenga una solicitud de fondos,
                                publicidad o solicitud de bienes o servicios sin previa autorizacion de la plataforma;
                            </li>
                            <li>
                                Su acceso al Servicio es para su uso personal. No puede permitir que otros usen el Servicio y no puede transferir cuentas a otros usuarios, las usuarios son totalmente responsables de sus publicaciones;
                            </li>
                            <li>
                                No utilizará el Servicio para infringir ningún derecho de privacidad, derecho de propiedad u otro derecho civil de ninguna persona; y
                            </li>
                            <li>
                                No reenviará ninguna carta en cadena, anuncios, spam o cualquier mensaje comercial de este tipo a través del Servicio.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Promoción del Sitio . Los usuarios registrados del Sitio pueden ser elegibles para participar en nuestro programa de publicidad de afiliados y
                        potencialmente ganar comisiones según la cantidad y la calidad de los usuarios pagos referidos al Sitio. Dicho usuario se denomina en el presente
                        como " Miembro Afiliado ". Para obtener más información, consulte el Apéndice A , que se incorpora al presente por referencia y forma parte de
                        estos Términos.
                    </li>
                    <li>
                        Conducta ilegal y prohibida . Además del "Código de conducta" anterior, las personas que aparecen en el Sitio tienen prohibido hacer lo siguiente:
                        <ol>
                            <li>
                                No puede haber menores de edad, niños, bebés o personas no autorizadas en foto o video o en la misma habitación. En caso de que algún
                                menor de edad aparezca en una foto o video, lo reportaremos de inmediato a la policia y a todas y cada una de las fuerzas del orden público
                                que consideremos apropiadas, a nuestro exclusivo y absoluto criterio.
                            </li>
                            <li>
                                La bestialidad, o animales/mascotas en fotos o videos en un contexto sexual o provocativo, o drogas ilegales (o drogas que pueden ser percibidas
                                como ilegales en otros lugares, por ejemplo, marihuana medicinal), están estrictamente prohibidas.
                            </li>
                            <li>
                                Los juguetes sexuales demasiado grandes o los juguetes sexuales con forma de animal no se pueden usar en fotos o videos, y los objetos
                                no se pueden usar como juguetes sexuales a menos que se comercialicen y vendan típicamente para ese propósito. Envíe un correo electrónico
                                a alekeydeveloper@gmail.com para obtener autorización para incorporar cualquier tipo de dispositivo mecánico, herramienta, "máquina sexual" u otro
                                equipo inusual en su publicación de medios (ya sea controlado por usted o de forma remota por los usuarios del Sitio), proporcionando una
                                propuesta detallada. Es posible que le solicitemos que firme una renuncia y liberación de responsabilidad para usar ciertos dispositivos en el Sitio.
                                Cualquier autorización o permiso que le demos puede ser revocado por nosotros en cualquier momento y por cualquier motivo, sin previo aviso, a nuestra
                                única y absoluta discreción.
                            </li>
                            <li>
                                No se permite el consumo de alcohol.
                            </li>
                            <li>
                                Está estrictamente prohibido actuar en estado de ebriedad, ya sea por drogas (ya sean recetadas, legales o ilegales)
                                o alcohol, nosotros asumimos que al hacer uso de la plataforma esta en totalmente uso de sus facultades.
                            </li>
                            <li>
                                No se permite el incesto (relaciones sexuales que involucran a miembros de la familia).
                            </li>
                            <li>
                                No se permite el diálogo excesivamente degradante o el abuso verbal.
                            </li>
                            <li>
                                No se permite mostrar o hacer referencia a la menstruación.
                            </li>
                            <li>
                                Las escenas de “Bukakke” no están permitidas.
                            </li>
                            <li>
                                Las exhibiciones de "Goatse" están prohibidas
                            </li>
                            <li>
                                Se prohíbe la actividad ilegal o insegura de cualquier tipo, violencia, sangre, tortura, dolor, asfixia erótica,
                                fisting, temas de violación o cualquier acción asociada con causarle daño, de cualquier manera.
                            </li>
                            <li>
                                No puede grabar desde un lugar público o desde un estudio o set que cree la impresión de que es un lugar público.
                            </li>
                            <li>
                                Está prohibido grabar al aire libre a menos que la foto o el video se hagan desde una propiedad privada, con el
                                consentimiento del dueño de la propiedad y en un área que no sea visible desde ninguna propiedad vecina
                            </li>
                            <li>
                                No puede discutir ni concertar servicios de prostitución o de acompañantes, publicamente en la plataforma "CariñosasHub".
                            </li>
                            <li>
                                Se prohíbe cualquier acción que pueda considerarse obscena en su comunidad.
                            </li>
                            <li>
                                Puede intercambiar información con los miembros del Sitio, incluida la información de contacto, pero NO PUEDE utilizar la
                                información de los miembros para proporcionar programas de cámara web o recibir pagos fuera del Sitio. Si vende algo a un
                                miembro, por ejemplo, ropa interior, o realiza cualquier otra transacción miscelánea, la venta debe completarse a cambio de Dinero virtual (definido a continuación).
                            </li>
                            <li>
                                No se le permite anunciar sitios web comerciales que ofrecen transmisiones de cámaras web en vivo, bajo ninguna circunstancia,
                                pero PUEDE mencionar sus propios perfiles personales, páginas de inicio y listas de deseos.
                            </li>
                            <li>
                                No se le permite solicitar la información de la cuenta de los miembros o iniciar sesión con cuentas que no les pertenecen.
                            </li>
                            <li>
                                Tiene prohibido hacer declaraciones, escritas o verbales, o provocar o alentar a otros a hacer declaraciones, escritas o verbales,
                                que difamen, menosprecien o critiquen de alguna manera el Sitio o el Servicio.
                            </li>
                        </ol>
                    </li>
                </ol>
                <p>
                    La lista anterior no es exclusiva y podemos, en cualquier momento, prohibir cualquier actividad que determinemos, a nuestro exclusivo y
                    absoluto criterio, como inapropiada. Nos reservamos el derecho de cancelar o suspender su acceso a todo o parte del Servicio en cualquier
                    momento, con o sin previo aviso, por participar en cualquier actividad inapropiada.
                </p>
                <ol>
                    <li>
                        Privacidad y uso de la información . Salvo que se establezca más detalladamente en nuestra  Política de privacidad , su información
                        personal no se divulgará a ningún tercero.
                    </li>
                    <li>
                        Contenido publicado en el Sitio.
                        <ol>
                            Al aceptar los Términos y condiciones de este Acuerdo, declara y garantiza que todas las imágenes y videos que cargue en el Sitio no infringen de
                            ninguna manera los derechos de propiedad intelectual de terceros. Por la presente, el Sitio afirma la inmunidad con respecto a todo el contenido
                            proporcionado por los miembros u otros terceros, según lo dispuesto por la ley, incluida, entre otras, no dejaremos que los actos atententen contra las buenas constumbres de nuestra sociedad.
                            Los miembros y otras personas tienen prohibido cargar, compartir o de alguna manera compartir o describir a cualquier persona en o a través del
                            Sitio/Servicio cualquier imagen, video o asunto que, en nuestra opinión, pueda ser ilegal u ofensivo, incluidos, entre otros, cualquier contenido
                            que involucre bestialidad, micción, otras excreciones corporales, material difamatorio o material obsceno o cualquier conducta que viole las
                            prohibiciones establecidas en el "Código de Conducta" anterior, o cualquier otra disposición de este Acuerdo. No puede usar el Servicio o el
                            Sitio para solicitar información que pueda usarse con fines ilegales o fomente actividades ilegales.
                        </ol>
                        <ol>
                            No reclamamos ningún derecho de propiedad sobre el texto, los archivos, las imágenes, las fotos, los videos, los sonidos, las obras musicales,
                            las obras de autoría, las aplicaciones o cualquier otro material (colectivamente, los "Materiales") que transmita, envíe, muestre o publicar
                            ("publicar") en, a través o en relación con el Servicio. Después de publicar los Materiales en, a través o en relación con el Servicio, usted
                            continúa conservando los derechos que pueda tener sobre ellos, sujeto a la licencia aquí contenida. Al publicar los Materiales en, a través o
                            en relación con el Servicio, usted otorga al Sitio una licencia no exclusiva, totalmente pagada y libre de regalías, sublicenciable y mundial
                            para usar, modificar, eliminar, agregar a , realizar públicamente, mostrar públicamente, reproducir y distribuir el Material, incluida, entre otras,
                            la distribución parcial o total de los Materiales, en cualquier formato de medios y a través de cualquier canal de medios. Además de la licencia
                            anterior, por la presente nos autoriza a enviar solicitudes de eliminación, de conformidad con la Ley de derechos de autor del milenio digital de diferentes pais,
                            a cualquier proveedor de servicios que aloje reproducciones de los Materiales que se hayan tomado del Sitio ( ej., un videoclip con nuestra marca de agua).
                        </ol>
                        <ol>
                            No puede usar el Sitio o el Servicio con fines comerciales, incluidos, entre otros, marketing, publicidad de bienes o servicios, oportunidades
                            de inversión, concursos o actividades similares. Además, el Sitio se reserva el derecho, a su exclusivo criterio, de suspender inmediatamente
                            su cuenta, solicitar una medida cautelar, presentar una reparación civil y/o informar cualquier conducta que viole estos términos y condiciones
                            a todas y cada una de las fuerzas del orden público que puedan tener jurisdicción sobre el asunto. En caso de que se inicien acciones o
                            procedimientos contra el Sitio como resultado del contenido que ha compartido, o como resultado de su participación en actividades prohibidas,
                            acepta indemnizar y eximir al Sitio de toda responsabilidad con respecto a todos los costos y gastos.
                        </ol>
                    </li>
                    <li>
                        Obligaciones de los miembros según 18 USC § 2257 . Debe tener en cuenta que, de conformidad con la ley federal, cualquier
                        representación visual que publique, comparta o realice en el Sitio que represente una conducta sexualmente explícita real,
                        representaciones de los genitales o el área púbica, o actividad sexualmente explícita simulada, tal como se definen dichos
                        términos en 18 USC §§ 2256(2)(A)(i)-(iv) y 2257A, requiere que usted mantenga los registros requeridos por 18 USC § 2257,
                        y tales publicaciones deben contener un “18 USC § 2257 Mantenimiento de registros Declaración de Cumplimiento de Requisitos.”
                        Su incumplimiento de las disposiciones de 18 USC § 2257 puede hacer que esté sujeto a enjuiciamiento penal y civil
                        por la violación de la ley federal del pais.
                    </li>
                    <li>
                        Uso de la Información en el Servicio. Usted reconoce y acepta que:
                        <ol>
                            <li>
                                No podemos garantizar la seguridad o privacidad de la información que proporciona a través de Internet o de otra manera; nos libera de
                                toda responsabilidad en relación con la violación de la seguridad de dicha información y/o mensajes y con respecto al uso de dicha
                                información por parte de terceros;
                            </li>
                            <li>
                                No somos responsables ni podemos controlar el uso de ninguna información, por parte de nadie, que usted proporcione a otras partes o al Servicio y
                                debe tener cuidado al seleccionar la información personal que proporciona a otros a través del Servicio;
                            </li>
                            <li>
                                No podemos asumir ninguna responsabilidad por el contenido de cualquier mensaje enviado por cualquier usuario en el Servicio, y usted nos
                                libera de toda responsabilidad en relación con el contenido de cualquier comunicación que pueda recibir de otros usuarios;
                            </li>
                            <li>
                                Usted reconoce que no puede emprender acciones legales contra el Sitio o cualquiera de sus empleados, funcionarios o agentes por
                                daños de ningún tipo, bajo ninguna teoría, como consecuencia del uso del Servicio;
                            </li>
                            <li>
                                Todas y cada una de las imágenes y videos cargados en el Servicio y/o el Sitio se convierten en propiedad licenciada del Sitio y
                                pueden ser utilizados por el Sitio, sin ninguna restricción, como materiales de marketing. Al aceptar este Acuerdo y sus Términos
                                y condiciones, nos autoriza específicamente a usar cualquier imagen que cargue en el Sitio/Servicio para comercializar el Sitio y
                                el Servicio a nuestro exclusivo criterio; y
                            </li>
                            <li>
                                No puede usar el Servicio para ningún propósito ilegal. Podemos negarnos a otorgarle o interrumpir su uso de un nombre de usuario,
                                por cualquier motivo, incluido, entre otros, que el nombre de usuario que ha elegido se hace pasar por otra persona, está protegido
                                por una marca comercial o ley de propiedad, o es vulgar o de otra manera. ofensivo, según lo determinemos a nuestro exclusivo criterio.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Interacciones/reuniones dentro y fuera del sitio . El Sitio no recomienda ni aprueba ninguna forma de interacción del usuario
                        fuera del Sitio y, como se indica en otra parte de este acuerdo, su uso e interacciones a través del Sitio son bajo su propio
                        riesgo. El uso del Sitio para organizar reuniones cara a cara con el fin de participar en actividades ilegales está estrictamente
                        prohibido y su cuenta estará sujeta a la cancelación inmediata. Si elige interactuar legalmente con cualquier miembro del Servicio
                        fuera del Sitio, lo hace bajo su propio riesgo y reconoce y acepta que no somos responsables de las consecuencias de dicha elección
                        de interactuar, ya sea en persona o de otra manera, fuera del Sitio.
                    </li>
                </ol>
                <p>
                    Debe, como mínimo, considerar la siguiente precaución si se reúne o mantiene correspondencia con alguien en cualquier sitio web de redes sociales:
                </p>
                <ol>
                    <li>
                        Cualquiera que sea capaz de cometer un robo de identidad también puede falsificar un perfil de miembro.
                    </li>
                    <li>
                        No hay sustituto para actuar con cautela al comunicarse con cualquier extraño que quiera conocerlo.
                    </li>
                    <li>
                        Nunca incluya su apellido, dirección de correo electrónico, domicilio, número de teléfono, lugar de trabajo o cualquier otra información de
                        identificación en su perfil de miembro o mensajes de correo electrónico iniciales. Deje de comunicarse con cualquier persona que lo presione
                        para obtener información personal o financiera o que intente engañarlo de alguna manera para que la revele.
                    </li>
                    <li>
                        Si elige tener una reunión cara a cara con otro miembro, siempre dígale a alguien de su familia o a un amigo a dónde va y cuándo regresará.
                        Nunca acepte que lo recojan en su casa. Proporcione siempre su propio medio de transporte hacia y desde la reunión, que debe ser en un
                        lugar público con mucha gente alrededor.
                    </li>
                    <li>
                        Todo dinero y obsequios enviados por usted a cualquier otro usuario directa o indirectamente, ya sea a través del Sitio o fuera del Sitio,
                        es bajo su propio riesgo. No intervendremos ni nos involucraremos en ninguna disputa entre los usuarios.
                    </li>
                </ol>
                <ol>
                    <li>
                        Sus representaciones y garantías. Al usar el Servicio, usted reconoce, representa y garantiza afirmativamente la veracidad y exactitud de
                        cada una de las siguientes declaraciones:
                    </li>
                    <ol>
                        <li>
                            La ley no le prohíbe usar el Servicio y tiene el derecho, la autoridad y la capacidad para celebrar este Acuerdo y cumplir con todos sus Términos y
                            condiciones tal como se publican aquí y se modifican ocasionalmente.
                        </li>
                        <li>
                            Está familiarizado con las leyes de su área que pueden afectar su derecho legal de acceder a material erótico o para adultos, y tiene el derecho legal
                            de acceder a dicho material y el Sitio tiene el derecho legal de transmitirle dicho material en su ubicación.
                        </li>
                        <li>
                            Usted comprende que, a través del uso del Servicio, estará expuesto a imágenes y videos visuales, descripciones verbales, sonidos de audio y otras
                            características y/o productos de naturaleza francamente erótica y de orientación sexual, que pueden incluir representaciones visuales gráficas y
                            descripciones de desnudez. y actividad sexual, y usted elige hacerlo voluntariamente, porque desea ver, leer y/o escuchar los diversos materiales
                            y/u ordenar y disfrutar del uso de dichos productos o funciones, que están disponibles, para su disfrute personal, información y/o educación;
                        </li>
                        <li>
                            Su elección de usar el Servicio es una manifestación de su interés en los asuntos sexuales que, en su opinión, son tanto saludables como normales y que,
                            según su experiencia, generalmente comparten los adultos promedio de su comunidad.
                        </li>
                        <li>
                            Está familiarizado con los estándares de su comunidad con respecto a la aceptación de tales materiales de orientación sexual, y los materiales que espera
                            encontrar a través del uso del Servicio están dentro de esos estándares;
                        </li>
                        <li>
                            A su juicio, el adulto promedio de su comunidad acepta el consumo de dichos materiales por parte de adultos dispuestos, en circunstancias como aquellas bajo
                            las cuales se proporciona el Servicio, ofreciendo un aislamiento razonable de los materiales para menores y adultos no dispuestos, y no encontrará tales
                            materiales apelar a un interés lascivo o ser manifiestamente ofensivo.
                        </li>
                        <li>
                            Es su deseo compartir y/o invitar a otros a compartir sus propios comportamientos privados y personales y comentar, calificar, criticar, organizar y recomendar en
                            función de lo que está expuesto al utilizar los Servicios, mientras invita a otros a hacer lo mismo.
                        </li>
                        <li>
                            El Sitio brinda acceso a un servicio en línea que comprende información y materiales creados y publicados, cargados o transmitidos por usted y otros usuarios
                            (cada uno un " Contribuidor ").
                        </li>
                        <li>
                            Los videos y las imágenes en el Sitio que están disponibles para su visualización (colectivamente, el " Contenido ") se almacenan o transmiten a través de nuestros
                            servidores bajo la dirección de nuestros usuarios.
                        </li>
                        <li>
                            Cualquier modificación del Contenido que cargan o transmiten nuestros usuarios, como la adición de una marca de agua, se realiza mediante un proceso automatizado.
                            En consecuencia, dado que el Colaborador es consciente de que dichas modificaciones se realizarán automáticamente en el momento de la transmisión, se considerará
                            que el Colaborador es la parte responsable de dicha modificación automática y se considerará el "autor" de dicho Contenido modificado automáticamente. El Sitio no
                            es responsable de las modificaciones que se produzcan en el Contenido como parte de su proceso de transmisión automática.
                        </li>
                        <li>
                            Cualquier revisión del Contenido cargado o transmitido que pueda realizar el Sitio antes o después de poner dicho Contenido a disposición del público es superficial
                            y solo tiene la intención de identificar de inmediato las violaciones obvias de este Acuerdo. En consecuencia, y a pesar de dicho control de acceso, el Contribuidor
                            que carga o transmite cualquier Contenido se considerará la parte bajo cuya dirección ese Contenido está disponible para otros a través del uso del Servicio.
                        </li>
                        <li>
                            El Sitio nunca ha dirigido, y nunca dirigirá, a sus usuarios para que carguen o transmitan Contenido que infrinja cualquier derecho que pertenezca a un tercero. Cargar
                            o transmitir Contenido que infrinja los derechos de terceros constituye una violación directa y material de este Acuerdo y hará que la cuenta del Contribuidor que carga
                            o transmita esté sujeta a suspensión y/o rescisión, según corresponda.
                        </li>
                        <li>
                            El Sitio asume correctamente que el Contribuidor que carga o transmite cualquier Contenido es el único titular de todos los derechos exclusivos de ese Contenido, excepto
                            cuando el Contenido por sí solo tenga alguna indicación obvia de lo contrario, como una marca de propiedad visible que identifique a una persona o entidad que no sea el
                            Contribuyente como titular exclusivo de los derechos.
                        </li>
                        <li>
                            Cuando el Contenido no tenga una marca de propiedad obvia que indique un propietario exclusivo, no se puede considerar que el Sitio tenga conocimiento real de que dicho
                            Contenido infringe los derechos de terceros.
                        </li>
                        <li>
                            El Sitio no tiene derecho ni capacidad para controlar las actividades de los Colaboradores que crean, publican, cargan o transmiten Contenido a través del Sitio. En caso
                            de que un Colaborador infrinja los derechos de un tercero al crear, publicar, cargar o transmitir Contenido, ese Colaborador es el único responsable de dicha infracción y
                            el Sitio no tiene control sobre dicha actividad.
                        </li>
                        <li>
                            Además de identificar una marca de propiedad obvia en cualquier Contenido que indique un propietario exclusivo, el Sitio no tiene otra capacidad para determinar si los derechos
                            correspondientes a una parte particular del Contenido pueden pertenecer a una parte que no sea el Contribuidor de carga o transmisión. Como el único otro medio del Sitio para
                            identificar el Contenido que puede infringir los derechos de un tercero, el Sitio se basa completamente en las notificaciones presentadas correctamente por parte de terceros que
                            afirman que se han violado sus derechos.
                        </li>
                    </ol>
                    <li>
                        Notificación de Infracción de Propiedad Intelectual . El Sitio respeta la propiedad intelectual de otros, y les pedimos a nuestros miembros y a otros
                        que hagan lo mismo. Observamos y cumplimos voluntariamente con la DMCA. ES POLÍTICA DE "CariñosasHub" TERMINAR LAS CUENTAS DE LOS INFRACTORES REPETITIVOS DE
                        LOS DERECHOS DE AUTOR, CUANDO CORRESPONDA. Si cree que su trabajo ha sido copiado mediante el uso del Servicio de una manera que constituye una
                        infracción de derechos de autor, o sus derechos de propiedad intelectual han sido violados de otra manera, envíenos más información a alekeydeveloper@gmail.com:
                        <ol>
                            <li>
                                una firma electrónica o física de la persona autorizada para actuar en nombre del propietario de los derechos de autor u otros derechos de propiedad intelectual;
                            </li>
                            <li>
                                descripción del trabajo protegido por derechos de autor u otra propiedad intelectual que afirma que se ha infringido;
                            </li>
                            <li>
                                una descripción de en qué parte del Servicio se encuentra el material que usted afirma que está infringiendo;
                            </li>
                            <li>
                                su dirección, número de teléfono y dirección de correo electrónico;
                            </li>
                            <li>
                                una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley; y
                            </li>
                            <li>
                                una declaración suya, hecha bajo pena de perjurio, de que la información anterior en su Aviso es precisa y que usted es el propietario de los derechos de
                                autor o de la propiedad intelectual o está autorizado para actuar en nombre del propietario de los derechos de autor o de la propiedad intelectual.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Dinero virtual . El Servicio puede, pero no está obligado a, incluir una moneda virtual en la aplicación (" Dinero virtual”) incluidos, entre otros, usuarios, monedas,
                        dinero en efectivo, fichas o puntos, que se pueden comprar con dinero del “mundo real” si es un adulto legal en su país de residencia. Aparte de una licencia limitada,
                        personal, revocable, intransferible y no sublicenciable para usar el Dinero virtual en el Servicio, usted no tiene ningún derecho o título sobre dicho Dinero virtual
                        que aparece o se origina en el Servicio, o cualquier otro atributo asociado con el uso del Servicio o almacenado dentro del Servicio. Tenemos el derecho absoluto de
                        administrar, regular, controlar, modificar y/o eliminar dicho Dinero virtual según lo creamos conveniente a nuestro exclusivo criterio, y no seremos responsables ante
                        usted ni ante nadie por el ejercicio de dichos derechos. Las transferencias de dinero virtual están estrictamente prohibidas, excepto cuando se autorice explícitamente
                        dentro del Servicio. Salvo que se indique expresamente lo contrario en el presente, usted no puede vender dinero virtual por dinero del "mundo real" ni intercambiar
                        dichos artículos por valor. Cualquier intento de hacerlo es una violación de este Acuerdo y puede resultar en una prohibición de por vida del Sitio y una posible acción
                        legal. Todo el dinero virtual que no haya sido comprado directamente por usted (p. ej., obsequios de otros usuarios, comisiones por recomendación, etc.) se perderá si
                        su cuenta se cancela o suspende por cualquier motivo, a nuestro exclusivo y absoluto criterio, o si dejamos de proporcionar el servicio.
                    </li>
                    <li>
                        Propinas _ El Sitio puede, pero no está obligado a permitir la propina de los Colaboradores verificados por edad a través del Servicio. En la medida
                        en que decidamos permitir las propinas, incluidos, entre otros, «regalos», usted reconoce y acepta que:
                        <ol>
                            <li>
                                Las propinas se realizan bajo su propia opción y riesgo. No se requiere propina para el uso del Servicio.
                            </li>
                            <li>
                                Las propinas solo se pueden realizar con dinero virtual. Los contribuyentes no pueden solicitar propinas a través de medios de pago que no sean dinero virtual.
                            </li>
                            <li>
                                Las propinas son una gratuidad voluntaria y no se pueden dar a cambio de servicios específicos. Prometer dar una propina a cambio de la realización de cualquier
                                acto está estrictamente prohibido, y dicha conducta resultará en una prohibición inmediata y de por vida del uso del Servicio.
                            </li>
                            <li>
                                Todas las propinas son cargables cuando se hacen. No devolveremos una propina hecha desde su cuenta, excepto en situaciones que consideremos, a nuestro exclusivo
                                y absoluto criterio, como extraordinarias.
                            </li>
                            <li>
                                Las propinas no alteran nuestro código de conducta. Está prohibido dar o recibir propinas a cambio de una conducta real o prometida en violación de este Acuerdo.
                            </li>
                            <li>
                                Los usuarios exhibicionistas nunca son elegibles para recibir propinas.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Compras de fotos y videos . El Sitio puede, pero no está obligado a, permitir que los usuarios publiquen Materiales (" Contenido pagado”) al que solo se
                        puede acceder después del pago de una cantidad específica de dinero virtual. Si publica cualquier Contenido de pago, declara y garantiza que (i) el Contenido
                        de pago que publique cumplirá en todos los aspectos con los términos de este Acuerdo; y (ii) tiene todos los derechos y permisos necesarios para publicar dicho
                        Contenido pagado y permitir que los usuarios accedan al mismo a cambio de un pago. Tendremos el derecho absoluto de eliminar cualquier Contenido pagado, en su
                        totalidad o en parte, por cualquier motivo o sin él. En el caso de que el Contenido de pago que publique resulte en devoluciones de cargo o solicitudes de reembolso
                        de los usuarios que compraron dicho Contenido de pago, nos reservamos el derecho de aplicar una tarifa de devolución de cargo a su cuenta y/o suspender su capacidad
                        para publicar Contenido de pago. Al comprar o acceder a cualquier Contenido pago, por lo tanto, demuestra su reconocimiento y acuerdo expresos de que (i) el Sitio
                        no es la fuente de dicho Contenido pagado; (ii) el usuario que publica dicho Contenido de pago es el único responsable de cualquier reclamación o responsabilidad
                        asociada con, que surja de, o que se relacione de algún modo con dicho Contenido de pago; (iii) su compra y/o uso de cualquier Contenido pagado es bajo su propio
                        riesgo; (iv) El Sitio no tiene la responsabilidad de ver o proyectar ningún Contenido pagado; y (v) usted libera para siempre al Sitio, y sus afiliados, sucesores,
                        cesionarios, funcionarios, empleados, agentes, directores, accionistas y abogados, de todos y cada uno de los reclamos y responsabilidades asociados con, que surjan
                        de, o de alguna manera relacionados con dicho Contenido pagado. (ii) el usuario que publica dicho Contenido de pago es el único responsable de cualquier reclamación
                        o responsabilidad asociada con, que surja de, o que se relacione de algún modo con dicho Contenido de pago; (iii) su compra y/o uso de cualquier Contenido pagado es
                        bajo su propio riesgo; (iv) El Sitio no tiene la responsabilidad de ver o proyectar ningún Contenido pagado; y (v) usted libera para siempre al Sitio, y sus afiliados,
                        sucesores, cesionarios, funcionarios, empleados, agentes, directores, accionistas y abogados, de todos y cada uno de los reclamos y responsabilidades asociados con, que
                        surjan de, o de alguna manera relacionados con dicho Contenido pagado. (ii) el usuario que publica dicho Contenido de pago es el único responsable de cualquier reclamación
                        o responsabilidad asociada con, que surja de, o que se relacione de algún modo con dicho Contenido de pago; (iii) su compra y/o uso de cualquier Contenido pagado es bajo
                        su propio riesgo; (iv) El Sitio no tiene la responsabilidad de ver o proyectar ningún Contenido pagado; y (v) usted libera para siempre al Sitio, y sus afiliados, sucesores,
                        cesionarios, funcionarios, empleados, agentes, directores, accionistas y abogados, de todos y cada uno de los reclamos y responsabilidades asociados con, que surjan de, o de
                        alguna manera relacionados con dicho Contenido pagado.
                    </li>
                    <li>
                        Retos _ De vez en cuando, podemos ofrecerle la oportunidad de participar en desafíos que se administrarán a través del Sitio y el Servicio. Si bien las reglas generales
                        que se aplican a todos los desafíos se presentan a continuación, los detalles particulares de cualquier desafío que se esté ejecutando actualmente se pueden encontrar
                        en la página de desafíos.
                        <ol>
                            <li>
                                Entrada y elegibilidad . A menos que se indique lo contrario en las reglas específicas de un desafío en particular, la entrada será automática para cualquier
                                usuario del Sitio. Todos los usuarios del Sitio registrados serán elegibles para participar en cada desafío, siempre que hayan cumplido con estos Términos y
                                que dicho desafío no esté prohibido en su jurisdicción de origen. Todos los desafíos serán nulos donde estén prohibidos.
                                Puede excluirse expresamente de cualquier desafío enviando un correo electrónico a  alekeydeveloper@gmail.com .
                            </li>
                            <li>
                                Períodos de desafío . Cada desafío tendrá una fecha de inicio publicada. Las fechas de finalización pueden proporcionarse tan pronto como sea posible después
                                de la determinación, pero no serán obligatorias. Cada desafío se ejecutará por un período de tiempo que comienza en la fecha de inicio publicada y finaliza en
                                la fecha de finalización publicada, si corresponde.
                            </li>
                            <li>
                                Conducta. Al participar en cualquier desafío realizado por el Sitio, usted demuestra que acepta cumplir y estar sujeto a nuestras decisiones, que son definitivas y
                                vinculantes en todos los aspectos. Nos reservamos el derecho, a nuestro exclusivo criterio, de descalificar a cualquier participante en cualquier desafío que
                                encontremos: (i) manipulando o intentando manipular el proceso de participación o la operación de un desafío; (ii) violar estos Términos o cualquier regla de desafío;
                                o (iii) actuando de manera antideportiva o disruptiva, o con la intención de molestar, abusar, amenazar o acosar a cualquier otra persona. Cualquier información falsa
                                proporcionada en el contexto del desafío por cualquier participante en relación con la identidad, la dirección postal o el incumplimiento de las reglas del desafío puede
                                resultar en la descalificación inmediata de un participante de cualquier concurso.
                            </li>
                            <li>
                                premios _ Las bases de cada concurso establecerán los premios que se otorgarán. En el caso de que se ofrezcan múltiples premios para un desafío en particular, pero no haya
                                un número suficiente de participantes elegibles para ese desafío, no se otorgarán todos los premios. Si un ganador no puede por cualquier motivo aceptar su premio,
                                entonces nos reservamos el derecho de no otorgar el premio o de otorgarlo a otro participante.
                            </li>
                            <li>
                                Sin recurso a procedimientos judiciales o de otro tipo . En la medida en que lo permita la ley, se excluyen los derechos de litigar, solicitar medidas cautelares o cualquier
                                otro recurso judicial o cualquier otro procedimiento en caso de disputas o reclamos que resulten de o estén relacionados con cualquier impugnación, y usted renuncia
                                expresamente todos y cada uno de esos derechos.
                            </li>
                            <li>
                                Condiciones Generales . Todos los desafíos se rigen por las leyes de Inglaterra. Aplican todas las leyes y regulaciones federales, estatales y locales. Al participar en
                                cualquier desafío, acepta estar sujeto a las reglas del desafío y a nuestras decisiones, que son definitivas y vinculantes en todos los asuntos relacionados con ese
                                concurso. No somos responsables de ningún error tipográfico o de otro tipo en la administración de cualquier desafío o en el anuncio de cualquier premio.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Seguimiento de la Información. Nos reservamos el derecho, pero no tenemos la obligación, de monitorear todos y cada uno de los mensajes y chats que tienen lugar a través del
                        Sitio. No somos responsables de ningún material ofensivo u obsceno que pueda ser transmitido de alguna manera por cualquiera y todos los usuarios
                        (incluidos los usuarios no autorizados, así como la posibilidad de "hackers"). Como se señaló anteriormente, tampoco somos responsables, bajo ninguna circunstancia,
                        por el uso de cualquier información personal, por parte de cualquier persona, que usted transmita de alguna manera a través del Servicio.
                    </li>
                    <li>
                        Terminación del Acceso al Servicio . Podemos, a nuestro exclusivo criterio, rescindir o suspender su acceso a todo o parte del Servicio en cualquier momento, con o sin
                        previo aviso, por cualquier motivo, incluido, entre otros, el incumplimiento de este Acuerdo, o sin motivo alguno. Sin limitar la generalidad de lo anterior, cualquier
                        actividad fraudulenta, abusiva o ilegal de otro modo puede ser motivo para cancelar su acceso a todo o parte del Servicio a nuestro exclusivo criterio, y nos reservamos
                        el derecho de referir dicha actividad a todos y cada uno. organismos encargados de hacer cumplir la ley correspondientes.
                    </li>
                    <li>
                        Información de propiedad . El Servicio contiene información que es propiedad nuestra y/o de los usuarios del Servicio. Afirmamos la protección total de los derechos de
                        autor en el Servicio, incluido todo el diseño y el código incorporado en el mismo. Cualquier información compartida o publicada por nosotros o los usuarios del Servicio
                        puede estar protegida, ya sea que se identifique como propiedad nuestra o del usuario. Usted acepta no modificar, copiar o distribuir dicha información de ninguna manera
                        sin haber recibido primero el permiso expreso del propietario de dicha información.
                    </li>
                    <li>
                        Sin responsabilidad. No somos responsables de ningún daño incidental, consecuente, especial, punitivo, ejemplar, directo o indirecto de ningún tipo, que pueda surgir de o
                        estar relacionado con su uso del Servicio, incluidos, entre otros, la pérdida de ingresos, ganancias, negocios o datos, o daños resultantes de virus, gusanos,
                        "caballos de Troya" u otro software o materiales destructivos, o comunicaciones realizadas por usted u otros usuarios del Servicio, o cualquier interrupción o suspensión
                        del Servicio, independientemente de la causa de la interrupción o suspensión. Cualquier reclamo contra nosotros se limitará a la cantidad que pagó, si la hubiere, por el
                        uso del Servicio durante los doce (4) meses anteriores. Podemos suspender o cambiar el Servicio o su disponibilidad para usted en cualquier momento, y usted puede dejar
                        de usar el Servicio en cualquier momento, consulte los detalles sobre la cancelación a continuación.
                    </li>
                    <li>
                        seguridad _ Su cuenta es privada y no debe ser utilizada por nadie más. Usted es responsable de todo uso o actividad en el Servicio por parte de los usuarios que utilizan su
                        nombre de usuario y contraseña, incluido, entre otros, el uso de su nombre de usuario y contraseña por parte de un tercero.
                    </li>
                    <li>
                        Otros enlaces . De vez en cuando, el Servicio puede contener enlaces a otros sitios y recursos (" Enlaces externos "). No somos responsables ni asumimos responsabilidad alguna
                        como resultado de la disponibilidad de enlaces externos o sus contenidos.
                    </li>
                    <li>
                        Sin garantías. El Servicio se distribuye "tal cual" y "según disponibilidad". No garantizamos que este Servicio sea ininterrumpido o libre de errores. Puede haber retrasos,
                        omisiones e interrupciones en la disponibilidad del Servicio. DONDE LO PERMITA LA LEY, USTED RECONOCE QUE EL SERVICIO SE PROPORCIONA SIN NINGUNA GARANTÍA DE NINGÚN TIPO,
                        YA SEA EXPLÍCITA O IMPLÍCITA, INCLUYENDO, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD O IDONEIDAD PARA UN FIN EN PARTICULAR; EL SITIO TAMPOCO OFRECE NINGUNA
                        GARANTÍA EN CUANTO A LOS RESULTADOS QUE PUEDAN OBTENERSE DEL USO DE LOS SERVICIOS O EN CUANTO A LA EXACTITUD O CONFIABILIDAD DE CUALQUIER INFORMACIÓN OBTENIDA A TRAVÉS DE
                        LOS SERVICIOS O QUE LOS DEFECTOS EN CUALQUIER SOFTWARE, HARDWARE O LOS SERVICIOS SERÁN CORREGIDOS. USTED ENTIENDE Y ACEPTA QUE CUALQUIER USO QUE HAGA DE CUALQUIER MATERIAL
                        Y/O DATOS DESCARGADOS U OBTENIDOS DE OTRO MODO MEDIANTE EL USO DEL SERVICIO ES BAJO SU PROPIA DISCRECIÓN Y RIESGO, Y QUE USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO A
                        SU SISTEMA INFORMÁTICO O PÉRDIDA DE DATOS RESULTANTES DE LA DESCARGA DE DICHO MATERIAL Y/O DATOS. No representamos ni respaldamos la precisión o confiabilidad de ningún consejo,
                        opinión, declaración u otra información mostrada, cargada o distribuida a través del Servicio por el Sitio o cualquier usuario del Servicio o cualquier otra persona o entidad.
                        Usted reconoce que cualquier confianza en dicha opinión, consejo, declaración o información será bajo su propio riesgo. Y QUE USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO
                        A SU SISTEMA INFORMÁTICO O PÉRDIDA DE DATOS QUE RESULTE DE LA DESCARGA DE DICHO MATERIAL Y/O DATOS. No representamos ni respaldamos la precisión o confiabilidad de ningún
                        consejo, opinión, declaración u otra información mostrada, cargada o distribuida a través del Servicio por el Sitio o cualquier usuario del Servicio o cualquier otra persona
                        o entidad. Usted reconoce que cualquier confianza en dicha opinión, consejo, declaración o información será bajo su propio riesgo. Y QUE USTED SERÁ EL ÚNICO RESPONSABLE DE
                        CUALQUIER DAÑO A SU SISTEMA INFORMÁTICO O PÉRDIDA DE DATOS QUE RESULTE DE LA DESCARGA DE DICHO MATERIAL Y/O DATOS. No representamos ni respaldamos la precisión o confiabilidad
                        de ningún consejo, opinión, declaración u otra información mostrada, cargada o distribuida a través del Servicio por el Sitio o cualquier usuario del Servicio o cualquier otra
                        persona o entidad. Usted reconoce que cualquier confianza en dicha opinión, consejo, declaración o información será bajo su propio riesgo.
                    </li>
                    <li>
                        Modificaciones . Podemos modificar este Acuerdo de vez en cuando. La notificación de los cambios en este Acuerdo se publicará en el Servicio o se enviará por correo electrónico,
                        según lo determinemos a nuestro exclusivo criterio. Si no está de acuerdo con alguna modificación, debe cancelar su uso del Servicio. Su uso continuo del Servicio ahora, o
                        después de la publicación de un aviso de cualquier cambio en este Acuerdo, constituirá una aceptación vinculante por su parte de este Acuerdo.
                    </li>
                    <li>
                        Divulgación y otras comunicaciones . Nos reservamos el derecho de enviarle un correo electrónico con el fin de informarle sobre cambios o adiciones al Servicio, o sobre
                        cualquier producto y servicio relacionado ofrecido por el Sitio o sus entidades afiliadas. Nos reservamos el derecho de divulgar información sobre su uso del Servicio y
                        datos demográficos en formularios que no revelen su identidad personal. Para obtener una descripción más detallada de la información que podemos divulgar, consulte nuestra
                        Política de privacidad , que se incorpora en su totalidad al presente mediante esta referencia.
                    </li>
                    <li>
                        Quejas _ Para resolver o informar una queja sobre el Servicio o los miembros que utilizan el Servicio, los usuarios deben enviar un correo electrónico detallando dicha queja a
                        support@uplust.com . En las circunstancias apropiadas, tomaremos medidas inmediatas para ayudar a resolver el problema.
                    </li>
                    <li>
                        registro _ Puede convertirse en miembro del Servicio completando un formulario de registro en línea, que debe ser aceptado por el Sitio. Al enviar el formulario de registro en línea,
                        el Sitio o su agente autorizado procesará la solicitud. En relación con completar el formulario de registro en línea, usted acepta:
                        <ol>
                            <li>
                                proporcionar información verdadera, precisa, actual y completa sobre usted según lo solicite el formulario de registro (dicha información es los " Datos de registro "); y
                            </li>
                            <li>
                                mantener y actualizar de inmediato los Datos de registro para mantenerlos verdaderos, precisos, actualizados y completos en todo momento mientras sea miembro.
                            </li>
                        </ol>
                    </li>
                </ol>
                <p>
                    Debe informar de inmediato al Sitio de todos los cambios en los Datos de registro, incluidos, entre otros, cambios en su dirección y cambios en la información de la
                    tarjeta de crédito que utilizó en relación con la facturación del Servicio. Si proporciona información falsa, inexacta, desactualizada o incompleta, o si el Sitio
                    o cualquiera de sus agentes autorizados tiene motivos razonables para sospechar que dicha información es falsa, inexacta, desactualizada o incompleta, el Sitio tiene
                    derecho a suspender o cancelar su cuenta y rechazar su uso actual o futuro del Servicio y el Sitio, así como posiblemente someterlo a responsabilidad penal y civil.
                    Usted es responsable de los pagos rechazados y de cualquier tarifa relacionada en la que incurramos con respecto a su cuenta.
                </p>
                <ul>
                    <li>
                        Cuenta de miembro y contraseña. Como parte del proceso de registro, se le otorgará un nombre de usuario y una contraseña únicos, que deberá proporcionar para poder
                        acceder a las partes no públicas del Servicio. Usted certifica que, cuando se le solicite que elija un nombre de usuario, no elegirá un nombre que lo represente
                        falsamente como otra persona, o un nombre que de otro modo pueda violar los derechos de un tercero. Nos reservamos el derecho de prohibir el uso de nombres de usuario
                        que, a nuestro exclusivo criterio, consideremos inapropiados. Nos reservamos el derecho de cancelar, en cualquier momento, la membresía de cualquier miembro que use su
                        nombre de usuario seleccionado en violación de estos Términos y Condiciones o de cualquier otra manera que, a nuestro exclusivo criterio, consideremos inapropiada. Su
                        membresía, el nombre de usuario y la contraseña son intransferibles y no asignables. Usted declara y garantiza que no revelará a ninguna otra persona su nombre de usuario
                        o contraseña únicos y que no proporcionará acceso al Servicio a ninguna persona menor de edad, o que no desee ver el contenido en el sitio web. Sitio. Usted es el único
                        responsable de mantener la confidencialidad de su nombre de usuario y contraseña y es totalmente responsable de todas las actividades que se realicen con su nombre de
                        usuario y contraseña. No divulgaremos su contraseña por razones de seguridad. Usted acepta (a) notificar de inmediato al Sitio sobre cualquier uso no autorizado de su
                        nombre de usuario o contraseña o cualquier otra violación de la seguridad, y (b) asegurarse de salir de su cuenta al final de cada sesión. Usted acepta que es el único
                        responsable de cualquier uso no autorizado del Servicio utilizando su cuenta hasta que notifique al Sitio por correo electrónico sobre dicho uso no autorizado. El acceso
                        no autorizado al Servicio es ilegal y constituye un incumplimiento de este Acuerdo. Usted acepta indemnizar al Sitio con respecto a todas las actividades realizadas a través
                        de su cuenta. Puede obtener acceso a sus registros de facturación si lo solicita razonablemente.
                        <p>
                            facturación _ STRACEO puede aparecer en la tarjeta de crédito, extracto bancario o factura telefónica del Suscriptor para todos los cargos aplicables. Sus compras en "CariñosasHub"
                            están sujetas a los Términos y condiciones de Straceo .
                        </p>
                        <ol>
                            <li>
                                Errores de facturación . Si cree que se le ha facturado erróneamente, notifíquenos inmediatamente dicho error. Si no recibimos noticias suyas dentro de los treinta (30)
                                días posteriores a la aparición de dicho error de facturación en cualquier estado de cuenta, dicha tarifa se considerará aceptable para usted a todos los efectos, incluida
                                la resolución de consultas realizadas por el emisor de su tarjeta de crédito. Usted nos libera de todas las responsabilidades y reclamos de pérdida que resulten de cualquier
                                error o discrepancia que no se nos informe dentro de los treinta (30) días posteriores a su publicación.
                            </li>
                            <li>
                                suscripción _ El Sitio puede tener tarifas de suscripción periódicas en el momento de la inscripción inicial para la suscripción. El miembro es responsable de dichas tarifas
                                de acuerdo con los términos y condiciones de dicho Sitio.
                            </li>
                            <li>
                                <ol>
                                    <li>
                                        Facturación recurrente automática. De acuerdo con los términos y condiciones del Sitio, las tarifas de suscripción pueden renovarse automáticamente al final del plazo original
                                        seleccionado o después, por un período de tiempo similar y por un monto similar o diferente, a menos que se reciba un aviso de cancelación del Suscriptor. A menos y hasta que
                                        este acuerdo se cancele de acuerdo con los términos del presente, el Suscriptor autoriza a "CariñosasHub" a cargar el método de pago elegido por el suscriptor para pagar el costo continuo
                                        de la membresía. El suscriptor autoriza además a "CariñosasHub" a cobrar el método de pago elegido por el suscriptor por todas y cada una de las compras adicionales de materiales
                                        proporcionados en el sitio.
                                    </li>
                                    <li>
                                        Cancelación . En cualquier momento, y sin causa, la suscripción al servicio podrá ser rescindida por el Suscriptor previa notificación al otro por correo electrónico o convencional,
                                        por chat, o por teléfono . Los suscriptores son responsables de los cargos incurridos hasta la fecha de terminación.
                                    </li>
                                    <li>
                                        Reembolsos _ Se pueden solicitar reembolsos por compras o cargos recurrentes poniéndose en contacto con el servicio de atención al cliente. No se emitirán reembolsos ni créditos por
                                        Membresías utilizadas parcialmente. Se puede solicitar la cancelación de toda la facturación recurrente futura. Nos reservamos el derecho de otorgar un reembolso o un crédito aplicable
                                        a las compras en el Sitio a su discreción. La decisión de devolución de un cargo no implica la obligación de emitir devoluciones futuras adicionales.
                                    </li>
                                </ol>
                            </li>

                        </ol>
                        <p>
                            Cancelación por parte del usuario . Puede cancelar su membresía en cualquier momento visitando  nuestra página de cancelación . Por la presente, acepta ser personalmente
                            responsable de todos y cada uno de los cargos incurridos por su nombre de usuario y contraseña hasta que finalice su membresía según lo dispuesto en este documento.
                            En caso de que cancele su cuenta, se pueden otorgar reembolsos por dinero virtual que usted compró directamente; no se le acreditarán fondos ni se podrán convertir en
                            efectivo u otra forma de reembolso a menos que usted haya pagado esos fondos al comprar Dinero Virtual. Una vez que procesemos su solicitud para cancelar su membresía,
                            ya no tendrá acceso a las áreas no públicas del Servicio.
                        </p>
                        <ol>
                            <li>
                                Terminación por el sitio. Sin limitar otros recursos, el Servicio puede emitir inmediatamente una advertencia, suspender temporalmente, suspender indefinidamente o
                                cancelar su acceso y uso del Servicio y negarse a brindarle nuestros servicios en cualquier momento, con o sin previo aviso, si: (a ) el Sitio cree que ha incumplido
                                cualquiera de estos Términos y Condiciones; (b) no podemos verificar o autenticar la información que nos proporciona; (c) creemos que sus acciones pueden causar
                                responsabilidad legal para usted, nuestros usuarios o nosotros; o (d) el Sitio decide cesar sus operaciones o discontinuar cualquier parte del Sitio o partes del mismo.
                                Además, acepta que ni el Sitio ni ningún tercero que actúe en nuestro nombre será responsable ante usted por la terminación de su membresía o acceso al Servicio. Usted
                                acepta que si el Sitio cancela su cuenta,
                            </li>
                            <li>
                                Después de la Terminación o Cancelación. Usted acepta que cuando cancela su membresía con el Servicio, será automáticamente eliminado y bloqueado del Servicio. No podrá
                                acceder a su cuenta en el Servicio. También acepta que al cancelar su cuenta, cualquier correo y todos los demás materiales de membresía se eliminarán inmediatamente
                                del Sitio y el Servicio y que dicha información será irrecuperable.
                            </li>
                            <li>
                                Indemnización. Usted acepta defender, indemnizar, defender y eximir de responsabilidad al Sitio y a sus afiliados, sucesores, cesionarios, funcionarios, empleados,
                                agentes, directores, accionistas y abogados frente a cualquier reclamo y responsabilidad, incluidos los de abogados y expertos razonables. ' tarifas, relacionadas
                                con o que surjan de: (i) cualquier incumplimiento por su parte de este Acuerdo; (ii) su uso (o mal uso) del Servicio, Sitio y/o Materiales Promocionales; (iii)
                                todas las conductas y actividades que ocurran usando su cuenta y/o Sitios de referencia, si corresponde; (iv) cualquier artículo o servicio vendido o anunciado en
                                relación con sus Sitios de referencia, si corresponde; (v) cualquier material difamatorio, calumnioso o ilegal contenido en su Contenido o su información y datos;
                                (vi) cualquier reclamo o afirmación de que cualquiera de sus Sitios de referencia, si los hay, contienen información, datos u otros materiales que infrinjan la patente,
                                los derechos de autor, la marca registrada u otros derechos de propiedad intelectual de terceros o violen los derechos de privacidad o publicidad de terceros; (vii)
                                acceso o uso de terceros de cualquier Material promocional que se le proporcione; (viii) cualquier reclamación relacionada con su(s) sitio(s) web; (ix) cualquier costo
                                incurrido en su nombre como resultado de su incumplimiento de la legislación local o inglesa; y/o (x) cualquier violación de este Acuerdo. Nos reservamos el derecho,
                                a nuestra costa, de participar en la defensa de cualquier asunto que de otro modo esté sujeto a indemnización por su parte, pero no tendremos ninguna obligación de hacerlo,
                                y este Acuerdo nos permite solicitar su indemnización posteriormente. No resolverá ningún reclamo o responsabilidad de este tipo sin el consentimiento previo por escrito
                                del Sitio. Usted comprende que tomaremos todas y cada una de las medidas para protegernos de cualquier litigio legal o civil, incluido, entre otros, la cancelación de su
                                cuenta, a nuestro exclusivo criterio. También comprende que cobraremos, por hora, por todo el tiempo dedicado a responder a quejas, disputas, reclamos de derechos de autor
                                o acciones de terceros que lo involucren a usted o a sus Sitios de referencia.
                            </li>
                            <li>
                                Divisibilidad . Si algún término, cláusula o disposición del presente es declarado inválido o inaplicable por un tribunal de jurisdicción competente, dicha invalidez no
                                afectará la validez o el funcionamiento de cualquier otro término, cláusula o disposición y dicho término, cláusula o disposición inválida se considerará separado de
                                este Acuerdo.
                            </li>
                            <li>
                                Arbitraje _ Cualquier disputa relacionada de alguna manera con su visita a Uplust.com se someterá a arbitraje confidencial en Inglaterra excepto que, en la medida en que
                                usted haya violado o amenazado con violar de alguna manera nuestros derechos de propiedad intelectual, podemos buscar medidas cautelares, equitativas o otro recurso
                                apropiado en cualquier tribunal estatal o federal y el Usuario acepta la jurisdicción exclusiva y el lugar en los tribunales ingleses.
                            </li>
                        </ol>
                    </li>
                </ul>
                <p>
                    Usted acepta que cualquier Disputa entre Usted y el Sitio se resolverá exclusiva y finalmente mediante arbitraje administrado o por un árbitro que será designado por el poder judiacil o
                    entidad pertinente, utilizando interpretaciones conforme a la ley inglesa y conducido de conformidad con sus reglas. , excepto que se
                    indique lo contrario a continuación. El arbitraje se limitará únicamente a la Disputa entre Usted y el Sitio.
                </p>
                <p>
                    El árbitro puede otorgar costos y gastos razonables, incluidos los honorarios de los abogados, en los que se haya incurrido al suspender o desestimar tales otros procedimientos o
                    al exigir el cumplimiento de la disposición de arbitraje. El laudo del árbitro, incluidos los honorarios de los abogados, será vinculante y
                    podrá presentarse como sentencia en cualquier tribunal de jurisdicción competente.
                </p>
                <p>
                    En la máxima medida permitida por la ley aplicable, ningún arbitraje bajo estos Términos y condiciones o la Política de privacidad se unirá a un arbitraje que involucre a
                    cualquier otra parte sujeta a estos Términos y condiciones o la Política de privacidad, ya sea a través de procedimientos de arbitraje colectivo o de otro modo. Usted
                    comprende que, en ausencia de esta disposición, habría tenido derecho a litigar disputas a través de un tribunal, incluido el derecho a litigar reclamos a nivel de clase
                    o acción colectiva, y que ha renunciado expresamente y con conocimiento de causa a esos derechos. y acordó resolver cualquier Disputa mediante arbitraje vinculante de
                    conformidad con las disposiciones de este párrafo.
                </p>
                <p>
                    A los efectos de esta disposición, el término "Disputa" significa cualquier disputa, controversia o reclamo que surja de o se relacione con: (i) estos Términos y condiciones
                    o la Política de privacidad, su interpretación o el incumplimiento, terminación, aplicabilidad o validez. del mismo; (ii) el pedido relacionado,
                    la compra, la entrega, la recepción o el uso de cualquier producto o servicio del Sitio; o iii) cualquier otra disputa que surja o se relacione con
                    la relación entre usted y el Sitio.
                </p>
                <p>
                    Usted acepta que, independientemente de cualquier estatuto o ley que establezca lo contrario, cualquier reclamo o causa de acción que surja o esté relacionado con el uso del
                    sitio web, los Términos y condiciones o la Política de privacidad debe presentarse dentro de UN MES después de que surgiera dicho reclamo o causa de acción.
                    o ser excluido para siempre.

                </p>
            </div>
        </div>
    )
}

export default Terminos