import React, { useState } from 'react'
import ScrollToTop from '../utils/ScrollToTop';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { Helmet } from 'react-helmet-async';

function Publicaranuncio() {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const tituloDinamico = "Kinesiologas en Perú - CARIÑOSASHUB";

    return (
        <div className='container_publicar_anuncio'>

            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="Anuncios de Kinesiologas en Peru, Putas en Lima, Kines Peru, Escorts maduras y chicas, Peruanas y Extranjeras Venezolanas, Colombianas ofrecen sexo en Lima."
                ></meta>

                <meta name="keywords"
                    content="Kinesiólogas, Kinesiólogas en perú, kines en perú, putas en perú, sexo en perú, escorts en perú, Prostitutas en perú, Putas peruanas, putas extranjeras en perú, kines Perú. Kines, maduras en perú, jovencitas en perú, kines lima perú. kines en lince, KINESIOLOGAS LINCE, KINES LOS OLIVOS, KINESIOLOGAS LOS OLIVOS, PUTAS LINCE, KINESIOLOGAS PUENTE PIEDRA, KINES MIRAFLORES."></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/" />

            </Helmet>

            <ScrollToTop />
            <div class="wrap">

                <div class="pricing-wrap">

                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one basico">
                                <div class="pricing-table-head">
                                    <h2>GRATIS</h2>
                                    <h3><sup>S/. </sup>0<sub>15/Dias</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>2 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>0 </span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>No Accesero Directo </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>No Nostrar </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span>.......... </span><span style={{ color: 'red' }}> .......... </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span>......... </span><span style={{ color: 'red' }}> ........ </span></li>
                                </ul>
                                <Link to={"/register"} class="pricing-table-button">
                                    Seleccionar plan
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one">
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>10<sub>/7 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>15<sub>/15 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head estandar-title">
                                    <h2>ESTANDAR</h2>
                                    <h3><sup>S/. </sup>35<sub>/MES</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>6 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>1 </span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>Se Mostrara una </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span> Directorio  </span><span style={{ color: 'red' }}> de anuncios </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span> 3 subidas por dia </span><span style={{ color: 'red' }}> ........ </span></li>
                                </ul>
                                <a onClick={handleShow}
                                    class="pricing-table-button estandar">
                                    Seleccionar plan
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="pricing-table">
                        <div class="pricing-table-month">
                            <div class="pricing-table-month-one">
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>15<sub>/7 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>20<sub>/15 dias</sub></h3>
                                </div>
                                <div class="pricing-table-head">
                                    <h2>Vip</h2>
                                    <h3><sup>S/. </sup>45<sub>/MES</sub></h3>
                                </div>
                            </div>

                            <div class="pricing-table-month-two">
                                <ul class="pricing-table-list">
                                    <li><i class="fa-solid fa-comment"></i> <span>Con Sú </span> <span style={{ color: 'red' }}> Descripcion</span></li>
                                    <li><i class="fa-solid fa-camera"></i> <span>7 </span><span style={{ color: 'red' }} > Fotografias</span> </li>
                                    <li><i class="fa-solid fa-video"></i> <span>3 </span><span style={{ color: 'red' }} > Videos</span></li>
                                    <li><i class="fa-solid fa-phone"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Telefonico</span></li>
                                    <li><i class="fa-brands fa-whatsapp"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Whatsapp</span></li>
                                    <li><i class="fa-solid fa-earth-americas"></i> <span>Accesero Directo </span><span style={{ color: 'red' }}> Redes sociales</span></li>
                                    <li><i class="fa-solid fa-map-location-dot"></i> <span>Se Mostrara una </span><span style={{ color: 'red' }}> Ubicación</span></li>
                                    <li><i class="fa-solid fa-hand-point-up"></i> <span>3 subidas por dia </span><span style={{ color: 'red' }}> Paginas </span></li>
                                    <li><i class="fa-solid fa-circle-check"></i> <span>Icono de </span><span style={{ color: 'red' }}> Verificación </span></li>
                                </ul>
                                <a onClick={handleShow}
                                    class="pricing-table-button">
                                    Seleccionar plan
                                </a>
                            </div>
                        </div>
                    </div>

                    {show &&
                        (<div class="miswitch_model">
                            <span id='infor_public_anuncio'>Contactenos por Nuestras Redes, Mas facil y sin tarjetas</span>

                            <i class="fa-regular fa-circle-xmark" id='close_btn_anuncios' onClick={handleClose}></i>

                            <div class="miswitch_model_btn">
                                <div className='btn_red_precio1'
                                    onClick={e => window.open('https://t.me/fanshots', '_blank')}
                                >
                                    <i class="fa-brands fa-telegram"></i>
                                </div>
                                <div className='btn_red_precio2'
                                    onClick={e => window.open('https://api.whatsapp.com/send?phone=+51913926030&text=Hola%20quiero%20anunciar%20en%20cariñosashub.com%F0%9F%99%82', '_blank')}
                                >
                                    <i class="fa-brands fa-whatsapp"></i>
                                </div>
                            </div>
                        </div>)
                    }

                </div>
                <ul className='header_precios'>
                    <li><i class="fa-solid fa-check"></i> Todos los anuncios se visualizaran de forma aleatoria.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios de paga tendran mayor presencia y visualización.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios Vip se visualizaran al incio de pagina en los caruseles.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios de paga tedran subidas automaicas y aleatorias.</li>
                    <li><i class="fa-solid fa-check"></i> Los anuncios Vip tienen un maximo de usuarios (solo puedes haber 20 anuncios).</li>
                </ul>

            </div>
        </div>
    )
}

export default Publicaranuncio