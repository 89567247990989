import React, { useRef, useState, useEffect, useContext } from "react";
import { Map, TileLayer, Popup, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

import { Link } from 'react-router-dom'
import { GlobalState } from '../GlobalState'


import LocateControl from '../components/mapaubicacion/Geo-local'

import { geosearch } from "esri-leaflet-geocoder";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

import { useSelector } from 'react-redux'

import HeaderInicio from "../components/mainpages/start/HeaderInicio";
import LoadMoreAnu from "../components/mainpages/products/LoadMoreAnu";
import Loading from "../components/mainpages/utils/loading/Loading";

const defaultCenter = [-12.069475, -77.022161];
const defaultZoom = 13;


function Mapaubicacion() {
    const [loading, setLoading] = useState(false)

    /******/
    const state = useContext(GlobalState)
    const [anuncios] = state.publicidadAPI.publicidad
    /*****/

    const mapRef = useRef();
    const [item, setItem] = useState(anuncios);

    /* show siderbar */

    const [show, setShow] = useState(true);

    const open = "sidebar";
    const closed = "sidebar sbclosed";

    /****color negro****/

    const { theme } = useSelector(state => state)

    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;

        if (!map) return;

        const control = geosearch();

        control.addTo(map);

        control.on("results", handleOnSearchResuts);

        return () => {
            control.off("results", handleOnSearchResuts);
        };
    }, []);

    /**
     * handleOnSearchResuts
     * @param {object} data Results object from esri-leaflet-geocoder
     */

    function handleOnSearchResuts(data) {
        console.log("Search results", data);
    }

    /*
    const content = divIcon({
      className: "custom-div-icon",
      html: `
      <div class='marker-pin'><img src='https://www.quever.news/u/fotografias/m/2021/6/1/f768x1-10605_10732_102.jpg'/></div>
  `,
      iconSize: [30, 42],
      iconAnchor: [20, 50]
  
      
    });*/

    return (
        <div>
            <div className="Ubicate-map" >

                <Map ref={mapRef} center={defaultCenter} zoom={defaultZoom} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="http://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                    />
                    {item.map(({ latitud, longitud, nombre, images, dcorta, telefono, _id }) => {

                        if (latitud !== undefined && longitud !== undefined && latitud !== null && longitud !== null && latitud !== '' && longitud !== '') {
                            return (
                                <Marker
                                    key={_id}
                                    position={[latitud, longitud]}
                                    icon={L.divIcon({
                                        className: "custom-div-icon",
                                        html: `
<div class='marker-pin'>
<div class='marker-state'>
<img src=${images[0].url} />
</div>
</div>
`,
                                        iconSize: [30, 42],
                                        iconAnchor: [20, 50]
                                    })}
                                >
                                    <Popup className="request-popup" >
                                        <div className="content-popup">
                                            <h1>{nombre}</h1>
                                            <div>
                                                <div className="content-ppopu-img">
                                                    <img src={images[0].url} width="150" height="150" alt="no img"
                                                        style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />
                                                </div>
                                                <span>
                                                    {dcorta}
                                                </span>
                                                <div className="cart-btns">
                                                    <a href={`tel:+${telefono}`} className="add-cart">
                                                        <i class="fa-solid fa-phone"></i>Llamar
                                                    </a>
                                                    <Link
                                                        className="add-cart buy-now" to={`/publicidad/${_id}`}
                                                    >
                                                        <i class="fa-solid fa-user"></i> Perfil
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </Marker>
                            );
                        } else {
                            return null; // O simplemente omitir el marcador si no hay coordenadas
                        }
                    })}
                    <LocateControl />
                </Map>

                <button
                    type="button"
                    className="btn-hide-siderbar"
                    onClick={() => setShow(!show)}
                >
                    {show === true ? <i class="fa-solid fa-x"></i> : <i class="fa-solid fa-bars-staggered"></i>}
                </button>

                {show && (
                    <div className={show === true ? open : closed}>
                        {item.map((data, index) => {
                            const latitude = data.latitud;
                            const longitude = data.longitud;

                            // Comprobar si latitude y longitude son valores válidos antes de continuar
                           if (latitude != null && longitude != null && latitude !== '' && longitude !== '') {
                            const latlong = [latitude, longitude];

                            function handleOnFlyTo() {
                                const { current = {} } = mapRef;
                                const { leafletElement: map } = current;

                                map.flyTo(latlong, 18, {
                                    duration: 4
                                });
                            }

                            return (
                                <div className="sbcontent">
                                    <div className="sbcontent-img-cont">
                                        <img src={data.images[0].url} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} />
                                    </div>
                                    <div>
                                        <div className="sbcontent-info-uno">
                                            <span className="info-titulo">{data.nombre}</span>
                                            <span className="info-precio" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >S/.{data.phora}</span>
                                        </div>
                                        <div className="sbcontent-info-dos">
                                            <span className="info-nacionalidad">{data.nacionalidad}</span>
                                            <span className="info-edad">{data.edad} <smoll>años</smoll></span>
                                        </div>
                                        <div className="sbcontent-info-parra">
                                            <p>
                                                {data.dcorta}
                                            </p>
                                        </div>
                                        <span className="info-distrito"><i class="fa-solid fa-location-dot"></i> {data.distrito}, <smoll>{data.provincia}</smoll> </span>
                                        <div className="info-btn">
                                            <div className="info-btn-whatsapp" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                <a href={`https://api.whatsapp.com/send?phone=${data.telefono}&text=hola,%20té%20vi%20en%20"cariñosashub.com"`}>
                                                    <i class="fab fa-whatsapp"></i>
                                                </a>
                                            </div>
                                            <button className="info-btn-perfil" type="button" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} >
                                                <Link id="btn_ver_list" to={`/publicidad/${data._id}`} >
                                                    <i class="fa-solid fa-user"></i> Perfil
                                                </Link>
                                            </button>
                                            <button
                                                className="info-btn-location"
                                                onClick={handleOnFlyTo} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}
                                            >
                                                <i class="fa-solid fa-location-dot"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            // Datos de latitud o longitud no válidos, omite la creación del marcador
                            return null;
                        }
                        })}
                    </div>
                )}

            </div>
        </div>
    );
}

export default Mapaubicacion