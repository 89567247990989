import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UserCard from '../UserCard'
import FollowBtn from '../FollowBtn'

import LoadIcon from '../../images/loading.gif'
import { getSuggestions } from '../../redux/actions/suggestionsAction'

const Suggestions = () => {
    const { auth, suggestions } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div >

            <div className="suggestions_actulizacion">
                {
                    !suggestions.loading &&
                    <i className="fas fa-redo" style={{ cursor: 'pointer' }}
                        onClick={() => dispatch(getSuggestions(auth.token))} />
                }
            </div>

            {
                suggestions.loading
                    ? <img src={LoadIcon} alt="loading" className="" />
                    : <div className="suggestions_new">
                        {
                            suggestions.users.map(user => (
                                <UserCard key={user._id} user={user} >
                                    <FollowBtn user={user} />
                                </UserCard>
                            ))
                        }
                    </div>
            }

        </div>
    )
}

export default Suggestions