import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Send from '../../../images/send.svg'
import LikeButton from '../../LikeButton'
import ShareModal from '../../ShareModal'
import { BASE_URL } from '../../../utils/config'

import { likeForum, unLikeForum, saveForum, unSaveForum } from '../../../redux/actions/forumAction'


const DetailFooter = ({ forum }) => {
    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)

    const [isShare, setIsShare] = useState(false)

    const { auth, theme, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const [saved, setSaved] = useState(false)
    const [saveLoad, setSaveLoad] = useState(false)

    // Likes
    useEffect(() => {
        if(forum.likes.find(like => like._id === auth.user._id)){
            setIsLike(true)
        }else{
            setIsLike(false)
        }
    }, [forum.likes, auth.user._id])

    const handleLike = async () => {
        if(loadLike) return;
        
        setLoadLike(true)
        await dispatch(likeForum({forum, auth, socket}))
        setLoadLike(false)
    }
    const handleUnLike = async () => {
        if(loadLike) return;

        setLoadLike(true)
        await dispatch(unLikeForum({forum, auth, socket}))
        setLoadLike(false)
    }


    // Saved
    useEffect(() => {
        if(auth.user.saved.find(id => id === forum._id)){
            setSaved(true)
        }else{
            setSaved(false)
        }
    },[auth.user.saved, forum._id])

    const handleSavePost = async () => {
        if(saveLoad) return;
        
        setSaveLoad(true)
        await dispatch(saveForum({forum, auth}))
        setSaveLoad(false)
    }

    const handleUnSavePost = async () => {
        if(saveLoad) return;

        setSaveLoad(true)
        await dispatch(unSaveForum({forum, auth}))
        setSaveLoad(false)
    }

    return (
        <div className="footer_forum_cards">
            <div className="footer_icons_">
                <div>
                    <LikeButton
                        isLike={isLike}
                        handleLike={handleLike}
                        handleUnLike={handleUnLike}
                    />

                    {/*<Link to={`/forum/${forum._id}`} className="text-dark">
                        <i className="far fa-comment" />
    </Link>*/}

                    <img src={Send} alt="Send" onClick={() => setIsShare(!isShare)} />
                </div>

                {
                    saved
                        ? <i className="fas fa-bookmark text-info"
                            onClick={handleUnSavePost} />

                        : <i className="far fa-bookmark"
                            onClick={handleSavePost} />
                }

            </div>

            <div className="d-flex justify-content-between">
                <h6 style={{ padding: '0 25px', cursor: 'pointer' }}>
                    {forum.likes.length} likes
                </h6>

                <h6 style={{ padding: '0 25px', cursor: 'pointer' }}>
                    {forum.comments.length} Comentarios
                </h6>
            </div>

            {
                isShare && <ShareModal url={`${BASE_URL}/forum/${forum._id}`} theme={theme} />
            }
        </div>
    )
}

export default DetailFooter