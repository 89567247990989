import { PUBLIC_TYPES } from '../actions/publicAction'
import { EditData } from '../actions/globalTypes'

const detailpublicReducer = (state = [], action) => {
    switch (action.type){
        case PUBLIC_TYPES.GET_PUBLIC:
            return [...state, action.payload]
        case PUBLIC_TYPES.UPDATE_PUBLIC:
            return EditData(state, action.payload._id, action.payload)
        default:
            return state;
    }
}


export default detailpublicReducer