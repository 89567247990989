import React from 'react';
import CreateProduct from '../components/mainpages/createProduct/CreateProduct';

const Createproduct = () => {
    return (
        <>
            <CreateProduct />
        </>
    );
}

export default Createproduct;