import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function AnuncioItem({ anuncio }) {
    const { theme } = useSelector(state => state)

    return (
        <div className="catalogo_card">
             <h2 title={anuncio.nombre} style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                {anuncio.nombre} <span className='spanh1'> {anuncio.edad} años</span>
            </h2>

            <Link id="btn_ver" to={`/kines/${anuncio._id}`}>
                <img src={anuncio.images[0].url} alt=""
                    style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} 
                    onClick={() => window.scrollTo({ top: '20px' })} />
            </Link>

            <div class="image">
                <img src={anuncio.iconBanader}
                    style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} /><span className='spanuno'>{anuncio.nacionalidad}</span>
                <img src="https://res.cloudinary.com/dtbxszqkz/image/upload/v1691435825/iconos/point_igyfrh.png" id="img_cart_ubication"
                    style={{ filter: theme ? 'invert(1)' : 'invert(0)' }} /><span className='spandos'>{anuncio.distrito}, {anuncio.provincia}</span>
            </div>
        </div>
    )
}

export default AnuncioItem