import React from 'react'
import { Link } from 'react-router-dom'


const ForumBody = ({ forum, theme }) => {

    return (
        <div class="col-md-forum-info">
            <div className='forum_icons_' >
                <Link to={`/profile/${forum.user._id}`}   >
                <img src={forum.user.avatar} />
                </Link>
            </div>
            <span class="views-number">
                {forum.user.username}
            </span>
            <div>
                <small>{forum.user.followers.length} Seguidores</small>
            </div>
        </div>
    )
}

export default ForumBody