import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import Products from './products/Products'
import DetailProduct from './detailProduct/DetailProduct'
import NotFound from './utils/not_found/NotFound'
import Categories from './categories/Categories'
import Cart from './cart/Cart'
import CreateProduct from './createProduct/CreateProduct'

//import Navbar from './navbar/Navbar'
//import indexBar from "./navbar/indexBar"


import { GlobalState } from '../../GlobalState'

function Pages() {
  const state = useContext(GlobalState)
  const [isLogged] = state.userAPI.isLogged
  const [isAdmin] = state.userAPI.isAdmin


  return (
    <Switch>
      <Route path="/productos" exact component={Products} />
      <Route path="/detail/:id" exact component={DetailProduct} />

      <Route path="/category" exact component={isAdmin ? Categories : NotFound} />
      <Route path="/create_product" exact component={isAdmin ? CreateProduct : NotFound} />
      <Route path="/edit_product/:id" exact component={isAdmin ? CreateProduct : NotFound} />

      <Route path="/pcart" exact component={Cart} />
    </Switch>
  )
}

export default Pages