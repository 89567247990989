import { GLOBALTYPES } from '../actions/globalTypes'

const warnigReducer = ( state = true, action ) => {
    switch (action.type){
        case GLOBALTYPES.WARNING:
            return action.payload;
            default:
                return state;
    }
}

export default warnigReducer