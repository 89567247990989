import React, { useContext, useState, useRef } from 'react'
import Loadin from '../../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../../GlobalState'
import LoadMoreAnu from '../../components/mainpages/products/LoadMoreAnu'
import HeaderVer from '../../components/compInicio/headerVertical'
import BarraDer from '../../components/compInicio/barraDerech'
import Footer from '../../components/compInicio/footer'

import Loading from '../../components/mainpages/utils/loading/Loading'

import ChatButton from '../../utils/chatButton'
import CategoriaPublic from '../../components/publicidad/CategoriaPublic'
import ScrollToTop from '../../utils/ScrollToTop'

import Portadainicio from '../../utils/portadainicio'
import Cardinicio from '../../utils/cardinicio'

import { Helmet } from 'react-helmet-async';


function Jesusmaria() {

  const [loading, setLoading] = useState(false)
  const directoryRef = useRef()

  const state = useContext(GlobalState)
  const [anunciosbrena] = state.publicidadAPI.publicjesusmaria
  /*-----------*/

  if (loading) return <div><Loading /></div>

  const tituloDinamico = "Kinesiologas Jesus maria- CARIÑOSASHUB";

  return (
    <>
      <Helmet>

        <title>{tituloDinamico}</title>

        <meta name="description"
          content="16023 kinesiólogas Jesús María, encuentra todas las putas y prostitutas Jesús María, anuncios eroticos de escorts y sexo Jesús María, kines vip, ."
        ></meta>

        <meta name="keywords"
          content="Kinesiólogas, Kinesiólogas en perú, kines en perú, putas en perú, sexo en perú, escorts en perú, Prostitutas en perú, Putas peruanas, putas extranjeras en perú, kines Perú. Kines, maduras en perú, jovencitas en perú, kines lima perú. kines en lince, KINESIOLOGAS LINCE, KINES LOS OLIVOS, KINESIOLOGAS LOS OLIVOS, PUTAS LINCE, KINESIOLOGAS PUENTE PIEDRA, KINES MIRAFLORES."></meta>

        <link rel="canonical" href="https://www.cariñosashub.com/kinesiologas-jesus-maria" />

      </Helmet>

      <div className='rj-container-sections'>
        <div className='rj-izquierda'>
          <HeaderVer />
        </div>
        <div className='rj-central'>
          <ScrollToTop />
          <ChatButton />
          <div className='container-pruebaPP'>
            < Portadainicio />
            <CategoriaPublic />
            <h2 ref={directoryRef}>Directorio De Chicas</h2>
            <div className='main-container'>
              <div class="card-principal">
                {
                  anunciosbrena.map(anuncio => {
                    return < Cardinicio anuncio={anuncio} />
                  })
                }
              </div>
              <LoadMoreAnu directoryRef={directoryRef} />
            </div>
            <Footer />
          </div>
        </div>
        <div className='rj-derecha'>
          <BarraDer />
        </div>
      </div>
    </>
  )
}

export default Jesusmaria