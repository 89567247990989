import React, { useState, useEffect, Profiler } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { follow, unfollow } from '../redux/actions/profileAction'

const FollowBtn = ({user}) => {
    const { theme } = useSelector(state => state)

    const [followed, setFollowed] = useState(false)

    const { auth, profile, socket } = useSelector(state => state)
    const dispatch = useDispatch()

    const [load, setLoad] = useState(false)

    useEffect(() => {
        if(auth.user.following.find(item => item._id === user._id)){
            setFollowed(true)
        }
        return () => setFollowed(false)
    }, [auth.user.following, user._id])

    
    const handleFollow =  async () => {
        if(load) return;

        setFollowed(true)
        setLoad(true)
        await dispatch(follow({users: profile.users, user, auth, socket}))
        setLoad(false)
    }

    const handleUnFollow = async () => {
        if(load) return;

        setFollowed(false)
        setLoad(true)
        await dispatch(unfollow({users: profile.users, user, auth, socket}))
        setLoad(false)
    }

    return (
        <>
        {
            followed
            ? <button className="btn-sugger-noseguir" style={{filter: theme ? 'invert(1)' : 'invert(0)'}}
            onClick={handleUnFollow}>
                <i class="fa-solid fa-x"></i> No Seguir
            </button>
            : <button className="btn-sugger-seguir" style={{filter: theme ? 'invert(1)' : 'invert(0)'}}
            onClick={handleFollow}>
                <i className="fas fa-user-plus"></i> Seguir
            </button>
        }
        </>
    )
}

export default FollowBtn
