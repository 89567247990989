import React from 'react'
import PublicBody from './public_card/PublicBody'
import PublicFooter from './public_card/PublicFooter'
import PublicHeader from './public_card/PublicHeader'

const PublicCard = ({post, theme}) => {
    return (
        <div className="card my-3">
            <PublicHeader post={post} />
            <PublicBody post={post} theme={theme} />
            <PublicFooter post={post} />
        </div>
    )
}

export default PublicCard