import React, { useState, useEffect } from 'react'
import EditUser from '../profile/EditUser'
import moment from 'moment'
import { Link } from 'react-router-dom'


function Carduserpro({ user }) {

  const [onEdit, setOnEdit] = useState(false)
  const [edituser, setEdituser] = useState(user)


  var x = new Date(moment(user.fechaincio).add(30, 'd').format('YYYY-MM-DD'));
  var y = new Date(moment().format('YYYY-MM-DD'));

  const igualar = moment(y).isSameOrAfter(x) //false si es menor a la fecha

  return (
    <div className='content_user_tot_modera'>
      {
        onEdit &&
        <EditUser setOnEdit={setOnEdit} edituser={edituser} />
      }
      <div className='content_user_tot_header'>
        <h4>{user.username}</h4>
        <div className='content_user_tot_edit'><span onClick={() => setOnEdit(true)} >Editar</span></div>
      </div>
      <div className='content_user_tot'>
        <div className='user_tot_avatar'>
          <img src={user.avatar} />
        </div>
        <div className='user_tot_info'>
          <h3>Datos del usuario</h3>
          <h4>Id usuario: <span>{user._id}</span></h4>
          <h4>Nombre completo: <span>{user.fullname}</span></h4>
          <h4>Usuario Verificado: <span className={user.verificacion === true ? 'user_tot_info_span1' : 'user_tot_info_span2'}>{user.verificacion === true ? 'Verificado' : 'No verificado'}</span></h4>
          <h4>Cantidad Seguidores: <span>{user.followers.length}</span></h4>
          <h4>Telefono: <span>{user.mobile}</span></h4>
          <h4>email: <span>{user.email}</span></h4>
          <h4>Pais: <span>{user.pais}</span></h4>
          <h4>Se unio el: <span>{moment(user.createdAt).format('DD-MM-YYYY')}</span></h4>
        </div>
        <div className='user_tot_suscrip' style={igualar === true ? { background: '#FADBD8' } : { background: '#D4EFDF' }} >
          <h3>Datos de Suscripción</h3>
          <h4>Estado: <span className={user.activopago === true ? 'span_Activo' : 'span_Inactivo'}>{user.activopago === true ? 'Activo' : 'Inactivo'}</span></h4>
          <h4>Fecha Termino: <span>{moment(user.fechaincio).add(30, 'd').format('DD-MM-YYYY')}</span></h4>
          <h4>Fecha Incio: <span>{moment(user.fechaincio).format('DD-MM-YYYY')}</span></h4>
          <h4>Rol: <span>{user.role}</span></h4>
          <h4>Rol comercial: <span>{user.rolecomercian}</span></h4>
          <Link to={`profile/${user._id}`}>Perfil</Link>
        </div>
      </div>
    </div>
  )
}

export default Carduserpro