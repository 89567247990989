import React from 'react'
import { Link } from 'react-router-dom'

function Headervip() {
    return (
        <div className="btn-page-videohome" id='headervip_home' >
            <Link to={'/posthot'}>
                <h3>
                    <i class="fa-solid fa-photo-film"></i> <spa>Post Hot</spa>
                </h3>
            </Link>

            <Link to={'/videohot'}>
                <h3>
                    <i class="fa-solid fa-clapperboard"></i> <spa>User Hot</spa>
                </h3>
            </Link>
            <Link to={'/vdiscoverhot'}>
                <h3>
                    <i class="fa-solid fa-clapperboard"></i> <spa>Discover Hot</spa>
                </h3>
            </Link>
            <Link to={'/videoshorthot'}>
                <h3>
                    <i class="fa-brands fa-hotjar"></i> <spa>Shorts</spa>
                </h3>
            </Link>
        </div>
    )
}

export default Headervip