import React from 'react'

const LoadMoreBtn = ({ result, page, load, handleLoadMore }) => {
    return (
        <>
            {
                result < 9 * (page - 1) ? '' :

                    !load && <div id='btn_LoadMoreBtn'>
                        <button onClick={handleLoadMore}>
                            Cargar más
                        </button>
                    </div>
            }

        </>
    )
}

export default LoadMoreBtn