import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'


const ForumHeader = ({ forum, theme }) => {

    return (
        <div class="col-forum-item">
            <div class="forum-icon">
                <i class="fa fa-star" style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}></i>
            </div>
            <div id='title_forum_a'>
                <Link to={`/forum/${forum._id}`} class="forum-item-title">{forum.titulo}</Link>
            </div>
            <div class="forum-sub-title">Creado por <Link to={`/profile/${forum.user._id}`} >{forum.user.username}</Link>  {moment(forum.createdAt).fromNow()}</div>
        </div>
    )
}

export default ForumHeader