import React, { useState, useEffect } from 'react'
import CommentCardFo from './CommentCardFo'

const CommentFoDisplay = ({ comment, forum, replyCm }) => {
    const [showRep, setShowRep] = useState([])
    const [next, setNext] = useState(1)

    useEffect(() => {
        setShowRep(replyCm.slice(replyCm.length - next))
    }, [replyCm, next])

    return (
        <div className="comment_display forum">
            <CommentCardFo comment={comment} forum={forum} commentId={comment._id} >
                <div className="pl-4">
                    {
                        showRep.map((item, index) => (
                            item.reply &&
                            <CommentCardFo
                                key={index}
                                comment={item}
                                forum={forum}
                                commentId={comment._id}
                            />
                        ))
                    }

                    {
                        replyCm.length - next > 0
                            ? <div style={{ cursor: 'pointer', color: 'crimson' }}
                                onClick={() => setNext(next + 10)}>
                                Ver más comentarios...
                            </div>

                            : replyCm.length > 1 &&
                            <div style={{ cursor: 'pointer', color: 'crimson' }}
                                onClick={() => setNext(1)}>
                                Ocultar comentarios...
                            </div>
                    }
                </div>
            </CommentCardFo>
        </div>
    )
}

export default CommentFoDisplay
