import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { Link } from 'react-router-dom';

const Portadainicio = () => {

    const settings3 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    return (
        <div className='portada_red_social'>
            <div className='uno_portada'>
                <Slider {...settings3}>
                    <div className='img_carousel_portal' id='img_carousel_portal'>
                        <div className='portal_content_'>
                            <h1>Cariñosas Hub</h1>
                            <p>
                                Unete a nuestra Red social CariñosasHub y sigue a tú cariñosa fovorita..
                            </p>
                        </div>
                        <div className='img_segun_portal'>
                            <img src="https://media.gq.com.mx/photos/5dc7210c6c7d2100088eb408/master/w_1600%2Cc_limit/GettyImages-544021513.jpg" alt="" />
                        </div>
                    </div>
                    <div className='img_carousel_portal' id='img_carousel_portal'>
                        <div className='portal_content_'>
                            <h1>Cariñosas</h1>
                            <p>
                                Podras ver anuncios, imagenes, publicaciones de tú cariñosa favorita..
                            </p>
                        </div>
                        <div className='img_segun_portal'>
                            <img src="https://images.ecestaticos.com/oTHEzjCpa6S6SJuG-WfgWndkyKE=/0x16:1693x970/1600x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2F415%2Fc7b%2Fbfa%2F415c7bbfa0b9d6322d8802920710a3ad.jpg" alt="" />
                        </div>
                    </div>
                    <div className='img_carousel_portal' id='img_carousel_portal'>
                        <div className='portal_content_'>
                            <h1>Cariñosas</h1>
                            <p>
                                Tendras un perfil para poder hacer publicaciones elevadas de tono,  reciviras notificaciones de tú cariñosa preferida..
                            </p>
                        </div>
                        <div className='img_segun_portal'>
                            <img src="https://www.65ymas.com/uploads/s1/81/82/16/bigstock-sexy-woman-maked-body-with-but-415843051_5_1242x621.jpeg" alt="" />
                        </div>
                    </div>
                    <div className='img_carousel_portal' id='img_carousel_portal'>
                        <div className='portal_content_'>
                            <h1>Cariñosas</h1>
                            <p>
                                En CariñosasHub podras encontarar avisos y publicaciones de kinesiologas, contenido y otros servicios...
                            </p>
                        </div>
                        <div className='img_segun_portal'>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7vtvYU-zb3qAyCgV3iMf5pvh2Ga5ksvK8wna0p18fHyfnCKhAfcz9zDFjlCGcsAy4VoY&usqp=CAU" alt="" />
                        </div>
                    </div>
                </Slider>
            </div>
            <div className='dos_portada'>
                <div class="container_profile_">
                    <div class="profile_avatar_">
                        <img src="https://cinepornogratis.com/wp-content/uploads/2022/03/lorena-garcia-actriz-nopor-320x180.jpg"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://cdni.pornpics.com/460/7/632/54078320/54078320_048_acc2.jpg"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://www.elergonomista.com/wp-content/uploads/2019/05/Valentina-Nappi.jpg"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://xxxlesvianas.com/wp-content/uploads/2022/09/Angela-White.webp"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2dq91z8t1aGGn4ofIaUWX0V-qUoq9rnmFu-lcR078p0xv1zq25BbHtfVni07RE3Y9xEI&usqp=CAU"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://pbs.twimg.com/media/EAkJEx8XkAIqzud.jpg"
                            alt="" />
                    </div>
                    <div class="profile_avatar_">
                        <img src="https://www.pornostars.xxx/wp-content/uploads/2018/08/aletta-ocean.jpg"
                            alt="" />
                    </div>
                </div>
                <div class="container_profile_content">
                    <div className='cariñosas_title_red'>
                        <h4>Tú</h4><h3> Cariñosas</h3>
                    </div>
                    <p>Sigue a tú cariñosa favorita, mira sus publicaciones mas Hot...</p>
                    <Link to="/register">
                        <span><i class="fa-sharp fa-solid fa-square-rss"></i>Unete</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Portadainicio