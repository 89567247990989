import { PERFILCOMER_TYPES } from '../actions/perfilAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    perfils: [],
    result: 0,
    page: 2
}

const postReducer = (state = initialState, action) => {
    switch (action.type){
        case PERFILCOMER_TYPES.CREATE_PERFILCOMER:
            return {
                ...state,
                perfils: [action.payload, ...state.perfils]
            };
        case PERFILCOMER_TYPES.LOADING_PERFILCOMER:
            return {
                ...state,
                loading: action.payload
            };
        case PERFILCOMER_TYPES.GET_PERFILCOMERS:
            return {
                ...state,
                perfils: action.payload.perfils,
                result: action.payload.result,
                page: action.payload.page
            };
        case PERFILCOMER_TYPES.UPDATE_PERFILCOMER:
            return {
                ...state,
                perfils: EditData(state.perfils, action.payload._id, action.payload)
            };
        case PERFILCOMER_TYPES.DELETE_PERFILCOMER:
            return {
                ...state,
                perfils: DeleteData(state.perfils, action.payload._id)
            };
        default:
            return state;
    }
}

export default postReducer