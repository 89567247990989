import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadIcon from '../../images/loading.gif'
import LoadMoreBtn from '../LoadMoreBtn'
import { getDataAPI } from '../../utils/fetchData'
import { PUBLIC_TYPES } from '../../redux/actions/publicAction'
import PublicCard from './PublicCard'

const Publicidad = () => {

    const { homePublic, auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [load, setLoad] = useState(false)

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`publics?limit=${homePublic.page * 9}`, auth.token)

        dispatch({
            type: PUBLIC_TYPES.GET_PUBLICS,
            payload: { ...res.data, page: homePublic.page + 1 }
        })

        setLoad(false)
    }


    return (
        <div>
            <div className="posts" >
                {
                    homePublic.publics.map(post => (
                        <PublicCard key={post._id} post={post} theme={theme} />
                    ))
                }

                {
                    load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                }


                <LoadMoreBtn result={homePublic.result} page={homePublic.page}
                    load={load} handleLoadMore={handleLoadMore} />
            </div>
        </div>
    )
}

export default Publicidad