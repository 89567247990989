import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { createAnuncio } from '../../redux/actions/anuncioAction'

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

import LocateControl from '../mapaubicacion/Geo-local'

import { geosearch } from "esri-leaflet-geocoder";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const iconPerson = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://res.cloudinary.com/geocivil/image/upload/v1650574213/iconos/favorite_rswlad.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

function AnuncioModal(props) {
    const mapRef = useRef();

    const { auth, theme, anuncio } = useSelector(state => state)
    const dispatch = useDispatch()

    const [images, setImages] = useState([])

    const [nombre, setNombre] = useState('')
    const [fullnombre, setFullnombre] = useState('')
    const [nacionalidad, setNacionalidad] = useState('')
    const [edad, setEdad] = useState()
    const [cabello, setCabello] = useState('')
    const [ojos, setOjos] = useState('')
    const [piel, setPiel] = useState('')
    const [contextura, setContextura] = useState('')

    const [titulo, setTitulo] = useState('')
    const [phora, setPhora] = useState()
    const [pmediahora, setPmediahora] = useState()
    const [ppack, setPpack] = useState()
    const [pvideollamada, setPvideollamada] = useState()
    const [horario, setHorario] = useState()
    const [telefono, setTelefono] = useState()
    const [dcorta, setDcorta] = useState('')
    const [descripcion, setDescripcion] = useState('')

    const [pais, setPais] = useState('')
    const [provincia, setProvincia] = useState('')
    const [distrito, setDistrito] = useState('')
    const [latitud, setLatitud] = useState()
    const [longitud, setLongitud] = useState()

    const [tiktok, setTiktok] = useState('')
    const [instagram, setInstagram] = useState('')
    const [telegram, setTelegram] = useState('')
    const [only, setOnly] = useState('')
    const [fanshot, setFanshot] = useState('')

    const [lugar1, setLugar1] = useState('')
    const [lugar2, setLugar2] = useState('')
    const [lugar3, setLugar3] = useState('')
    const [lugar4, setLugar4] = useState('')

    const [servicio1, setServicio1] = useState('')
    const [servicio2, setServicio2] = useState('')
    const [servicio3, setServicio3] = useState('')
    const [servicio4, setServicio4] = useState('')
    const [servicio5, setServicio5] = useState('')
    const [servicio6, setServicio6] = useState('')
    const [servicio7, setServicio7] = useState('')
    const [servicio8, setServicio8] = useState('')

    const [especial1, setSEspecial1] = useState('')
    const [especial2, setSEspecial2] = useState('')
    const [especial3, setSEspecial3] = useState('')
    const [especial4, setSEspecial4] = useState('')
    const [especial5, setSEspecial5] = useState('')
    const [especial6, setSEspecial6] = useState('')
    const [especial7, setSEspecial7] = useState('')
    const [especial8, setSEspecial8] = useState('')

    const [dgustos, setDgustos] = useState('')
    const [dservicio, setDservicio] = useState('')
    const [dlugares, setDlugares] = useState('')
    const [descripcionfi, setDescripcionfi] = useState('')

    const [error, setError] = useState('')



    /*-----------ubicacion politica------------*/
    const [unidades, setUnidades] = useState([]);
    const [unidadeSelecionada, setUnidadeSelecionada] = useState();

    function onChangeUnidade(e) {
        const value = e.target.value;
        setUnidadeSelecionada();
        unidades.forEach((unidade) => {
            if (unidade.name === value) setUnidadeSelecionada(unidade.setores);
        });
        setProvincia(value)
    }

    useEffect(() => {
        const data = [
            {
                id: "1",
                name: "Amazonas",
                setores: ["Misma Ciudad", "Chachapoyas", "Leymebamba", "Bagua Grande", "Pedro Ruiz", "Lamud", "Pomacochas"]
            },
            {
                id: "2",
                name: "Ancash",
                setores: ["San Luis", "Casma", "Corongo", "Huaraz", "Huari", "Huarmey", "Caraz", "Piscobamba", "Ocros", "Cabana", "Pomabamba", "Chimbote", "Sihuas", "Yungay"]
            },
            {
                id: "3",
                name: "Apurímac",
                setores: ["Abancay", "Andahuaylas", "Antabamba", "Aymaraes", "Cotabambas", "Grau", "Chincheros"]
            },
            {
                id: "4",
                name: "Arequipa",
                setores: ["Arequipa", "Islay", "Camaná", "La Unión", "Condesuyos", "Caylloma", "Caraveli", "Castilla"]
            }, {
                id: "5",
                name: "Ayacucho",
                setores: ["Cangallo", "Huamanga", "Huanca Sancos", "Huanta", "La Mar", "Lucanas", "Parinacochas", "Sara Sara", "Sucre", "Fajardo", "Vilcashuamán"]
            }
            , {
                id: "6",
                name: "Cajamarca",
                setores: ["Cajabamba", "Cajamarca", "Celendín", "Chota", "Contumazá", "Cutervo", "Hualgayoc", "Jaén", "San Ignacio", "San Marcos", "San Miguel", "San Pablo", "Santa Cruz"]
            },
            {
                id: "7",
                name: "Callao",
                setores: ["Callao", "Bellavista", "Carmen de la Legua", "La Perla", "La Punta", "Ventanilla", "Mi Perú"]
            },
            {
                id: "8",
                name: "Cusco",
                setores: ["Acomayo", "Anta", "Calca", "Canas", "Canchis", "Cuzco", "Chumbivilcas", "Espinar", "La Convención", "Paruro", "Paucartambo", "Quispicanchi", "Urubamba"]
            },
            {
                id: "9",
                name: "Huancavelica",
                setores: ["Huancavelica", "Tayacaja", "Angaraes", "Acobamba", "Churcampa", "Huaytara", "Castrovirreyna"]
            }, {
                id: "10",
                name: "Huanuco",
                setores: ["Huanuco", "Puerto Inca", "Leoncio Prado", "Marañón", "Huamalíes", "Pachitea", "Lauricocha", "Huacaybamba", "Ambo", "Dos de Mayo", "Yarowilca"]
            },
            {
                id: "11",
                name: "Ica",
                setores: ["Ica", "Chincha", "Pisco", "Nasca", "Palpa"]
            },
            {
                id: "12",
                name: "Junin",
                setores: ["Huancayo", "Chupaca", "Junin", "Tarma", "Yauli", "Jauja", "Chanchamayo", "Satipo", "Concepción"]
            },
            {
                id: "13",
                name: "La Libertad",
                setores: ["Ascope", "Bolívar", "Chepén", "Gran Chimú", "Julcán", "Otuzco", "Pacasmayo", "Pataz", "Sánchez Carrión", "Santiago de Chuco", "Trujillo", "Virú"]
            },
            {
                id: "14",
                name: "Lambayeque",
                setores: ["Chiclayo", "Lambayeque", "Ferreñafe"]
            },
            {
                id: "15",
                name: "Lima",
                setores: ["Ancon", "Pte. Piedra", "Sta. Rosa", "Carabayllo", "Comas", "Los Olivos", "Independencia", "S. M. Porres",
                    "S. J. de Lurigancho", "Sta. Anita", "Cieneguilla", "Ate Vitarte", "La Molina", "Chaclacayo", "Lurigancho", "El Agustino",
                    "S. J. de Miraflores", "V.M. de Triunfo", "Villa el Salvador", "Lúrin y Pachacamac", "Cercado", "San Luis", "Breña", "La Victoria",
                    "Rimac", "Lince", "San Miguel", "Jesús María", "Magdalena", "Pblo. Libre", "Barranco", "Miraflores", "Surco", "San Borja",
                    "Surquillo", "San Isidro", "Chorrillos"
                ]
            },
            {
                id: "16",
                name: "Loreto",
                setores: ["Alto Amazonas", "Marañón", "Loreto", "Ramón Castilla", "Maynas", "Putumayo", "Requena", "Ucayali"]
            },
            {
                id: "17",
                name: "Madre De Dios",
                setores: ["Tambopata", "Manu", "Tahuamanu"]
            },
            {
                id: "18",
                name: "Moquegua",
                setores: ["Mariscal Nieto", "Sánchez Cerro", "Ilo"]
            },
            {
                id: "19",
                name: "Pasco",
                setores: ["Daniel Alcides Carrión", "Oxapampa", "Pasco"]
            },
            {
                id: "20",
                name: "Piura",
                setores: ["Ayabaca", "Huancabamba", "Morropón", "Paita", "Piura", "Sechura", "Sullana", "Talara"]
            },
            {
                id: "21",
                name: "Puno",
                setores: ["Azángaro", "Carabaya", "Chucuito", "El Collao", "Huancané", "Lampa", "Melgar", "Moho", "Puno", "Putina", "San Román", "Sandia", "Yunguyo"]
            },
            {
                id: "22",
                name: "San Martin",
                setores: ["Bellavista", "El Dorado", "Huallaga", "Lamas", "Mariscal Cáceres", "Moyobamba", "Picota", "Rioja", "San Martín", "Tocache"]
            },
            {
                id: "23",
                name: "Tacna",
                setores: ["Tacna", "Tarata", "Jorge Basadre", "Candarave"]
            },
            {
                id: "24",
                name: "Tumbes",
                setores: ["Tumbes", "Corrales", "SJ De La Virgen", "La Cruz", "Pampas de Hospital", "San Jacinto"]
            },
            {
                id: "25",
                name: "Ucayali",
                setores: ["Coronel Portillo", "Atalaya", "Padre Abad", "Purús"]
            }

        ];
        setUnidades(data);
    }, []);

    /*---------------*/

    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;

        if (!map) return;

        const control = geosearch();

        control.addTo(map);

        control.on("results", handleOnSearchResuts);

        return () => {
            control.off("results", handleOnSearchResuts);
        };
    }, []);

    /**
     * handleOnSearchResuts
     * @param {object} data Results object from esri-leaflet-geocoder
     */

    function handleOnSearchResuts(data) {
        console.log("Search results", data);
    }

    /*-----icons banderas------------*/
    const [banderas, setBanderas] = useState([])
    const [iconBanader, setIconBander] = useState('')

    function onChangeBandera(e) {
        const value = e.target.value;
        setUnidadeSelecionada();
        banderas.forEach((banera) => {
            if (banera.name === value) setIconBander(banera.icon);
        });
        setNacionalidad(value)
    }

    useEffect(() => {
        const data = [
            {
                id: 1,
                name: "Argentina",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/argentina_lcbql7.png"
            },
            {
                id: 2,
                name: "Boliviana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088203/iconos/bolivia_lxmah1.png"
            },
            {
                id: 3,
                name: "Brasileña",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667080002/iconos/pngwing.com_9_bj2vo2.png"
            },
            {
                id: 4,
                name: "Chilena",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088204/iconos/chile1_vc3tn6.png"
            },
            {
                id: 5,
                name: "Colombiana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1650512366/iconos/colombia_2_m2dmx0.png"
            },
            {
                id: 6,
                name: "japonesa",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/japon_r0gqwt.png"
            },
            {
                id: 7,
                name: "Cubana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/cuba_gpymue.png"
            },
            {
                id: 8,
                name: "Ecuatoriana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088203/iconos/ecuador_fug5xw.png"
            },
            {
                id: 9,
                name: "Española",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/espa%C3%B1a_th0knl.png"
            },
            {
                id: 10,
                name: "Estadounidense",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088205/iconos/eeuu_vpmbmy.png"
            },
            {
                id: 11,
                name: "Salvadoreña",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088203/iconos/salvador_ctk4jz.png"
            },

            {
                id: 12,
                name: "Finlandesa",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/filandeza_zq3lmt.png"
            },
            {
                id: 13,
                name: "Mexicana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/mexico_tlvjz4.png"
            },
            {
                id: 14,
                name: "Panameña",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/panama_inoh4q.png"
            },
            {
                id: 15,
                name: "Paraguaya",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088203/iconos/paraguay_sssxft.png"
            },
            {
                id: 16,
                name: "Peruana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088204/iconos/peru_ml3mbc.png"
            },
            {
                id: 17,
                name: "Uruguaya",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088203/iconos/urugay_fkkpkc.png"
            },
            {
                id: 18,
                name: "Venezolana",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1667088202/iconos/venezuela_kchuy2.png"
            },
            {
                id: 19,
                name: "OTRO",
                icon: "https://res.cloudinary.com/geocivil/image/upload/v1650567918/iconos/event_sgtnnt.png"
            }

        ];
        setBanderas(data);
    }, []);

    /*-------------------------------*/

    /*function onChangeNumerotel(e) {
        const numerotel = e.target.value;
        const procesado = numerotel.replace(/\s+/g, '')
        setTelefono(procesado)
    }*/

    function onChangeNombre(e) {
        const nombreuser = e.target.value;
        const nombreuserM = nombreuser.toUpperCase();
        const nomprocesado = nombreuserM.replace(/\s+/g, '')
        setNombre(nomprocesado)
    }

    /*----------------------*/
    //Mapa de ubicacion
    const [markerLatLng, setMarkerLatLng] = useState(
        props.markerLatLngDefault || 0
    );

    const [viewPort] = useState({
        center: [-12.071888393987825, -77.01072692871095],
        zoom: 2,
        ...props
    });

    const onClickMap = (e) => setMarkerLatLng(e.latlng);
    ////
    /*--------editar----------*/

    useEffect(() => {
        if (anuncio.onEdit) {
            setNombre(anuncio.nomprocesado)
            setDescripcion(anuncio.descripcion)
            setImages(anuncio.images)
        }
    }, [anuncio])

    /*------*/

    const handleChangeInputimg = e => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {
            if (!file) return err = "file does not exist."

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                return err = "image format is incorrect"
            }

            return newImages.push(file)

        })
        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        setImages([...images, ...newImages])
    }

    const deleteImages = (index) => {
        const newArr = [...images]
        newArr.splice(index, 1)
        setImages(newArr)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (images.length === 0)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Por favor agregue sus fotos" }
            })

        if (!nombre)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar su Nombre" }
            })
        if (nombre.length > 12)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Su nombre completo excedio el numero de letras permitida (12 letras max)" }
            })

        if (!fullnombre)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar su Nombre completo" }
            })
        if (fullnombre.length > 30)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Su nombre completo excedio el numero de letras permitida (30 letras max)" }
            })

        if (!nacionalidad)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar una Nacionalidad" }
            })

        if (!edad)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar su edad" }
            })

        if (!titulo)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar un Titulo" }
            })

        if (titulo.length > 40)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Su Titulo excedio el numero de letras permitida (40 letras max)" }
            })

        if (!descripcion)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar una Descripcion" }
            })

        if (descripcion.length > 900)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "La Descripcion excedio el numero de letras permitida (900 letras Max)" }
            })

        if (!dcorta)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que ingresar un Descripcion corta" }
            })

        if (dcorta.length > 150)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Descripcion Corta excedio el numero de letras permitido (150 caracteres max)" }
            })

        if (!pais)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que elejir el pais" }
            })

        if (!provincia)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que elejir un departamento" }
            })

        if (!distrito)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que elejir el distrito" }
            })

        if (!longitud)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Tiene que elejir un punto en el mapa y dar check en los cuadros" }
            })

        if (!telefono)
            return dispatch({
                type: GLOBALTYPES.ALERT, payload: { error: "Olvido su numero telefonico" }
            })

        dispatch(createAnuncio({
            nombre, images, fullnombre, nacionalidad, edad, cabello, ojos, piel, contextura,
            titulo, phora, pmediahora, ppack, pvideollamada, horario, telefono, dcorta, descripcion,
            pais, provincia, distrito, latitud, longitud, tiktok, instagram, telegram, only, fanshot,
            lugar1, lugar2, lugar3, lugar4, servicio1, servicio2, servicio3, servicio4, servicio5, servicio6,
            servicio7, servicio8, especial1, especial2, especial3, especial4, especial5, especial6, especial7,
            especial8, dgustos, dservicio, dlugares, descripcionfi, iconBanader, auth
        }))

        setImages([])

        setNombre('')
        setFullnombre('')
        setNacionalidad('')
        setEdad()
        setCabello('')
        setOjos('')
        setPiel('')
        setContextura('')

        setTitulo('')
        setPhora()
        setPmediahora()
        setPpack()
        setPvideollamada()
        setHorario('')
        setTelefono('')
        setDcorta('')
        setDescripcion('')

        setPais('')
        setProvincia('')
        setDistrito('')
        setLatitud('')
        setLongitud('')

        setTiktok('')
        setInstagram('')
        setTelegram('')
        setOnly('')
        setFanshot('')

        setLugar1('')
        setLugar2('')
        setLugar3('')
        setLugar4('')

        setServicio1('')
        setServicio2('')
        setServicio3('')
        setServicio4('')
        setServicio5('')
        setServicio6('')
        setServicio7('')
        setServicio8('')

        setSEspecial1('')
        setSEspecial2('')
        setSEspecial3('')
        setSEspecial4('')
        setSEspecial5('')
        setSEspecial6('')
        setSEspecial7('')
        setSEspecial8('')

        setDgustos('')
        setDservicio('')
        setDlugares('')
        setDescripcionfi('')

        setIconBander('')

        dispatch({ type: GLOBALTYPES.ANUNCIO, payload: false })
    }


    return (
        <div className='anuncio_modal'>
            <form onSubmit={handleSubmit}>
                <div className='anuncio_header'>
                    <h5 className='m-0'>Crear Anuncio</h5>
                    <span onClick={() => dispatch({
                        type: GLOBALTYPES.ANUNCIO, payload: false
                    })} >
                        &times;
                    </span>
                </div>

                <div className='anuncio_body'>
                    <span style={{ color: 'red' }}>Importante!!!</span>
                    <p className='parrafo_initial'>{auth.user.username}, ya puedes crear tú anuncio.. llenar todos los campos es obligatorio
                        para poder tener mayor llegada a los usuarios, gracias!!!</p>

                    <h6>Datos Del Anunciante</h6>
                    <div className='content_datos_user'>
                        <input type="text" id="nombre" name="nombre" placeholder='Nombre corto' required
                            onChange={onChangeNombre} />

                        <input type="text" id="fullnombre" name="fullnombre" placeholder='Nombre completo' required
                            onChange={e => setFullnombre(e.target.value)} />

                        <div class="col-100">
                            {/*<select class="form-select" id="nacionalidad" aria-label="País" name="nacionalidad" required
                                onChange={e => setNacionalidad(e.target.value)} >
                                <option selected hidden>Tú Nacionalidad</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Boliviana">Boliviana</option>
                                <option value="Brasileña">Brasileña</option>
                                <option value="Chilena">Chilena</option>
                                <option value="Colombiana">Colombiana</option>
                                <option value="Coreana">Coreana</option>
                                <option value="Cubana">Cubana</option>
                                <option value="Ecuatoriana">Ecuatoriana</option>
                                <option value="Española">Española</option>
                                <option value="Estadounidense">Estadounidense</option>
                                <option value="Finlandesa">Finlandesa</option>
                                <option value="Mexicana">Mexicana</option>
                                <option value="Panameña">Panameña</option>
                                <option value="Salvadoreña">Salvadoreña</option>
                                <option value="Paraguaya">Paraguaya</option>
                                <option value="Peruana">Peruana</option>
                                <option value="Uruguaya">Uruguaya</option>
                                <option value="Venezolana">Venezolana</option>
                                <option value="OTRO">OTRO</option>
                </select>*/}
                            <div class="col-100">
                                <select onChange={onChangeBandera} className="form-select" aria-label="Default select example" id="select-pais" name='departamento' required>
                                    <option value={null} selected hidden>Nacionalidad</option>
                                    {banderas.map((banera) => (
                                        <option key={banera.id} value={banera.name}>
                                            {banera.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <div class="col-100">
                            <select onChange={(e) => setDistrito(e.target.value)} class="form-select" id="select-lugar" aria-label="Lugar" name='distrito' required>
                                <option value={null} selected hidden onChange={e => setDistrito(e.target.value)}>
                                    {unidadeSelecionada ? "Distrito" : "Selecione"}
                                </option>
                                {unidadeSelecionada &&
                                    unidadeSelecionada.map((setor, index) => (
                                        <option key={setor.index} value={setor.index} >
                                            {setor}
                                        </option>
                                    ))}
                            </select>
                                    </div>*/}
                        </div>

                        <div class="col-100">
                            <select class="form-select" type="number" id="edad" aria-label="Edad" onChange={e => setEdad(e.target.value)} name="edad" required>
                                <option selected hidden>tú Edad</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <h6>Color Cabello</h6>
                    <div className='Content_Check'>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="cabello" id="rubio" required
                                value="Rubio" onChange={e => setCabello(e.target.value)} />
                            <label class="form-check-label" >
                                Rubio
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="cabello" id="negro"
                                value="Negro" onChange={e => setCabello(e.target.value)} />
                            <label class="form-check-label" >
                                Negro
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="cabello" id="castaño"
                                value="Castaño" onChange={e => setCabello(e.target.value)} />
                            <label class="form-check-label" >
                                Castaño
                            </label>
                        </div>
                    </div>

                    <h6>Color Ojos</h6>
                    <div className='Content_Check'>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="ojos" id="ojos" required
                                value="Oscuros" onChange={e => setOjos(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Oscuros
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="ojos" id="ojos"
                                value="Claros" onChange={e => setOjos(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Claros
                            </label>
                        </div>
                    </div>

                    <h6>Color De Piel</h6>
                    <div className='Content_Check'>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="piel" id="piel" required
                                value="Blanca" onChange={e => setPiel(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Blanca
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="piel" id="piel"
                                value="Morena" onChange={e => setPiel(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Morena
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="piel" id="piel"
                                value="Trigueña" onChange={e => setPiel(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault3">
                                Trigueña
                            </label>
                        </div>
                    </div>

                    <h6>Contextura</h6>
                    <div className='Content_Check'>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="contextura" id="contextura" required
                                value="Esbelta" onChange={e => setContextura(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Esbelta
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="contextura" id="contextura"
                                value="Delgada" onChange={e => setContextura(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Delgada
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="contextura" id="contextura"
                                value="Normal" onChange={e => setContextura(e.target.value)} />
                            <label class="form-check-label" for="flexRadioDefault3">
                                Normal
                            </label>
                        </div>
                    </div>

                    <h6>Descripción Del Anuncio:</h6>
                    <div className='Descripcion_anuncion'>
                        <div className='titulo_anuncio_pr'>
                            <input type="text" id="titulo" name="titulo" placeholder='titulo del anuncio' required
                                onChange={e => setTitulo(e.target.value)} />
                        </div>

                        <input type="number" id="phora" name="phora" placeholder='Precio por Hora / escriba solo numero' required
                            onChange={e => setPhora(e.target.value)} />

                        <input type="number" id="pmediahora" name="pmediahora" placeholder='Precio por 1/2 hora / escriba solo numero'
                            onChange={e => setPmediahora(e.target.value)} />

                        <input type="number" id="ppack" name="ppack" placeholder='Precio por tú pack / escriba solo numero'
                            onChange={e => setPpack(e.target.value)} />

                        <input type="number" id="pvideollamada" name="pvideollamada" placeholder='Precio videollamada / escriba solo numero'
                            onChange={e => setPvideollamada(e.target.value)} />

                        <div class="col-100">
                            <select class="form-select" id="horario" name="horario" aria-label="horario" required
                                onChange={e => setHorario(e.target.value)} >
                                <option selected hidden>Horarios</option>
                                <option value="Lunes-Domingo/9:00am-10:00pm">Lunes-Domingo/9:00am-10:00pm</option>
                                <option value="Lunes-Sabado/9:00am-10:00pm">Lunes-Sabado/9:00am-10:00pm</option>
                                <option value="Lunes-Viernes/9:00am-10:00pm">Lunes-Viernes/9:00am-10:00pm</option>
                                <option value="Lunes-Domingo/12:00pm-10:00pm">Lunes-Domingo/12:00pm-11:00pm</option>
                                <option value="Lunes-Sabado/12:00pm-11:00pm">Lunes-Sabado/12:00pm-11:00pm</option>
                                <option value="Lunes-Viernes/12:00pm-11:00pm">Lunes-Viernes/12:00pm-11:00pm</option>
                                <option value="Lunes-Domingo/24 H">Lunes-Domingo/24 H</option>
                                <option value="Lunes-Sabado/24 H">Lunes-Sabado/24 H</option>
                                <option value="Lunes-viernes/24 H">Lunes-viernes/24 H</option>
                                <option value="No Definido">No Definido</option>
                            </select>
                        </div>

                        {/*<input type="number" id="telefono" name="telefono" placeholder='Tú # de  telefono' required pattern="[0-9]*"
                            onChange={onChangeNumerotel} />*/}

                        <div className='input_mobile_anuncio'>
                            <PhoneInput id="telefono" name="telefono" country='pe' value={telefono} required
                                className="form-control" onChange={setTelefono} />
                        </div>

                        <div className='textearea_content'>
                            <textarea type="text" id="dcorta" name="dcorta" placeholder='Descripción Corta' required
                                onChange={e => setDcorta(e.target.value)} />
                            <textarea type="text" id="descripcion" name="descripcion" placeholder='Descripción total' required
                                onChange={e => setDescripcion(e.target.value)} />
                        </div>
                    </div>

                    <h6>Ubicacion:</h6>
                    <div className='content_ubicacion'>
                        {/*<input type="text" id="pais" name="pais" placeholder='Solo Perú por ahora' value="Perú" readonly="readonly"
                            onChange={e => setPais(e.target.value)} />*/}
                        <div class="col-100">
                            <select class="form-select" id="select-pais" aria-label="País" onChange={e => setPais(e.target.value)} name="pais" required >
                                <option selected hidden>Pais</option>
                                <option value="Perú">Argentina</option>
                                <option value="Perú">Bolivia</option>
                                <option value="Perú">Brasil</option>
                                <option value="Perú">Chile</option>
                                <option value="Perú">Colombia</option>
                                <option value="Perú">España</option>
                                <option value="Perú">Mexico</option>
                                <option value="Perú">Paraguay</option>
                                <option value="Perú">Perú</option>
                                <option value="Perú">Uruguay</option>
                                <option value="Perú">Venezuela</option>
                            </select>
                        </div>

                        <div class="col-100">
                            <select onChange={onChangeUnidade} class="form-select" id="select-pais" aria-label="Pais" name='departamento' required>
                                <option value={null} selected hidden>Departamento</option>
                                {unidades.map((unidade) => (
                                    <option key={unidade.id} value={unidade.name}>
                                        {unidade.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div class="col-100">
                            <select onChange={(e) => setDistrito(e.target.value)} class="form-select" id="select-lugar" aria-label="Lugar" name='distrito' required>
                                <option value={null} selected hidden onChange={e => setDistrito(e.target.value)}>
                                    {unidadeSelecionada ? "Distrito" : "Selecione"}
                                </option>
                                {unidadeSelecionada &&
                                    unidadeSelecionada.map((setor, index) => (
                                        <option key={setor.index} value={setor.index} >
                                            {setor}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        {/*<input type="text" id="latitud" name="latitud" placeholder='latitud' value={markerLatLng.lat}
                            onChange={e => setLatitud(e.target.value)} />

                        <input type="text" id="longitud" name="longitud" placeholder='longitud' value={markerLatLng.lng}
                                    onChange={e => setLongitud(e.target.value)} />*/}

                        <div class="input_coordenadas">
                            {markerLatLng
                                ? <div className='coordenas_inputs'>
                                    <span style={{ color: '#E74C3C', fontSize: '15px' }}>Tienes que dar check en ambos cuadros para confirmar la ubicacion de tu servicio</span>
                                    <div className='coordenas_div'>
                                        <div className='coordenadas_latlong'>
                                            <input class="form-check-input" type="checkbox" value={markerLatLng.lat} id="log" name="log" required
                                                onChange={e => setLatitud(e.target.value)} />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {markerLatLng.lat}
                                            </label>
                                        </div>
                                        <div className='coordenadas_latlong'>
                                            <input class="form-check-input" type="checkbox" value={markerLatLng.lng} id="long" name="long" required
                                                onChange={e => setLongitud(e.target.value)} />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {markerLatLng.lng}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : <div><span style={{ color: '#E74C3C', fontSize: '17px' }}>Haz clic en el punto donde vas a laborar, solo como referencia...este paso es obligatorio!!</span></div>
                            }
                        </div>

                        {/*<input type="number" id="latitud" name="latitud" placeholder='latitud'  required
                            value={markerLatLng.lat} onChange={onChangelatitud} />
                        <input type="number" id="longitud" name="longitud" placeholder='longitud' required
                            value={markerLatLng.lng} onChange={onChangelongitud} />*/}
                    </div>
                    {/*<div>
                        {markerLatLng
                            ? <div>
                                <li>Tú latitud: {markerLatLng.lat} </li>
                                <li>Tú longitud: {markerLatLng.lng}</li>
                            </div>
                            : 'Haz clic en el punto donde te vas a ubicar'}
                        </div>*/}
                    <div className='ubication_map'>
                        <Map center={[-12.071888393987825, -77.01072692871095]} zoom={10} ref={mapRef}
                            onClick={onClickMap} {...props} viewPort={viewPort} style={{ width: "100%", height: "100%" }}>
                            <LocateControl />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {markerLatLng && (
                                <Marker
                                    position={markerLatLng}
                                    ref={(ref) => ref && ref.leafletElement.openPopup()}
                                    icon={iconPerson}
                                >
                                    <Popup position={markerLatLng}>
                                        {/*<pre>{JSON.stringify(markerLatLng, null, 2)}</pre>*/} Aquí es donde vas a laborar!!
                                    </Popup>
                                </Marker>
                            )}

                        </Map>
                    </div>

                    <h6>Redes:</h6>
                    <div className='content_redes'>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3">https://www.tiktok.com/</span>
                            <input type="text" class="form-control" aria-describedby="basic-addon3"
                                id="tiktok" name="tiktok" placeholder='Tú Tiktok'
                                onChange={e => setTiktok(e.target.value)} />
                        </div>

                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3">https://www.instagram.com/</span>
                            <input type="text" class="form-control" aria-describedby="basic-addon3"
                                id="instagram" name="instagram" placeholder='Tú Instagram'
                                onChange={e => setInstagram(e.target.value)} />
                        </div>

                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3">https://www.telegram.com/</span>
                            <input type="text" class="form-control" aria-describedby="basic-addon3"
                                id="telegram" name="telegram" placeholder='Tú Telegram'
                                onChange={e => setTelegram(e.target.value)} />
                        </div>

                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3">https://onlyfans.com/</span>
                            <input type="text" class="form-control" aria-describedby="basic-addon3"
                                id="only" name="only" placeholder='Tú Onlyfans'
                                onChange={e => setOnly(e.target.value)} />
                        </div>

                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3">https://fons-hot/</span>
                            <input type="text" class="form-control" aria-describedby="basic-addon3"
                                id="fanshot" name="fanshot" placeholder='Tú Fans-hot'
                                onChange={e => setFanshot(e.target.value)} />
                        </div>
                    </div>

                    <h6>Detalles Adicionales:</h6>
                    <div className='content_detail_ad'>
                        <textarea name='dgustos' placeholder='Tús Gustos' required
                            onChange={e => setDgustos(e.target.value)} />
                        <textarea name='dservicio' placeholder='Detalle De tú Servicio'
                            onChange={e => setDservicio(e.target.value)} />
                        <textarea name='dlugares' placeholder='lugares donde frecuentas'
                            onChange={e => setDlugares(e.target.value)} />
                        <textarea name='descripcionfi' placeholder='Breve descripcion fisica'
                            onChange={e => setDescripcionfi(e.target.value)} />
                    </div>

                    <h6>Lugar Del Servicio:</h6>
                    <div className='content_servicios'>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Hoteles" id="lugar1" name="lugar1" required
                                onChange={e => setLugar1(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Hoteles
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Departamento Propio" id="lugar2" name="lugar2"
                                onChange={e => setLugar2(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Departamento Propio
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Viaje" id="lugar3" name="lugar3"
                                onChange={e => setLugar3(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Viaje
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="A Domicilio" id="lugar4" name="lugar4"
                                onChange={e => setLugar4(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                A Domicilio
                            </label>
                        </div>
                    </div>

                    <h6>Tus Servicios:</h6>
                    <span>Servicio Normal</span>
                    <div className='content_servicios'>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio1" name="servicio1" required
                                value="Oral con Condon" onChange={e => setServicio1(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Oral con Condon
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio2" name="servicio2"
                                value="Masaje Erotico" onChange={e => setServicio2(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Masaje Erotico
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio3" name="servicio3"
                                value="Beso En Boca" onChange={e => setServicio3(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Beso En Boca
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio4" name="servicio4"
                                value="Trato De Novia" onChange={e => setServicio4(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Trato De Novia
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio5" name="servicio5"
                                value="Fantacia y Disfras" onChange={e => setServicio5(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Fantacia y Disfras
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio6" name="servicio6"
                                value="Actriz Porno" onChange={e => setServicio6(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Actriz Porno
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio7" name="servicio7"
                                value="Kamasutra" onChange={e => setServicio7(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Kamasutra
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="servicio8" name="servicio8"
                                value="Eyaculación Facial" onChange={e => setServicio8(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Eyaculación Facial
                            </label>
                        </div>
                    </div>

                    <span>Servicio Especiales</span>
                    <div className='content_servicios'>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial1" name="especial1"
                                value="Sexo Anal" onChange={e => setSEspecial1(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Sexo Anal
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial2" name="especial2"
                                value="Trios" onChange={e => setSEspecial2(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Trios
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial3" name="especial3"
                                value="Oral Sin Condon" onChange={e => setSEspecial3(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Oral Sin Condon
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial4" name="especial4"
                                value="Fetichismo" onChange={e => setSEspecial4(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Fetichismo
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial5" name="especial5"
                                value="Garganta Profunda" onChange={e => setSEspecial5(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Garganta Profunda
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial6" name="especial6"
                                value="Beso Negro" onChange={e => setSEspecial6(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Beso Negro
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial7" name="especial7"
                                value="Kamasutra" onChange={e => setSEspecial7(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Kamasutra
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="especial8" name="especial8"
                                value="Despedida Solteros" onChange={e => setSEspecial8(e.target.value)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Despedida Solteros
                            </label>
                        </div>
                    </div>

                    <div className="show_images">
                        {
                            images.map((img, index) => (
                                <div key={index} id="file_img">
                                    <img src={
                                        img.url ? img.url
                                            : URL.createObjectURL(img)}
                                        alt="images" className='img-thumbnail'
                                        style={{
                                            filter: theme ? 'invert(1)' : 'invert(0)'
                                        }} />
                                    <span onClick={() => deleteImages(index)} >&times;</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="input_images">
                        <div className='file_upload'>
                            <i className='fas fa-image' style={{ cursor: 'pointer' }} /><br />
                            <small style={{ color: 'crimson' }}>Subir images</small>
                            <input type="file" name="file" id="file" multiple accept='image/*'
                                onChange={handleChangeInputimg} />
                        </div>
                    </div>
                </div>
                
                <div className='anuncio_footer'>
                    <button className='btn btn-secondary w-100' type='submit' >
                        crear anuncio
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AnuncioModal