import React from 'react'

const Ventacontenido = () => {
    return (
        <div className='page-vent-contenido'>
            <div>
                <i class="bounce bounce_down fa ">
                    <i class="fa-solid fa-person-digging" id='icons-construct'>
                    </i>
                </i>
            </div>
            <h2>Sitio en Construcción...</h2>
        </div>
    )
}

export default Ventacontenido