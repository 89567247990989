import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Cardinicio = ({ anuncio }) => {

    ////modal
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /////
    ////modal2
    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClick1 = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow1();
        handleClick1();
    };

    return (
        <>
           { /*{show && (
                <div id="alerta-titulo-telegram">
                    <a href='https://t.me/grupofanshot' >
                        <span>Unete a nuestro grupo de Telegram @grupofanshot</span>
                    </a>
                    <button onClick={handleClose} type="button" class="outline-danger"><i class="fa-solid fa-xmark"></i></button>
                </div>
           )}*/}

            {show1 && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder guardar ó darle like a un anuncio, tienes que crear tú cuenta, es gratis!!!
                        </h4>
                        <div>
                            <button onClick={handleClose1} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <div class="card-secun">
                <div className="img-card">
                    <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                        <img src={anuncio.images[0].url} alt="" />
                    </Link>
                    <span className='material-icons' onClick={handleButtonClick}>favorite</span>
                </div>
                <div className='card-footer1'>
                    <div className='card-nombre'>
                        <div className='card-name-nombre'>
                            <i class="fa-solid fa-star"></i>
                            <h1 style={{ marginLeft: '6px' }}>{anuncio.nombre}</h1>
                        </div>
                        <span>{anuncio.edad} <small>años</small></span>
                    </div>
                    <div className='card-footer-nacio'>
                        <img src={anuncio.iconBanader} />
                        <span>{anuncio.nacionalidad}</span>
                    </div>
                    <div className='card-lugar'>
                        <div>
                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                            <span>{anuncio.distrito}</span>
                        </div>
                        <small>{anuncio.provincia}</small>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cardinicio