import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { GlobalState } from '../../GlobalState';

function Detanuncio() {
    const params = useParams()
    const state = useContext(GlobalState)
    const [anuncios] = state.productsAPI.anuncios
    const [detailAnuncio, setDetailAnuncio] = useState([])

    useEffect(() => {
        if (params.id) {
            anuncios.forEach(anuncio => {
                if (anuncio._id === params.id) setDetailAnuncio(anuncio)
            })
        }
    }, [params.id, anuncios])

    if (detailAnuncio.length === 0) return null;

    return (
        <div className="detail">
            <img src={detailAnuncio.images[0].url} alt="" />
            <div className="box-detail">
                <div className="row">
                    <h2>{detailAnuncio.content}</h2>
                </div>
            </div>
        </div>
    )
}

export default Detanuncio