import React, { useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import HeaderVer from '../components/compInicio/headerVertical'
import BarraDer from '../components/compInicio/barraDerech'
import { GlobalState } from '../GlobalState'
import Footer from '../components/compInicio/footer'
import LoadMoreAnu from '../components/mainpages/products/LoadMoreAnu'

import Loading from '../components/mainpages/utils/loading/Loading'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CategoriaPublic from '../components/publicidad/CategoriaPublic'
import ScrollToTop from '../utils/ScrollToTop'

import { Helmet } from 'react-helmet-async';

/*------*/
function filterByCategory(categoryName, categoryFilters, item) {
    if (!categoryFilters.length) return true;
    return categoryFilters.some((filter) => item[categoryName] === filter);
}
/*--------*/

function Limasur() {
    const [loading] = useState(false)
    const directoryRef = useRef()

    const state = useContext(GlobalState)
    const [anuncios] = state.publicidadAPI.publicidad

    /* --filtros vip-- */
    const getVip = anuncios.filter(anunciovip => anunciovip.categoria == "Vip")
    const getPreferencial = anuncios.filter(anunciopref => anunciopref.categoria == "Preferencial")
    /*-------------*/

    const Limasur = anuncios.filter(
        (dep) => dep.distrito === "Barranco" || dep.distrito === "Chorrillos" || dep.distrito === "San Borja" || dep.distrito === "Surquillo"
            || dep.distrito === "San Isidro" || dep.distrito === "Miraflores" || dep.distrito === "S. J. de Miraflores" || dep.distrito === "Surco"
            || dep.distrito === "Lúrin y Pachacamac" || dep.distrito === "Villa el Salvador" || dep.distrito === "V.M. de Triunfo" || dep.distrito === "Magdalena"
    );

    /*--------------*/
    const appTypeFilters = [...new Set(Limasur.map((d) => d.distrito))];
    const nacionalidadFilters = [...new Set(Limasur.map((d) => d.nacionalidad))];

    let [appTypes, setAppTypes] = useState([]);
    let [nacionalidads, setNacionalidads] = useState([]);


    let filteredDevelopers = Limasur.filter((dev) => {
        return (
            filterByCategory("distrito", appTypes, dev) &&
            filterByCategory("nacionalidad", nacionalidads, dev)
        )
    });
    /*-----------*/
    ////modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow();
        handleClick();
    };

    if (loading) return <div><Loading /></div>

    const tituloDinamico = "Kinesiologas lima sur - CARIÑOSASHUB";

    return (
        <>
            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="Tenemos kines, putas en lima sur. Anuncios eróticos de kinesiologas y sexo en lima sur, jovencitas maduras y bellas putas en lima sur, Kinesiologas, escorts y prostitutas en lima sur. Prositutas peruanas y extranjeras en lima sur, Anuncios Kinesiologas en lima sur, encuentra Kines Putas y prostitutas de lima sur. Anuncios eróticos de escorts y sexo en lima sur"
                ></meta>

                <meta name="keywords"
                    content="Kinesiologas lima sur, puta lima sur, putas por el sur, kinesiologas sur, putas lima sur, kinesiologas en lima sur, kines lima sur, kinesiologas lima, kines metro lima sur, sexo lima sur"></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/" />

            </Helmet>
            {show && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder guardar ó darle like a un anuncio, tienes que unirte a nuestra red social "CariñosasHub", es gratis!!!
                        </h4>
                        <div>
                            <button onClick={handleClose} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <div className='rj-container-sections'>
                <div className='rj-izquierda'>
                    <HeaderVer />
                </div>
                <div className='rj-central'>
                    <div className='container-pruebaPP'>
                        <ScrollToTop />
                        <CategoriaPublic />
                        <h2>Filtro</h2>
                        <div className="contenedor_tags">
                            <h3>Distrito</h3>
                            <ul className="boxs-container" >
                                {appTypeFilters.map((type) => (
                                    <li className='content_dist'>
                                        <input
                                            className="inp-cbx" id={type}
                                            name="appType"
                                            type="checkbox"
                                            value={type}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    setAppTypes([...appTypes, type]);
                                                } else {
                                                    setAppTypes(
                                                        appTypes.filter((distrito) => distrito !== type)
                                                    );
                                                }
                                            }}
                                        />
                                        <label for={type} href="#lancha" >{type}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="contenedor_tags">
                            <h3>Nacionalidad</h3>
                            <ul className="boxs-container2">
                                {nacionalidadFilters.map((filter) => (
                                    <li className='content_dist'>
                                        <input
                                            className="inp-cbx" id={filter}
                                            name="nacionalidad"
                                            type="checkbox"
                                            value={filter}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    setNacionalidads([...nacionalidads, filter]);
                                                } else {
                                                    setNacionalidads(
                                                        nacionalidads.filter((nacionalidad) => nacionalidad !== filter)
                                                    );
                                                }
                                            }}
                                        />
                                        <label for={filter}>{filter}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <h2 ref={directoryRef}>Directorio De Chicas</h2>
                        <div className='main-container'>
                            <div class="card-principal">
                                {
                                    filteredDevelopers.map(anuncio => {
                                        return <div class="card-secun">
                                            <div className="img-card">
                                                <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                                    <img src={anuncio.images[0].url} alt="" />
                                                </Link>
                                                <span className='material-icons' onClick={handleButtonClick}>favorite</span>
                                            </div>
                                            <div className='card-footer1'>
                                                <div className='card-nombre'>
                                                    <div className='card-name-nombre'>
                                                        <i class="fa-solid fa-star"></i>
                                                        <h1 style={{ marginLeft: '6px' }}>{anuncio.nombre}</h1>
                                                    </div>
                                                    <span>{anuncio.edad} <small>años</small></span>
                                                </div>
                                                <div className='card-footer-nacio'>
                                                    <img src={anuncio.iconBanader} />
                                                    <span>{anuncio.nacionalidad}</span>
                                                </div>
                                                <div className='card-lugar'>
                                                    <div>
                                                        <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                                                        <span>{anuncio.distrito}</span>
                                                    </div>
                                                    <small>{anuncio.provincia}</small>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <LoadMoreAnu directoryRef={directoryRef} />
                        </div>
                        <Footer />
                    </div>
                </div>
                <div className='rj-derecha'>
                    <BarraDer />
                </div>
            </div>
        </>
    )
}

export default Limasur