import {combineReducers} from 'redux'
import auth from './authReducer'
import alert from './alertReducer'
import theme from './themeReducer'
import profile from './profileReducer'
import status from './statusReducer'
import homePosts from './postReducer'
import modal from './modalReducer'
import detailPost from './detailPostReducer'
import discover from './discoverReducer'
import suggestions from './suggestionsReducer'
import socket from './socketReducer'
import notify from './notifyReducer'
import message from './messageReducer'
import online from './onlineReducer'
import call from './callReducer'
import peer from './peerReducer'
import anuncio from './anuncioReducer'
import tiktok from './tiktokReducer'
import homeTiktoks from './linktiktokReducer'
import homeAnuncio from './anuncioposReducer'
import homeVideos from './videoReducer'
import homeVideosuser from './videouserReducer'
import homePostshot from './posthotReducer'
import homehotVideos from './videohotReducer'
import homeVideouserHot from './videouserhotReducer'

import homepostPerfil  from './perfilpostReducer'

import homePublic  from './publicReducer'
import statuPublic  from './statuPublicReducer'
import detailPublic  from './detailpublicReducer'

import homePerfilComer from './perfilReducer'
import statusPerfil  from './statusPerfilReducer'
import detailPerfil from './detailPerfilReducer'

import homeForums from './forumReducer'
import statusForum from './statformReducer'
import detailForum from './detailForumReducer'

import warning from './warningReducer'

export default combineReducers ({
    auth,
    alert,
    theme,
    profile,
    status,
    homePosts,
    modal,
    detailPost,
    discover,
    suggestions,
    socket,
    notify,
    message,
    online,
    call,
    peer,
    anuncio,
    homeAnuncio,
    tiktok,
    homeTiktoks,
    homeVideos,
    homeVideosuser,
    homePostshot,
    homehotVideos,
    homeVideouserHot,

    homepostPerfil,

    homePublic,
    statuPublic,
    detailPublic,

    homePerfilComer,
    detailPerfil,
    statusPerfil,

    homeForums,
    statusForum,
    detailForum,
    warning
})