import React from 'react';
import Products from '../components/mainpages/products/Products';

const ProductsView = () => {
    return ( 
        <>
        <Products/>
        </>
     );
}
 
export default ProductsView;