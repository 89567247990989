import { GLOBALTYPES, DeleteData } from './globalTypes'
import { getDataAPI, patchDataAPI } from '../../utils/fetchData'
import { imageUpload } from '../../utils/imageUpload'
import { createNotify, removeNotify } from '../actions/notifyAction'


export const PROFILE_TYPES = {
    LOADING: 'LOADING_PROFILE',
    GET_USER: 'GET_PROFILE_USER',
    FOLLOW: 'FOLLOW',
    UNFOLLOW: 'UNFOLLOW',

    GET_ID: 'GET_PROFILE_ID',
    GET_POSTS: 'GET_PROFILE_POSTS',
    GET_PUBLICS: 'GET_PROFILE_PUBLICS',
    GET_PERFILS: 'GET_PROFILE_PERFILS',

    UPDATE_POST: 'UPDATE_PROFILE_POST',
    UPDATE_PUBLIC: 'UPDATE_PROFILE_PUBLIC'
}

export const getProfileUsers = ({id, auth}) => async (dispatch) => {
    dispatch({type: PROFILE_TYPES.GET_ID, payload: id})

        try {
            dispatch({type: PROFILE_TYPES.LOADING, payload: true})
        const res = getDataAPI(`/user/${id}`, auth.token)
        const res1 = getDataAPI(`/user_posts/${id}`, auth.token)
        const res2 = getDataAPI(`/user_publics/${id}`, auth.token)
        const res3 = getDataAPI(`/user_perfilcomers/${id}`, auth.token)
        
        const users = await res;
        const posts = await res1;
        const publics = await res2;
        const perfils = await res3;


        dispatch({
            type: PROFILE_TYPES.GET_USER,
            payload: users.data
        })
    
        dispatch({
            type: PROFILE_TYPES.GET_POSTS,
            payload: {...posts.data, _id: id, page: 2}
        })

        dispatch({
            type: PROFILE_TYPES.GET_PUBLICS,
            payload: {...publics.data, _id: id, page: 2}
        })

        dispatch({
            type: PROFILE_TYPES.GET_PERFILS,
            payload: {...perfils.data, _id: id, page: 2}
        })

        dispatch({type: PROFILE_TYPES.LOADING, payload: false})

        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
}

export const updateProfileUser = ({userData, avatar, auth, mobile}) => async (dispatch) => {
    if(!userData.fullname)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Por favor agregar nombre completo."}})

    if(userData.fullname.length > 25)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Nombre demasiado larga."}})

    if(!userData.sobrenombre)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Tiene que ingresar un sobrenombre"}})

    if(userData.sobrenombre.length > 20)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su sobrenombre es muy largo Max 20 caracteres"}})

    if(userData.mipresentacion.length > 35)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su Presentacion es muy largo Max 35 caracteres"}})

    if(userData.informacionuno.length > 35)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su Informacion uno es muy largo Max 35 caracteres"}})

    if(userData.informaciondos.length > 35)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su Informacion uno es muy largo Max 35 caracteres"}})

    if(userData.informaciontres.length > 35)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su Informacion uno es muy largo Max 35 caracteres"}})

    if(userData.intereses.length > 20)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su Informacion uno es muy largo Max 35 caracteres"}})

    if(userData.story.length > 100)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Su historia es demasiado larga."}})

    try {
        let media;
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        if(avatar) media = await imageUpload([avatar])

        const res = await patchDataAPI("user", {
            ...userData,
            avatar: avatar ? media[0].url : auth.user.avatar,
            mobile
        }, auth.token)

        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                ...auth,
                user: {
                    ...auth.user, ...userData,
                    avatar: avatar ? media[0].url : auth.user.avatar,
                    mobile
                }
            }
        })

        dispatch({type: GLOBALTYPES.ALERT, payload: {success: res.data.msg}})
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateUsuario = ({verificacion, activopago, fechaincio, role, rolecomercian, ids }) => async (dispatch) => {

    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await patchDataAPI(`usuario/${ids}`, {
            verificacion, activopago, fechaincio, role, rolecomercian
        })
        
        console.log(res)

        dispatch({type: GLOBALTYPES.ALERT, payload: { loading: false }})
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}

export const follow = ({users, user, auth, socket}) => async (dispatch) => {
    let newUser;
    
    if(users.every(item => item._id !== user._id)){
        newUser = {...user, followers: [...user.followers, auth.user]}
    }else{
        users.forEach(item => {
            if(item._id === user._id){
                newUser = {...item, followers: [...item.followers, auth.user]}
            }
        })
    }

    dispatch({ type: PROFILE_TYPES.FOLLOW, payload: newUser })


    dispatch({
        type: GLOBALTYPES.AUTH, 
        payload: {
            ...auth,
            user: {...auth.user, following: [...auth.user.following, newUser]}
        }
    })

    try {
        const res = await patchDataAPI(`user/${user._id}/follow`, null, auth.token)
        socket.emit('follow', res.data.newUser)

         // Notify
         const msg = {
            id: auth.user._id,
            text: 'ha comenzado a seguirte.',
            recipients: [newUser._id],
            url: `/profile/${auth.user._id}`,
        }

        dispatch(createNotify({msg, auth, socket}))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }

}

export const unfollow = ({users, user, auth, socket}) => async (dispatch) => {
    
    let newUser;

    if(users.every(item => item._id !== user._id)){
        newUser = {...user, followers: DeleteData(user.followers, auth.user._id)}
    }else{
        users.forEach(item =>{
            if(item._id === user._id){
                newUser = {...item, followers: DeleteData(item.followers, auth.user._id)}
            }
        })
    }

    dispatch({type: PROFILE_TYPES.UNFOLLOW, payload: newUser})

    dispatch({
        type: GLOBALTYPES.AUTH,
        payload: {
                ...auth,
                user: {
                    ...auth.user, 
                    following: DeleteData(auth.user.following, newUser._id)
                }
        }
    })


    try {
        const res = await patchDataAPI(`user/${user._id}/unfollow`, null, auth.token)
        socket.emit('unFollow', res.data.newUser)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'ha comenzado a seguirte.',
            recipients: [newUser._id],
            url: `/profile/${auth.user._id}`,
        }

        dispatch(removeNotify({msg, auth, socket}))
        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }

}
