import React, {createContext, useState, useEffect} from 'react'
import ProductsAPI from './api/ProductsAPI'
import AnunciosAPI from './api/AnunciosAPI'
import UserAPI from './api/UserAPI'
import PostAPI from './api/PostAPI'
import PublicidadAPI from './api/PublicidadAPI'

import CategoriesAPI from './api/CategoriesAPI'
import axios from 'axios'


export const GlobalState = createContext()

export const DataProvider = ({children}) =>{
    const [token, setToken] = useState(false)

    
/*
    useEffect(() =>{
        const firstLogin = localStorage.getItem('firstLogin')
        if(firstLogin){
            const refreshToken = async () =>{
                const res = await axios.get('/user/refresh_token')
        
                setToken(res.data.accesstoken)
    
                setTimeout(() => {
                    refreshToken()
                }, 10 * 60 * 1000)
            }
            refreshToken()
        }
    },[])
*/

 useEffect(() =>{
        const refreshtoken = async () => {
            const res = await axios.get('/api/refresh_token')
            
            setToken(res.data.accesstoken)

            setTimeout(() => {
                refreshtoken()
            }, 10 * 60 * 1000)
        }
        refreshtoken()
    }, [])

    const state = {
        token: [token, setToken],
        productsAPI: ProductsAPI(),
        anunciosAPI: AnunciosAPI(),
        userAPI: UserAPI(token),
        postAPI: PostAPI(),
        categoriesAPI: CategoriesAPI(),
        publicidadAPI: PublicidadAPI()
    } 
    
    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}